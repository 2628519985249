import { useTranslation } from 'react-i18next';
import { SingleColumnTable } from './single-column-table';

export const CargoInsuranceExcludedCommoditiesTable = () => {
	const { t } = useTranslation();

	const column1 = [
		{ text: t('Ammunition, weapons, explosives') },
		{ text: t('Antiques, works of art and silverware') },
		{
			text: t(
				'Any means of non-cash payment (e.g. cheques, ready to use credit-cards, fiscal stamps, securities, negotiable instruments, treasury notes and the like)',
			),
		},
		{
			text: t(
				'Any cargo whilst being transported under its own power or whilst being towed on its own axle (e.g. motor vehicles, motor cycles, caravans, horse boxes, trailered boats)',
			),
		},
		{ text: t('Blood and/or blood plasma') },
		{ text: t('Bulk dry and bulk liquid goods, including those carried in flexitanks') },
		{ text: t('Bullion and all precious metals') },
		{ text: t('Cement, cement products') },
		{ text: t('Cigarettes, cigars and other finished tobacco products') },
		{ text: t('Dangerous goods class 1, 6.2, 7, 9, UN3111-UN3120, UN3231-UN3240, UN3507') },
		{ text: t('Designs, patterns, plans, deeds, manuscripts and all other documents') },
	];

	const column2 = [
		{ text: t('Furs, fur garments') },
		{ text: t('Household goods & personal effects') },
		{ text: t('Jewelry') },
		{ text: t('Livestock, bloodstock, living creatures & living botanicals') },
		{ text: t('Loose precious gemstones') },
		{ text: t('Money of every description (e.g. cash, bank notes, coins, currency notes)') },
		{ text: t('Perishable & temperature sensitive commodities') },
		{ text: t('Prototypes and one of a kind interests') },
		{ text: t('Stamps, tickets and the like') },
		{ text: t('Traded metals such as copper, tin, etc. in pure form') },
		{ text: t('Unpacked / unprotected steel (such as pipes, coils, bars, sheet, etc.)') },
	];

	return (
		<div className='w-full h-full flex items-start gap-[16px]'>
			<SingleColumnTable dataSource={column1} />

			<SingleColumnTable dataSource={column2} />
		</div>
	);
};
