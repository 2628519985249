import iconFactory from './icon-factory';

const Svg = () => (
	<svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M0 4.5C0 2.29086 1.79086 0.5 4 0.5H20C22.2091 0.5 24 2.29086 24 4.5V20.5C24 22.7091 22.2091 24.5 20 24.5H4C1.79086 24.5 0 22.7091 0 20.5V4.5Z'
			fill='#80BDFF'
		/>
		<path
			d='M9.02 3.33998L3.63 7.53998C2.73 8.23998 2 9.72998 2 10.86V18.27C2 20.59 3.89 22.49 6.21 22.49H17.79C20.11 22.49 22 20.59 22 18.28V11C22 9.78998 21.19 8.23998 20.2 7.54998L14.02 3.21998C12.62 2.23998 10.37 2.28998 9.02 3.33998Z'
			stroke='white'
			stroke-width='1.5'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
		<path
			d='M12 18.49V15.49'
			stroke='white'
			stroke-width='1.5'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
	</svg>
);

export default iconFactory(Svg);
