import React, { createContext, useContext } from 'react';
import { cacheContext } from '../cache';
import { screenContext } from '../screen';
import { userContext } from '../user';

const global = {
	userContext,
	cacheContext,
	screenContext,
};

export const globalContext = createContext(global);

export const GlobalContext: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
	return <globalContext.Provider value={global}>{children}</globalContext.Provider>;
};

export const useGlobalContext = () => useContext(globalContext);
