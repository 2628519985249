import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetQuoteListProps, GetQuoteListRes } from './interface';

export * from './interface';

export const getQuoteList = async (props: GetQuoteListProps) => {
	return await axios.get<GetQuoteListRes>(`/tl/ltl/quotes`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params: props.params,
	});
};
