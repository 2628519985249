import { createContext, useContext } from 'react';
import { prepayOrder } from './prepay-order.context';

export const prepayOrderContext = createContext(prepayOrder);

export const OrderContext: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
	return <prepayOrderContext.Provider value={prepayOrder}>{children}</prepayOrderContext.Provider>;
};

export const usePrepayOrderContext = () => useContext(prepayOrderContext);
