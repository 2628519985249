import React from 'react';
import { Marker, Popup } from 'react-map-gl';
import DestinationIcon from '@/icons/destination-icon';
import clsx from 'clsx';
import { Point } from '../../type';

export default ({ point, opacity = false }: { point: Point; opacity?: boolean }) => {
	const [open, setOpen] = React.useState(false);

	return (
		<>
			<Marker longitude={point.longitude} latitude={point.latitude} color='red'>
				<div onClick={() => setOpen(!open)}>{point.icon ?? <DestinationIcon />}</div>
			</Marker>
			<Popup
				offset={[0, -25] as [number, number]}
				anchor='center'
				longitude={point.longitude}
				latitude={point.latitude}
				closeButton={false}
				closeOnClick={false}
				closeOnMove={false}
				className={clsx('to-city-popup bg-primary-dark-01', { 'opacity-50': opacity })}
			>
				<div className='cursor-pointer' onClick={() => setOpen(!open)}>
					{point.name}
				</div>
			</Popup>
			{!!point.popup && open && (
				<Popup
					offset={[0, -75] as [number, number]}
					anchor='center'
					longitude={point.longitude}
					latitude={point.latitude}
					closeOnMove={false}
				>
					{point.popup}
				</Popup>
			)}
		</>
	);
};
