import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { useLTLContext } from '@/services/contexts';
import { generateNumberOfUnits } from '@/services/contexts/ltl/ltl.context';
import { observer } from 'mobx-react-lite';
import { ItemTitle } from './item-title';

export const ShipmentNumberOfUnits = observer(() => {
	const { t } = useTranslation();

	const ltlContext = useLTLContext();

	return (
		<ItemTitle
			content={t(`Number of units`)}
			value={
				<Typography.Text ellipsis>
					{generateNumberOfUnits(ltlContext.stepOneFormValues.items)}
				</Typography.Text>
			}
		/>
	);
});
