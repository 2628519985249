import { createContext, useContext } from 'react';
import { billingCode } from './billing-code.context';

export const billingCodeContext = createContext(billingCode);

export const BillingCodeContext: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
	return <billingCodeContext.Provider value={billingCode}>{children}</billingCodeContext.Provider>;
};

export const useBillingCodeContext = () => useContext(billingCodeContext);
