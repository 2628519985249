import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { AddProjectModal } from '@/components/add-project';
import { useAddProjectModal } from '@/components/add-project/use-add-project-modal';
import { CreateProjectPanel, useCreateProjectModal } from '@/components/create-project-panel';

interface StartProjectPanelProps {
	order: {
		id: number;
		mbl_number: string;
		port_of_discharge: {
			id: number;
			name: string;
		};
		final_port: {
			id: number;
			name: string;
		};
		warehouse: {
			id: number;
			name: string;
		};
		containers: {
			id: number;
			number: string;
		}[];
	};
}

/**
 * ! 传进来的 order 只有 final_port_id，没有 name，需要确认
 * final_port 是 warehouse，可以搜索获得
 */

export const StartProjectPanel = ({ order }: StartProjectPanelProps) => {
	const { t } = useTranslation();

	const { openingAddProjectModal, openProjectModal, closeProjectModal } = useAddProjectModal();

	const { openingCreateProjectModal, openCreateProjectModal, closeCreateProjectModal } =
		useCreateProjectModal();

	return (
		<div className='box-border p-[36px_48px] w-[412px] flex flex-col items-center rounded-[16px] border-[2px] border-solid border-primary-light-01 shadow-[0px_4px_10px_0px_#0000000D]'>
			<div className=' mb-[6px] h4-sb'>{t(`Start with project?`)}</div>
			<div className=' mb-[29px] foot-note-1-r text-center'>
				{t(
					`You can orgnaize your orders by projects, but you can still choose to view you orders independently!`,
				)}
			</div>
			<Button
				type='primary'
				className='mb-[12px] w-[215px] body-3-b text-white'
				onClick={openCreateProjectModal}
			>
				{t(`Create a new project`)}
			</Button>
			<Button
				onClick={openProjectModal}
				className='w-[215px] border-primary-regular body-3-m text-primary-regular'
			>
				{t(`Add to a current project`)}
			</Button>

			{openingCreateProjectModal && (
				<CreateProjectPanel
					type='create'
					open={openingCreateProjectModal}
					onClose={closeCreateProjectModal}
					defaultValue={{
						port_of_discharges: [
							{
								id: order.port_of_discharge.id,
								name: order.port_of_discharge.name,
							},
						],
						final_ports: [
							{
								id: order.final_port.id,
								name: order.final_port.name,
							},
						],
						warehouses: [
							{
								id: order.warehouse.id,
								name: order.warehouse.name,
							},
						],
						containers: order.containers.map((c) => ({
							id: c.id,
							number: c.number,
							mbl_number: order.mbl_number,
							port_of_discharge: {
								id: order.port_of_discharge.id,
								name: order.port_of_discharge.name,
							},
							final_port: { id: order.final_port.id, name: order.final_port.name },
							warehouse: { id: order.warehouse.id, name: order.warehouse.name },
						})),
						orderId: [order.id],
					}}
				/>
			)}
			<AddProjectModal
				modalOptions={{
					open: openingAddProjectModal,
				}}
				orderIds={[order.id]}
				onClose={closeProjectModal}
			/>
		</div>
	);
};
