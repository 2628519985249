import dayjs from 'dayjs';

export const generatePreMonthIndex = (nums: number) => {
	// 获取当前日期
	let current = dayjs();

	// 存储月份的数组
	const months: string[] = [];

	// 获取当前月份及前五个月
	for (let i = 0; i < nums; i++) {
		// 将月份名称添加到数组中
		months.unshift(current.format('MMM'));
		// 减少一个月
		current = current.subtract(1, 'month');
	}

	return months;
};
