import { useFTLContext } from '@/services/contexts';
import { observer } from 'mobx-react-lite';
import { MapBox } from '@/pages/search-rate/components/mapbox';

export const ShipmentInfoMap = observer(() => {
	const ftlContext = useFTLContext();

	return (
		<div className='w-full h-full bg-primary-dark-01'>
			<MapBox
				start={ftlContext.pickupAddress.city}
				to={ftlContext.destinationAddress.city}
				components={{ distance: true }}
			/>
		</div>
	);
});
