import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PossibleCharge } from '@/interfaces/possible-charges.interface';
import { Rate } from '@/interfaces/rate.interface';
import { Scenario } from '@/interfaces/scenario.interface';
import clsx from 'clsx';
import _ from 'lodash';
import { OriginalRate } from '@/pages/search-rate/search-rate-result/components/fees/original-rate';
import RenderPossibleChargeNameOrCode from '@/pages/search-rate/search-rate-result/components/possible-charges/render-possible-charge-name-or-code';

interface FeeTableProps {
	scenarios: Scenario<PossibleCharge>[];
	className?: string;
}

export default ({ scenarios, className }: FeeTableProps) => {
	const { t } = useTranslation();

	const bestScenario = scenarios?.find((s) => s.name.startsWith('Best Scenario'));
	const averageScenario = scenarios?.find((s) => s.name.startsWith('Average Scenario'));

	const mergedScenarios = useMemo(() => {
		if (!bestScenario || !averageScenario) return [];
		const bestCharges = bestScenario.possible_charges;
		const averageCharges = averageScenario.possible_charges;

		const res: {
			name: string;
			code: string;
			best: PossibleCharge | null;
			average: PossibleCharge | null;
		}[] = [];

		bestCharges?.forEach((charge) => {
			const averageCharge = averageCharges.find((c) => c.name === charge.name) ?? null;
			res.push({
				name: charge.name,
				code: charge.code,
				best: charge,
				average: averageCharge,
			});
		});

		const bestNames = bestCharges?.map((c) => c.name);
		averageCharges?.forEach((charge) => {
			if (bestNames.includes(charge.name)) return;

			res.push({
				name: charge.name,
				code: charge.code,
				best: null,
				average: charge,
			});
		});

		return res;
	}, [bestScenario, averageScenario]);

	const rates = useMemo(() => {
		if (!bestScenario || !averageScenario) return [];
		const bestCharges = bestScenario.rates;
		const averageCharges = averageScenario.rates;

		const res: { name: string; best: Rate | null; average: Rate | null }[] = [];

		bestCharges.forEach((charge) => {
			const averageCharge = averageCharges.find((c) => c.name === charge.name) ?? null;
			res.push({
				name: charge.name,
				best: charge,
				average: averageCharge,
			});
		});

		const bestNames = bestCharges.map((c) => c.name);
		averageCharges.forEach((charge) => {
			if (bestNames.includes(charge.name)) return;

			res.push({
				name: charge.name,
				best: null,
				average: charge,
			});
		});

		return res;
	}, []);

	const bestAmount = bestScenario?.total_rate ?? '-';
	const averageAmount = averageScenario?.total_rate ?? '-';

	return (
		<div className={clsx('text-[12px]', className)}>
			{/* header */}
			<div className='flex mb-[18px] h-[14px] font-[700]'>
				<div className='flex-grow-0 w-[208px] h-full'>
					{t('Charges')}
					<span className='ml-[4px] font-[400] text-[#747578] underline'>
						{/* {t('Truck Conditions')} */}
					</span>
				</div>
				<div className=' flex-grow pr-[32px] w-[100px] h-full text-right'>{t('Best')}</div>
				<div className=' flex-grow-0  w-[160px] h-full text-right'>{t('Average')} </div>
			</div>
			{/* base rate */}
			{rates.map((s) => {
				return (
					<div className='flex mb-[8px] h-[14px] text-[#007BFF] font-[700]'>
						<div className='flex-grow-0 w-[208px] h-full'>{t(s.name)}</div>
						<div className=' flex-grow pr-[32px] w-[100px] h-full text-right'>
							${s.best?.rate}
							{s.best?.original_rate && s.best?.rate != s.best?.original_rate && (
								<span className='ml-1'>
									<OriginalRate originalRate={`$${s.best?.original_rate}`} />
								</span>
							)}
						</div>
						<div className=' flex-grow-0  w-[160px] h-full text-right'>
							${s.average?.rate}
							{s.average?.original_rate && s.average?.rate != s.average?.original_rate && (
								<span className='ml-1'>
									<OriginalRate originalRate={`$${s.average?.original_rate}`} />
								</span>
							)}
						</div>
					</div>
				);
			})}
			{/* possible charges */}
			{mergedScenarios.map((scenario) => {
				return (
					<div className='flex mb-[8px] h-[14px]'>
						<div className='flex-grow-0 w-[208px] h-full'>
							<RenderPossibleChargeNameOrCode nameOrCode={scenario.code} />
						</div>
						<div className=' flex-grow pr-[32px] w-[100px] h-full text-right'>
							{scenario.best?.rate_text ? `$${scenario.best?.rate_text}` : ''}
						</div>
						<div className=' flex-grow-0  w-[160px] h-full text-right'>
							{scenario.average?.rate_text ? `$${scenario.average?.rate_text}` : ''}
						</div>
					</div>
				);
			})}
			{/* amount */}
			<div className='h-[1px] bg-[#E7E7E7]' />
			<div className='flex mt-[17px] h-[14px] foot-note-1-b'>
				<div className='flex-grow-0 w-[208px] h-full'></div>
				<div className=' flex-grow pr-[32px] w-[100px] h-full text-right'>
					<span className='mr-[41px]'>{t('Estimated Total')}:</span>${bestAmount}
				</div>
				<div className=' flex-grow-0 w-[160px] h-full text-right'>${averageAmount}</div>
			</div>
		</div>
	);
};
