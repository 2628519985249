import { useTranslation } from 'react-i18next';
import ProjectHomeIcon from '@/icons/project-home-icon';
import { ClockCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

export const ProjectsHeader = () => {
	const { t } = useTranslation();

	return (
		<div
			style={{
				borderBottom: '1px solid #D0D4E4',
			}}
			className='flex flex-col justify-center p-[12px_24px] w-full h-[96px] bg-white'
		>
			<div className='flex'>
				<ProjectHomeIcon className='mr-[8px]' />
				<div className='h3-b text-primary-dark-01'>{t(`Dashboard Home`)}</div>
			</div>
			<div className='flex body-4-b text-grey-01'>
				<ClockCircleOutlined className='mr-[4px]' />
				<span>{dayjs().format('MMM D, YYYY')}</span>
			</div>
		</div>
	);
};
