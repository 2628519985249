import React from 'react';
import warehouseActiveSvg from '@/assets/warehouse-active.svg';
import warehouseSvg from '@/assets/warehouse.svg';

const WareHouseIcon = ({
	active,
	...props
}: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
	active?: boolean;
}) => {
	return <img {...props} src={active ? warehouseActiveSvg : warehouseSvg} />;
};

export default WareHouseIcon;
