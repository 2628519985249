import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { CreateWarehouseWithCheck, CreateWarehouseWithCheckRes } from './interface';

export const createWarehouseWithCheck = async (props: CreateWarehouseWithCheck) => {
	return await axios.post<CreateWarehouseWithCheckRes>(`/newWarehousesWithCheck`, props.data, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
