import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Input, InputRef } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useBoolean, useSafeState } from 'ahooks';
import clsx from 'clsx';
import { Gap } from '@/components/gap';
import { apiHooks } from '@/hooks/apis';

type Option = { label: string; value: number };

export const WarehouseFilter = ({
	selectedValue,
	setSelectedValue,
}: {
	selectedValue: Option[];
	setSelectedValue: (v: { label: string; value: number }[]) => void;
}) => {
	const { t } = useTranslation();

	const selectedIds = selectedValue.map((i) => i.value);

	const { runAsync, data } = apiHooks.useSearchWarehouses({
		debounceWait: 500,
	});

	const options: Option[] = data.map((i) => ({
		label: i.name,
		value: i.id,
	}));

	const [isFocusing, { toggle, setTrue: openOptions }] = useBoolean(false);

	const [inputValue, setInputValue] = useSafeState(t(`Warehouse`));

	const inputRef = useRef<InputRef>(null);

	const handleChangeInputValue = (v: string) => {
		setInputValue(v);
	};

	const handleItemClick = (option: Option) => {
		const isSelected = selectedIds.includes(option.value);

		if (isSelected) {
			setSelectedValue(selectedValue.filter((v) => v.value !== option.value));
		} else {
			setSelectedValue([...selectedValue, option]);
		}
	};

	useEffect(() => {
		if (inputValue.length < 2) return;
		runAsync({
			params: {
				keyword: inputValue,
				page: 1,
				per_page: 20,
			},
		});
	}, [inputValue, runAsync]);

	return (
		<div
			className={clsx(
				'w-[178px] h-[30px] overflow-hidden bg-white',
				isFocusing && 'border-[1px] border-solid border-primary-regular rounded-[8px]',
			)}
		>
			{/* input */}
			<div className=' box-border p-[0_16px] w-[full] h-full flex items-center body-4-m text-black'>
				{isFocusing ? (
					<Input
						ref={inputRef}
						variant='borderless'
						value={inputValue}
						className='w-[100px] grow'
						onChange={(e) => handleChangeInputValue(e.target.value)}
					/>
				) : (
					<div
						className='w-[100px] grow whitespace-nowrap'
						onClick={() => {
							openOptions();
						}}
					>
						{t(`Warehouse`)}
					</div>
				)}

				<div className=' cursor-pointer' onClick={toggle}>
					{isFocusing ? <UpOutlined /> : <DownOutlined />}
				</div>
			</div>

			{/* result */}
			{isFocusing && (
				<div className='z-50 foot-note-1-r max-h-[200px] overflow-y-scroll overflow-x-hidden bg-white'>
					{options
						.filter((o) => o.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()))
						.map((o) => (
							<div
								className={clsx(
									`box-border p-[0_16px] min-h-[32px] w-[178px] flex items-center hover:bg-primary-light-05 cursor-pointer`,
									selectedIds.includes(o.value) && 'bg-primary-light-05',
								)}
								onClick={() => handleItemClick(o)}
							>
								<Checkbox checked={selectedIds.includes(o.value)} />
								<Gap width='12px' />
								<div className='grow shrink overflow-hidden break-words'>{o.label}</div>
							</div>
						))}
				</div>
			)}
		</div>
	);
};
