import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetUserByTokenRes } from './interface';

export const getUserByToken = async () => {
	return await axios.get<GetUserByTokenRes>(`/user`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
