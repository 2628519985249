import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { DeleteFileProps, DeleteFileRes } from './interface';

export const deleteFile = async (props: DeleteFileProps) => {
	const { documentId, draftDocumentId, orderId } = props.path;
	const url =
		`/documents/${documentId}` +
		(orderId ? `/order_customer/${orderId}` : `/draft_documents/${draftDocumentId}`);

	return await axios.delete<DeleteFileRes>(url, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
