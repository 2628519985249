import iconFactory from './icon-factory';

const Svg = () => (
	<svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M2 5.32547C2 4.30648 2.82607 3.48043 3.84506 3.48047L9.62224 3.48066C10.1071 3.48068 10.5725 3.67159 10.9178 4.01208L12.2603 5.33625C12.2893 5.36486 12.3284 5.3809 12.3692 5.3809L19.2393 5.38069C20.2583 5.38066 21.0844 6.20671 21.0844 7.22569V9.34602C21.0844 9.81271 20.7061 10.191 20.2394 10.191C19.7727 10.191 19.3944 9.81271 19.3944 9.34602V7.22569C19.3944 7.14009 19.325 7.07069 19.2394 7.07069L12.3692 7.07089C11.8843 7.07091 11.4188 6.88 11.0736 6.53948L9.73102 5.21531C9.70201 5.1867 9.66292 5.17067 9.62218 5.17066L3.84501 5.17047C3.7594 5.17047 3.69 5.23986 3.69 5.32547V19.7407C3.69 19.7685 3.69733 19.7946 3.71015 19.8171L5.68833 12.022C5.7038 11.955 5.72725 11.8909 5.75764 11.831C5.81487 11.7177 5.89594 11.621 5.99285 11.5457C6.08971 11.4702 6.20345 11.4154 6.32744 11.3878C6.39296 11.3731 6.46075 11.3661 6.52954 11.3676H20.4082C21.6112 11.3676 22.4924 12.5004 22.1965 13.6665L20.3733 20.8509C20.3208 21.0578 20.1961 21.2274 20.033 21.3394C19.8801 21.4916 19.6694 21.5857 19.4367 21.5857H3.845C2.82604 21.5857 2 20.7596 2 19.7407V5.32547ZM18.8722 19.8957L20.5585 13.2508C20.5833 13.1528 20.5093 13.0576 20.4082 13.0576H7.1691L5.4338 19.8957H18.8722Z'
			fill='#007BFF'
		/>
	</svg>
);

export default iconFactory(Svg);
