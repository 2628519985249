import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal as AntdModal, Button } from 'antd';
import RecommendRoutesImg from '@/assets/recommend-routes.jpg';
import { getRecommendRates } from '@/services/apis';
import { GetRecommendRatesData } from '@/services/apis/get-recommend-rates/interface';
import { RecommendRoutesModalProps } from './type';

const modalContentStyle: React.CSSProperties = {
	padding: '26px 68px 22px 76px',
};

export default ({
	toCityId,
	intermodalRegionId,
	onRateClick,
	onOk,
	open,
	...props
}: RecommendRoutesModalProps) => {
	const { t } = useTranslation();

	const [rates, setRates] = useState<GetRecommendRatesData[]>([]);

	useEffect(() => {
		if (!intermodalRegionId || !toCityId) return;
		getRecommendRates({
			params: { intermodal_region_id: intermodalRegionId, to_city_id: toCityId },
		}).then((res) => {
			setRates(res.data.data);
		});
	}, [toCityId, intermodalRegionId]);

	return (
		<AntdModal {...props} width={633} bodyStyle={modalContentStyle} open={open} footer={null}>
			<div className='text-center'>
				<div className='mb-[28px]'>
					<img src={RecommendRoutesImg} width={289} />
				</div>

				<div className='text-[18px] text-[#555770]'>
					{t(
						'Sorry, we don’t have the rate you’re looking for yet Here are some recommended routes',
					)}
				</div>

				<div className='mb-[46px] font-[500] text-[14px] text-[#28293D]'>
					{rates &&
						rates.length > 0 &&
						rates.map((rate) => (
							<div
								className='mb-[12px] h-[40px] leading-[40px] bg-[#E6F0FA] cursor-pointer'
								onClick={() => onRateClick(rate)}
							>
								<strong>{`${rate.intermodal_region?.name} -> ${rate.to_city.full_name}`}</strong>
							</div>
						))}
				</div>

				<div className='flex justify-end'>
					<Button type='default' className='h-[48px] font-[500] text-[16px]' onClick={onOk}>
						{t('Request rates')}
					</Button>
				</div>
			</div>
		</AntdModal>
	);
};
