import { useTranslation } from 'react-i18next';
import { LTLAddressTypeLabelMap } from '@/interfaces/ltl-address-type.enum';
import { pickupAccesorials } from '@/interfaces/quote.interface';
import { useLTLContext } from '@/services/contexts';
import { observer } from 'mobx-react-lite';
import { LiftGateTooltip } from './liftgate-tooltip';

export const ShipmentInfoPickupAddress = observer(() => {
	const { t } = useTranslation();

	const ltlContext = useLTLContext();

	const pickupAddress = ltlContext.pickupAddress;

	const pickupAddressType = ltlContext.pickUpAddressType;

	const pickupAccessorials = ltlContext.stepOneFormValues.pickup_accessorials ?? [];

	return (
		<div className='flex flex-col gap-[4px] h-full'>
			<div className='h4-b'>{`${pickupAddress.city.full_name.split(',').slice(0, 2).join(',')} ${
				pickupAddress.zipcode
			}`}</div>
			<div className='grow body-3-r'>{t(LTLAddressTypeLabelMap[pickupAddressType])}</div>
			<div className='body-3-r'>
				{pickupAccessorials
					.map((k) => pickupAccesorials.find((i) => i.key === k))
					.filter((i) => !!i)
					.map((i) =>
						i.key === 'liftgate' ? (
							<span className='flex items-center gap-[4px]'>
								{i.name}
								{ltlContext.calcIsLiftGateRequired() && <LiftGateTooltip />}
							</span>
						) : (
							<span>{i.name}</span>
						),
					)}
			</div>
		</div>
	);
});
