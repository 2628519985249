import React from 'react';
import destinationSvg from '@/assets/destination.svg';

const DestinationIcon = (
	props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
) => {
	return <img {...props} src={destinationSvg} />;
};

export default DestinationIcon;
