import dayjs, { Dayjs } from 'dayjs';

export const formatMMDDYYYYDate = (date: string | Dayjs | undefined | null) => {
	if (!date) return '';
	return dayjs(date).format('MM/DD/YYYY');
};

export const formatMMMDYYYYDate = (date: string | Dayjs | undefined | null) => {
	if (!date) return '';
	return dayjs(date).format('MMM D, YYYY');
};
