import { Divider } from 'antd';
import { Order } from '@/interfaces/order.interface';
import { observer } from 'mobx-react-lite';
import DocumentsHeader from './documents-header';
import DocumentsTable from './documents-table';

interface DocumentsProps {
	order: Order;
	fetchData: () => void;
}

export default observer(({ order, fetchData }: DocumentsProps) => {
	return (
		<div>
			<DocumentsHeader order={order} fetchData={fetchData} />
			<Divider className='mt-[16px] mb-[16px]' />
			<DocumentsTable order={order} fetchData={fetchData} />
		</div>
	);
});
