import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Radio } from 'antd';
import { LiveOrDrop } from '@/interfaces/live-or-drop.enum';
import { useGlobalContext, useSearchRateContext } from '@/services/contexts';
import { observer } from 'mobx-react-lite';
import { DeliveryTypeSelectLabel } from '@/pages/search-rate/book/components/route-info/components/delivery-type-select';
import { TerminalDetail } from './terminal-detail';

export const Fees = observer(() => {
	const { t } = useTranslation();

	const searchRateContext = useSearchRateContext();
	const { userContext } = useGlobalContext();

	const searchDatas = searchRateContext.searchDatas;

	const [selectedTerminal, setSelectedTerminal] = useState<string>('All');

	const maxFee = searchDatas
		? Math.max(
				...searchDatas.map((data) =>
					Math.max(...data.scenarios.map((scenario) => Number(scenario.total_rate))),
				),
		  )
		: 0;

	return (
		<div className='mt-[26px] pl-[8px] pr-[8px]'>
			{/* head */}
			<div className='flex justify-between items-center mb-[24px]'>
				<div className='inline-block pl-[24px] pr-[32px] font-[700] text-[16px] leading-[16px] tracking-[0.5px]'>
					{t('Estimated Total with Additional Terminal Fees')}
				</div>
				{userContext.getUserInfo()?.__enable_drop_feature && (
					<div className='flex space-x-1 items-center'>
						<div className='font-[700] text-[12px] leading-[14px] tracking-[0.5px]'>
							<DeliveryTypeSelectLabel />
						</div>
						<Radio.Group
							options={[
								{ label: t('Live'), value: LiveOrDrop.Live },
								{ label: t('Drop'), value: LiveOrDrop.Drop },
							]}
							onChange={(e) => (searchRateContext.liveOrDrop = Number(e.target.value))}
							value={searchRateContext.liveOrDrop}
							optionType='button'
							buttonStyle='solid'
						/>
					</div>
				)}
			</div>
			{/* second head */}
			<div className='flex mb-[26px] pl-[24px] pr-[32px]'>
				<div className='flex-grow-0 flex-shrink-0 w-[240px]'>
					<div className='inline-block font-[700] text-[12px] leading-[14px] tracking-[0.5px]'>
						{t('Service Provider')} / {t('Terminal')}
					</div>
					{/* <div className='flex mt-[5px]'>
						{[t('All'), t('Port'), t('Ramp')].map((i) => {
							const isSelected = selectedTerminal === i;
							return isSelected ? (
								<div
									className={`mr-[4px] pt-[1px] pb-[1px] pl-[9px] pr-[9px] h-[20px] w-fit border-[1px] border-solid border-[#004A99] rounded-[4px] bg-[#004A99] text-[#ffffff] text-[12px]`}
									onClick={() => setSelectedTerminal('All')}
								>
									{i}
								</div>
							) : (
								<div
									className={`mr-[4px] pt-[1px] pb-[1px] pl-[9px] pr-[9px] h-[20px] w-fit border-[1px] border-solid border-[#004A99] rounded-[4px] bg-[#ffffff] text-[#004A99] text-[12px]`}
									onClick={() => setSelectedTerminal(i)}
								>
									{i}
								</div>
							);
						})}
					</div> */}
				</div>
				<div className='flex flex-col flex-grow'>
					<div className='inline-block font-[700] text-[12px] leading-[14px] tracking-[0.5px]'>
						{t('Range of Estimated Total')}
					</div>
					<div className='inline-block font-[700] text-[12px] leading-[14px] tracking-[0.5px]'>
						{`(${t('Best to Average')})`}
					</div>
				</div>
				<div className='flex-grow-0 flex-shrink-0 w-[160px] pl-[38px]'>
					<div className='inline-block font-[700] text-[12px] leading-[14px] tracking-[0.5px]'>
						{t('Select a Terminal')}
					</div>
					<div className='inline-block font-[700] text-[12px] leading-[14px] tracking-[0.5px]'>
						{t('If Known')}
					</div>
				</div>
			</div>
			{/* fees list */}
			<div>
				{/* fee card map */}
				{searchDatas &&
					searchDatas.map((data, index) => {
						return <TerminalDetail index={index} searchData={data} maxFee={maxFee} />;
					})}
			</div>
		</div>
	);
});
