import { MapRef } from 'react-map-gl';
import { calcCorners } from './calc-corners';

/**
 * 缩放到给定坐标的范围
 * @param coordinates
 * @returns
 */
export const fitCoordinates = (
	mapRef: MapRef,
	coordinates: [number | undefined, number | undefined][],
	cachedKey: string,
) => {
	coordinates = coordinates.filter((c) => c[0] !== undefined && c[1] !== undefined);

	const corners =
		coordinates.length === 0
			? [
					[-1, -1],
					[-1, -1],
			  ]
			: calcCorners(coordinates);

	const cacheKey = corners.flat().join('-');

	if (cacheKey === cachedKey) return cacheKey;

	// 如果没有坐标，展示完整地图
	if (coordinates.length === 0) {
		mapRef?.flyTo({
			center: [-96, 37.8],
			zoom: 4,
		});
		return cacheKey;
	}

	// 如果只有一组坐标，移动到该点
	if (coordinates.length === 1) {
		mapRef?.flyTo({
			// @ts-ignore
			center: coordinates[0],
		});
		return cacheKey;
	}

	// 如果有多组坐标，全部展示
	if (coordinates.length > 1) {
		// @ts-ignore
		mapRef?.fitBounds(corners, { padding: { top: 30, bottom: 30, left: 30, right: 30 } });
		return cacheKey;
	}

	return cacheKey;
};
