import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetCityByIdProps, GetCityByIdRes, SearchCityProps, SearchCityRes } from './interface';

export const searchCity = async (props: SearchCityProps) => {
	return await axios.get<SearchCityRes>(`/cities/${props.path.cityName}`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params: props.params,
	});
};

export const getCityById = async (props: GetCityByIdProps) => {
	return await axios.get<GetCityByIdRes>(`/cityById/${props.path.id}`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
