import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetContainerProps, GetContainerRes } from './interface';

export const getContainer = async (props: GetContainerProps) => {
	return await axios.get<GetContainerRes>(`/containers/${props.path.containerId}`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
