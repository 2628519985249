import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { ArchivedProjectProps, ArchivedProjectRes } from './interface';

export const archivedProject = async (props: ArchivedProjectProps) => {
	return await axios.patch<ArchivedProjectRes>(`/projects/${props.path.projectId}/archived`, null, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
