import { useTranslation } from 'react-i18next';
import { Select, SelectProps, Spin } from 'antd';
import { apiHooks } from '@/hooks/apis';

export type DestinationCityMultiSelectorProps = SelectProps;

export const DestinationCityMultiSelector = (props: DestinationCityMultiSelectorProps) => {
	const { t } = useTranslation();

	const {
		data: cities,
		loading,
		runAsync,
	} = apiHooks.useSearchCity({
		debounceWait: 500,
	});

	const options = cities.map((city) => ({
		label: city.full_name,
		value: String(city.id),
	}));

	const handleSearch = async (text: string) => {
		if (text.length < 2) return;
		runAsync({
			path: {
				cityName: text,
			},
		});
	};

	return (
		<Select
			mode='multiple'
			labelInValue
			optionFilterProp='label'
			style={{ width: '100%' }}
			// @ts-ignore
			placeholder={t('Keep blank to select all.')}
			options={options as { label: string; value: string }[]}
			tagRender={() => null}
			onSearch={handleSearch}
			notFoundContent={loading ? <Spin size='small' /> : null}
			{...props}
		/>
	);
};
