import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetFTLShipmentReq, GetFTLShipmentRes } from './interface';

export * from './interface';

export const getFTLShipment = async (props: GetFTLShipmentReq) => {
	return await axios.get<GetFTLShipmentRes>(`/ftl/shipments/${props.path.id}`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
