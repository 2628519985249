import { useMap } from 'react-map-gl';
import { Button } from 'antd';
import MinusIcon from '@/icons/minus-icon';
import PlusIcon from '@/icons/plus-icon';

export default () => {
	const { searchratemap } = useMap();

	/**
	 * 增减 zoom
	 * @param changeValue 改变量
	 * @returns
	 */
	const changeZoom = (changeValue: number) => {
		if (!searchratemap?.getMap()) return;
		const zoom = searchratemap.getZoom();
		searchratemap.setZoom(zoom + changeValue);
	};

	return (
		<div className='absolute right-[8px] bottom-[8px] flex flex-col bg-[#ffffff] rounded-[5px]'>
			<Button
				className='border-[0px]'
				icon={<PlusIcon />}
				onClick={() => {
					changeZoom(1);
				}}
			/>
			<div className=' box-border p-[0_4px] w-full h-[1px] bg-grey-05' />
			<Button
				className='border-[0px]'
				icon={<MinusIcon />}
				onClick={() => {
					changeZoom(-1);
				}}
			/>
		</div>
	);
};
