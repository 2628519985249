import clsx from 'clsx';

interface BoxProps {
	title: React.ReactNode;
	style?: React.CSSProperties;
	className?: string;
	extra?: React.ReactNode;
	children: React.ReactNode;
}

export const Box = ({ title, style, className, extra, children }: BoxProps) => (
	<div
		style={style}
		className={clsx(
			'mb-[16px] p-[24px] rounded-[7px] overflow-hidden border-[1px] border-solid border-primary-light-04',
			className,
		)}
	>
		<div className='flex justify-between body-3-b'>
			{title}
			{extra}
		</div>
		{children}
	</div>
);
