import { LiveOrDrop } from '@/interfaces/live-or-drop.enum';
import { WeightUnit } from '@/interfaces/weight-unit.enum';
import { Container } from './type';

export const emptyRecord: Partial<Container> = {
	number: undefined,
	type: undefined,
	package: undefined,
	weight: undefined,
	weight_unit: WeightUnit.KG,
	delivery_reference: undefined,
	urgent: false,
	seal_number: undefined,
	is_dg: false,
	is_overweight: false,
	is_soc: false,
	is_reefer: false,
};
