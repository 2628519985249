import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetBalanceProps } from './interface';

export const getBalance = async () => {
	return await axios.get<GetBalanceProps>(`/wallet/checkBalance`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
