import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { UpdateAgentUserProps, UpdateAgentUserRes } from './interface';

export const updateUser = async (props: UpdateAgentUserProps) => {
	return await axios.put<UpdateAgentUserRes>(`/agent/users/${props.data.id}`, props.data, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
