import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Select, Tag } from 'antd';
import CircleBlueFillIcon from '@/icons/circle-blue-fill';
import DestinationIcon from '@/icons/destination-icon';
import Exchange24pxIcon from '@/icons/exchange-24px-icon';
import { LTLAddressType } from '@/interfaces/ltl-address-type.enum';
import { destinationAccesorials, pickupAccesorials } from '@/interfaces/quote.interface';
import { useLTLContext } from '@/services/contexts';
import { useBoolean } from 'ahooks';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Gap } from '@/components/gap';
import { formItemWrapper } from '../../form-item-wrapper';
import { AddressTypeRadio } from './address-type-radio';
import { DeliveryStatement } from './delivery-statement';
import { DeselectLGModal } from './deselect-lg-modal';
import { ZipCodeSelect } from './zip-code-select';

interface ZipCodePickerProps {
	errors: {
		pickup: {
			isError: boolean;
			reason: string;
		};
		destination: {
			isError: boolean;
			reason: string;
		};
	};
}

const OptionRender = ({ value, label }: { value: string; label: string | React.ReactNode }) => {
	const { t } = useTranslation();

	const isPopular = ['appointment', 'liftgate', 'residential'].includes(value);

	if (isPopular) {
		return (
			<div className='w-full flex gap-[4px]'>
				<span>{label}</span>
				<Tag color='blue'>{t(`Popular`)}</Tag>
			</div>
		);
	}
	return <div>{label}</div>;
};

export const ZipCodePicker = observer(({ errors }: ZipCodePickerProps) => {
	const { t } = useTranslation();

	const ltlContext = useLTLContext();

	const [deselectLG, setDeselectLG] = useState<'pickup' | 'delivery' | 'none'>('none');

	return (
		<div
			style={{
				gridTemplateColumns: 'minmax(24px, 24px) minmax(528px, 528px) minmax(528px, 528px)',
			}}
			className='w-full grid items-center gap-x-[36px]'
		>
			{/* row 1 */}
			<CircleBlueFillIcon />
			<ZipCodeSelect
				placeholder={t('Pickup City') + ' / ' + t(`Zipcode`) + ' / ' + t(`Address`)}
				value={
					ltlContext.pickupAddress
						? ltlContext.pickupAddress.id !== -1
							? {
									label: ltlContext.pickupAddress?.name ?? '',
									value: `warehouse-${ltlContext.pickupAddress.id}`,
							  }
							: {
									label: ltlContext.pickupAddress?.city?.full_name_zipcode ?? '',
									value: `city-${ltlContext.pickupAddress.city.id}`,
							  }
						: undefined
				}
				style={{
					...formItemWrapper.style,
					...(errors.pickup.isError ? { borderColor: '#ff4d4f' } : {}),
				}}
				className={`${formItemWrapper.className} w-[510px] h-[42px]`}
				onSelect={(address) => {
					// @ts-ignore
					ltlContext.pickupAddress = address;
				}}
			/>
			<Form.Item name='pickup_accessorials' className='grow shrink w-[480px] overflow-hidden'>
				<Select
					placeholder={t(`Add`) + ' ' + t(`pickup accessorials`)}
					mode='multiple'
					maxTagCount='responsive'
					suffixIcon={null}
					variant='borderless'
					style={formItemWrapper.style}
					className={`${formItemWrapper.className} w-full h-[42px]`}
					options={pickupAccesorials.map((i) => ({
						label: t(i.name),
						value: i.key,
					}))}
					optionRender={(option) => (
						<OptionRender value={option.value as string} label={option.label} />
					)}
					onDeselect={(value) => {
						if (
							value === 'liftgate' &&
							ltlContext.pickUpAddressType === LTLAddressType.RESIDENTIAL
						) {
							setDeselectLG('pickup');
						}
					}}
				/>
			</Form.Item>
			{/* gap */}
			<Gap height='12px' />
			<Gap height='12px' />
			<Gap height='12px' />
			{/* row 2 */}
			<Exchange24pxIcon
				className='z-10 cursor-pointer translate-x-[1px]'
				onClick={() => {
					const temp = ltlContext.pickupAddress;
					ltlContext.pickupAddress = ltlContext.destinationAddress;
					ltlContext.destinationAddress = temp;
				}}
			/>
			<AddressTypeRadio
				value={ltlContext.pickUpAddressType}
				classNames='w-[510px]'
				onChange={(val) => {
					ltlContext.pickUpAddressType = val;

					/**
					 * 2. 如果Pickup的address type 选择为Residential，则增值服务中的Liftgate Pickup自动勾选，允许取消。点击取消时提示弹窗。
					 * 3. 如果Pickup的address type 选择为Trade Show，则增值服务中的Liftgate Pickup自动勾选，允许取消。
					 * 4. 如果先选择了Residential或Trade Show，又把地址切换为其他，则Liftgate Pickup不自动取消，但是允许手动取消。
					 */
					if (
						(val === LTLAddressType.RESIDENTIAL || val === LTLAddressType.TRADESHOW) &&
						!ltlContext.stepOneFormValues.pickup_accessorials.includes('liftgate')
					) {
						ltlContext.stepOneFormValues.pickup_accessorials = [
							...ltlContext.stepOneFormValues.pickup_accessorials,
							'liftgate',
						];
					}
				}}
			/>
			<div></div>
			{/* gap */}
			<Gap height='24px' />
			<Gap height='24px' />
			<Gap height='24px' />
			{/* row 4 */}
			<DestinationIcon />
			<div className='flex gap-[4px] w-[528px]'>
				<ZipCodeSelect
					placeholder={t('Destination City') + ' / ' + t(`Zipcode`) + ' / ' + t(`Address`)}
					value={
						ltlContext.destinationAddress
							? ltlContext.destinationAddress.id !== -1
								? {
										label: ltlContext.destinationAddress?.name ?? '',
										value: `warehouse-${ltlContext.destinationAddress.id}`,
								  }
								: {
										label: ltlContext.destinationAddress?.city?.full_name_zipcode ?? '',
										value: `city-${ltlContext.destinationAddress.city.id}`,
								  }
							: undefined
					}
					style={{
						...formItemWrapper.style,
						...(errors.destination.isError ? { borderColor: '#ff4d4f' } : {}),
					}}
					className={`${formItemWrapper.className} grow h-[42px]`}
					onSelect={(address) => {
						// @ts-ignore
						ltlContext.destinationAddress = address;
					}}
				/>
				<DeliveryStatement />
			</div>
			<Form.Item name='destination_accessorials' className='grow shrink w-[480px] overflow-hidden'>
				<Select
					placeholder={t(`Add`) + ' ' + t(`destination accessorials`)}
					mode='multiple'
					maxTagCount='responsive'
					suffixIcon={null}
					variant='borderless'
					style={formItemWrapper.style}
					className={`${formItemWrapper.className} w-full  h-[42px]`}
					options={destinationAccesorials.map((i) => ({
						label: t(i.name),
						value: i.key,
					}))}
					optionRender={(option) => (
						<OptionRender value={option.value as string} label={option.label} />
					)}
					onDeselect={(value) => {
						if (
							value === 'liftgate' &&
							ltlContext.destinationAddressType === LTLAddressType.RESIDENTIAL
						) {
							setDeselectLG('delivery');
						}
					}}
				/>
			</Form.Item>
			{/* gap */}
			<Gap height='12px' />
			<Gap height='12px' />
			<Gap height='12px' />
			{/* row 5 */}
			<div></div>
			<AddressTypeRadio
				value={ltlContext.destinationAddressType}
				classNames='w-[510px]'
				onChange={(val) => {
					ltlContext.destinationAddressType = val;

					/**
					 * 2. 如果Delivery的address type 选择为Residential，则增值服务中的Liftgate Delivery自动勾选，且不允许用户手动取消。
					 * 3. 如果Delivery的address type 选择为Trade Show，则增值服务中的Liftgate Delivery自动勾选，允许取消。
					 * 4. 如果先选择了Residential或Trade Show，又把地址切换为其他，则Liftgate Delivery不自动取消，但是允许手动取消。
					 */
					if (
						(val === LTLAddressType.RESIDENTIAL || val === LTLAddressType.TRADESHOW) &&
						!ltlContext.stepOneFormValues.destination_accessorials.includes('liftgate')
					) {
						ltlContext.stepOneFormValues.destination_accessorials = [
							...ltlContext.stepOneFormValues.destination_accessorials,
							'liftgate',
						];
					}
				}}
			/>
			<div></div>

			<DeselectLGModal
				width={'600px'}
				open={deselectLG !== 'none'}
				onOk={() => {
					deselectLG === 'pickup'
						? !ltlContext.stepOneFormValues.pickup_accessorials.includes('liftgate') &&
						  (ltlContext.stepOneFormValues.pickup_accessorials = [
								...ltlContext.stepOneFormValues.pickup_accessorials,
								'liftgate',
						  ])
						: !ltlContext.stepOneFormValues.destination_accessorials.includes('liftgate') &&
						  (ltlContext.stepOneFormValues.destination_accessorials = [
								...ltlContext.stepOneFormValues.destination_accessorials,
								'liftgate',
						  ]);
					setDeselectLG('none');
				}}
				onCancel={() => setDeselectLG('none')}
			/>
		</div>
	);
});
