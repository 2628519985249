import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetShipmentListProps, GetShipmentListRes } from '../get-shipment-list';

export const getFtlOrders = async (props: GetShipmentListProps) => {
	return await axios.get<GetShipmentListRes>(`agent/orders/ftlOrders`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params: props.params,
	});
};
