import { FTLQuote } from '@/interfaces/ftl-quote.interface';
import { FTLShipment, ShipmentTimeType } from '@/interfaces/ftl-shipment.interface';
import { Warehouse } from '@/interfaces/warehouse.interface';
import { FTLQuoteRate } from '@/services/apis';
import { Dayjs } from 'dayjs';
import { makeAutoObservable, runInAction } from 'mobx';
import calcPremium from '@/utils/calc-premium';
import { FTLDestinationAddress, FTLPickupAddress } from './interface';

const initShipmentFormValues = {
	item_description: '',
	item_units: 0,
	item_package_type: 'Pallet',
	item_weight: 0,
	item_weight_unit: 'lbs',
	customer_reference_number: '',
	pickup_number: '',
	dropoff_number: '',
	cartons: 0,

	is_insurance_entrusted: true,
	cargo_value: undefined,
};
class FTLContext {
	private _step: 1 | 2 | 3;

	private _pickupAddress?: FTLPickupAddress;

	private _destinationAddress?: FTLDestinationAddress;

	private _pickupDate: Dayjs | undefined;

	// private _selectedEquipment: { label: string; value: string } | undefined;

	private _quote?: FTLQuote;

	private _rate?: FTLQuoteRate;

	private _shipmentFormValues: {
		item_description: string;
		item_units: number;
		item_package_type: string;
		item_weight: number;
		item_weight_unit: string;

		customer_reference_number?: string;
		pickup_number?: string;
		dropoff_number?: string;

		cartons: number;

		is_insurance_entrusted: boolean;
		cargo_value: undefined | number;
	} = {
		...initShipmentFormValues,
	};

	private _savePickupAddress = false;

	private _saveDestinationAddress = false;

	private _pickupTimeWindow: {
		value: ShipmentTimeType;
		start: Dayjs | undefined;
		end: Dayjs | undefined;
		specific: Dayjs | undefined;
	} = {
		value: ShipmentTimeType.WINDOW,
		start: undefined,
		end: undefined,
		specific: undefined,
	};

	private _destinationTimeWindow: {
		value: ShipmentTimeType;
		start: Dayjs | undefined;
		end: Dayjs | undefined;
		specific: Dayjs | undefined;
	} = {
		value: ShipmentTimeType.WINDOW,
		start: undefined,
		end: undefined,
		specific: undefined,
	};

	private _shipment?: FTLShipment;

	constructor() {
		makeAutoObservable(this);
		this.init();
	}

	get step() {
		return this._step;
	}

	set step(value: typeof this._step) {
		runInAction(() => {
			this._step = value;
		});
	}

	get pickupAddress() {
		return this._pickupAddress;
	}

	set pickupAddress(value: typeof this._pickupAddress) {
		runInAction(() => {
			this._pickupAddress = value;
		});
	}

	get destinationAddress() {
		return this._destinationAddress;
	}

	set destinationAddress(value: typeof this._destinationAddress) {
		runInAction(() => {
			this._destinationAddress = value;
		});
	}

	get pickupDate() {
		return this._pickupDate;
	}

	set pickupDate(value: typeof this._pickupDate) {
		runInAction(() => {
			this._pickupDate = value;
		});
	}

	// get selectedEquipment() {
	// 	return this._selectedEquipment;
	// }

	// set selectedEquipment(value: typeof this._selectedEquipment) {
	// 	runInAction(() => {
	// 		this._selectedEquipment = value;
	// 	});
	// }

	get quote() {
		return this._quote;
	}

	set quote(value: typeof this._quote) {
		runInAction(() => {
			this._quote = value;
		});
	}

	get rate() {
		return this._rate;
	}

	set rate(value: typeof this._rate) {
		runInAction(() => {
			this._rate = value;
		});
	}

	get shipmentFormValues() {
		return this._shipmentFormValues;
	}

	set shipmentFormValues(value: typeof this._shipmentFormValues) {
		runInAction(() => {
			this._shipmentFormValues = value;
		});
	}

	get savePickupAddress() {
		return this._savePickupAddress;
	}

	set savePickupAddress(value: typeof this._savePickupAddress) {
		runInAction(() => {
			this._savePickupAddress = value;
		});
	}

	get saveDestinationAddress() {
		return this._saveDestinationAddress;
	}

	set saveDestinationAddress(value: typeof this._saveDestinationAddress) {
		runInAction(() => {
			this._saveDestinationAddress = value;
		});
	}

	get pickupTimeWindow() {
		return this._pickupTimeWindow;
	}

	set pickupTimeWindow(value: typeof this._pickupTimeWindow) {
		runInAction(() => {
			this._pickupTimeWindow = value;
		});
	}

	get destinationTimeWindow() {
		return this._destinationTimeWindow;
	}

	set destinationTimeWindow(value: typeof this._destinationTimeWindow) {
		runInAction(() => {
			this._destinationTimeWindow = value;
		});
	}

	get shipment() {
		return this._shipment;
	}

	set shipment(value: typeof this._shipment) {
		runInAction(() => {
			this._shipment = value;
		});
	}

	get premium() {
		return calcPremium(this.shipmentFormValues.cargo_value);
	}

	init() {
		this.step = 1;
		this.pickupAddress = undefined;
		this.destinationAddress = undefined;
		this.pickupDate = undefined;
		this.quote = undefined;
		this.rate = undefined;
		this.shipmentFormValues = {
			...initShipmentFormValues,
		};
		this.savePickupAddress = false;
		this.saveDestinationAddress = false;
		this.pickupTimeWindow = {
			value: ShipmentTimeType.WINDOW,
			start: undefined,
			end: undefined,
			specific: undefined,
		};
		this.destinationTimeWindow = {
			value: ShipmentTimeType.WINDOW,
			start: undefined,
			end: undefined,
			specific: undefined,
		};
		this.shipment = undefined;
	}
}

export const ftl = new FTLContext();
