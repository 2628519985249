import { useCallback } from 'react';
import { Modal, ModalProps } from 'antd';
import { Order } from '@/interfaces/order.interface';
import { Port } from '@/interfaces/port.interface';
import { ProjectCriteriaTypeEnum } from '@/interfaces/project-criteria-type.enum.ts';
import { TimeRange } from '@/interfaces/time-range.interface';
import { Warehouse } from '@/interfaces/warehouse.interface';
import { GetProjectDataItem } from '@/services/apis';
import { CreateProjectData } from '@/services/apis/create-project/interface.ts';
import { useHistoryTravel, useMemoizedFn, useSetState } from 'ahooks';
import { useCreateProject } from '@/hooks/apis/use-create-project/index.ts';
import { SuccessProjectPanel } from '../success-project-modal.tsx';
import { useSuccessProjectModal } from '../success-project-modal.tsx/use-success-add-project-modal';
import { DestinationAndWarehousePanel } from './destination-warehouse-panel';
import { FirstPanel } from './first-panel';
import './index.less';
import { IrCityPanel } from './ir-city-panel';
import { MBLPanel } from './mbl-panel';
import { PodLaPanel } from './pod-la-panel/index.tsx';
import { SecondPanel } from './second-panel';
import { CreateProjectPanelSider } from './sider';
import { TimeRangePanel } from './time-ranges-panel';

export { useCreateProjectModal } from './use-create-project-modal';

export interface CreateProjectPanelValue {
	name: string;
	description: string;
	memo: string;
	port_of_discharges: { id: number; name: string }[];
	final_ports: { id: number; name: string }[];
	warehouse_cities: { id: number; name: string }[];
	warehouses: { id: number; name: string }[];
	time_ranges: {
		final_port_eta?: TimeRange;
		created_at?: TimeRange;
	};
	containers: {
		id: number;
		number: string;
		mbl_number: string;
		port_of_discharge: Pick<Port, 'id' | 'name'>;
		final_port: Pick<Port, 'id' | 'name'>;
		warehouse: Pick<Warehouse, 'id' | 'name'>;
	}[];
	orderId?: Order['id'][];
}

/**
 * create 创建完订单后创建，需要 criteria
 * edit 编辑项目，仅需要 base info，并且 name 不可编辑
 * batch-create 批量选择订单并创建，仅需要 base info
 */
export interface CreateProjectPanelProps extends ModalProps {
	type: 'create' | 'edit' | 'batch-create';
	defaultValue?: Partial<CreateProjectPanelValue>;
	projectId?: GetProjectDataItem['id'];
	onClose: () => void;
}

type Index = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export const CreateProjectPanel = ({
	type,
	defaultValue,
	projectId,
	onClose,
	...options
}: CreateProjectPanelProps) => {
	const {
		value: index,
		setValue: setIndex,
		backLength,
		forwardLength,
		back,
		forward,
	} = useHistoryTravel<Index>(1);

	const goPre = useCallback(() => {
		if (backLength > 0) {
			back();
		} else if (index > 1) {
			setIndex((index - 1) as Index);
		}
	}, [backLength, index, back, setIndex]);

	const goNext = useCallback(() => {
		if (forwardLength > 0) {
			forward();
		} else if (index < 6) {
			setIndex((index + 1) as Index);
		}
	}, [forwardLength, index, forward, setIndex]);

	const [localValue, setLocalValue] = useSetState<Partial<CreateProjectPanelValue>>({
		name: '',
		description: '',
		memo: '',

		port_of_discharges: [],
		final_ports: [],
		warehouse_cities: [],
		warehouses: [],
		time_ranges: {},
		containers: [],
		orderId: [],
		...defaultValue,
	});

	const { openingSuccessProjectModal, openProjectModal, closeProjectModal } =
		useSuccessProjectModal();

	const { loading: createLoading, runAsync: runCreateProject } = useCreateProject();

	const createProject = useMemoizedFn(async (type: ProjectCriteriaTypeEnum) => {
		try {
			const createProjectData: CreateProjectData =
				type === ProjectCriteriaTypeEnum.ongoing
					? {
							name: localValue.name,
							type,
							description: localValue.description,
							memo: localValue.memo,
							port_of_discharge_ids: localValue.port_of_discharges.map((v) => v.id),
							final_port_ids: localValue.final_ports.map((v) => v.id),
							warehouse_city_ids: localValue.warehouse_cities.map((v) => v.id),
							warehouse_ids: localValue.warehouses.map((v) => v.id),
							time_ranges: localValue.time_ranges,
					  }
					: {
							name: localValue.name,
							type,
							description: localValue.description,
							memo: localValue.memo,
							container_ids: localValue.containers.map((v) => v.id),
					  };

			if (localValue.orderId?.[0]) {
				createProjectData.order_id = String(localValue.orderId?.[0]);
			}

			await runCreateProject({
				data: createProjectData,
			});
			openProjectModal();
		} catch (error) {
			console.log(error);
		}
	});

	return (
		<>
			<Modal
				open={true}
				footer={null}
				width={1080}
				className='create-project-panel'
				onCancel={onClose}
				{...options}
			>
				<div className='h-[820px] flex'>
					{/* side */}
					<div className='w-[380px] flex-grow-0 flex-shrink-0 bg-primary-light-04'>
						<CreateProjectPanelSider index={index} />
					</div>

					{/* main */}
					<div className='flex-grow flex-shrink overflow-hidden p-[92px_54px_48px_54px]'>
						{index === 1 && (
							<FirstPanel
								type={type}
								projectId={projectId}
								value={{
									name: localValue.name,
									description: localValue.description,
									memo: localValue.memo,
									orderId: localValue.orderId,
								}}
								onChange={(v) => setLocalValue(v)}
								onClose={onClose}
								goPre={goPre}
								goNext={goNext}
							/>
						)}
						{index === 2 && (
							<SecondPanel
								projectName={localValue.name}
								value={localValue}
								goIndex={(index) => setIndex(index as Index)}
								clearData={(type: ProjectCriteriaTypeEnum) => {
									if (type === ProjectCriteriaTypeEnum.ongoing) {
										setLocalValue({
											final_ports: [],
											warehouse_cities: [],
											warehouses: [],
											time_ranges: {},
										});
									} else {
										setLocalValue({
											containers: [],
										});
									}
								}}
								goPre={goPre}
								goNext={goNext}
							/>
						)}
						{index === 3 && (
							<PodLaPanel
								projectName={localValue.name}
								value={localValue.port_of_discharges}
								onChange={(v) => setLocalValue({ port_of_discharges: v })}
								goPre={goPre}
								goNext={goNext}
							/>
						)}
						{index === 4 && (
							<IrCityPanel
								projectName={localValue.name}
								value={localValue.final_ports}
								onChange={(v) => setLocalValue({ final_ports: v })}
								goPre={goPre}
								goNext={goNext}
							/>
						)}
						{index === 5 && (
							<DestinationAndWarehousePanel
								projectName={localValue.name}
								warehouse_cities={localValue.warehouse_cities}
								onWarehouseCitiesChange={(v) => setLocalValue({ warehouse_cities: v })}
								warehouses={localValue.warehouses}
								onWarehousesChange={(v) => setLocalValue({ warehouses: v })}
								goPre={goPre}
								goNext={goNext}
							/>
						)}
						{index === 6 && (
							<TimeRangePanel
								projectName={localValue.name}
								value={localValue.time_ranges}
								createLoading={createLoading}
								onChange={(v) => setLocalValue({ time_ranges: v })}
								goPre={goPre}
								onClick2={() => createProject(ProjectCriteriaTypeEnum.ongoing)}
							/>
						)}
						{index === 7 && (
							<MBLPanel
								projectName={localValue.name}
								values={localValue}
								createLoading={createLoading}
								onChange={(v) => setLocalValue({ containers: v })}
								goPre={goPre}
								onClick2={() => createProject(ProjectCriteriaTypeEnum.manual)}
							/>
						)}
					</div>
				</div>
			</Modal>
			<SuccessProjectPanel
				open={openingSuccessProjectModal}
				onClose={() => {
					closeProjectModal();
					onClose();
				}}
			/>
		</>
	);
};
