import { useTranslation } from 'react-i18next';
import { Empty, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import ShippingTruckIcon from '@/icons/shipping-truck-icon';
import { OrderFile } from '@/interfaces/file.interface';
import { Order } from '@/interfaces/order.interface';
import { observer } from 'mobx-react-lite';
import Actions from './actions';
import PreviewLabel from './preview-label';

interface DocumentsTableProps {
	order: Order;
	fetchData: () => void;
}

const EmptyContent = () => {
	const { t } = useTranslation();

	return (
		<Empty
			imageStyle={{ display: 'none' }}
			description={
				<div className='flex-center'>
					<ShippingTruckIcon className='text-grey-03' />
					<span className='h-4-m text-grey-02'>{t('There’s nothing here.')}</span>
				</div>
			}
		/>
	);
};

export default observer(({ order, fetchData }: DocumentsTableProps) => {
	const { t } = useTranslation();

	const files = order.files;

	const columns: ColumnsType<OrderFile> = [
		{
			dataIndex: ['custom_properties', 'document_type'],
			title: t('Type'),
		},
		{
			dataIndex: ['file_name'],
			title: t('File Name'),
			render: (_, record) => <PreviewLabel order={order} record={record} />,
		},
		{
			dataIndex: ['size'],
			title: t('Size'),
		},
		{
			dataIndex: ['created_at'],
			title: t('Update Time'),
		},
		{
			key: 'Action',
			title: ' ',
			render(_, record) {
				return <Actions order={order} record={record} onDeleteSuccess={() => fetchData()} />;
			},
		},
	];

	return (
		<Table
			columns={columns}
			dataSource={files ?? undefined}
			pagination={false}
			size='small'
			locale={{
				emptyText: <EmptyContent />,
			}}
		/>
	);
});
