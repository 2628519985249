import iconFactory from './icon-factory';

const _Svg = () => (
	<svg width='34' height='30' viewBox='0 0 34 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M0.333252 30V6.66667L16.9999 0L33.6666 6.66667V30H23.6666V16.6667H10.3333V30H0.333252ZM12.4166 30V27.5H14.9166V30H12.4166ZM15.7499 25V22.5H18.2499V25H15.7499ZM19.0833 30V27.5H21.5833V30H19.0833Z'
			fill='url(#paint0_linear_919_18429)'
		/>
		<defs>
			<linearGradient
				id='paint0_linear_919_18429'
				x1='-2.55868'
				y1='15'
				x2='33.6666'
				y2='15'
				gradientUnits='userSpaceOnUse'
			>
				<stop stop-color='#0094FF' />
				<stop offset='1' stop-color='#0165FA' />
			</linearGradient>
		</defs>
	</svg>
);

export default iconFactory(_Svg);
