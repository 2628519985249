import { useTranslation } from 'react-i18next';
import { Collapse, CollapseProps, Divider } from 'antd';
import ProjectExceptionExpandActiveIcon from '@/icons/project-exception-expand-active-icon';
import ProjectExceptionExpandIcon from '@/icons/project-exception-expand-icon';
import { GetProjectsExceptionsRes } from '@/services/apis';
import { ContainerDetailModal, useContainerDetailModal } from '@/components/container-detail-modal';
import { Gap } from '@/components/gap';
import { ProjectExceptions } from '@/components/project-exceptions';
import { apiHooks } from '@/hooks/apis';
import { ArrElement } from '@/utils/array-element.type';
import { NoExceptionEmpty } from '../no-exception-empty';
import './index.less';

interface ExceptionItems extends ArrElement<CollapseProps['items']> {
	items: GetProjectsExceptionsRes['containers'];
	projectId: number;
}

const ExceptionItem = ({
	id,
	number,
	order_id,
	trouble_statuses,
	projectId,
}: ArrElement<GetProjectsExceptionsRes['containers']> & { projectId: number }) => {
	const { openingContainerDetailModal, openContainerDetailModal, closeContainerDetailModal } =
		useContainerDetailModal();

	return (
		<>
			<div className='flex justify-between p-[12px_16px] cursor-pointer'>
				<div
					className='body-4-b text-primary-dark-02 cursor-pointer'
					onClick={openContainerDetailModal}
				>
					{number}
				</div>
				<ProjectExceptions exceptions={trouble_statuses} />
			</div>
			{openingContainerDetailModal && (
				<ContainerDetailModal
					projectId={projectId}
					orderId={order_id}
					containerId={id}
					open={openingContainerDetailModal}
					onClose={closeContainerDetailModal}
				/>
			)}
		</>
	);
};

export const ExceptionPanel = () => {
	const { t } = useTranslation();

	const { data } = apiHooks.useGetProjectsExceptions();

	const items: ExceptionItems[] = (data ?? []).map((d) => ({
		key: d.project_id,
		label: d.project_name,
		items: d.containers,
		projectId: d.project_id,
	}));

	const collapseItems: CollapseProps['items'] = items.map(
		({ items, label, projectId, ...collapseProps }) => ({
			...collapseProps,
			label: (
				<div className='p-[4px] flex items-center'>
					<div className='body-3-b text-primary-dark-01'>{label}</div>
					<Gap width='6px' />
					<div className='foot-note-2-b text-[#374557]'>{`(${items.length})`}</div>
				</div>
			),
			children: items.map((i, index) => (
				<div key={i.id}>
					<ExceptionItem {...i} projectId={projectId} />
					{index !== items.length - 1 && <Divider style={{ margin: 0 }} />}
				</div>
			)),
		}),
	);

	return (
		<div className='w-full h-full flex flex-col overflow-hidden'>
			<div className='grow-0 shrink-0 h4-sb text-primary-dark-01'>{t(`Exception`)}</div>
			<Gap height='16px' />
			<div className='grow shrink overflow-scroll'>
				{collapseItems.length === 0 ? (
					<>
						<NoExceptionEmpty />
					</>
				) : (
					<>
						<Collapse
							items={collapseItems}
							expandIconPosition={'end'}
							className='exception-collapse'
							expandIcon={(props) =>
								props.isActive ? (
									<ProjectExceptionExpandActiveIcon />
								) : (
									<ProjectExceptionExpandIcon />
								)
							}
						/>
					</>
				)}
			</div>
		</div>
	);
};
