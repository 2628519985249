import { useLocation, useParams } from 'react-router-dom';

export interface ShipmentOrderPath {
	type: 'ltl' | 'ftl';
	id: string;
}

export default () => {
	const { orderId } = useParams();

	const location = useLocation();

	const type = location.pathname.includes('ltl') ? 'ltl' : 'ftl';

	return {
		id: orderId,
		type,
	};
};
