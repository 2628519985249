import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, Empty, Pagination } from 'antd';
import { useMount } from 'ahooks';
import CenterSpin from '@/components/center-spin';
import { apiHooks } from '@/hooks/apis';
import DraftOrder from './draft-order';

const DraftOrdersDrawer = () => {
	const { t } = useTranslation();

	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const handleOpenDrawer = () => setIsDrawerOpen(true);
	const handleCloseDrawer = () => setIsDrawerOpen(false);

	const {
		runAsync: getDraftOrders,
		data: draftOrders,
		loading: isLoading,
		pagination,
	} = apiHooks.useGetDraftOrders();

	const {
		current,
		total,
		pageSize,
		changeCurrent,
		changePageSize,
		onChange: handleChangePagination,
	} = pagination;

	useMount(async () => {
		changeCurrent(1);
		changePageSize(6);
		await getDraftOrders(
			{
				pageSize: 6,
				current: 1,
			},
			{
				params: {},
			},
		);
	});

	return (
		<>
			<Button type='primary' onClick={handleOpenDrawer}>
				{t('See All Draft Orders')}
			</Button>
			<Drawer
				open={isDrawerOpen}
				width={894}
				title={t('Draft Orders')}
				onClose={handleCloseDrawer}
				footer={
					<div className='flex justify-center'>
						<Pagination
							total={total}
							current={current}
							pageSize={pageSize}
							onChange={handleChangePagination}
						/>
					</div>
				}
			>
				{isLoading ? (
					<CenterSpin size='large' />
				) : draftOrders.length === 0 ? (
					<Empty />
				) : (
					<div className='flex flex-wrap'>
						{draftOrders.map((draftOrder) => (
							<DraftOrder key={draftOrder.id} draftOrder={draftOrder} />
						))}
					</div>
				)}
			</Drawer>
		</>
	);
};

export default DraftOrdersDrawer;
