import { useState } from 'react';
import { Button } from 'antd';
import { OrderFile } from '@/interfaces/file.interface';
import { Order } from '@/interfaces/order.interface';
import { downloadFile } from '@/services/apis/download-file';

export default ({ order, record }: { order: Order; record: OrderFile }) => {
	const [loading, setLoading] = useState(false);

	const handleClick = async () => {
		setLoading(true);
		try {
			await downloadFile(
				{
					path: {
						documentId: record.id,
						orderId: order.id,
					},
					other: {
						fileName: record.file_name,
					},
				},
				{ preview: true },
			);
		} finally {
			setLoading(false);
		}
	};
	return (
		<Button type='link' loading={loading} className='cursor-pointer' onClick={handleClick}>
			{record.file_name}
		</Button>
	);
};
