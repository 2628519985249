import { useTranslation } from 'react-i18next';
import { Button, Space } from 'antd';
import { Invoice } from '@/interfaces/invoice.interface';
import { downloadInvoice } from '@/services/apis';
import { DownloadOutlined } from '@ant-design/icons';
import { Tag } from '@/components/tag';
import Bill from './bill';

export const BillContent: React.FC<{
	invoice: Invoice;
}> = ({ invoice }) => {
	const { t } = useTranslation();

	const handleDownloadFile = (invoice) => {
		downloadInvoice(
			{ path: { invoiceId: invoice.id }, other: { fileName: String(invoice.id) } },
			{ preview: false },
		);
	};

	return (
		<div>
			{/* header */}
			<div className='flex justify-between'>
				{/* left */}
				<div>
					{/* title */}
					<Space className='mb-[2px]'>
						<div className='body-3-b'>{invoice?.uid}</div>
						<div className='flex gap-x-1'>
							{invoice.state && (
								<Tag
									value={t(`${invoice.state}${!invoice.is_invoice_confirmed ? ' Invoice' : ''}`)}
								/>
							)}
							{invoice.is_customer_confirmed && <Tag value={t('Customer Confirm')} />}
							{invoice.is_invoice_confirmed && <Tag value={t('Invoice Confirm')} />}
						</div>
					</Space>

					{/* sub title */}
					{invoice.is_invoice_confirmed && (
						<div className='foot-note-2-r'>
							{t('Issued on ')}
							{invoice?.invoiced_at}
						</div>
					)}
				</div>
				{/* right */}
				{invoice.is_invoice_confirmed && (
					<Button onClick={() => handleDownloadFile(invoice)}>
						<DownloadOutlined />
						{t('Download')}
					</Button>
				)}
			</div>

			{/* table */}
			<Bill invoice={invoice} className='mb-[40px]' />
		</div>
	);
};
