import { useTranslation } from 'react-i18next';
import { useLTLContext } from '@/services/contexts';
import { observer } from 'mobx-react-lite';

export const ShipmentInfoEditButton = observer(() => {
	const { t } = useTranslation();

	const ltlContext = useLTLContext();

	return (
		<div
			className=' box-border p-[4px_10px] w-fit border-[1px] border-solid border-grey-01 rounded-[4px] body-4-sb text-grey-01 flex justify-center items-center cursor-pointer'
			onClick={() => (ltlContext.step = 1)}
		>
			{t(`Edit shipment`)}
		</div>
	);
});
