import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { isOrderExpiredProps, isOrderExpiredRes } from './interface';

export const isOrderExpired = async (props: isOrderExpiredProps) => {
	return await axios.get<isOrderExpiredRes>(`/newOrders/${props.path.orderId}/isExpired`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
