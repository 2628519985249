import React from 'react';
import EditSvg from '@/assets/edit.svg';

const EditIcon = (
	props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
) => {
	return <img {...props} src={EditSvg} />;
};

export default EditIcon;
