import { observer } from 'mobx-react-lite';

interface PanelProps {
	children: React.ReactNode;
	footer: React.ReactNode;
}

export default observer(({ children, footer }: PanelProps) => (
	<div className='mx-[30px] p-[30px_50px_25px_30px] h-full  rounded-[14px] bg-white shadow-[0px_4px_10px_0px_#0000000D]'>
		{/* content */}
		<div style={{ height: 'calc(100% - 106px)', overflowY: 'scroll', overflowX: 'hidden' }}>
			{children}
		</div>
		{/* button */}
		<div className='w-full h-[106px]'>{footer}</div>
	</div>
));
