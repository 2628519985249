import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetProjectExceptionsProps, GetProjectExceptionsRes } from './interface';

export * from './interface';

export const getProjectExceptions = async (props: GetProjectExceptionsProps) => {
	return await axios.get<GetProjectExceptionsRes>(`/projects/${props.path.projectId}/exceptions`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
