import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetProjectContainersMapProps, GetProjectContainersMapRes } from './interface';

export * from './interface';

export const getProjectContainersMap = async (props: GetProjectContainersMapProps) => {
	return await axios.get<GetProjectContainersMapRes>(
		`/projects/${props.path.projectId}/containersMap`,
		{
			headers: {
				Authorization: accessTokenManager.getAccessToken(),
			},
		},
	);
};
