import { Divider, Form, Select, Space } from 'antd';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { CntrSizeGroupProps } from './interface';

const options = [20, 40, 45].map((value) => ({
	label: `${value}FT`,
	value,
}));

export const CntrSizeGroup = observer(
	({ formItemKey, className, wrapperClass, type, ...props }: CntrSizeGroupProps) => {
		const form = Form.useFormInstance();

		const selectedCntrSize = Form.useWatch(formItemKey);

		const handleSelect = (value: number) => {
			form.setFieldValue(formItemKey, value);
		};

		if (type === 'select') {
			return (
				<Select
					{...props}
					variant='borderless'
					options={options}
					className={clsx('w-full', className)}
					value={selectedCntrSize}
					onSelect={(i) => handleSelect(i)}
				/>
			);
		}

		return (
			<div className={clsx('flex items-center h-[20px]', wrapperClass)}>
				{options.map((o, index) => {
					const isSelected = selectedCntrSize === o.value;
					return (
						<>
							<span
								className={`font-[${isSelected ? '600' : '400'}] text-[16px] text-[${
									isSelected ? '#007BFF' : '#000000'
								}]`}
								onClick={() => handleSelect(o.value)}
							>
								{o.label}
							</span>
							{index !== options.length - 1 && (
								<Divider type='vertical' className='h-[20px] border-[#E7E7E7]' />
							)}
						</>
					);
				})}
			</div>
		);
	},
);
