export enum LTLAddressType {
	BUSINESS = 'business',
	RESIDENTIAL = 'residential',
	TRADESHOW = 'tradeshow',
	LIMITED_ACCESS = 'limitedAccess',
}

export const lTLNeededReportAddressTypeArr = [
	LTLAddressType.RESIDENTIAL,
	LTLAddressType.TRADESHOW,
	LTLAddressType.LIMITED_ACCESS,
];

export const LTLAddressTypeLabelMap = {
	[LTLAddressType.BUSINESS]: 'Business',
	[LTLAddressType.RESIDENTIAL]: 'Residential',
	// [LTLAddressType.TRADESHOW]: 'Trade Show',
	[LTLAddressType.LIMITED_ACCESS]: 'Limited Access',
};

export const LTLAddressTypeLabels = Object.values(LTLAddressTypeLabelMap);

export type LTLAddressTypeLabelType =
	(typeof LTLAddressTypeLabelMap)[keyof typeof LTLAddressTypeLabelMap];
