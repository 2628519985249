import qs from 'qs';
import { NeededSearchParam } from '@/pages/search-rate/needed-search-params';

export const isURLSearchParamsValid = (searchParams: qs.ParsedQs, params: NeededSearchParam[]) => {
	return params.every((p) => {
		const params = searchParams[p.key];
		return p.required ? isSearchParamValid(params) : true;
	});
};

export const isSearchParamValid = (param: any) => {
	return !(
		param === undefined ||
		param === null ||
		param === '' ||
		param === 'undefined' ||
		param === 'null'
	);
};
