import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { UpdateOrderProps, UpdateOrderRes } from './interface';

export const updateOrder = async (props: UpdateOrderProps) => {
	return await axios.put<UpdateOrderRes>(`/newOrders/${props.path.orderId}`, props.data, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
