import { useEffect } from 'react';
import { Spin } from 'antd';
import CenterSpin from '@/components/center-spin';
import { SimplePagination } from '@/components/simple-pagenation';
import { apiHooks } from '@/hooks/apis';
import { useWindowSize } from '@/hooks/use-window-size';
import { NoProjectEmpty } from '../no-project-empty';
import { ProjectItem } from './project-item';

export const ProjectGrid = ({
	type,
	selectedTab,
}: {
	type: 'all' | 'favorite' | 'archived';
	selectedTab: string;
}) => {
	const { isWidth } = useWindowSize();

	const expectedPageSize = isWidth ? 6 : 4;

	const { runAsync, pagination, data, loading, refresh } = apiHooks.useGetProjects({
		defaultPageSize: expectedPageSize,
		defaultCurrent: 1,
	});

	const { current, total, totalPage, changeCurrent } = pagination;

	useEffect(() => {
		runAsync(
			{
				current,
				pageSize: expectedPageSize,
			},
			{
				params: {
					is_favorited: type === 'favorite',
					is_archived: type === 'archived',
				},
			},
		);
	}, [current, expectedPageSize, type, selectedTab, runAsync]);

	return (
		<div className='w-full h-full flex flex-col justify-between'>
			{loading ? (
				<CenterSpin />
			) : (data ?? []).length === 0 ? (
				<>
					<NoProjectEmpty />
				</>
			) : (
				<>
					<div
						style={{
							gridTemplateColumns: `repeat(${isWidth ? 3 : 2}, minmax(0, 1fr))`,
						}}
						className='grid grid-cols-2 grid-rows-2 gap-4'
					>
						{(data ?? []).map((item) => (
							<ProjectItem key={item.id} project={item} refresh={refresh} />
						))}
					</div>
				</>
			)}

			<div className='flex justify-center'>
				<SimplePagination
					label={`${current} - ${totalPage}`}
					onPre={() => changeCurrent(current > 2 ? current - 1 : 1)}
					onNext={() => changeCurrent(current < total ? current + 1 : total)}
				/>
			</div>
		</div>
	);
};
