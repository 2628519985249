import { useTranslation } from 'react-i18next';
import { Button, Result } from 'antd';
import useNavigateToIndex from '@/hooks/use-navigate-to-index';

export default () => {
	const { t } = useTranslation();

	const { navigateToIndex } = useNavigateToIndex();

	return (
		<Result
			status='warning'
			title={t('Sorry, something went wrong.')}
			className='pt-0 w-screen h-screen flex flex-col justify-center'
			extra={
				<Button
					type='primary'
					key='console'
					onClick={() => {
						navigateToIndex();
					}}
				>
					{t('Go index')}
				</Button>
			}
		/>
	);
};
