import { getRequestedRates } from '@/services/apis';
import {
	GetRequestedRatesProps,
	RequestedRatesResData,
} from '@/services/apis/get-requested-rates/interface';
import { makeAutoObservable, runInAction } from 'mobx';

class RequestedRateContext {
	constructor() {
		makeAutoObservable(this);
	}

	private _requestedRates: RequestedRatesResData[] = [];

	private _isFetching = false;

	get requestedRates() {
		return this._requestedRates;
	}

	set requestedRates(requestedRates: typeof this._requestedRates) {
		runInAction(() => {
			this._requestedRates = requestedRates;
		});
	}

	get isFetching() {
		return this._isFetching;
	}

	set isFetching(isFetching: typeof this._isFetching) {
		runInAction(() => {
			this._isFetching = isFetching;
		});
	}

	public async fetchRequestedRates(args?: GetRequestedRatesProps) {
		this.isFetching = true;
		const res = await getRequestedRates(args).finally(() => (this.isFetching = false));
		this.isFetching = false;
		return res;
	}

	public async fetchRequestedRatesAndAutoSet(args?: GetRequestedRatesProps) {
		const res = await this.fetchRequestedRates(args);
		// this.requestedRates = new Array(100).fill(0).map(i => res.data.data[0]);
		this.requestedRates = new Array(30).fill(0).flatMap(() => res.data.data);
	}
}

export const requestedRate = new RequestedRateContext();
