import { createContext, useContext } from 'react';
import { ftl } from './ftl.context';

export const ftlContext = createContext(ftl);

export const FTLContext: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
	return <ftlContext.Provider value={ftl}>{children}</ftlContext.Provider>;
};

export const useFTLContext = () => useContext(ftlContext);
