import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CargoQuery } from '@/services/apis';
import { useMemoizedFn } from 'ahooks';
import qs from 'qs';
import valueTransfer from '@/utils/value-transfer';

export type RequestedRatesQuery = Partial<CargoQuery & { cargo_types: number[] }>;

export default () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const parsedSearchParams = useMemo(() => {
		const searchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });

		const res = {};

		valueTransfer(res, 'to_city_id', searchParams.to_city_id, Number);
		valueTransfer(res, 'intermodal_region_id', searchParams.intermodal_region_id, Number);
		valueTransfer(res, 'state', searchParams.state, (states: string[]) => states);
		valueTransfer(res, 'cargo_types', searchParams.cargo_types, (types: string[]) =>
			types.map((t) => Number(t)),
		);
		valueTransfer(res, 'per_page', searchParams.per_page, (v) => (v ? Number(v) : 20));
		valueTransfer(res, 'page', searchParams.page, (v) => (v ? Number(v) : 1));

		return res as Partial<RequestedRatesQuery>;
	}, [searchParams]);

	const changeSearchParams = useMemoizedFn((values: any) => {
		console.log('changeSearchParams', { ...parsedSearchParams, ...values });
		setSearchParams(qs.stringify({ ...parsedSearchParams, ...values }));
	});

	return {
		parsedSearchParams,
		changeSearchParams,
	};
};
