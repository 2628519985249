import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { DeleteWarehouseProps, DeleteWarehouseRes } from './interface';

export const deleteWarehouse = async (props: DeleteWarehouseProps) => {
	return await axios.delete<DeleteWarehouseRes>(`/warehouses/${props.path.warehouseId}`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
