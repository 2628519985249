import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Empty, message, Space } from 'antd';
import { PossibleCharge } from '@/interfaces/possible-charges.interface';
import { useGlobalContext } from '@/services/contexts';
import { CopyOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import copy from 'copy-to-clipboard';
import { orderBy, sortBy } from 'lodash';
import { observer } from 'mobx-react-lite';
import CenterSpin from '@/components/center-spin';
import { billingCodeNameTranslateKey } from '@/utils/billing-code-translate';
import { BillingCodeDefinitionPopup } from './billing-code-definition-popup';
import Probability from './Probability';
import RateText from './RateText';
import RenderPossibleChargeNameOrCode from './render-possible-charge-name-or-code';

export const PossibleCharges = observer(
	(props: {
		rate?: string;
		title?: string;
		possible_charges: PossibleCharge[] | undefined;
		loading?: boolean;
		disableWrapper?: boolean;
		showProbability?: boolean;
		emptyDescription?: string;
	}) => {
		const { t } = useTranslation();

		const isWindows = navigator.userAgent.includes('Win');

		const possible_charges = useMemo(() => {
			const ps = orderBy(
				props.possible_charges || [],
				[(p) => (p.probability ? Number(p.probability) : 0), (p) => p.code],
				['desc', 'asc'],
			);
			const front = [];

			const dropfIndex = ps.findIndex((obj) => obj.code === 'DROPF');
			if (dropfIndex !== -1) {
				const dropfObject = ps.splice(dropfIndex, 1)[0];
				front.unshift(dropfObject);
			}

			return [...front, ...ps];
		}, [props.possible_charges]);

		const { screenContext } = useGlobalContext();

		const rate = props.rate;

		const title = props.title ?? t('Accessorial Charges');

		const th = `<tr><td>Type</td><td></td><td>Prices</td></tr>`;

		const CopyButton = () => (
			<Button
				type='link'
				icon={<CopyOutlined />}
				onClick={() => {
					const textarea = document.createElement('textarea');
					textarea.value = possible_charges
						.map(
							({ code, name, explanation, rate_text }) =>
								`${t(billingCodeNameTranslateKey(code))}: $${rate_text}${
									explanation ? ` - ${explanation}` : ''
								}`,
						)
						.join('\n');
					document.body.appendChild(textarea);
					textarea.select();
					document.execCommand('copy');
					document.body.removeChild(textarea);
					message.success(t('Copied to clipboard'));

					//v3
					// if (
					// 	copy(
					// 		possible_charges
					// 			.map(
					// 				({ code, name, explanation, rate_text }) =>
					// 					`${t(billingCodeNameTranslateKey(code))}: $${rate_text}${
					// 						explanation ? ` - ${explanation}` : ''
					// 					}`,
					// 			)
					// 			.join(isWindows ? '\r\n' : '\n'),
					// 	)
					// ) {
					// 	message.success(t('Copied to clipboard'));
					// }

					//v1
					// const data = (possible_charges ?? [])
					// 	.map(
					// 		({ name, explanation, rate_text }) =>
					// 			`<tr>${[name ?? ' ', explanation ?? ' ', rate_text ?? ' ']
					// 				.map((i) => `<td>${i}</td>`)
					// 				.join('')}</tr>`,
					// 	)
					// 	.join('');
					// const blob = new Blob([`<table>${th}${data}</table>`], { type: 'text/html' });
					// navigator.clipboard.write([new ClipboardItem({ [blob.type]: blob })]);

					// v2
					// const data = possible_charges
					// 	.map(
					// 		({ code, name, explanation, rate_text }) =>
					// 			`<div>${t(billingCodeNameTranslateKey(code))}: $${rate_text}${explanation ? ` - ${explanation}` : ''}</div>`,
					// 	)
					// 	.join(' ');
					// const blob = new Blob([`${data}`], { type: 'text/html' });
					// navigator.clipboard.write([new ClipboardItem({ 'text/html': blob })]);
				}}
			>
				{t('Copy')}
			</Button>
		);

		if (props.loading) return <CenterSpin />;

		return (
			<div
				className={clsx(
					'flex flex-col bg-[#ffffff]',
					!props.disableWrapper &&
						'border-box pt-[26px] pl-[24px] border-[1px] border-solid border-[#E7E7E7] rounded-[10px]',
					screenContext.isScreenShot ? 'h-fit' : 'h-full',
				)}
			>
				{/* title */}
				<div className='mb-[8px] pr-[24px] font-[700] text-[16px] tracking-[0.5px]'>
					{title}
					<CopyButton />
				</div>

				{/* table header */}
				<div className='mb-[8px] pr-[24px] flex justify-between text-[#747578] text-[12px] font-[700]'>
					<div className='flex-grow flex'>
						<div className='mr-[11px] w-[52px]'>{t('Chance')}</div>
						<div>{t('Type')}</div>
					</div>
					<div className=' flex-grow-0'>{t('Prices')}</div>
				</div>

				{/* divider */}
				<div className='mr-[24px] mb-[15px] h-[2px] bg-[#E7E7E7]'></div>

				{/* content */}
				<div
					className={clsx('flex-grow pr-[13px]  overflow-y-scroll', {
						'overflow-y-scroll': false,
						'h-[100px]': false,
					})}
				>
					{possible_charges.map((c) => (
						<div className='flex justify-between mb-[8px]'>
							{/* left */}
							<div className='flex-grow flex'>
								{/* tag */}
								{!!props.showProbability && <Probability possibleCharge={c} />}
								{/* charge name */}
								<div className='font-[400] text-[12px] leading-[16px]'>
									<div className='inline-block'>
										<RenderPossibleChargeNameOrCode nameOrCode={c.code} /> {''}
										<BillingCodeDefinitionPopup code={c.code} explanation={c.explanation} />
									</div>
									{!!c.explanation && (
										<div>
											<small>{c.explanation}</small>
										</div>
									)}

									{/* <div className='text-[#747578]'>{c.explanation}</div> */}
								</div>
							</div>

							{/* right */}

							<div className='text-right flex-grow-0'>
								<RateText possibleCharge={c} />
							</div>
						</div>
					))}

					{possible_charges.length == 0 && !!props.emptyDescription && (
						<div className='w-full h-full flex-center'>
							<Empty description={props.emptyDescription} />
						</div>
					)}
				</div>
			</div>
		);
	},
);
