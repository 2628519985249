import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetDraftOrderProps, GetDraftOrderRes } from './interface';

export const getDraftOrder = async (props: GetDraftOrderProps) => {
	return await axios.get<GetDraftOrderRes>(`/draftOrders/${props.path.draftOrderId}`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
