import { useTranslation } from 'react-i18next';
import { Popover } from 'antd';
import Check16pxActiveIcon from '@/icons/check-16px-active-icon';
import Close16pxActiveIcon from '@/icons/close-16px-active-icon';
import Destination16pxIcon from '@/icons/destination-16px-icon';
import Group16pxActiveIcon from '@/icons/group-16px-active-icon';
import Group16pxIcon from '@/icons/group-16px-icon';
import Ir16pxIcon from '@/icons/ir-16px-icon';
import Mbl16pxIcon from '@/icons/mbl-16px-icon';
import Progress16pxIcon from '@/icons/progress-16px-icon';
import { useBoolean } from 'ahooks';
import { Gap } from '@/components/gap';
import { FormState } from '../type';

export enum GroupOptionKeys {
	progress = 'progress',
	mbl = 'mbl',
	final_port = 'final_port',
	destination = 'destination',
}

export interface GroupProps {
	value: FormState['group'];
	onChange: (value: FormState['group']) => void;
}

export const Group = ({ value, onChange }: GroupProps) => {
	const { t } = useTranslation();

	const hasValue = value !== undefined;

	const [open, { set }] = useBoolean();

	const options = [
		{
			label: t(`Progress`),
			icon: <Progress16pxIcon />,
			value: GroupOptionKeys.progress,
		},
		{
			label: t(`MBL`),
			icon: <Mbl16pxIcon />,
			value: GroupOptionKeys.mbl,
		},
		{
			label: t(`IR`),
			icon: <Ir16pxIcon />,
			value: GroupOptionKeys.final_port,
		},
		{
			label: t(`Destination`),
			icon: <Destination16pxIcon />,
			value: GroupOptionKeys.destination,
		},
	];

	const content = (
		<div className='w-[228px]'>
			{options.map((option) => {
				const isSelected = value === option.value;
				return (
					<div
						style={{
							backgroundColor: isSelected ? '#E6F0FA' : 'white',
						}}
						className=' box-border p-[12px_8px] w-full h-[40px] rounded-[4px] flex justify-between items-center cursor-pointer'
						onClick={() => onChange(option.value)}
					>
						<div className='flex items-center'>
							{option.icon}
							<Gap width='8px' />
							{option.label}
						</div>
						<div>{isSelected && <Check16pxActiveIcon />}</div>
					</div>
				);
			})}
		</div>
	);

	return (
		<Popover
			title=''
			trigger={'click'}
			placement='bottomLeft'
			autoAdjustOverflow
			arrow={false}
			content={content}
			open={open}
			onOpenChange={set}
		>
			<div>
				{hasValue ? (
					<div className='box-border p-[0_12px] h-[32px] flex items-center rounded-[16px] bg-primary-light-04 body-4-m text-primary-regular'>
						<Group16pxActiveIcon />
						<Gap width='8px' />
						<div>
							{t(`Group`)}
							{`: `}
							{options.find((o) => o.value === value)?.label}
						</div>
						<Gap width='8px' />
						<Close16pxActiveIcon className='cursor-pointer' onClick={() => onChange(undefined)} />
					</div>
				) : (
					<div
						style={{ backgroundColor: open ? '#E7E7E7' : 'white' }}
						className='box-border p-[0_12px] h-[32px] flex items-center border-[1px] border-solid border-grey-04 rounded-[16px] body-4-m text-grey-01'
					>
						<Group16pxIcon />
						<Gap width='8px' />
						<div>{t(`Group`)}</div>
					</div>
				)}
			</div>
		</Popover>
	);
};
