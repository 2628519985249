import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetProjectsExceptionsRes } from './interface';

export * from './interface';

export const getProjectsExceptions = async () => {
	return await axios.get<GetProjectsExceptionsRes[]>(`/projectHome/exceptions`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
