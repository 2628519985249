import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, message, Tooltip } from 'antd';
import DraftOrderDestinationIcon from '@/icons/draft-order-destination-icon';
import DraftOrderPortIcon from '@/icons/draft-order-port-icon';
import { DraftOrder as IDraftOrder } from '@/interfaces/draft-order.interface';
import { isOrderExpired } from '@/services/apis';

const ViewDraftOrdersButtonDraftOrder = ({ draftOrder }: { draftOrder: IDraftOrder }) => {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);
	const handleEditDraftOrder = () => {
		setIsLoading(true);
		isOrderExpired({ path: { orderId: draftOrder.id } })
			.then((response) => {
				if (!response.data.is_expired) {
					navigate(`/search-rate/book?draftOrderId=${draftOrder.id}`);
				} else {
					message.error(t('The rate for this draft has expired. Not editable.'));
				}
			})
			.catch((error) => {
				message.error(t('Open edit draft order error'));
				console.error(error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<div
			className='mr-[20px] mb-[40px] p-[17px_12px_0_12px] w-[21.5rem] min-h-fit max-h-full flex flex-col justify-between bg-grey-06 rounded-[6px] shadow-[0px_6px_18px_0px_#8F95B240]'
			key={draftOrder.id}
		>
			<div className='mb-[20px] flex-grow grid grid-cols-[repeat(2,_1fr)] grid-rows-[17px_1fr_33px]'>
				{[t('Port'), t('Destination')].map((text) => (
					<div className='mb-[3px] foot-note-4-10px' key={text}>
						{text}
					</div>
				))}

				{[
					{
						icon: <DraftOrderPortIcon />,
						iconText: draftOrder.port_of_discharge.name,
					},
					{
						icon: <DraftOrderDestinationIcon />,
						iconText: draftOrder.final_port.name,
					},
				].map(({ icon, iconText }) => (
					<div key={iconText} className='mb-[18px] flex items-center'>
						<div className='mr-[7px]'>{icon}</div>
						<div className='font-[600] text-[16px] text-[#004A99]'>{iconText}</div>
					</div>
				))}

				{[
					{
						secondTitle: t('POD ETA'),
						secondText: draftOrder.port_of_discharge_eta,
					},
					{
						secondTitle: t('Number of containers'),
						secondText: String(draftOrder.container_count ?? 0),
					},
				].map(({ secondTitle, secondText }) => (
					<div key={secondText}>
						<div className='foot-note-4-10px'>{secondTitle}</div>
						<div className='body-4-m'>{secondText}</div>
					</div>
				))}
			</div>

			<div>
				<div className='mb-[14px] h-[1px] bg-grey-04' />

				{/* bottom */}
				<div className='mb-[10px] flex justify-between items-center'>
					{/* left */}
					<div>
						<div className='font-[700] text-[20px] text-primary-dark-02'>
							${draftOrder.sellRate?.total_rate}
						</div>
						<div>
							{`${t('Pricing valid until')} `}
							{draftOrder.sellRate?.expired_at}
						</div>
					</div>
					{/* right */}

					{draftOrder.is_expired ? (
						<Tooltip title={t('The rate for this draft has expired. Not editable.')}>
							<Button disabled type='primary'>
								{t('Edit')}/{t('Order')}
							</Button>
						</Tooltip>
					) : (
						<Button type='primary' loading={isLoading} onClick={handleEditDraftOrder}>
							{t('Edit')}/{t('Order')}
						</Button>
					)}
				</div>

				{/* bottom border */}
				<div className=' border-[2px] border-solid border-primary-light-01 rounded-[2px]' />
			</div>
		</div>
	);
};

export default ViewDraftOrdersButtonDraftOrder;
