import { useCallback } from 'react';
import { useSafeState } from 'ahooks';
import dayjs from 'dayjs';

export const useCalendar = () => {
	const [base, setBase] = useSafeState<dayjs.Dayjs>(dayjs());

	const goToday = useCallback(() => {
		setBase(dayjs());
	}, [setBase]);

	const goPreMonth = useCallback(() => {
		setBase(base.subtract(1, 'month'));
	}, [base, setBase]);

	const goNextMonth = useCallback(() => {
		setBase(base.add(1, 'month'));
	}, [base, setBase]);

	return {
		base,
		goToday,
		goPreMonth,
		goNextMonth,
	};
};
