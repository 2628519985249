import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { MultiOrdersCreateProjectProps, MultiOrdersCreateProjectRes } from './interface';

export const multiOrdersCreateProject = async (props: MultiOrdersCreateProjectProps) => {
	return await axios.post<MultiOrdersCreateProjectRes>(`/projects`, props.data, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
