import { createContext, useContext } from 'react';
import { ltl } from './ltl.context';

export const ltlContext = createContext(ltl);

export const LTLContext: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
	return <ltlContext.Provider value={ltl}>{children}</ltlContext.Provider>;
};

export const useLTLContext = () => useContext(ltlContext);
