import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { UpdateShipmentProps, UpdateShipmentRes } from './interface';

export * from './interface';

export const updateShipment = async (props: UpdateShipmentProps) => {
	return await axios.put<UpdateShipmentRes>(`/tl/ltl/shipments/${props.path.id}`, props.data, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
