import { LTLAddressType, lTLNeededReportAddressTypeArr } from './ltl-address-type.enum';
import { ShipmentItem } from './shipment.interface';

export const packageingTypes = ['Pallet', 'Crate'] as const;

export const pickupAccesorials = [
	{ name: 'Liftgate Pickup', key: 'liftgate' },
	{ name: 'Inside Pickup', key: 'inside' },
] as const;

export const destinationAccesorials = [
	{ name: 'Liftgate Delivery', key: 'liftgate' },
	{ name: 'Appointment', key: 'appointment' },
	{ name: 'Inside Delivery', key: 'inside' },
] as const;

export const shipmentAccesorials = [
	{ name: 'Hazardous materials', key: 'hazmat' },
	// { name: 'Stackable', key: 'stackable' },
	// { name: 'Turnable', key: 'turnable' },
] as const;

const pickupAccesorialsKeys = [
	...pickupAccesorials.map((i) => i.key),
	...lTLNeededReportAddressTypeArr,
] as const;

const destinationAccesorialsKeys = [
	...destinationAccesorials.map((i) => i.key),
	...lTLNeededReportAddressTypeArr,
] as const;

const shipmentAccesorialsKeys = shipmentAccesorials.map((i) => i.key);

export enum QuoteBusinessStatus {
	DRAFT = 0,
	SUBMITED = 10,
	CONFIRMED = 20,
	BOOKED = 30,
	EXPIRED = 40,
}

export const quoteBusinessStatusMap = {
	0: 'Draft',
	10: 'Submitted',
	20: 'Updated',
	30: 'Booked',
	40: 'Expired',
};

export interface Quote {
	id: number;
	uid: string;
	user_id: number;
	tl_type: string; // 'LTL'
	job_total: number;
	status: string; // "0/2"
	created_at: string; // "2024-08-11T08:38:52.000000Z"
	business_status: QuoteBusinessStatus;

	pickup_zipcode: string;
	destination_zipcode: string;
	pickup_city_name: string;
	pickup_state_name: string;
	destination_city_name: string;
	destination_state_name: string;
	pickup_date: string;
	pickup_accessorials: typeof pickupAccesorialsKeys;
	destination_accessorials: typeof destinationAccesorialsKeys;
	shipment_accessorials: typeof shipmentAccesorialsKeys;
	items: ShipmentItem[];

	pickup_address_type: LTLAddressType;
	destination_address_type: LTLAddressType;
}
