import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popover, Tag, Tooltip } from 'antd';
import DownArrowIcon from '@/icons/down-arrow-icon';
import UpArrowIcon from '@/icons/up-arrow-icon';
import { PossibleCharge } from '@/interfaces/possible-charges.interface';
import { Rate } from '@/interfaces/rate.interface';
import { SearchRatesResData } from '@/services/apis/search-rates/interface';
import { useGlobalContext, useSearchRateContext } from '@/services/contexts';
import { InfoCircleOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import _, { min } from 'lodash';
import { observer } from 'mobx-react-lite';
import transformNumberToEnglish from '@/utils/transform-number-to-english';
import { BillingCodeDefinitionPopup } from '../possible-charges/billing-code-definition-popup';
import RenderPossibleChargeNameOrCode from '../possible-charges/render-possible-charge-name-or-code';
import { CompanyProfile } from './company-profile';
import FeeSlider from './fee-slider';
import { OriginalRate } from './original-rate';

export interface TerminalDetailProps {
	searchData: SearchRatesResData;
	index: number;
	maxFee: number;
}

const TerminalTag = ({
	tag,
	label,
	tagClass,
}: {
	tag: string;
	label?: string;
	tagClass?: string;
}) => (
	<div className='flex mr-[8px]'>
		<Tag
			color='#004A99'
			className={clsx('min-w-[56px] w-fit h-[24px] text-center leading-[24px]', tagClass)}
		>
			{tag}
		</Tag>
		{label && <div className='body-4-b'>{label}</div>}
	</div>
);

export const TerminalDetail = observer(({ searchData, index, maxFee }: TerminalDetailProps) => {
	const { t } = useTranslation();

	const isAllTerminal = searchData.terminals.length === 0;
	const searchRateContext = useSearchRateContext();

	const { screenContext, userContext } = useGlobalContext();

	useEffect(() => {
		setIsShowDetail(
			searchRateContext.selectedSearchData?.id === searchData.id || screenContext.isScreenShot,
		);
	}, [screenContext.isScreenShot]);

	/**
	 * 当前项是否被选中
	 */
	const isSelected = searchRateContext.selectedSearchData?.id === searchData.id;

	const [isShowDetail, setIsShowDetail] = useState(isSelected);

	const isMultipleTerminals = searchData.terminals.length > 1;

	const baseRate = _.sum(searchData.rates.map((r) => Number(r.amount)));

	const scenarios = searchData.scenarios;

	const bestScenario = scenarios.find((s) => s.name.startsWith('Best Scenario'));
	const averageScenario = scenarios.find((s) => s.name.startsWith('Average Scenario'));

	const _minFee = Number(bestScenario?.total_rate);
	const _maxFee = Number(averageScenario?.total_rate);

	const mergedScenarios = useMemo(() => {
		if (!bestScenario || !averageScenario) return [];
		const bestCharges = bestScenario.possible_charges;
		const averageCharges = averageScenario.possible_charges;

		const res: {
			code: string;
			name: string;
			explanation: string;
			best: PossibleCharge | null;
			average: PossibleCharge | null;
		}[] = [];

		bestCharges.forEach((charge) => {
			const averageCharge = averageCharges.find((c) => c.name === charge.name) ?? null;
			res.push({
				code: charge.code,
				name: charge.name,
				explanation: charge.explanation,
				best: charge,
				average: averageCharge,
			});
		});

		const bestNames = bestCharges.map((c) => c.name);
		averageCharges.forEach((charge) => {
			if (bestNames.includes(charge.name)) return;

			res.push({
				code: charge.code,
				name: charge.name,
				explanation: charge.explanation,
				best: null,
				average: charge,
			});
		});

		return res;
	}, [bestScenario, averageScenario]);

	const rates = useMemo(() => {
		if (!bestScenario || !averageScenario) return [];
		const bestCharges = bestScenario.rates;
		const averageCharges = averageScenario.rates;

		const res: { name: string; best: Rate | null; average: Rate | null }[] = [];

		bestCharges.forEach((charge) => {
			const averageCharge = averageCharges.find((c) => c.name === charge.name) ?? null;
			res.push({
				name: charge.name,
				best: charge,
				average: averageCharge,
			});
		});

		const bestNames = bestCharges.map((c) => c.name);
		averageCharges.forEach((charge) => {
			if (bestNames.includes(charge.name)) return;

			res.push({
				name: charge.name,
				best: null,
				average: charge,
			});
		});

		return res;
	}, [bestScenario, averageScenario]);

	const bestAmount = bestScenario ? bestScenario.total_rate : baseRate;
	const averageAmount = averageScenario ? averageScenario.total_rate : baseRate;

	if (scenarios.length === 0) return null;

	return (
		<div className=' mb-[8px] pt-[24px] pb-[16px] pl-[24px] pr-[32px] bg-[#ffffff] min-h-[88px] rounded-[8px]'>
			{/* header */}
			<div className='flex'>
				<div
					className='flex-grow-0 flex-shrink-0 w-[240px] mt-[8px]'
					onClick={() => setIsShowDetail((isShow) => !isShow)}
				>
					<div className='flex items-center mb-[4px]'>
						{!searchData.vendor_id && (
							<Tooltip title='Save time and book directly with DrayEasy'>
								<InfoCircleOutlined className='mr-[4px]' />
							</Tooltip>
						)}

						{!!searchData.vendor_id && (
							<Popover
								title={searchData.vendor_name}
								content={<CompanyProfile searchData={searchData} />}
							>
								<InfoCircleOutlined className='mr-[4px]' />
							</Popover>
						)}
						<div className='font-bold'>{searchData.vendor_name}</div>
					</div>

					{!!searchData.contract_rate_name && (
						<div className='flex mb-[4px]'>For {searchData.contract_rate_name}</div>
					)}

					<div
						className={clsx('flex', {
							// 'opacity-50': !isSelected ,
						})}
					>
						{isAllTerminal ? (
							<TerminalTag tag={t('All Terminal')} />
						) : isMultipleTerminals ? (
							<span className='body-4-b'>{`Group ${transformNumberToEnglish(index + 1)}`}</span>
						) : (
							<TerminalTag
								tag={searchData.terminals[0]?.firms_code}
								label={searchData.terminals[0]?.name}
							/>
						)}
						{userContext.getUserInfo()?.__enable_drop_feature &&
							Number(searchData.drop_fee) === 0 && (
								<Tag color='blue' className='h-[24px]'>
									{t('Free Drop')}
								</Tag>
							)}
						{searchData.vendor_id && (
							<Tag color='blue' className='h-[24px]'>
								{t('Prepaid Only')}
							</Tag>
						)}
					</div>
				</div>

				<div
					className='flex flex-grow relative cursor-pointer'
					onClick={() => setIsShowDetail((isShow) => !isShow)}
				>
					<FeeSlider
						range
						key={`rate-freeslide-${_minFee}-${_maxFee}`}
						min={0}
						max={maxFee * 1.5}
						marks={{ [_minFee]: `$${_minFee}`, [_maxFee]: `$${_maxFee}` }}
						defaultValue={[_minFee, _maxFee]}
						disabled={true}
					/>
					<div className='w-[24px] h-[24px]'>
						{isShowDetail ? <UpArrowIcon /> : <DownArrowIcon />}
					</div>

					{/* <Button type="text" className="w-[24px] h-[24px]" icon={isShowDetail ? <UpArrowIcon /> : <DownArrowIcon />} onClick={() => setIsShowDetail(pre => !pre)} /> */}
				</div>

				<div className='flex-grow-0 flex-shrink-0 pl-[38px] w-[150px]'>
					{isSelected ? (
						<Button
							type='primary'
							className='w-full'
							onClick={() => setIsShowDetail((isShow) => !isShow)}
						>
							{t('Selected')}
						</Button>
					) : (
						<Button
							className='w-full'
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
								setIsShowDetail(true);
								searchRateContext.setSelectedSearchDataById(searchData.id);
							}}
						>
							{t('Select')}
						</Button>
					)}
				</div>
			</div>

			{/* terminals */}
			{isMultipleTerminals && (
				<div>
					<div className='mb-[16px] font-[12px] text-[12px] text-primary-dark-02'>
						{t('Terminals with the same rate')}
					</div>
					<div className='flex'>
						{searchData.terminals.map((terminal) => (
							<TerminalTag tag={terminal?.firms_code} label={terminal?.name} />
						))}
					</div>
				</div>
			)}

			{/* detail */}
			<div style={{ display: isShowDetail ? 'unset' : 'none' }} className='text-[12px]'>
				{/* header */}
				<div className='flex mb-[18px] h-[14px] font-[700]'>
					<div className='flex-grow-0 w-[240px] h-full'>
						{t('Charges')}
						<span className='ml-[4px] font-[400] text-[#747578] underline'>
							{/* {t('Truck Conditions')} */}
						</span>
					</div>
					<div className=' flex-grow pr-[32px] w-[100px] h-full text-right'>{t('Best')}</div>
					<div className=' flex-grow-0  w-[160px] h-full text-right'>{t('Average')}</div>
				</div>
				{/* base rate */}
				{rates.map((s) => {
					return (
						<div className='flex mb-[8px] h-[14px] text-[#007BFF] font-[700]'>
							<div className='flex-grow-0 w-[240px] h-full'>{t(s.name)}</div>
							<div className=' flex-grow pr-[32px] w-[100px] h-full text-right'>
								${s.best?.rate}
								{searchData.has_tier_rate &&
									s.best?.original_rate &&
									s.best?.rate != s.best?.original_rate && (
										<span className='ml-1'>
											<OriginalRate originalRate={`$${s.best?.original_rate}`} />
										</span>
									)}
							</div>
							<div className=' flex-grow-0  w-[160px] h-full text-right'>
								${s.average?.rate}
								{searchData.has_tier_rate &&
									s.average?.original_rate &&
									s.average?.rate != s.average?.original_rate && (
										<span className='ml-1'>
											<OriginalRate originalRate={`$${s.average?.original_rate}`} />
										</span>
									)}
							</div>
						</div>
					);
				})}
				{/* possible charges */}
				{mergedScenarios.map((s) => {
					return (
						<div className='flex mb-[8px] h-[14px]'>
							<div className='flex-grow-0 w-[240px] h-full'>
								<RenderPossibleChargeNameOrCode nameOrCode={s.code} />{' '}
								<BillingCodeDefinitionPopup code={s.code} explanation={s.explanation} />
							</div>
							<div className=' flex-grow pr-[32px] w-[100px] h-full text-right'>
								{s.best?.rate_text && `$${s.best?.rate_text}`}
							</div>
							<div className=' flex-grow-0  w-[160px] h-full text-right'>
								{s.average?.rate_text && `$${s.average?.rate_text}`}
							</div>
						</div>
					);
				})}
				{/* amount */}
				<div className='h-[1px] bg-[#E7E7E7]' />
				<div className='flex mt-[17px] h-[14px]'>
					<div className='flex-grow-0 h-full'>{searchData.note}</div>
					<div className='flex-grow pr-[32px] w-[100px] h-full text-right foot-note-1-b'>
						<span className='mr-[41px]'>{t('Estimated Total')}:</span>${bestAmount}
					</div>
					<div className='flex-grow-0 w-[160px] h-full text-right foot-note-1-b'>
						${averageAmount}
					</div>
				</div>
			</div>
		</div>
	);
});
