import { useTranslation } from 'react-i18next';

export const HowToPayMyBill = () => {
	const { t } = useTranslation();

	return (
		<div className=' box-border w-full h-[316px] px-[24px] py-[16px] border-[2px] border-solid border-grey-05 bg-grey-06'>
			<div className='h-[100px] flex items-center'>
				<div className='body-4-b text-black font-[700]'>{t(`How to pay my bill?`)}</div>
			</div>

			<div className='body-4-r text-grey-01 font-[400]'>
				{t(
					'Please reach out to our customer service team who will provide you with the necessary account details and instructions for making the payment.',
				)}
			</div>
		</div>
	);
};
