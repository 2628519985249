import { useEffect, useState } from 'react';
import { Button, Dropdown, Input, MenuProps, message, Table } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';
import { Warehouse } from '@/interfaces/warehouse.interface';
import { deleteWarehouse, getWarehouses } from '@/services/apis';
import { DeleteOutlined, EditOutlined, MoreOutlined, SearchOutlined } from '@ant-design/icons';
import { useDebounceFn } from 'ahooks';
import { t } from 'i18next';
import _ from 'lodash';
import useAddressModal from '../search-rate/book/components/route-info/components/use-address-modal';
import Panel from '@/components/panel';
import useAddressParams, { AddressQuery } from './use-address-params';

export default () => {
	const { parsedSearchParams, changeSearchParams } = useAddressParams();

	const [addresses, setAddresses] = useState<Warehouse[]>([]);

	const [paginationPage, setPaginationPage] = useState({
		total: 0,
		pageSize: parsedSearchParams.per_page,
		current: parsedSearchParams.page,
	});

	const [fetching, setFetching] = useState(false);

	const { AddressModal, openAddressModal } = useAddressModal();

	const actionsItems: MenuProps['items'] = [
		{
			key: 'edit',
			label: t('Edit Address'),
			icon: <EditOutlined className='text-[24px] text-primary-regular' />,
		},
		{
			key: 'delete',
			label: t('Delete'),
			icon: <DeleteOutlined className='text-[24px] text-updated-red' />,
		},
	];

	const { run: handleSearch } = useDebounceFn(
		(() => {
			let _fetchIndex = 0;

			return async (query: AddressQuery) => {
				_fetchIndex++;
				const fetchIndex = _fetchIndex;

				setFetching(true);

				const params = {
					page: 1,
					per_page: 20,
					...query,
				};

				let res;
				if (params.keyword === '') {
					res = await getWarehouses({ params });
				} else {
					res = await getWarehouses({
						params,
					});
				}
				if (fetchIndex !== _fetchIndex) return;

				const isErrorPagination =
					res.data.meta.current_page !== 1 && (res.data.data ?? []).length === 0;

				const newPagination = isErrorPagination
					? {
							total: res.data.meta.total,
							pageSize: res.data.meta.per_page,
							current: 1,
					  }
					: {
							total: res.data.meta.total,
							pageSize: res.data.meta.per_page,
							current: res.data.meta.current_page,
					  };

				setPaginationPage(newPagination);
				changeSearchParams({
					per_page: newPagination.pageSize,
					page: newPagination.current,
					name: params.keyword,
				});

				setAddresses(res.data.data);
				setFetching(false);
			};
		})(),
		{ wait: 500 },
	);

	const handleChange: TableProps<Warehouse>['onChange'] = (pagination) => {
		const { current, pageSize } = pagination;

		const values = {
			...parsedSearchParams,
			page: current,
			per_page: pageSize,
		};

		handleSearch({ ...values });
	};

	const handleAction: (warehouse: Warehouse) => MenuProps['onClick'] =
		(warehouse) =>
		({ key, domEvent }) => {
			domEvent.stopPropagation();
			domEvent.preventDefault();

			if (key === 'edit') {
				openAddressModal(warehouse);
			} else if (key === 'delete') {
				deleteWarehouse({ path: { warehouseId: warehouse.id } })
					.then(() => {
						message.success(t('Delete address success'));
					})
					.catch(() => {
						message.error(t('Delete address failed'));
					})
					.finally(() => handleSearch(parsedSearchParams));
			}
		};

	const addressColumns: ColumnsType<Warehouse> = [
		{
			title: t('Name'),
			dataIndex: 'name',
			width: '130px',
			sorter: (a, b) => a.name.localeCompare(b.name),
		},
		{
			title: t('Code'),
			dataIndex: 'code',
			width: '80px',
			// a.code may be null, fix below sorter
			sorter: (a, b) => (a.code || '').localeCompare(b.code),
		},
		{
			title: t('City'),
			dataIndex: ['city', 'full_name'],
			width: '100px',
			sorter: (a, b) => (a.city?.full_name || '').localeCompare(b.city.full_name),
		},
		{
			title: t('Address'),
			dataIndex: 'address',
			width: '200px',
		},
		{
			title: t('D/O Address'),
			width: '200px',
		},
		{
			title: `${t('Contact')}/${t('Phone')}/${t('Email')}`,
			dataIndex: 'contacts',
			width: '240px',
			render: (contacts: Warehouse['contacts']) => {
				const contact = contacts?.[0] ?? { name: '', phone: '', email: '' };
				return (
					<div>
						<div>{contact.name}</div>
						<div>{contact.phone}</div>
						<div>{contact.email}</div>
					</div>
				);
			},
			sorter: (a, b) => a.contacts?.[0].name.localeCompare(b.contacts?.[0].name),
		},
		{
			title: t('Creator'),
			dataIndex: ['user', 'name'],
			width: '80px',
			sorter: (a, b) => a.user.name.localeCompare(b.user.name),
		},
		{
			title: ' ',
			width: '36px',
			render: (_, warehouse) => (
				<Dropdown menu={{ items: actionsItems, onClick: handleAction(warehouse) }}>
					<Button type='text' icon={<MoreOutlined />} />
				</Dropdown>
			),
		},
	];

	useEffect(() => {
		handleSearch(parsedSearchParams);
	}, []);

	return (
		<div className=' box-border p-[51px_40px_0_40px] h-screen flex flex-col gap-[12px]'>
			<div className='flex justify-between items-center'>
				<div className='h3-b'>{t('Address')}</div>
				<Button type='primary' onClick={() => openAddressModal()}>
					{t('New Address')}
				</Button>
			</div>

			<Panel classNames='h-[calc(100%_-_47px)]'>
				<div className='mb-[20px] pl-[16px] flex light-border rounded-[5px]'>
					<SearchOutlined width={20} />
					<Input
						placeholder={t(
							'Search for ID, Name, Code, City, Address, D/O Address, Phone/Email....',
						)}
						variant='borderless'
						onChange={(e) => {
							handleSearch({ keyword: e.target.value });
						}}
					/>
				</div>

				<div style={{ height: 'calc(100% - 99px)' }}>
					<Table
						columns={addressColumns}
						dataSource={addresses}
						loading={fetching}
						className='auto-h-full-table  custom-table'
						pagination={{ position: ['bottomCenter'], ...paginationPage }}
						onChange={handleChange}
					/>
				</div>

				<AddressModal onAfterSave={() => handleSearch(parsedSearchParams)} />
			</Panel>
		</div>
	);
};
