import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { Suggetion } from './interface';

export const getAddressSuggestions = async (query) => {
	return await axios.get<{ data: Suggetion[] }>(`/newWarehouses/address/suggestions`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params: {
			query,
		},
	});
};
