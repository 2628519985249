import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { UpdateCompanyProps, UpdateCompanyRes } from './interface';

export const updateCompany = async (props: UpdateCompanyProps) => {
	return await axios.put<UpdateCompanyRes>(`/agent/companies/${props.data.id}`, props.data, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
