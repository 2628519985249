import iconFactory from './icon-factory';

const Svg = () => (
	<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z'
			stroke='#007BFF'
			stroke-width='2'
		/>
		<path
			d='M12 8L9 5L6 8'
			stroke='#007BFF'
			stroke-width='1.5'
			stroke-linecap='square'
			stroke-linejoin='round'
		/>
		<path
			d='M9 16L9 6'
			stroke='#007BFF'
			stroke-width='1.5'
			stroke-linecap='square'
			stroke-linejoin='round'
		/>
		<path
			d='M9 16C9 14.3333 10.2 11 15 11'
			stroke='#007BFF'
			stroke-width='1.5'
			stroke-linecap='square'
		/>
	</svg>
);

export default iconFactory(Svg);
