import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Tooltip } from 'antd';
import EmptyReturned16pxActiveIcon from '@/icons/empty-returned-16px-active-icon';
import EmptyReturned21pxIcon from '@/icons/empty-returned-21px-icon';
import Truck16pxActiveIcon from '@/icons/truck-16px-active-icon';
import Truck21pxIcon from '@/icons/truck-21px-icon';
import Warehouse16pxActiveIcon from '@/icons/warehouse-16px-active-icon';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useSafeState } from 'ahooks';
import { CenterTabs, CenterTabsItemProps } from '@/components/center-tabs';
import { useCenterTabs } from '@/components/center-tabs/use-center-tabs';
import { Gap } from '@/components/gap';
import { useTabSwitcher } from '@/components/tab-switcher';
import { MyFocusHeader } from './header';
import { ItemRender } from './item-render';
import { useGetProjectMyFocusPagination } from './use-get-project-my-focus-pagination';
import { useGetProjectMyFocusTotal } from './use-get-project-my-focus-total';

export const FOCUS_TYPE_PICKUP = 'pickup';
export const FOCUS_TYPE_DELIVERED = 'delivered';
export const FOCUS_TYPE_EMPTY_RETURNED = 'empty_returned';

export const MyFocusPanel = () => {
	const { t } = useTranslation();
	const [selectedTime, setSelectedTime] = useSafeState();

	const tabSwitcherProps = useTabSwitcher(
		{
			label: t(`List`),
			value: 'list',
		},
		{
			label: t(`Map`),
			value: 'map',
		},
	);

	const { activeKey, onChange } = useCenterTabs(FOCUS_TYPE_PICKUP);

	const enableMap = tabSwitcherProps.tabSwitcherValue === 'map';

	const { projectId } = useParams();

	const {
		pickup: totalPickup,
		delivery: totalDelivery,
		emptyReturn: totalEmptyReturn,
		loading: totalLoading,
		selectIR: totalSelectIR,
		reset: totalReset,
	} = useGetProjectMyFocusTotal({ projectId, date: selectedTime });

	const {
		data: pickupData,
		loading: pickupLoading,
		mapData: pickupMapData,
		mapLoading: pickupMapLoading,
		// total: pickupTotal,
		selectedIR: pickupSelectedIR,
		// requestNextPage: pickupRequestNextPage,
		selectIR: pickupSelectIR,
		reset: pickupReset,
	} = useGetProjectMyFocusPagination({ type: FOCUS_TYPE_PICKUP, projectId, date: selectedTime });

	const {
		data: deliveredData,
		loading: deliveredLoading,
		mapData: deliveredMapData,
		mapLoading: deliveredMapLoading,
		// total: deliveredTotal,
		selectedIR: deliveredSelectedIR,
		// requestNextPage: deliveredRequestNextPage,
		selectIR: deliveredSelectIR,
		reset: deliveredReset,
	} = useGetProjectMyFocusPagination({ type: FOCUS_TYPE_DELIVERED, projectId, date: selectedTime });

	const {
		data: emptyReturnedData,
		loading: emptyReturnedLoading,
		mapData: emptyReturnedMapData,
		mapLoading: emptyReturnedMapLoading,
		// total: emptyReturnedTotal,
		selectedIR: emptyReturnedSelectedIR,
		// requestNextPage: emptyReturnedRequestNextPage,
		selectIR: emptyReturnedSelectIR,
		reset: emptyReturnedReset,
	} = useGetProjectMyFocusPagination({
		type: FOCUS_TYPE_EMPTY_RETURNED,
		projectId,
		date: selectedTime,
	});

	const items: CenterTabsItemProps[] = [
		{
			key: FOCUS_TYPE_PICKUP,
			label: (
				<span className='body-4-sb'>
					{t(`Pickup`)}
					{` (${totalPickup?.actual_total}/${totalPickup.est_total}) `}
					<Tooltip title={`${totalPickup.actual_total} Actual / ${totalPickup.est_total} EST`}>
						<InfoCircleOutlined />
					</Tooltip>
				</span>
			),
			children: (
				<ItemRender
					icon={<Truck16pxActiveIcon />}
					activeIcon={<Truck21pxIcon />}
					type={activeKey as 'pickup' | 'delivered' | 'empty_returned'}
					enableMap={enableMap}
					points={pickupMapData.map((i) => ({
						id: i.intermodal_region.id,
						label: `${i.total}`,
						latitude: i.intermodal_region.latitude,
						longitude: i.intermodal_region.longitude,
					}))}
					dataSource={pickupData}
					selectedPointId={pickupSelectedIR}
					loading={pickupLoading}
					mapLoading={pickupMapLoading}
					// onNextPage={pickupRequestNextPage}
					onMarkClick={pickupSelectIR}
				/>
			),
		},
		{
			key: FOCUS_TYPE_DELIVERED,
			label: (
				<span className='body-4-sb'>
					{t(`Delivery`)}
					{` (${totalDelivery.actual_total}/${totalDelivery.est_total}) `}
					<Tooltip title={`${totalDelivery.actual_total} Actual / ${totalDelivery.est_total} EST`}>
						<InfoCircleOutlined />
					</Tooltip>
				</span>
			),
			children: (
				<ItemRender
					icon={<Warehouse16pxActiveIcon />}
					activeIcon={<Warehouse16pxActiveIcon />}
					type={activeKey as 'pickup' | 'delivered' | 'empty_returned'}
					enableMap={enableMap}
					points={deliveredMapData.map((i) => ({
						id: i.intermodal_region.id,
						label: `${i.total}`,
						latitude: i.intermodal_region.latitude,
						longitude: i.intermodal_region.longitude,
					}))}
					dataSource={deliveredData}
					selectedPointId={deliveredSelectedIR}
					loading={deliveredLoading}
					mapLoading={deliveredMapLoading}
					// onNextPage={deliveredRequestNextPage}
					onMarkClick={deliveredSelectIR}
				/>
			),
		},
		{
			key: FOCUS_TYPE_EMPTY_RETURNED,
			label: (
				<span className='body-4-sb'>
					{t(`Empty Return`)}
					{`(${totalEmptyReturn.actual_total})`}
				</span>
			),
			children: (
				<ItemRender
					icon={<EmptyReturned16pxActiveIcon />}
					activeIcon={<EmptyReturned21pxIcon />}
					type={activeKey as 'pickup' | 'delivered' | 'empty_returned'}
					enableMap={enableMap}
					points={emptyReturnedMapData.map((i) => ({
						id: i.intermodal_region.id,
						label: `${i.total}`,
						latitude: i.intermodal_region.latitude,
						longitude: i.intermodal_region.longitude,
					}))}
					dataSource={emptyReturnedData}
					selectedPointId={emptyReturnedSelectedIR}
					loading={emptyReturnedLoading}
					mapLoading={emptyReturnedMapLoading}
					// onNextPage={emptyReturnedRequestNextPage}
					onMarkClick={emptyReturnedSelectIR}
				/>
			),
		},
	];

	useEffect(() => {
		pickupReset(selectedTime);
		deliveredReset(selectedTime);
		emptyReturnedReset(selectedTime);
		totalReset(selectedTime);
	}, [selectedTime, pickupReset, deliveredReset, emptyReturnedReset]);

	return (
		<div className='h-full w-full flex flex-col'>
			<MyFocusHeader
				tabSwitcherProps={tabSwitcherProps}
				onSelectedTime={(time) => setSelectedTime(time)}
			/>
			<Gap height='16px' />
			<CenterTabs
				items={items}
				activeKey={activeKey}
				wrapperClassName='flex-grow flex-shrink overflow-hidden'
				bodyWrapperClassName='overflow-hidden'
				onChange={onChange}
			/>
		</div>
	);
};
