import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CircleBlueFillIcon from '@/icons/circle-blue-fill';
import DestinationIcon from '@/icons/destination-icon';
import { FTLQuote } from '@/interfaces/ftl-quote.interface';
import { QuoteBusinessStatus, quoteBusinessStatusMap } from '@/interfaces/quote.interface';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import CenterSpin from '@/components/center-spin';
import { Gap } from '@/components/gap';
import { apiHooks } from '@/hooks/apis';

const QuoteHistoryItem = observer(({ item }: { item: FTLQuote }) => {
	const { t } = useTranslation();

	const navigate = useNavigate();

	return (
		<div
			className='relative box-border overflow-hidden p-[16px] pb-0 w-[336px] h-[130px] flex flex-col justify-center border-[1px] border-solid border-primary-light-01 rounded-[8px] bg-white cursor-pointer'
			onClick={() => navigate(`/ftl-quote-history?quote_id=${item.id}`)}
		>
			{[QuoteBusinessStatus.BOOKED, QuoteBusinessStatus.EXPIRED].includes(item.business_status) && (
				<div className=' absolute top-0 right-0 w-[64px] h-[24px] bg-[#fff2e8] rounded-[0_0_0_4px] text-center body-3-sb text-[#FA541C]'>
					{t(quoteBusinessStatusMap[item.business_status] ?? '')}
				</div>
			)}

			<div className='grid grid-cols-[24px_8px_auto] grid-rows-[auto_auto]'>
				<CircleBlueFillIcon />
				<div></div>
				<div className='body-3-sb text-black'>{`${item.pickup_city_name}, ${item.pickup_state_name} ${item.pickup_zipcode}`}</div>
			</div>

			<Gap height='12px' />

			<div
				style={{
					position: 'absolute',
					left: `27px`,
					top: `52px`,
					height: `15px`,
					border: '1px dashed grey',
				}}
			/>

			<div className='grid grid-cols-[24px_8px_auto] grid-rows-[auto_auto]'>
				<DestinationIcon />
				<div></div>
				<div className='body-3-sb text-black'>{`${item.destination_city_name}, ${item.destination_state_name} ${item.destination_zipcode}`}</div>
				<div></div>
				<div></div>
				<div className='flex justify-end'>
					<div className='footnote-1-r text-theme-1-1-10'>
						{dayjs(item.pickup_date).format('MM/DD/YYYY')}
					</div>
				</div>
			</div>
		</div>
	);
});

export const QuoteHistory = observer(() => {
	const { loading: getFTLQuoteListLoading, data: quoteList } = apiHooks.useGetFTLQuoteList({
		defaultParams: [
			{
				current: 1,
				pageSize: 6,
			},
			{
				params: {},
			},
		],
	});

	if (getFTLQuoteListLoading) return <CenterSpin />;

	return (
		<div className='w-full h-full grid grid-cols-3 grid-rows-[repeat(2,auto)] justify-between gap-y-[16px]'>
			{quoteList.slice(0, 6).map((item) => (
				<QuoteHistoryItem item={item} />
			))}
		</div>
	);
});
