import { useTranslation } from 'react-i18next';
import { Modal, ModalProps, Select } from 'antd';
import { Order } from '@/interfaces/order.interface.ts';
import { useSafeState } from 'ahooks';
import { apiHooks } from '@/hooks/apis';
import { SuccessProjectPanel } from '../success-project-modal.tsx';
import { useSuccessProjectModal } from '../success-project-modal.tsx/use-success-add-project-modal';

export interface AddProjectModalProps {
	modalOptions?: ModalProps;
	orderIds: Order['id'][];
	onClose?: () => void;
}

export const AddProjectModal = ({ modalOptions, orderIds, onClose }: AddProjectModalProps) => {
	const { t } = useTranslation();

	const {
		runAsync: searchProjects,
		loading: searchingProject,
		data: searchProjectData,
	} = apiHooks.useSearchProjects();

	const {
		runAsync: bulkAddToProject,
		loading: addingToProject,
		data: addedProjectData,
	} = apiHooks.useBulkAddToProject();

	const { openingSuccessProjectModal, openProjectModal: openSuccessProjectModal } =
		useSuccessProjectModal();

	const [selectedProjectId, setSelectedProject] = useSafeState<number>();

	const handleClose = () => {
		onClose && onClose();
	};

	const handleSearch = async (name: string) => {
		if (name.length < 2) return;
		await searchProjects({ params: { name } });
	};

	const handleSelect = (value: number) => setSelectedProject(value);

	const handleConfirm = async () => {
		await bulkAddToProject({
			path: {
				projectId: selectedProjectId,
			},
			data: {
				order_ids: orderIds,
			},
		});
		handleClose();
		openSuccessProjectModal();
	};

	return (
		<>
			<Modal
				closable={false}
				okText={t(`Done`)}
				cancelText={t(`Cancel`)}
				cancelButtonProps={{
					className: 'border-primary-regular text-primary-regular',
				}}
				width={612}
				confirmLoading={addingToProject}
				onOk={handleConfirm}
				onCancel={handleClose}
				{...modalOptions}
			>
				<div className='pt-[28px] h-[250px]'>
					<div className=' font-[700] text-[36px] text-center'>{t(`Add to a current project`)}</div>
					<div className='mb-[30px] body-3-r text-center'>
						{t(
							`You can choose to organize your orders in already existed project. Each project has its own critera.`,
						)}
					</div>
					<div className=' flex justify-center'>
						<span className='mr-[16px] body-3-b'>{t(`Project Name`)}</span>
						<Select
							showSearch
							loading={searchingProject}
							placeholder={t(`Search`)}
							options={searchProjectData.map((d) => ({
								label: d.name,
								value: d.id,
							}))}
							className=' min-w-[359px]'
							onSelect={handleSelect}
							onSearch={(text) => handleSearch(text)}
						/>
					</div>
				</div>
			</Modal>
			<SuccessProjectPanel open={openingSuccessProjectModal} onClose={onClose} />
		</>
	);
};
