import { useTranslation } from 'react-i18next';
import { Button, Space, Table, Tag } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';
import { AgentUser } from '@/interfaces/agent-user.interface';
import { updateUser } from '@/services/apis/update-agent-user';
import '@/styles/no-cell-border-table.less';
import { LoadingOutlined } from '@ant-design/icons';
import { useMemoizedFn, useMount } from 'ahooks';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { Gap } from '@/components/gap';
import { apiHooks } from '@/hooks/apis';
import { STATUS_DISABLED, STATUS_ENABLED, STATUS_VERIFYING } from './data';
import useAgentUsersParams from './use-agent-users-params';
import { TIER_MAP } from './use-company-modal/data';
import useUserModal from './use-user-modal';

const AgentUsers = observer(() => {
	const { t } = useTranslation();

	const { parsedSearchParams, changeSearchParams } = useAgentUsersParams();

	const {
		runAsync: getUserList,
		data: userList,
		loading: userListLoading,
		pagination,
		refresh,
	} = apiHooks.useGetAgentUsers();

	const { current, total, pageSize, onChange: changePagination } = pagination;

	const handleChangeUserStatus = async (record: AgentUser) => {
		await updateUser({
			data: {
				id: record.id,
				status: record.status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED,
			},
		});
		refresh();
	};

	const columns: ColumnsType<AgentUser> = [
		{
			title: t('ID'),
			dataIndex: 'id',
			width: 60,
			fixed: 'left',
		},
		{
			title: t('User'),
			dataIndex: 'name',
			width: 120,
			fixed: 'left',
		},
		{
			title: t('Email'),
			dataIndex: 'email',
			width: 120,
		},
		{
			title: t('Company'),
			dataIndex: ['company', 'name'],
			width: 200,
		},
		{
			title: t('Bill to'),
			dataIndex: ['bill_to', 'name'],
			width: 200,
		},
		{
			title: t('Tier'),
			dataIndex: ['company', 'tier_rate'],
			width: 100,
			render: (value) => TIER_MAP[value],
		},
		{
			title: `LTL ${t('Margin')} %`,
			dataIndex: ['company', 'tl_margin_percent'],
			width: 140,
			render: (value) => `${value}%`,
		},
		{
			title: `FTL ${t('Margin')} %`,
			dataIndex: ['company', 'ftl_margin_percent'],
			width: 140,
			render: (value) => `${value}%`,
		},
		{
			title: t('Status'),
			dataIndex: 'status',
			width: 100,
			render: (_, record) => (
				<Tag color={record.status === STATUS_ENABLED ? 'green' : 'red'}>{record.status_name}</Tag>
			),
		},
		{
			title: t('Verified At'),
			dataIndex: 'verified_at',
			width: 120,
			render: (value) => (
				<Tag color={value ? 'green' : 'red'}>
					{value ? dayjs(value).format('MM/DD/YYYY') : 'N/A'}
				</Tag>
			),
		},
		{
			title: t('Actions'),
			key: 'actions',
			width: 140,
			fixed: 'right',
			render: (_, record) => (
				<Space>
					<Button
						disabled={!record.verified_at && record.status === STATUS_VERIFYING}
						type='link'
						className='px-0'
						onClick={() => {
							handleChangeUserStatus(record);
						}}
					>
						{record.status === STATUS_ENABLED ? t('Disable') : t('Enable')}
					</Button>
					<Button
						type='link'
						className='px-0'
						onClick={() => {
							openUserModal(record);
						}}
					>
						{t('Edit')}
					</Button>
				</Space>
			),
		},
	];

	const handleChange: TableProps<AgentUser>['onChange'] = useMemoizedFn((pagination) => {
		const { current, pageSize } = pagination;
		changePagination(current, pageSize);

		changeSearchParams({
			page: current,
			per_page: pageSize,
		});
	});

	useMount(async () => {
		await getUserList(
			{ pageSize: parsedSearchParams.per_page ?? 20, current: parsedSearchParams.page ?? 1 },
			{ params: {} },
		);
		changePagination(parsedSearchParams.page ?? 1, parsedSearchParams.per_page ?? 20);
	});

	const { openUserModal, UserModal } = useUserModal();

	return (
		<div className='h-screen w-full p-[48px] flex flex-col bg-white overflow-hidden'>
			{/* header */}
			<div className='grow-0 shrink-0 w-full h-[44px] flex items-center gap-[8px]'>
				<div className='h3-b text-black'>{t('Users')}</div>
				{userListLoading && <LoadingOutlined />}
				<div className='flex-auto' />
				<Button type='primary' onClick={() => openUserModal()}>
					{t('New User')}
				</Button>
			</div>

			<Gap height='24px' />

			{/* table */}
			<Table
				className='no-cell-border-table'
				rowKey='id'
				columns={columns}
				dataSource={userList}
				bordered
				pagination={{
					position: ['bottomCenter'],
					showSizeChanger: true,
					total,
					current,
					pageSize,
				}}
				scroll={{ scrollToFirstRowOnChange: true, x: 'max-content', y: 'calc(100vh - 259px)' }}
				onChange={handleChange}
			/>

			{/* modal */}
			<UserModal onAfterSave={() => refresh()} />
		</div>
	);
});

export default AgentUsers;
