import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { CreateQuoteProps, CreateQuoteRes } from './interface';

export * from './interface';

export const createQuote = async (props: CreateQuoteProps) => {
	return await axios.post<CreateQuoteRes>(`/tl/ltl/quotes`, props.data, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
