import { useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import { RightOutlined, UpOutlined } from '@ant-design/icons';
import { useMemoizedFn } from 'ahooks';
import './index.less';
import { MenuGroupProps } from './interface';

export const MenuGroup = ({ name, items, mode }: MenuGroupProps) => {
	const navigate = useNavigate();

	// 自动匹配路由
	const getSelectedKey = useMemoizedFn(
		(items: MenuGroupProps['items'], parentKeys?: string[], selectedKeys?: string[]) => {
			if (!items || !Array.isArray(items)) return;

			const submenuKeys = ['drayage', 'ltl', 'ftl'];
			const currentPath = window.location.pathname;
			parentKeys = parentKeys ?? []; // 参与路径匹配
			selectedKeys = selectedKeys ?? []; // 参与 Menu 展示

			items.forEach((i) => {
				const isMenuItem = !submenuKeys.includes(i.key as string);

				parentKeys.push(i.key as string);

				// 如果当前是 子菜单
				if (!isMenuItem) {
					// @ts-ignore
					getSelectedKey(i.children, parentKeys, selectedKeys);
				} else {
					const pathKeys = parentKeys.filter((k) => !submenuKeys.includes(k));
					if (pathKeys.length > 0 && currentPath.startsWith(`/${pathKeys.join('/')}`)) {
						if (pathKeys.length === 1) {
							selectedKeys.push(parentKeys[0]);
						}
						selectedKeys.push(String(i.key));
					}
				}
				parentKeys.pop();
			});

			return selectedKeys;
		},
	);

	return (
		<div className='menu-group'>
			{/* group name */}
			{name && <div className='menu-group-name'>{name}</div>}
			{/* menu */}
			<Menu
				mode={mode}
				onClick={({ key }) => {
					// language 不是 menu
					if (['setting', 'zh-CN', 'en-US'].includes(key)) return;

					navigate(key);
				}}
				items={items}
				defaultOpenKeys={getSelectedKey(items)}
				selectedKeys={getSelectedKey(items)}
				expandIcon={({ isOpen }) =>
					isOpen ? (
						<UpOutlined style={{ color: 'white' }} />
					) : (
						<RightOutlined style={{ color: 'white' }} />
					)
				}
			/>
		</div>
	);
};
