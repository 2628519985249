import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Badge } from 'antd';
import { CenterTabs, CenterTabsItemProps } from '@/components/center-tabs';
import { useCenterTabs } from '@/components/center-tabs/use-center-tabs';
import { Gap } from '@/components/gap';
import { apiHooks } from '@/hooks/apis';
import { ExceptionPanel } from './exception-panel';
import './index.less';
import { ProgressPanel } from './progress-panel';

export const ContainerTrackingPanel = () => {
	const { t } = useTranslation();

	const { projectId } = useParams();

	const { data: exceptionsData, loading: exceptionLoading } = apiHooks.useGetProjectExceptions({
		defaultParams: [{ path: { projectId } }],
	});

	const items: CenterTabsItemProps[] = [
		{
			key: 'Progress',
			label: <span className='body-4-sb'>{t(`Progress`)}</span>,
			children: <ProgressPanel />,
		},
		{
			key: 'Exception',
			label: (
				<div className='flex body-4-sb'>
					{t(`Exception`)}
					<Gap width='4px' />
					<Badge count={exceptionsData?.exceptions?.total ?? 0} />
				</div>
			),
			children: <ExceptionPanel data={exceptionsData} loading={exceptionLoading} />,
		},
	];

	const { activeKey, onChange } = useCenterTabs(items[0].key);

	return (
		<div className='h-full w-full flex flex-col'>
			<div className='flex-grow-0 flex-shrink-0 body-3-b'>{t(`Container Tracking`)}</div>
			<Gap height='8px' />
			<CenterTabs
				activeKey={activeKey}
				items={items}
				gap='8px'
				wrapperClassName='container-tracking-panel-tabs flex-grow flex-shrink overflow-hidden'
				bodyWrapperClassName='overflow-hidden'
				onChange={onChange}
			/>
		</div>
	);
};
