import { useTranslation } from 'react-i18next';
import './index.less';

const Item = ({ subtitle, content }: { subtitle: string; content: string }) => {
	return (
		<div className='mb-[36px]'>
			<div className='mb-[12px] flex items-center'>
				<div className='mr-[10px] w-[10px] h-[10px] flex justify-center items-center'>
					<div className='w-[8px] h-[8px] rounded-[50%] bg-white' />
				</div>
				<div className='h3-b text-white'>{subtitle}</div>
			</div>

			<div className='flex items-center'>
				<div className='mr-[10px] w-[10px] h-[10px] flex justify-center items-center'></div>
				<div className='w-[384px] body-3-m text-white'>{content}</div>
			</div>
		</div>
	);
};

export default () => {
	const { t } = useTranslation();

	const items = [
		{
			subtitle: t('Instant Quotes'),
			content: t('Access real-time online pricing inquiries for all major North American routes.'),
		},
		{
			subtitle: t('Top-tier Drayage Service'),
			content: t(
				'Enjoy premium service with a vast carrier network, specialized team and 24/7 support.',
			),
		},
		{
			subtitle: t('Visibility'),
			content: t(`Monitor your shipment's real-time updates for significant milestones.`),
		},
		{
			subtitle: t('API Integration'),
			content: t(
				'Our API is a seamless, powerful solution for integrating DrayEasy into your platform.',
			),
		},
	];

	return (
		<div className='introduction'>
			<div className='mb-[36px] h1-b text-white'>{t('Why DrayEasy?')}</div>

			{items.map((item) => (
				<Item {...item} />
			))}
		</div>
	);
};
