import { LOCAL_ACCESS_TOKEN_KEY } from './constant';

class AccessTokenManager {
	public getAccessToken() {
		const accessToken = localStorage.getItem(LOCAL_ACCESS_TOKEN_KEY);
		return `Bearer ${accessToken}`;
	}

	public setAccessToken(accessToken: string) {
		localStorage.setItem(LOCAL_ACCESS_TOKEN_KEY, accessToken);
	}

	public clearAccessToken() {
		localStorage.removeItem(LOCAL_ACCESS_TOKEN_KEY);
	}

	public isAccessTokenValid() {
		const accessToken = localStorage.getItem(LOCAL_ACCESS_TOKEN_KEY);
		return !!accessToken;
	}
}

const accessTokenManager = new AccessTokenManager();

export default accessTokenManager;
