import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import PopularIcon from '@/icons/popular-icon';
import { City } from '@/interfaces/city.interface';
import { IntermodalRegion } from '@/interfaces/intermodal-region.interface';
import { getHistory } from '@/services/apis';
import { History } from '@/services/apis/get-history/interface';
import { useGlobalContext, useSearchRateContext } from '@/services/contexts';

const { Paragraph } = Typography;

const HistoryItem = ({
	history,
	onClick,
}: {
	history: History;
	onClick: (toCity: City, intermodalRegion: IntermodalRegion) => void;
}) => {
	const [suffix, setSuffix] = useState('');

	return (
		<div
			key={history.id}
			className=' p-[0px_10px] border-[1px] border-solid border-[#80BDFF] w-[270px] h-[46px] flex-center bg-[#EAF4FE] rounded-[8px] cursor-pointer '
			onClick={() => onClick(history.to_city, history.intermodal_region)}
		>
			<Paragraph
				style={{ margin: 0 }}
				className='font-[500] text-[14px] text-primary-dark-01'
				ellipsis={{
					tooltip: `${history.to_city?.full_name} <- ${history.intermodal_region?.name}`,
					rows: 2,
					suffix: suffix,
					onEllipsis(ellipsis) {
						ellipsis && setSuffix('...');
					},
				}}
			>
				{history.to_city?.name}, {history.to_city?.state} {'<-'} {history.intermodal_region?.name}
			</Paragraph>
		</div>
	);
};

export const HistoryGallery = () => {
	const { t } = useTranslation();

	const [histories, setHistories] = useState<History[]>([]);

	const searchRateContext = useSearchRateContext();
	const { cacheContext } = useGlobalContext();

	const getHistories = async () => {
		const res = await getHistory();
		setHistories(res.data.data);
	};

	const handleClick = (toCity: City, intermodalRegion: IntermodalRegion) => {
		searchRateContext.selectedToCity = toCity;
		searchRateContext.selectedIntermodalRegion =
			cacheContext.intermodalRegions.find((i) => i.id === intermodalRegion.id) ?? intermodalRegion;
	};

	useEffect(() => {
		getHistories();
	}, []);

	return (
		<div className='relative z-0 mt-[150px] ml-[80px] mr-[80px]'>
			<div className='relative top-[20px] pt-[5px] pl-[37px] w-[297px] h-[48px] rounded-[10px] bg-[#80BDFF] font-[Note Sans] font-[700] text-[#ffffff] text-[16px] leading-[17px]'>
				{/* icon */}
				<PopularIcon className='mr-[13px]' />
				<span>{t('History routes')}</span>
			</div>
			<div className=' relative z-10  grid grid-cols-[repeat(auto-fill,270px)] gap-x-[80px] gap-y-[30px] pt-[32px] pb-[32px] pl-[35px] pr-[35px] border-[1px] border-solid border-[#80BDFF] rounded-[10px] bg-[#ffffff]'>
				{histories &&
					histories.map((history) => <HistoryItem history={history} onClick={handleClick} />)}
			</div>
		</div>
	);
};
