import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Layout } from 'antd';
import { Order } from '@/interfaces/order.interface';
import { getOrder } from '@/services/apis';
import { observer } from 'mobx-react-lite';
import CenterSpin from '@/components/center-spin';
import OrderHeader from './order-header';
import OrderSider from './order-sider';

const { Content } = Layout;

export default observer(() => {
	const { orderId, containerId } = useParams();
	const [order, setOrder] = useState<Order>();
	const [isFetching, setIsFetching] = useState(false);

	useEffect(() => {
		setIsFetching(true);
		getOrder({ path: { orderId: Number(orderId) } })
			.then((res) => setOrder(res.data.data))
			.catch(() => {})
			.finally(() => setIsFetching(false));
	}, [orderId]);

	if (isFetching) return <CenterSpin size='large' />;

	if (!order) return null;

	return (
		<Layout className='h-full'>
			<OrderHeader order={order} />
			<Layout className='h-full' hasSider>
				<OrderSider order={order} selectedContainerId={Number(containerId)} />
				<Content>
					<Outlet />
				</Content>
			</Layout>
		</Layout>
	);
});
