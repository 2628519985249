import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';

export const regenerateApiToken = async () => {
	return axios.patch('/users/regenerateToken', null, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};

export const deleteApiToken = () => {
	return axios.patch('/users/deleteToken', null, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
