import { useLTLContext } from '@/services/contexts';
import { ArrowRightOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';

export const ShipmentInfoRoute = observer(() => {
	const ltlContext = useLTLContext();

	return (
		<div className='flex items-center gap-[4px] h4-b text-[#333333]'>
			<div>{`${ltlContext.pickupAddress.city.full_name.split(',').slice(0, 2).join(',')}`}</div>
			<div>
				<ArrowRightOutlined />
			</div>
			<div>{`${ltlContext.destinationAddress.city.full_name
				.split(',')
				.slice(0, 2)
				.join(',')}`}</div>
		</div>
	);
});
