import React from 'react';
import portSvg from '@/assets/port.svg';

const PortIcon = (
	props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
) => {
	return <img {...props} src={portSvg} />;
};

export default PortIcon;
