import { baseURL } from '..';
import { downloadFactory } from '../download-factory';
import { DownloadInvoiceProps } from './interface';

export const downloadInvoice = async (
	props: DownloadInvoiceProps,
	config?: { preview?: boolean },
) => {
	const { invoiceId } = props.path;
	const url = `${baseURL}newOrders/${invoiceId}/invoicePDF`;

	downloadFactory(url, props.other.fileName, { preview: config?.preview });
};
