import { useState } from 'react';
import PathIcon from '@/icons/path-icon';
import RcSteps from 'rc-steps';
import { StepProps as RcStepProps } from 'rc-steps/lib/Step';

const useSteps = (items: RcStepProps[]) => {
	const [current, setCurrent] = useState(0);

	const UnshowLine = () => (
		<div
			style={{
				width: '125px',
				height: '2px',
				backgroundColor: 'transparent',
			}}
		></div>
	);

	const WaitLine = () => (
		<div
			style={{
				width: '125px',
				height: '2px',
				backgroundColor: '#AAD4FA',
			}}
		></div>
	);

	const ProcessLine = () => (
		<div
			style={{
				width: '125px',
				height: '2px',
				backgroundColor: '#186ADE',
			}}
		></div>
	);

	const FinishLine = ProcessLine;

	const ItemRender = (item: RcStepProps) => {
		const itemIndex = items.findIndex((i) => i.title === item.title);

		const status = itemIndex === current ? 'process' : itemIndex < current ? 'finish' : 'wait';

		const getRenderLine = () => {
			if (status === 'finish') return <FinishLine />;
			if (status === 'process') return <ProcessLine />;
			if (status === 'wait') return <WaitLine />;
		};

		const getRenderNode = () => {
			if (status === 'finish')
				return (
					<div>
						{/* node */}
						<div className='flex justify-center items-center w-[18px] h-[18px] rounded-[50%] bg-[#186ADE]'>
							<PathIcon />
						</div>
					</div>
				);
			if (status === 'process')
				return (
					<div>
						{/* node */}
						<div className='flex justify-center items-center w-[18px] h-[18px] border-[2px] border-solid border-[#186ADE] rounded-[50%] bg-[#AAD4FA]' />
					</div>
				);
			if (status === 'wait')
				return (
					<div>
						{/* node */}
						<div className='flex justify-center items-center w-[18px] h-[18px] border-[2px] border-solid border-[#AAD4FA] rounded-[50%] bg-[#FFFFFF]' />
					</div>
				);
		};

		return (
			<div>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					{/* 前进度 */}
					{itemIndex !== 0 ? getRenderLine() : <UnshowLine />}
					{/* 节点 */}
					{getRenderNode()}
					{/* 后进度 */}
					{itemIndex !== items.length - 1 ? getRenderLine() : <UnshowLine />}
				</div>
				{/* title */}
				<div className=' text-center font-[600] text-[18px] text-[#1C2B36]'>{item.title}</div>
			</div>
		);
	};

	const Steps = () => (
		<RcSteps
			current={current}
			style={{ height: 'fit-content', display: 'flex' }}
			itemRender={ItemRender}
			items={items}
		/>
	);

	return {
		Steps,
		current,
		setCurrent,
	};
};

export default useSteps;
