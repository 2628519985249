import { Divider } from 'antd';
import { Gap } from '@/components/gap';
import { ReferenceAndInstructions } from './reference-and-instructions';
import { ShipmentDetailItem } from './shipment-detail-item';
import { LTLShipmentItemList } from './shipment-item-list';

export const LTLShipmentContent = () => {
	return (
		<div className='w-[710px] flex flex-col'>
			{/* title */}
			<Divider />

			<Gap height='24px' />

			<ReferenceAndInstructions />

			<Divider />

			<LTLShipmentItemList />

			<Divider />

			<ShipmentDetailItem type='pickup' />

			<Divider />

			<ShipmentDetailItem type='destination' />
		</div>
	);
};
