import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetBillingCodesRes } from './interface';

export const getBillingCodes = async () => {
	return await axios.get<GetBillingCodesRes>(`/billingCodes`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
