import { useTranslation } from 'react-i18next';
import { Input, message } from 'antd';
import { GetProjectDataItem } from '@/services/apis';
import { useSetState } from 'ahooks';
import { Gap } from '@/components/gap';
import { RequiredLabel } from '@/components/required-label';
import { SuccessProjectPanel } from '@/components/success-project-modal.tsx';
import { useSuccessProjectModal } from '@/components/success-project-modal.tsx/use-success-add-project-modal';
import { apiHooks } from '@/hooks/apis';
import { useRule } from '@/hooks/use-rule';
import { CreateProjectPanelProps, CreateProjectPanelValue } from '..';
import { Buttons, ButtonsProps } from '../buttons';

export type FirstPanelValue = Pick<
	CreateProjectPanelValue,
	'name' | 'description' | 'memo' | 'orderId'
>;

export interface FirstPanelProps
	extends Pick<CreateProjectPanelProps, 'type'>,
		Pick<ButtonsProps, 'goPre' | 'goNext'> {
	projectId: GetProjectDataItem['id'];
	value: FirstPanelValue;
	onChange: (value: FirstPanelValue) => void;
	onClose: () => void;
}

export const FirstPanel = ({
	type,
	projectId,
	value,
	onChange,
	onClose,
	goPre,
	goNext,
}: FirstPanelProps) => {
	const { t } = useTranslation();

	const { getFirstError, trigger, removeError } = useRule();

	const nameStatus = getFirstError('name');

	const { openingSuccessProjectModal, openProjectModal: openSuccessProjectModal } =
		useSuccessProjectModal();

	const [localValue, setLocalValue] = useSetState<FirstPanelValue>(value);

	const { runAsync: editProject, loading: editProjectLoading } = apiHooks.useEditProject();

	const { runAsync: multiOrdersCreateProject, loading: multiOrdersCreateProjectLoading } =
		apiHooks.useMultiOrdersCreateProject();

	const notGoToNext = type === 'edit' || type === 'batch-create';

	const handleNext = async () => {
		if (localValue.name === '') {
			trigger('name', 'required', t(`Please enter project name`));
			return;
		}

		if (type === 'create') {
			onChange(localValue);
			goNext();
		} else if (type === 'edit') {
			await editProject({
				path: {
					projectId,
				},
				data: {
					name: localValue.name,
					description: localValue.description,
					memo: localValue.memo,
				},
			});
			message.success(t(`Update success`));
			onClose();
		} else if (type === 'batch-create') {
			await multiOrdersCreateProject({
				data: {
					name: localValue.name,
					description: localValue.description,
					memo: localValue.memo,
					order_id: localValue.orderId,
				},
			});
			openSuccessProjectModal();
		} else {
			console.error('[SecondPanel] unexpected type', type);
		}
	};

	return (
		<>
			<div className='w-full h-full flex flex-col'>
				<div className='grow shrink'>
					<div>
						<div className='body-3-b text-grey-01'>
							<RequiredLabel label={t('Project Name')} />
						</div>
						<Input
							disabled={type === 'edit'}
							value={localValue.name}
							status={nameStatus.status}
							onChange={(e) => {
								const name = e.target.value;
								setLocalValue({ name });
								if (name !== '') removeError('name', 'required');
							}}
						/>
						{nameStatus.reason && (
							<div className='foot-note-1-r text-additional-warning'>{nameStatus.reason}</div>
						)}
					</div>
					<Gap height='24px' />

					<div>
						<div className='body-3-b text-grey-01'>{t('Project Description')}</div>
						<Input.TextArea
							value={localValue.description}
							onChange={(e) => setLocalValue({ description: e.target.value })}
						/>
						<Gap height='8px' />
						<div className='foot-note-2-r text-grey-01'>
							{t(
								`When new created orders satisfied the need of this criteria, it will be automatically added to this project. You can also add more IR cities in the futrue.`,
							)}
						</div>
					</div>
					<Gap height='24px' />

					<div>
						<div className='body-3-b text-grey-01'>{t('Note')}</div>
						<Input.TextArea
							value={localValue.memo}
							onChange={(e) => setLocalValue({ memo: e.target.value })}
						/>
					</div>
				</div>

				<Buttons
					text2={notGoToNext ? t(`Done`) : t(`Next`)}
					onClick2={handleNext}
					preventDefaultClick2
					button1Props={{
						className: 'hidden',
					}}
					button2Props={{
						loading: editProjectLoading || multiOrdersCreateProjectLoading,
					}}
					goPre={goPre}
					goNext={goNext}
				/>
			</div>
			<SuccessProjectPanel open={openingSuccessProjectModal} onClose={onClose} />
		</>
	);
};
