import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

// import timezone from 'timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const today = (): dayjs.Dayjs => {
	return dayjs().tz('America/Los_Angeles');
};

export const dateToLa = (date: Dayjs): Dayjs => {
	return date.tz('America/Los_Angeles');
};

export const isTomorrowOrTodayOrEarlierLa = (date: Dayjs): boolean => {
	const _today = today();

	const tomorrow = _today.add(1, 'day');

	return (
		date.isSame(tomorrow.format('YYYY-MM-DD'), 'day') ||
		date.isSame(_today.format('YYYY-MM-DD'), 'day') ||
		date.isBefore(_today.format('YYYY-MM-DD'))
	);
};
