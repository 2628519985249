import React from 'react';
import circleBlueFillSvg from '@/assets/circle-blue-fill.svg';

const CircleBlueFillIcon = (
	props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
) => {
	return <img {...props} src={circleBlueFillSvg} />;
};

export default CircleBlueFillIcon;
