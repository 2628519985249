import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button } from 'antd';
import { GetProjectExceptionsContainer, GetProjectExceptionsRes } from '@/services/apis';
import { HistoryOutlined } from '@ant-design/icons';
import CenterSpin from '@/components/center-spin';
import { ContainerDetailModal, useContainerDetailModal } from '@/components/container-detail-modal';
import { Gap } from '@/components/gap';
import { ProjectExceptions } from '@/components/project-exceptions';
import { NoExceptionEmpty } from '@/pages/projects/no-exception-empty';
import { ResolvedExceptionModal } from './resolved-exception-modal';
import { useResolvedExceptionModal } from './use-resolved-exception-modal';

interface ExceptionItemProps {
	projectId: string | number;
	container: GetProjectExceptionsContainer;
}

const ExceptionItem = ({ projectId, container }: ExceptionItemProps) => {
	const { number, final_port_eta, trouble_status } = container;

	const { openingContainerDetailModal, openContainerDetailModal, closeContainerDetailModal } =
		useContainerDetailModal();

	return (
		<>
			<div
				style={{ borderBottom: '1px solid #E7E7E7' }}
				className=' box-border w-full h-[80px] p-[12px_16px]'
			>
				<div className='flex justify-between'>
					<div
						className=' foot-note-1-m text-black cursor-pointer'
						onClick={openContainerDetailModal}
					>{`# ${number}`}</div>
					<div className=' foot-note-1-r text-grey-01'>{final_port_eta}</div>
				</div>

				<Gap height='12px' />

				<ProjectExceptions exceptions={trouble_status} />
			</div>
			{openingContainerDetailModal && (
				<ContainerDetailModal
					projectId={projectId}
					orderId={container.order.id}
					containerId={container.id}
					open={openingContainerDetailModal}
					onClose={closeContainerDetailModal}
				/>
			)}
		</>
	);
};

export const ExceptionPanel = ({
	data,
	loading,
}: {
	data: GetProjectExceptionsRes;
	loading: boolean;
}) => {
	const { t } = useTranslation();

	const { projectId } = useParams();

	const { openingResolvedExceptionModal, openResolvedExceptionModal, closeResolvedExceptionModal } =
		useResolvedExceptionModal();

	if (loading) return <CenterSpin />;

	return (
		<>
			<div className='flex flex-col items-center h-full'>
				{!data?.exceptions?.total || data.exceptions.total === 0 ? (
					<NoExceptionEmpty />
				) : (
					<div className='w-full flex-grow flex-shrink overflow-y-scroll'>
						{/* { number: 'EITU1335471', final_port_eta: "Nov 23,2022", trouble_statuses: ['Cet Exam', 'Customer Hold'] } */}
						{(data?.exceptions?.containers ?? []).map((container) => (
							<ExceptionItem container={container} projectId={projectId} />
						))}
					</div>
				)}
				<div className='flex-grow-0 flex-shrink-0 flex justify-center'>
					<Button
						type='text'
						icon={<HistoryOutlined />}
						className='body-4-m text-grey-01'
						onClick={openResolvedExceptionModal}
					>
						{t(`History`)}
					</Button>
				</div>
			</div>
			{openingResolvedExceptionModal && (
				<ResolvedExceptionModal
					open={openingResolvedExceptionModal}
					dataSource={data?.history_exceptions?.containers ?? []}
					onClose={closeResolvedExceptionModal}
				/>
			)}
		</>
	);
};
