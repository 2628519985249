import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DatePicker } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import { useBoolean, useSafeState } from 'ahooks';
import dayjs, { Dayjs } from 'dayjs';
import { Gap } from '@/components/gap';
import { SimplePagination } from '@/components/simple-pagenation';
import { TabSwitcher, useTabSwitcher } from '@/components/tab-switcher';
import { today } from '@/utils/today.type';

interface TimePickerProps {
	label?: React.ReactNode;
	value: Dayjs;
	onChange: (value: Dayjs) => void;
}

const TimePicker = ({ label, value, onChange }: TimePickerProps) => {
	const [openingDatePicker, { toggle, set }] = useBoolean();

	return (
		<div className='h-full relative flex items-center'>
			{/* trigger */}
			<DatePicker
				open={openingDatePicker}
				defaultValue={dayjs(dayjs(), 'YYYY-MM-DD')}
				className=' absolute bottom-0 -z-10 opacity-0 w-[1px] h-[1px]'
				onChange={(date) => {
					onChange(date);
				}}
				onOpenChange={(o) => !o && set(o)}
			/>

			{/* show */}
			<div className='h-full body-4-m' onClick={toggle}>
				{label ?? value.format('MMMM D')}
			</div>
		</div>
	);
};

export interface MyFocusHeaderProps {
	tabSwitcherProps: ReturnType<typeof useTabSwitcher>;
	onSelectedTime: (time) => void;
}

export const MyFocusHeader = ({ tabSwitcherProps, onSelectedTime }: MyFocusHeaderProps) => {
	const { t } = useTranslation();

	const { item1, item2, tabSwitcherValue, onTabSwitcherChange } = tabSwitcherProps;

	const [selectedTime, setSelectedTime] = useSafeState(dayjs());

	useEffect(() => {
		onSelectedTime(dayjs(selectedTime).format('YYYY-MM-DD'));
	}, [selectedTime]);

	return (
		<div className='flex justify-between items-center'>
			<div className='flex '>
				<div className='flex-grow-0 flex-shrink-0 flex items-center body-3-b'>{t(`My Focus`)}</div>
				<Gap width='16px' />
				<SimplePagination
					label={
						<TimePicker
							label={
								<div className='w-full h-full flex items-center cursor-pointer'>
									{selectedTime.format('ddd, M/D')}
									<Gap width='4px' />
									<CalendarOutlined />
								</div>
							}
							value={selectedTime}
							onChange={setSelectedTime}
						/>
					}
					onPre={() => setSelectedTime((t) => t.subtract(1, 'day'))}
					onNext={() => setSelectedTime((t) => t.add(1, 'day'))}
				/>
				<Gap width='16px' />
				<Button type='text' ghost onClick={() => setSelectedTime(today())}>
					<span className='underline'>{t('Today')}</span>
				</Button>
			</div>

			<div className='flex items-center'>
				<TabSwitcher
					item1={item1}
					item2={item2}
					value={tabSwitcherValue}
					onChange={onTabSwitcherChange}
				/>
			</div>
		</div>
	);
};
