import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';

export const getDocUrl = async (id: string, target: string, targetId: number) => {
	return await axios.get<string>(`newDocuments/${id}/${target}/${targetId}/url`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
