import { Form, FormProps } from 'antd';
import SearchIcon from '@/icons/search-icon';
import { CargoSelectValue } from '@/interfaces/cargo-select-value.enum';
import { useSearchRateContext } from '@/services/contexts';
import { observer } from 'mobx-react-lite';
import CenterSpin from '@/components/center-spin';
import { CargoSelect } from '@/pages/search-rate/components/search-rate-form/components/cargo-select';
import { CntrSizeGroup } from '@/pages/search-rate/components/search-rate-form/components/cntr-size-group';
import { IntermodalRegionSelect } from '@/pages/search-rate/components/search-rate-form/components/intermodal-region-select';
import { ResidentialAddressCheckbox } from '@/pages/search-rate/components/search-rate-form/components/residential-address-checkbox';
import { ToCitySelect } from '@/pages/search-rate/components/search-rate-form/components/to-city-select';
import useSearchRatesParams from '@/pages/search-rate/hooks/use-search-rates-params';
import { OnSearchFormFinish } from '@/pages/search-rate/interface';
import calcIsOverWeight from '@/utils/calc-is-over-weight';

interface SearchResultFormProps {
	onFinish: OnSearchFormFinish;
}

export default observer(function ({ onFinish }: SearchResultFormProps) {
	const [form] = Form.useForm();
	const searchRateContext = useSearchRateContext();
	const { cleanedSearchParams } = useSearchRatesParams();

	const selectedSearchData = searchRateContext.selectedSearchData;

	// const remainingRateSearchLimit = searchRateContext.remainingRateSearchLimit;
	// const rateSearchLimit = searchRateContext.rateSearchLimit;

	if (searchRateContext.isFetching) return <CenterSpin size='default' />;

	const { is_residential, cargo_types, cntr_size, weight, weight_unit, return_address_zipcode } =
		cleanedSearchParams;

	const handleValuesChange: FormProps['onValuesChange'] = (changedValue, values) => {
		watchContainerChanges(changedValue, values);
	};

	// 自动标记 OW
	const watchContainerChanges: FormProps['onValuesChange'] = (changedValue, values) => {
		const weight = changedValue.weight;
		if (!weight || weight === '') return;
		const cargo_types = values.cargo_types ?? [];
		const isReefer = cargo_types.includes(CargoSelectValue.RF);
		const size = Number(values.cntr_size);
		const unit = values.weight_unit;

		const preIsOverweight = cargo_types.includes(CargoSelectValue.OW);
		const isOverweight = calcIsOverWeight(isReefer, size, Number(weight), unit);

		if (isOverweight && !preIsOverweight) {
			form.setFieldValue('cargo_types', [...cargo_types, CargoSelectValue.OW]);
		} else if (!isOverweight && preIsOverweight) {
			form.setFieldValue(
				'cargo_types',
				cargo_types.filter((v) => v !== CargoSelectValue.OW),
			);
		}
	};

	return (
		<Form
			onFinish={(values) => onFinish(values, { navigateIfParamsValid: true })}
			form={form}
			initialValues={{
				is_residential,
				cargo_types,
				cntr_size,
				weight,
				weight_unit,
				return_address_zipcode,
			}}
			onValuesChange={handleValuesChange}
			className='m-auto pl-[24px] text-[28px] font-[700] text-[#ffffff]'
		>
			<div className='flex items-center pr-[12px] h-[64px] rounded-[8px] bg-[#ffffff]'>
				<Form.Item noStyle name='to_city_id'>
					<ToCitySelect
						formItemKey='to_city_id'
						type='result'
						defaultCities={
							searchRateContext.selectedToCity ? [searchRateContext.selectedToCity] : undefined
						}
						defaultValue={searchRateContext.selectedToCity?.id}
						wrapperClass='mr-[10px] flex-grow w-[100px]'
						className='flex-grow'
						variant='borderless'
						onClear={() => {
							searchRateContext.selectedToCity = null;
							searchRateContext.selectedSearchData = null;
						}}
					/>
				</Form.Item>

				<Form.Item noStyle name='is_residential'>
					<div className='flex w-[200px]'>
						<ResidentialAddressCheckbox
							formItemKey='is_residential'
							className='font-[400] text-[12px] text-[#007BFF]'
						/>
					</div>
				</Form.Item>

				<Form.Item noStyle name='intermodal_region_id'>
					<IntermodalRegionSelect
						formItemKey='intermodal_region_id'
						type='result'
						defaultIntermodalRegions={
							searchRateContext.selectedIntermodalRegion
								? [searchRateContext.selectedIntermodalRegion]
								: undefined
						}
						defaultValue={searchRateContext.selectedIntermodalRegion?.id}
						wrapperClass='flex-grow w-[100px]'
						variant='borderless'
						onClear={() => {
							searchRateContext.selectedIntermodalRegion = null;
							searchRateContext.selectedSearchData = null;
						}}
					/>
				</Form.Item>

				<div className=' flex items-center flex-grow w-[100px]'>
					<Form.Item noStyle name='cargo_types'>
						<CargoSelect
							formItemKey='cargo_types'
							className='flex-grow'
							wrapperClass='w-full flex'
							variant='borderless'
						/>
					</Form.Item>
				</div>

				<div className='flex items-center mr-[12px] w-[100px] text-[blue]'>
					<Form.Item noStyle name='cntr_size'>
						<CntrSizeGroup formItemKey='cntr_size' type='select' />
					</Form.Item>
				</div>

				{/* button */}
				<div className='flex justify-center items-center w-[48px] h-[48px] rounded-[50%] bg-[#007BFF] cursor-pointer'>
					<SearchIcon onClick={() => form.submit()} />
				</div>
			</div>
		</Form>
	);
});
