import dayjs from 'dayjs';

export const formatMMDDYYYYToMMMDDYYYY = (date: string) => {
	if (!date) return '';
	return dayjs(date, 'MM/DD/YYYY').format('MMM DD YYYY');
};

export const formatMMDDYYYYToYYYYMMDD = (date: string) => {
	if (!date) return '';
	return dayjs(date, 'MM/DD/YYYY').format('YYYY-MM-DD');
};

export const formateTimeToHMMA = (date: string) => {
	if (!date) return '';
	return dayjs(date).format('h:mm A');
};

export const formateActEstTime = ({
	act,
	est,
}: {
	act: string | undefined;
	est: string | undefined;
}) => {
	return act ? formatMMDDYYYYToYYYYMMDD(act) : est ? `Est. ${formatMMDDYYYYToYYYYMMDD(est)}` : '';
};

export const formatTimeToYYYYMMDD = (date: string) => {
	if (!date || date.length === 0) return '';
	return dayjs(date).format('YYYY-MM-DD');
};
