import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, message } from 'antd';
import { Order } from '@/interfaces/order.interface';
import { sendMessage } from '@/services/apis';
import { SendOutlined } from '@ant-design/icons';
import Avatar from '@/components/avatar';

const TableExpandedRowMessagesTab = ({ order, refresh }: { order: Order; refresh: () => void }) => {
	const { t } = useTranslation();

	const [newMessage, setNewMessage] = useState('');
	const [isSending, setIsSending] = useState(false);
	const handleSend = async () => {
		setIsSending(true);
		sendMessage({
			path: { orderId: order.id },
			data: { body: newMessage },
		})
			.then(() => {
				message.success('send message successfully');
				refresh();
			})
			.catch((error) => {
				console.error(error);
				message.error(t('send error'));
			})
			.finally(() => {
				setIsSending(false);
			});
	};

	return (
		<div className='h-[362px]'>
			<div className='pl-[20px] h-[258px] overflow-y-scroll bg-white'>
				{order.messages.map((message) => (
					<div
						key={message.id}
						className='p-[10px_64px_12px_0] flex items-center border-t-0 border-r-0 border-l-0 border-b-[1px]  border-solid border-grey-03'
					>
						<Avatar className='mr-[16px] w-[48px] h-[48px]' name={message.messager.name} />
						<div>
							<div>
								<span className='mr-[8px] body-4-b'>{message.messager.name}</span>
								<span className='foot-note-1-m text-grey-02'>{message.created_at}</span>
							</div>
							<div className='body-4-r'>{message.body}</div>
						</div>
					</div>
				))}
			</div>
			<div className='m-[16px_24px] p-[8px_8px_8px_16px] h-[64px] flex justify-between items-center border-[1px] border-solid border-grey-02 rounded-[4px] text-grey-02 shadow-[0px_4px_10px_0px_#0000000D] '>
				<Input.TextArea
					value={newMessage}
					onChange={(e) => setNewMessage(e.target.value)}
					variant='borderless'
					autoSize={{ minRows: 2, maxRows: 2 }}
				/>
				<Button
					className='!w-[48px] !h-[48px] !rounded-[4px]'
					type='primary'
					loading={isSending}
					icon={<SendOutlined />}
					disabled={!newMessage}
					onClick={handleSend}
				></Button>
			</div>
		</div>
	);
};

export default TableExpandedRowMessagesTab;
