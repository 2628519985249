import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { updateCustomerPrepayOrderProps } from './interface';

export const updateCustomerPrepayOrder = async (props: updateCustomerPrepayOrderProps) => {
	return await axios.patch(
		`agent/customers/prepayOrders/${props.data.id}/status`,
		{
			...props.data,
		},
		{
			headers: {
				Authorization: accessTokenManager.getAccessToken(),
			},
		},
	);
};
