import React from 'react';
import DropSvg from '@/assets/drop.svg';

const DropIcon = (
	props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
) => {
	return <img {...props} src={DropSvg} />;
};

export default DropIcon;
