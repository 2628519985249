import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { DeleteProjectProps, DeleteProjectRes } from './interface';

export const deleteProject = async (props: DeleteProjectProps) => {
	const { projectId } = props.path;

	return await axios.delete<DeleteProjectRes>(`/projects/${projectId}`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
