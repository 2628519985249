import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { requestMoreRateProps, requestMoreRateRes } from './interface';

export const requestMoreRate = async (props: requestMoreRateProps) => {
	return await axios.post<requestMoreRateRes>(`/newRequestedRates`, props.data, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
