import { useTranslation } from 'react-i18next';
import { Select, SelectProps } from 'antd';
import { useGlobalContext } from '@/services/contexts';

export const IRSelector = (props: Omit<SelectProps, 'options'>) => {
	const { t } = useTranslation();

	const { cacheContext } = useGlobalContext();

	const options = cacheContext.intermodalRegions.map((i) => ({
		label: i.name,
		value: i.id,
	}));

	return (
		<Select
			placeholder={t(`Select IR`)}
			options={options}
			optionFilterProp='label'
			showSearch
			allowClear
			className='w-full'
			{...props}
		/>
	);
};
