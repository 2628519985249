import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { GetRecommendRatesData } from '@/services/apis/get-recommend-rates/interface';
import { useGlobalContext } from '@/services/contexts';
import { SearchRateContext, useSearchRateContext } from '@/services/contexts/search-rate';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import qs from 'qs';
import { IndustryNews } from './book/components/route-info/components/industry-news';
import { HistoryGallery } from './components/history';
import { MapBox } from './components/mapbox';
import { SearchRateForm } from './components/search-rate-form';
import { TerminalIcon } from './components/terminal-icon';
import useRecommendRoutesModal from './hooks/use-recommend-routes-modal';
import useRequestRateModal from './hooks/use-request-rate-modal';
import useSearchRatesParams from './hooks/use-search-rates-params';
import { OnSearchFormFinish } from './interface';
import { SearchRateResult } from './search-rate-result';

const SearchRateApp = observer(() => {
	const navigate = useNavigate();

	const { cacheContext } = useGlobalContext();

	const { isSearchRatesParamsValid, cleanedSearchParams } = useSearchRatesParams();

	const { openModal, RequestRateModal } = useRequestRateModal();

	const searchRateContext = useSearchRateContext();

	const { screenContext } = useGlobalContext();

	const {
		openModal: openRecommendRoutesModal,
		closeModal: closeRecommendRoutesModal,
		RecommendRoutesModal,
	} = useRecommendRoutesModal();

	const to = searchRateContext.selectedToCity;
	const start = searchRateContext.selectedIntermodalRegion;

	const terminalPoints =
		start?.terminals && !start?.has_ocean_port
			? start.terminals.map((t) => ({
					...t,
					icon: <TerminalIcon facility_type={t.facility_type} isActive={false} />,
					popup: t.full_address,
			  }))
			: [];

	const handleRateClick = (rate: GetRecommendRatesData) => {
		const { intermodal_region, to_city } = rate;

		const { intermodal_region_id, to_city_id } = cleanedSearchParams;

		if (intermodal_region.id === intermodal_region_id && to_city.id === to_city_id) {
			window.location.reload();
			return;
		}

		const newQs = {
			...cleanedSearchParams,
			intermodal_region_id: intermodal_region.id,
			to_city_id: to_city.id,
		};

		delete newQs['order_id'];

		navigate(`/search-rate?${qs.stringify(newQs)}`);

		closeRecommendRoutesModal();
	};

	const handleFinish: OnSearchFormFinish = async (
		values: any,
		extra?: { selectedCity?: any; navigateIfParamsValid?: boolean; disableNavigate?: boolean },
	) => {
		// 校验参数
		if (!isSearchRatesParamsValid(values)) {
			message.error('Invalid params');
			return;
		}

		const fetchParams = { ...values };

		// result 情况下直接跳转
		if (extra?.navigateIfParamsValid) {
			navigate(`/search-rate?${qs.stringify({ ...values })}`);
			return;
		}

		try {
			const searchDatas = await searchRateContext.fetchSearchDatas(
				{ ...fetchParams },
				{ replaceSelectedIfNoData: true },
			);

			if (!searchDatas) {
				return;
			}

			if (searchDatas.length === 0) {
				openRecommendRoutesModal({
					intermodalRegionId: values.intermodal_region_id,
					toCityId: values.to_city_id,
					onRateClick: handleRateClick,
					onOk: () => {
						closeRecommendRoutesModal();
						openModal({
							intermodalRegionId: values.intermodal_region_id,
							toCityId: values.to_city_id,
							toCity: extra?.selectedCity,
						});
					},
				});
			} else {
				!extra.disableNavigate && navigate(`/search-rate?${qs.stringify({ ...values })}`);
			}
		} catch (error) {
			console.error('fetchSearchDatas error', error);
		}
	};

	const component = isSearchRatesParamsValid() ? (
		<SearchRateResult onFinish={handleFinish} />
	) : (
		<div
			className={clsx(
				'relative overflow-y-auto',
				screenContext.isScreenShot ? 'h-max' : 'h-screen',
			)}
		>
			<div className='w-full h-[50vh]'>
				<MapBox
					start={start}
					to={to}
					otherPoints={terminalPoints}
					components={{ distance: true }}
				/>
			</div>
			<div>
				<IndustryNews />
				<SearchRateForm onFinish={handleFinish} />
			</div>
			<HistoryGallery />
		</div>
	);

	return (
		<>
			{component}
			<RecommendRoutesModal />
			<RequestRateModal />
		</>
	);
});

export const SearchRate = observer(() => {
	const { userContext } = useGlobalContext();
	const navigate = useNavigate();

	useEffect(() => {
		if (userContext.type == 3) {
			navigate('/orders');
		}
	}, [userContext.type, navigate]);
	return (
		<SearchRateContext>
			<SearchRateApp />
		</SearchRateContext>
	);
});
