import { extractNumber } from './extract-number';

export const formatePhoneNumber = (phone: string | number) => {
	if (!phone) return '';

	const phoneNumber = typeof phone === 'number' ? phone : extractNumber(phone);
	const phoneStrArr = `${phoneNumber}`.split('').slice(0, 10);

	let formateNumber = '';

	const prefix = phoneStrArr.slice(0, 3).join('');

	if (phoneStrArr.length > 3) {
		formateNumber += `(${prefix})`;
	} else {
		formateNumber += prefix;
	}

	const middle = phoneStrArr.slice(3, 6).join('');
	formateNumber += `${middle}`;

	const last = phoneStrArr.slice(6).join('');
	if (phoneStrArr.length > 6) {
		formateNumber += `-${last}`;
	}

	return formateNumber;
};
