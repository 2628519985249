import { AvatarProps as AntdAvatarProps, Avatar } from 'antd';
import clsx from 'clsx';

interface AvatarProps extends AntdAvatarProps {
	name: string;
	className?: string;
}

export default ({ name, className }: AvatarProps) => (
	<Avatar
		gap={2}
		shape='circle'
		className={clsx('bg-[#007BFF] text-[#ffffff] flex-center', className)}
	>
		{name}
	</Avatar>
);
