import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Button,
	Col,
	message,
	Modal,
	Progress,
	Row,
	Space,
	Table,
	TableProps,
	Upload,
	UploadFile,
	UploadProps,
} from 'antd';
import DeleteOneIcon from '@/icons/delete-one-icon';
import DownloadBoldIcon from '@/icons/download-bold-icon';
import InboxOutlinedIcon from '@/icons/inbox-oulined-icon';
import { OrderFile } from '@/interfaces/file.interface';
import { Order } from '@/interfaces/order.interface';
import { uploadFile } from '@/services/apis';
import { DownloadOutlined, FileAddOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import _ from 'lodash';
import { convert } from '@/utils/convert-units';
import DeleteButton from './delete-button';
import PreviewButton from './preview-button';

const getSize = (size: number | string) => {
	if (typeof size === 'string') return size;
	const convertedSize = convert(size).from('b').toBest() ?? { val: 0, unit: 'B' };
	return `${convertedSize.val} ${convertedSize.unit}`;
};

const UploadItemRender: UploadProps['itemRender'] = (_, file, __, actions) => {
	const { t } = useTranslation();

	const { download, remove } = actions;

	const data = file.response?.data?.[0] as OrderFile | undefined;

	const size = data?.size ?? file?.size ?? 0;

	const getFileStatusText = () => {
		if (file.status === 'error' || file.status === 'removed' || file.percent === 0) return '';
		// @ts-expect-error types not match
		if (file.status === 'done' || file.status === 'success' || file?.percent === 100)
			return `uploaded at ${dayjs(data?.created_at).format('M/D h:m')}`;
		if (file.status === 'uploading' || file.percent > 0) return t('uploading');
		return '';
	};

	return (
		<div className='flex mt-[16px] p-[10px_16px_9px_7px] border-[2px] border-solid border-[#747578] rounded-[6px] text-neutral-dark-grey'>
			{/* left */}
			<div className='flex-grow mr-[14px]'>
				{/* filename */}
				<div className='font-[500] text-[16px] '>{data?.file_name ?? file.name}</div>
				{/* progress */}
				<div>
					<Progress size={['100%', 1]} percent={file.percent} showInfo={false} />
				</div>
				{/* other information */}
				<div className='flex items-center font-[400] text-[14px]'>
					{/* size */}
					<div>{`${getSize(size)}`}</div>
					<div className='ml-[11px] mr-[11px] w-[2px] h-[2px] bg-neutral-dark-grey rounded-[50%]' />
					{/* update time */}
					<div>{getFileStatusText()}</div>
				</div>
			</div>
			{/* right */}
			<div className='flex justify-between items-center w-[46px]'>
				<DownloadBoldIcon className='cursor-pointer w-[16px] h-[16px]' onClick={download} />
				<DeleteOneIcon className='cursor-pointer w-[16px] h-[16px]' onClick={remove} />
			</div>
		</div>
	);
};

const TableExpandedRowDocumentsTab = ({
	order,
	refresh,
}: {
	order: Order;
	refresh: () => void;
}) => {
	const { t } = useTranslation();

	const [isModalOpen, setIsModalOpen] = useState(false);
	const openModal = () => setIsModalOpen(true);
	const closeModal = () => setIsModalOpen(false);
	const handleModalOk = () => {
		closeModal();
		refresh();
	};

	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const handleUploadChange: UploadProps['onChange'] = ({ fileList }) => {
		setFileList([...fileList]);
	};
	const handleUploadRemove: UploadProps['onRemove'] = (file) => {
		setFileList(fileList.filter((f) => f.uid !== file.uid));
	};
	const beforeUpload: UploadProps['beforeUpload'] = (file) => {
		setFileList([...fileList, file]);
		return false;
	};

	const [isUploading, setIsUploading] = useState(false);
	const handleUpdateFile = (file: UploadFile, patches: { path: string; value: any }[]) => {
		const index = fileList.findIndex((f) => f.uid === file.uid);
		if (index < 0) return;
		const target = { ...fileList[index] };
		patches.forEach(({ path, value }) => {
			_.set(target, path, value);
		});
		setFileList([...fileList.slice(0, index), target, ...fileList.slice(index + 1)]);
	};
	const handleUpload = async () => {
		setIsUploading(true);
		const uploadResponses = await Promise.allSettled(
			fileList.map((file) => {
				const formData = new FormData();
				formData.append('documents', file.originFileObj);
				return uploadFile({
					path: { orderId: order.id.toString() },
					data: formData,
					config: {
						onUploadProgress: (event) => {
							handleUpdateFile(file, [{ path: 'percent', value: event.progress ?? 0 }]);
						},
					},
				})
					.then((response) => {
						handleUpdateFile(file, [
							{ path: 'percent', value: 100 },
							{ path: 'status', value: 'done' },
							{ path: 'response', value: response },
						]);
					})
					.catch((error) => {
						console.error('file', file.fileName, 'upload error', error);
						handleUpdateFile(file, [
							{ path: 'percent', value: 0 },
							{ path: 'status', value: 'error' },
						]);
						throw new Error(error);
					});
			}),
		);
		const successCount = uploadResponses.filter((r) => r.status === 'fulfilled').length;
		const errorCount = uploadResponses.filter((r) => r.status === 'rejected').length;
		message.info(`finish files uploading, success: ${successCount}, error: ${errorCount}`);
		setIsUploading(false);
	};

	useEffect(() => {
		if (fileList.length === 0) return;
		if (fileList.every((file) => ['done', 'error'].includes(file.status ?? ''))) {
			handleModalOk();
		}
	}, [fileList]);

	const columns: TableProps<OrderFile>['columns'] = [
		{
			title: t('Type'),
			dataIndex: ['custom_properties', 'document_type'],
			width: 100,
		},
		{
			title: t('File Name'),
			dataIndex: 'file_name',
			width: 120,
			sorter: (a, b) => a.file_name.localeCompare(b.file_name),
			render: (_, record) => (
				<PreviewButton
					path={{ documentId: record.id, orderId: order.id }}
					other={{ fileName: record.file_name }}
				>
					{record.file_name}
				</PreviewButton>
			),
		},
		{
			title: t('Update date'),
			dataIndex: 'created_at',
			width: 120,
			sorter: (a, b) => (dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? 1 : -1),
		},
		{
			title: t('Size'),
			dataIndex: 'size',
			width: 80,
		},
		{
			title: t('Actions'),
			key: 'actions',
			width: 140,
			render: (value, record) => (
				<Space>
					<PreviewButton
						icon={<DownloadOutlined />}
						type='text'
						path={{ documentId: record.id, orderId: order.id }}
						other={{ fileName: record.file_name }}
					></PreviewButton>
					<DeleteButton
						popconfirmProps={{
							title: t('Delete the task'),
							description: t('Are you sure to delete this task?'),
						}}
						path={{ documentId: record.id, orderId: order.id }}
						afterDelete={refresh}
					></DeleteButton>
				</Space>
			),
		},
	];

	return (
		<Row>
			<Col span={12} className='body-3-b'>
				<span>{t('Uploaded Files')}</span>
				<span className='text-grey-02'>({order.files?.length ?? 0})</span>
			</Col>
			<Col span={12} className='text-right'>
				<Button type='primary' ghost icon={<FileAddOutlined />} onClick={openModal}>
					{t('Upload')}
				</Button>
				<Modal
					title={t('Upload Files')}
					open={isModalOpen}
					onCancel={closeModal}
					onOk={handleUpload}
					okText={t('Upload')}
					confirmLoading={isUploading}
				>
					<Upload.Dragger
						fileList={fileList}
						multiple
						itemRender={UploadItemRender}
						beforeUpload={beforeUpload}
						onChange={handleUploadChange}
						onRemove={handleUploadRemove}
					>
						<p className='ant-upload-drag-icon'>
							<InboxOutlinedIcon />
						</p>
						<p className='ant-upload-text'>
							<span className='text-[#367cf2] underline cursor-pointer'>{t('Choose File')}</span>{' '}
							{t('or drag file to this area to upload')}
						</p>
						<p className='ant-upload-hint'>{t('Support for a single or bulk upload.')}</p>
					</Upload.Dragger>
				</Modal>
			</Col>
			<Col span={24}>
				<Table
					columns={columns}
					dataSource={order.files}
					pagination={false}
					scroll={{ x: 'max-content' }}
				/>
			</Col>
		</Row>
	);
};

export default TableExpandedRowDocumentsTab;
