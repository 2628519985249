import { Radio } from 'antd';
import './index.less';
import { TabSwitcherProps } from './type';

export * from './use-tab-switcher';

export const TabSwitcher = ({ item1, item2, value, onChange }: TabSwitcherProps) => {
	return (
		<Radio.Group value={value} className='tab-switcher' onChange={(e) => onChange(e.target.value)}>
			{[item1, item2].map((item) => (
				<Radio.Button value={item.value} disabled={item.disabled}>
					{item.label}
				</Radio.Button>
			))}
		</Radio.Group>
	);
};
