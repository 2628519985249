import iconFactory from './icon-factory';

const Svg = () => (
	<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z'
			fill='#007BFF'
		/>
		<path
			d='M12 8L9 5L6 8'
			stroke='white'
			stroke-width='1.5'
			stroke-linecap='square'
			stroke-linejoin='round'
		/>
		<path
			d='M9 16L9 6'
			stroke='white'
			stroke-width='1.5'
			stroke-linecap='square'
			stroke-linejoin='round'
		/>
		<path
			d='M9 16C9 14.3333 10.2 11 15 11'
			stroke='white'
			stroke-width='1.5'
			stroke-linecap='square'
		/>
	</svg>
);

export default iconFactory(Svg);
