import { Tooltip } from 'antd';
import { WarningTwoTone } from '@ant-design/icons';

const Risks = ({ risks }: { risks: string[] }) => {
	if (!risks || risks.length === 0) return null;

	return (
		<Tooltip title={risks.join(', ')}>
			<WarningTwoTone twoToneColor='#FFAD01' />
		</Tooltip>
	);
};

export default Risks;
