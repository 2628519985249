import { SearchRateContext } from '@/services/contexts';

export default function ErrorTest() {
	const test = () => {
		const a = 1;

		console.log(a[0][0]);
	};

	return (
		<SearchRateContext>
			<div>
				<h1>TEST Error</h1>
				<button onClick={test}>test</button>
			</div>
		</SearchRateContext>
	);
}
