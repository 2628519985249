import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { BulkAddToProjectProps, BulkAddToProjectRes } from './interface';

export const bulkAddToProject = async (props: BulkAddToProjectProps) => {
	return await axios.patch<BulkAddToProjectRes>(
		`/projects/${props.path.projectId}/bulkAddOrders`,
		props.data,
		{
			headers: {
				Authorization: accessTokenManager.getAccessToken(),
			},
		},
	);
};
