import { useTranslation } from 'react-i18next';
import { Popover } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

export const PickupDateStatement = () => {
	const { t } = useTranslation();

	return (
		<Popover
			title={t('Statement about the pick-up time of the platform')}
			content={
				<div>
					{t(
						'Dear Customer, thank you for choosing our services! Please note that the pickup time in your order is an estimated time and may vary due to uncontrollable factors such as traffic, weather, and loading delays. Ensure that your goods are ready before the estimated pickup time to avoid long waits for the driver, which could result in additional charges. Thank you for your understanding and cooperation. If you have any questions, please feel free to contact us!',
					)}
				</div>
			}
			overlayClassName='max-w-[50vw]'
		>
			<QuestionCircleOutlined className=' cursor-pointer' />
		</Popover>
	);
};
