import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { GetLTLAutofillProps, GetLTLAutofillRes } from './interface';

export * from './interface';

const URL = import.meta.env.VITE_AUTOFILL_AI_URL;
const SECRET_KEY = import.meta.env.VITE_AUTH_SECRET_KEY;
const URL_FOR_SHIPMENT = import.meta.env.VITE_AUTOFILL_FOR_SHIPMENT_AI_URL;

// 新增的校验函数
const generateAuthData = (content: string) => {
	const timestamp = Math.floor(Date.now() / 1000).toString();
	const message = `${content}${timestamp}`;
	const signature = CryptoJS.HmacSHA256(message, SECRET_KEY).toString();
	return { timestamp, signature };
};

// 修改后的 getLTLAIAutofill 函数
export const getLTLAIAutofill = async (props: GetLTLAutofillProps) => {
	const { content } = props.data;
	const authData = generateAuthData(content);

	const response = await axios.post<GetLTLAutofillRes>(
		URL,
		{
			...props.data,
			...authData,
		},
		{
			headers: {
				Authorization: accessTokenManager.getAccessToken(),
			},
		},
	);

	return response;
};

// 修改后的 getLTLAIAutofillForShipment 函数
export const getLTLAIAutofillForShipment = async (props: GetLTLAutofillProps) => {
	const { content } = props.data;
	const authData = generateAuthData(content);

	return await axios.post<GetLTLAutofillRes>(
		URL_FOR_SHIPMENT,
		{
			...props.data,
			...authData,
		},
		{
			headers: {
				Authorization: accessTokenManager.getAccessToken(),
			},
		},
	);
};
