import TickCircleColorIcon from '@/icons/tick-circle-color-icon';
import TickCircleErrorIcon from '@/icons/tick-circle-error-icon';
import TickCircleIcon from '@/icons/tick-circle-icon';

interface OrderReleaseStatusIconProps {
	eta: string | undefined | null;
	time: string | undefined | null | boolean;
}

export default ({ eta, time }: OrderReleaseStatusIconProps) => {
	if (time) {
		return <TickCircleColorIcon />;
	}

	return <TickCircleIcon />;

	// if (time === false) {
	// 	return <TickCircleErrorIcon />;
	// }

	// return <TickCircleIcon />;
};
