import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Dropdown, MenuProps, Popconfirm } from 'antd';
import { ProjectCriteriaTypeEnum } from '@/interfaces/project-criteria-type.enum';
import { GetProjectDataItem } from '@/services/apis';
import {
	DeleteOutlined,
	EditOutlined,
	EllipsisOutlined,
	InboxOutlined,
	LoadingOutlined,
	StarFilled,
	StarOutlined,
	WarningOutlined,
} from '@ant-design/icons';
import { CreateProjectPanel, useCreateProjectModal } from '@/components/create-project-panel';
import { Gap } from '@/components/gap';
import { StatusCircle } from '@/components/status-circle';
import { apiHooks } from '@/hooks/apis';

interface ProjectItemProps {
	project: GetProjectDataItem;
	refresh: () => void;
}

enum DropdownItemKeys {
	Archive = 'Archive',
	Favorite = 'Favorite',
	Delete = 'Delete',
	Edit = 'Edit',
}

export const ProjectItem = ({ project, refresh }: ProjectItemProps) => {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const isFavorited = !!project.favorite_at;

	const isArchived = !!project.archived_at;

	const isAllReturned = useMemo(() => {
		const nums = project.returned.split(' / ').map((i) => Number(i));
		return nums[0] === nums[1];
	}, [project]);

	const isProcessing = project.status === 1 || !isAllReturned;

	const {
		openingCreateProjectModal: openingEditProjectModal,
		openCreateProjectModal: openEditProjectModal,
		closeCreateProjectModal: closeEditProjectModal,
	} = useCreateProjectModal();

	const { runAsync: favoriteProject, loading: favoriteLoading } = apiHooks.useFavoriteProject();

	const { runAsync: unfavoriteProject, loading: unfavoriteLoading } =
		apiHooks.useUnfavoriteProject();

	const { runAsync: archivedProject, loading: archivedProjectLoading } =
		apiHooks.useArchivedProject();

	const { runAsync: deleteProject, loading: deleteProjectLoading } = apiHooks.useDeleteProject();

	const items: MenuProps['items'] = [
		{
			key: DropdownItemKeys.Edit,
			label: t(`Edit`),
			icon: <EditOutlined style={{ fontSize: '14px' }} />,
		},
		{
			key: DropdownItemKeys.Archive,
			label: (
				<Popconfirm
					title={t('Archive the project')}
					description={t('Are you sure to archive this project?')}
					onConfirm={() => handleClickDropdownItem(DropdownItemKeys.Archive)}
					okText={t('Yes')}
					cancelText={t('No')}
				>
					{t(`Archive`)}
				</Popconfirm>
			),
			icon: archivedProjectLoading ? (
				<LoadingOutlined style={{ fontSize: '14px' }} />
			) : (
				<InboxOutlined style={{ fontSize: '14px' }} />
			),
			disabled: !!project.archived_at,
		},
		{
			key: DropdownItemKeys.Favorite,
			label: isFavorited ? t(`Unfavorite`) : t(`Favorite`),
			icon:
				favoriteLoading || unfavoriteLoading ? (
					<LoadingOutlined style={{ fontSize: '14px' }} />
				) : isFavorited ? (
					<StarFilled style={{ fontSize: '14px', color: 'yellow' }} />
				) : (
					<StarOutlined style={{ fontSize: '14px' }} />
				),
		},
		{
			key: DropdownItemKeys.Delete,
			label: (
				<Popconfirm
					title={t('Delete the project')}
					description={t('Are you sure to delete this project?')}
					onConfirm={() => handleClickDropdownItem(DropdownItemKeys.Delete)}
					okText={t('Yes')}
					cancelText={t('No')}
				>
					{t(`Delete`)}
				</Popconfirm>
			),
			icon: deleteProjectLoading ? (
				<LoadingOutlined style={{ fontSize: '14px' }} />
			) : (
				<DeleteOutlined style={{ fontSize: '14px' }} />
			),
		},
	];

	const handleClickDropdownItem = async (key: DropdownItemKeys) => {
		switch (key) {
			case DropdownItemKeys.Edit: {
				openEditProjectModal();
				break;
			}
			case DropdownItemKeys.Archive: {
				await archivedProject({ path: { projectId: project.id } });
				refresh();
				break;
			}
			case DropdownItemKeys.Favorite: {
				isFavorited
					? await unfavoriteProject({ path: { projectId: project.id } })
					: await favoriteProject({ path: { projectId: project.id } });
				refresh();
				break;
			}
			case DropdownItemKeys.Delete: {
				await deleteProject({ path: { projectId: project.id } });
				refresh();
				break;
			}
			default: {
				console.error('[handleClickDropdownItem] unexpected drop item key', key);
			}
		}
	};

	return (
		<>
			<div
				style={{
					borderLeftWidth: '8px',
					borderColor: isProcessing ? '#c8e3ff' : '#E7E7E7',
				}}
				className=' box-border p-[16px] w-full h-full border-[1px] border-solid border-primary-light-02 rounded-[8px] cursor-pointer'
				onClick={() => navigate(`/projects/${project.id}`)}
			>
				<div className='mb-[4px] w-full flex justify-between'>
					<div className='body-3-b text-black'>{project.name}</div>
					<Dropdown
						menu={{
							items,
							onClick: ({ key, domEvent }) => {
								domEvent.preventDefault();
								domEvent.stopPropagation();
								[DropdownItemKeys.Edit, DropdownItemKeys.Favorite].includes(
									key as DropdownItemKeys,
								) && handleClickDropdownItem(key as DropdownItemKeys);
							},
						}}
						overlayStyle={{ width: '200px' }}
					>
						<EllipsisOutlined
							style={{ fontSize: '22px' }}
							className=' cursor-pointer'
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
							}}
						/>
					</Dropdown>
				</div>

				<div className='mb-[22px] foot-note-1-b text-grey-01'>
					{t(`Criteria`)}
					{`: `}
					{project.type === ProjectCriteriaTypeEnum.manual
						? t(`Manual Criteria`)
						: t(`Ongoing Criteria`)}
				</div>

				<div className='w-[full] flex justify-between'>
					<div className='flex items-center'>
						{/* 点 */}
						<StatusCircle inactive={!isAllReturned} />
						<Gap width='8px' />
						{/* <div className='flex body-4-b text-grey-01'>
							{project.returned}
							<Gap width='2px' />
							{t(`Returned`)}
						</div> */}
						<div className='flex body-4-b text-grey-01'>
							{project.container_total}
							{/* <Gap width='2px' /> */}
							{/* {t(`Returned`)} */}
						</div>
					</div>
					{project.exception > 0 && (
						<div className=' text-additional-warning'>
							<WarningOutlined style={{ fontSize: '21px' }} />
							<span className='body-4-b'>{project.exception}</span>
						</div>
					)}
				</div>
			</div>
			{openingEditProjectModal && (
				<CreateProjectPanel
					open={openingEditProjectModal}
					type='edit'
					defaultValue={{
						name: project.name,
						description: project.description,
						memo: project.memo,
					}}
					projectId={project.id}
					onClose={() => {
						closeEditProjectModal();
						refresh();
					}}
				/>
			)}
		</>
	);
};
