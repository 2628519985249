import { FTLShipment } from '@/interfaces/ftl-shipment.interface';
import { Shipment } from '@/interfaces/shipment.interface';
import { makeAutoObservable, runInAction } from 'mobx';

class ShipmentOrderContext {
	constructor() {
		makeAutoObservable(this);
	}

	private _shipmentOrder: Shipment | FTLShipment | undefined;

	get shipmentOrder() {
		return this._shipmentOrder;
	}

	set shipmentOrder(value: typeof this._shipmentOrder) {
		runInAction(() => {
			this._shipmentOrder = value;
		});
	}
}

export const shipmentOrder = new ShipmentOrderContext();
