import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const styleMap: Record<string, string> = {
	['All Released']: 'text-updated-green bg-[#DFF0DF]',
	['Not Released']: 'text-grey-01 bg-grey-05',
	['On Hold']: 'text-updated-red bg-[#FFE0E0]',
};

const OrderReleaseStatusTag = ({ status }: { status: string }) => {
	const { t } = useTranslation();

	const className = clsx(
		'p-[8px] w-[100px] h-[32px] flex-center rounded-[50px] foot-note-1-m',
		styleMap[status],
	);

	return <div className={className}>{t(status)}</div>;
};

export default OrderReleaseStatusTag;
