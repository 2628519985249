import { Company } from '@/interfaces/company.interface';
import { updateCustomerPrepayOrder } from '@/services/apis';
import { addCustomerPrepayOrders } from '@/services/apis/create-agent-customer-prepay-order';
import { getAgentCompanies } from '@/services/apis/get-agent-companies';
import { getAgentCompany } from '@/services/apis/get-agent-company';
import { makeAutoObservable, runInAction } from 'mobx';

class AgentContext {
	constructor() {
		makeAutoObservable(this);
	}

	private _companies: Company[];
	private _agentCompany: Company;

	get companies() {
		return this._companies || [];
	}

	set companies(data: Company[]) {
		runInAction(() => {
			this._companies = data;
		});
	}

	get agentCompany() {
		return this._agentCompany;
	}

	set agentCompany(agentCompany: Company) {
		this._agentCompany = agentCompany;
	}

	async fetchCompanies() {
		const res = await getAgentCompanies();
		this.companies = res.data.data;
	}

	async fetchAgentCompany() {
		const res = await getAgentCompany();
		this.agentCompany = res.data.data;
	}

	public async init() {
		console.log('Initializing AgentContext...');
		await this.fetchCompanies();
		// await this.fetchAgentCompany();
	}

	public async addPrepayOrders(values) {
		const formData = new FormData();
		formData.append('documents[0]', values['document']);
		formData.append('amount', values['amount']);
		formData.append('bank', values['bank']);
		formData.append('exchange_rate', values['exchange_rate'] || 1);
		formData.append('exchange_type', values['exchange_type'] || 0);
		formData.append('user_id', values['user_id']);
		formData.append('agent_id', values['agent_id']);

		await addCustomerPrepayOrders(formData);
	}

	public async approvePrepayOrder(id: number, user_id: number) {
		return await updateCustomerPrepayOrder({ data: { id, status: 1, user_id } });
	}

	public async rejectPrepayOrder(id: number, user_id: number) {
		return await updateCustomerPrepayOrder({ data: { id, status: -1, user_id } });
	}
}

export const agent = new AgentContext();
