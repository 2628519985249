import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import {
	GetProjectDeliveryCountStatisticsProps,
	GetProjectDeliveryCountStatisticsRes,
} from './interface';

export * from './interface';

export const getProjectDeliveryCountStatistics = async (
	props: GetProjectDeliveryCountStatisticsProps,
) => {
	return await axios.get<GetProjectDeliveryCountStatisticsRes>(
		`/projects/${props.path.projectId}/deliveryCountStatistics/${props.path.type}`,
		{
			headers: {
				Authorization: accessTokenManager.getAccessToken(),
			},
			params: props.params,
		},
	);
};
