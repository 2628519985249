import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetFTLQuoteListReq, GetFTLQuoteListRes } from './interface';

export * from './interface';

export const getFTLQuoteList = async (props: GetFTLQuoteListReq) => {
	return await axios.get<GetFTLQuoteListRes>(`/ftl/quotes`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params: props.params,
	});
};
