import { useTranslation } from 'react-i18next';
import { Button, Form } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import Input from '@/components/input';

const Item = Form.Item;

export default () => {
	const { t } = useTranslation();

	const form = Form.useFormInstance();

	const emails: any[] = form.getFieldValue('emails') ?? [];

	return (
		<div>
			{emails.map((email, index) => (
				<div className='flex gap-[26px]'>
					<Item
						name={['emails', index]}
						className='flex-grow'
						rules={[
							{ type: 'email', message: t('Not a valid email') },
							{ required: true, message: t('Please Enter Emails') },
						]}
					>
						<Input />
					</Item>
					<Item>
						<Button
							type='text'
							icon={
								<DeleteOutlined
									onClick={() => {
										emails.splice(index, 1);
										form.setFieldValue('emails', [...emails]);
									}}
								/>
							}
						/>
					</Item>
				</div>
			))}
			<Button type='primary' ghost onClick={() => form.setFieldValue('emails', [...emails, ''])}>
				{t('Add Email')}
			</Button>
		</div>
	);
};
