import { Warehouse } from '@/interfaces/warehouse.interface';
import { LTLPickupAddress } from '@/services/contexts/ltl/interface';
import { useMemoizedFn } from 'ahooks';
import { apiHooks } from '@/hooks/apis';

export const useGenerateAddress = () => {
	const { runAsync: searchCity } = apiHooks.useSearchCity();

	const generateAddress = useMemoizedFn(
		async ({
			city_name,
			zipcode,
			state_name,
			address1,
			address2,
		}: {
			city_name?: string;
			zipcode?: string;
			state_name?: string;
			address1?: string;
			address2?: string;
		}) => {
			let address: LTLPickupAddress;

			if (city_name || zipcode) {
				let cityName = '';
				if (city_name) {
					cityName = city_name;
					if (state_name) {
						cityName += `, ${state_name} `;
					}
				}
				if (zipcode) {
					cityName += `${zipcode}`;
				}
				const pickupCityRes = await searchCity({ path: { cityName } });

				if (pickupCityRes.data.data.length > 0) {
					const _pickupCity = pickupCityRes.data.data[0];
					address = {
						id: -1,
						name: '',
						code: null,
						zipcode: _pickupCity.zipcode,
						is_residential: false,
						address: address1 ?? '',
						address2: address2 ?? '',
						special_request: '',
						city: _pickupCity,
						country: _pickupCity.country,
						customer_memo: '',
						appointment_by: -1,
						created_at: '',
						updated_at: '',
						delivery_order_address: '',
						contacts: [
							// @ts-ignore
							{
								name: '',
								email: '',
								phone: '',
								phone_ext: '',
							},
						],
						// @ts-ignore
						user: {},
					};
				}
			}

			return address;
		},
	);

	return { generateAddress };
};
