import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetOrdersProps, GetOrdersRes } from '../get-orders/interface';

export const getAgentOrders = async (props: GetOrdersProps) => {
	return await axios.get<GetOrdersRes>(`agent/orders/drayOrders`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params: props.params,
	});
};
