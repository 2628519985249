import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { UpdateFTLQuoteProps, UpdateFTLQuoteRes } from './interface';

export * from './interface';

export const updateFTLQuote = async (props: UpdateFTLQuoteProps) => {
	return await axios.put<UpdateFTLQuoteRes>(`/ftl/quotes/${props.path.id}`, props.data, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
