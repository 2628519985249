import { Fragment, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

const TableExpandedRowOrderDetailsTabCard = ({
	title,
	content,
}: {
	title: string;
	content: Record<string, ReactNode>;
}) => {
	const { t } = useTranslation();

	return (
		<div className='p-[24px] rounded-[4px] overflow-hidden shadow-[0px_4px_10px_0px_#0000000D] bg-white'>
			{/* title */}
			<div className='mb-[10px] body-3-m leading-[27px] border-t-0 border-l-0 border-r-0 border-b-[1px] border-solid border-grey-04'>
				{title}
			</div>

			{/* content */}
			<div className='grid grid-cols-[140px_1fr]'>
				{Object.entries(content).map(([k, v]) => (
					<Fragment key={k}>
						<div className='mb-[14px] body-4-m'>{k}</div>
						<span className='body-4-r text-grey-01'>{v ?? t('N/A')}</span>
					</Fragment>
				))}
			</div>
		</div>
	);
};

export default TableExpandedRowOrderDetailsTabCard;
