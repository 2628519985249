import { makeAutoObservable, runInAction } from 'mobx';

class ScreenContext {
	constructor() {
		makeAutoObservable(this);
	}

	private _isScreenShot = false;

	get isScreenShot() {
		return this._isScreenShot;
	}

	set isScreenShot(isScreenShot: typeof this._isScreenShot) {
		runInAction(() => {
			this._isScreenShot = isScreenShot;
		});
	}
}

export const screenContext = new ScreenContext();
