import React from 'react';
import InboxOutlinedSvg from '@/assets/inbox-oulined.svg';

const InboxOutlinedIcon = (
	props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
) => {
	return <img {...props} src={InboxOutlinedSvg} />;
};

export default InboxOutlinedIcon;
