import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const indexKey = '/search-rate';

export default () => {
	const navigate = useNavigate();

	const navigateToIndex = useCallback(() => {
		navigate(indexKey);
	}, [navigate]);

	return {
		navigateToIndex,
	};
};
