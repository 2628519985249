import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, message, Modal, Space, Tabs } from 'antd';
// 引入useTranslation
import { useLTLContext } from '@/services/contexts';
import { useBoolean } from 'ahooks';
import { toJS } from 'mobx';
import './index.less';
import { PackingResult, packItems } from './ltl-pallet-bin-picking';
import { PalletData, PalletInputComponent } from './ltl-pallet-spec';

interface showHiddenClickTip {
	clickTip: number;
	timestamp: number;
}

export function PalletCalculator() {
	const ThreeJSVisualization = lazy(() => import('./ltl-pallet-3d-visualization'));

	const { t } = useTranslation(); // 使用useTranslation
	const [pallets, setPallets] = useState([]);
	const [palletInfo, setPalletInfo] = useState([]);
	const [showResults, setShowResults] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [activeTab, setActiveTab] = useState('1');
	const ltlContext = useLTLContext();
	const [loading, { setTrue: startLoading, setFalse: endLoading }] = useBoolean(false);
	const [inputData, setInputData] = useState({
		palletWeightMax: 907, // 907kg, 约2000lbs
		items: [
			{ length: 45, width: 40, height: 30, weight: 10, qty: 120, flippable: false },
			// { length: 15, width: 10, height: 8, weight: 5, qty: 75, flippable: false },
			// { length: 25, width: 20, height: 15, weight: 20, qty: 30, flippable: true },
		],
	});
	// 加入计算中状态 为 bool
	const [calculating, setCalculating] = useState(false);

	const [palletData, setPalletData] = useState<PalletData>({
		palletLengthInches: 48,
		palletWidthInches: 40,
		palletHeightMaxInches: 72,
	});

	const handleInputPalletDataChange = (field: keyof PalletData, value: number) => {
		setPalletData((prevData) => ({
			...prevData,
			[field]: value,
		}));
	};

	// const [activeMenu, setActiveMenu] = useState("common"); // Track active menu
	const [activeMenu, setActiveMenu] = useState('quick'); // Track active menu

	// 新增 state 用于 Quick Estimate
	const [quickEstimateData, setQuickEstimateData] = useState({
		totalVolume: '',
		totalWeight: '',
	});

	// 新增 Quick Estimate 的计算结果
	const [quickEstimateResult, setQuickEstimateResult] = useState(null);

	// 处理 Quick Estimate 输入变化
	const handleQuickEstimateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value, max, min } = e.target;
		const val = Math.max(Number(min), Math.min(Number(max), Number(value) || Number(min)));
		setQuickEstimateData((prev) => ({ ...prev, [name]: val }));
	};

	// Quick Estimate
	const calculateQuickEstimate = () => {
		const { totalVolume, totalWeight } = quickEstimateData;
		const { palletLengthInches, palletWidthInches, palletHeightMaxInches } = palletData;
		const cmPerInch = 2.54;
		const palletLength_cm = palletLengthInches * cmPerInch;
		const palletWidth_cm = palletWidthInches * cmPerInch;
		const palletHeightMax_cm = palletHeightMaxInches * cmPerInch - 15; // 减去托盘自身高度 cm
		const palletVolume = (palletLength_cm * palletWidth_cm * palletHeightMax_cm) / 1e6; // 转换为立方米
		const palletWeight = 18; // 托盘自重，单位：公斤

		if (!totalVolume && !totalWeight) {
			return;
		}

		const totalVolumeFloat = parseFloat(totalVolume) || 0;
		const totalWeightFloat = parseFloat(totalWeight) || 0;

		if (totalVolumeFloat <= 0 && totalWeightFloat <= 0) {
			return;
		}

		// 根据体积和重量分别计算所需托盘数
		const palletsByVolume = Math.ceil(totalVolumeFloat / palletVolume);
		const palletsByWeight = Math.ceil(totalWeightFloat / 907); // 907kg = 2000lbs  rate 2.20462

		// 取较大值作为所需托盘数
		const totalPallets = Math.max(palletsByVolume, palletsByWeight);

		// avg weight / per pallet 单位为 kg
		const avgWeightPerPallet = Math.round(
			(totalWeightFloat + palletWeight * totalPallets) / totalPallets,
		);
		const avgWeightLbPerPallet = Math.round(avgWeightPerPallet * 2.20462); // 转换为磅
		// avg volume / per pallet
		const avgVolumePerPallet = Math.round((totalVolumeFloat / totalPallets) * 100) / 100; // 保留两位小数

		// 计算平均高度
		const avgHeight_cm = Math.round(
			(avgVolumePerPallet * 1e6) / (palletLength_cm * palletWidth_cm),
		);
		const avgHeight_in = Math.round((avgHeight_cm + 15) / cmPerInch); // 15cm 为托盘自身高度

		setQuickEstimateResult({
			N: totalPallets,
			W: avgWeightLbPerPallet,
			H_in: avgHeight_in,
			L_in: Math.round(palletLength_cm / cmPerInch),
			W_in: Math.round((palletWidth_cm / cmPerInch) * 100) / 100, // 保留两位小数
		});

		setShowResults(true);
		setActiveTab('2');
	};

	const handleInputChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		index: number,
		field: string,
	) => {
		const newInputData = { ...inputData };
		if (field === 'items') {
			newInputData.items[index][e.target.name] =
				e.target.type === 'checkbox'
					? e.target.checked
					: Math.min(Math.max(Number(e.target.value) || 0, 0), 9999);
		} else {
			newInputData[field] = Number(e.target.value);
		}
		setInputData(newInputData);
	};

	const addItem = () => {
		setInputData((prevData) => ({
			...prevData,
			items: [
				...prevData.items,
				{ length: 0, width: 0, height: 0, weight: 0, qty: 0, flippable: false },
			],
		}));
	};

	const removeItem = (index: number) => {
		setInputData((prevData) => ({
			...prevData,
			items: prevData.items.filter((_, i) => i !== index),
		}));
	};

	useEffect(() => {
		setPalletInfo(
			pallets.map((pallet, index) => {
				const { length, width, height } = pallet.dimensions;
				return {
					palletNumber: index + 1,
					boxes: pallet.items.length,
					dimensions: `${length}x${width}x${height}`,
					totalWeight: `${pallet.totalWeight}kg`,
					totalHeight: `${pallet.totalHeight}cm`,
				};
			}),
		);
	}, [pallets]);

	const handleSubmit = async (e) => {
		e.preventDefault(); // 确保阻止默认表单提交行为
		setShowResults(true);
		// setShowModal(false); // 提交后隐藏输入表单

		setCalculating(true);

		try {
			// 将输入的数据转换为Item对象
			const items = inputData.items.map((item) => ({
				length: item.length,
				width: item.width,
				height: item.height,
				weight: item.weight,
				qty: item.qty,
				flippable: item.flippable,
			}));

			// 调试信息，确保 items 数组正确
			console.log('Items:', items);

			// 调用palletCalculator并更新状态
			const { palletLengthInches, palletWidthInches, palletHeightMaxInches } = palletData;
			const binLength = Math.round(palletLengthInches * 2.54);
			const binWidth = Math.round(palletWidthInches * 2.54);
			const binHeight = Math.round(palletHeightMaxInches * 2.54) - 15; // 减去托盘自身高度
			const binMaxWeight = inputData.palletWeightMax - 18; // 减去托盘自重
			const packingResult = packItems(items, binLength, binWidth, binHeight, binMaxWeight);
			console.log('packingResult:', packingResult);
			setPackingResult(packingResult);

			// const calculatedPallets = palletCalculator(
			// 	Math.round(palletData.palletLengthInches * 2.54),
			// 	Math.round(palletData.palletWidthInches * 2.54),
			// 	Math.round(palletData.palletHeightMaxInches * 2.54),
			// 	inputData.palletWeightMax,
			// 	items,
			// );
			// setPallets(calculatedPallets);

			const newPallets = [];
			packingResult.bins.forEach((bin) => {
				const pallet = {
					items: bin.items.map((item) => ({
						item: item,
						position: [item.length, item.weight, item.height],
					})),
					totalWeight: bin.items.reduce((sum, item) => sum + item.weight, 0),
					totalHeight: bin.spaces.reduce((max, space) => Math.max(max, space.z), 0),
					dimensions: {
						length: bin.length,
						width: bin.width,
						height: bin.height,
					},
				};
				newPallets.push(pallet);
			});
			// 更新托盘状态
			setPallets(newPallets);

			// 计算货物总体积和总重量
			// const totalWeight =
			// 	calculatedPallets.reduce((sum, pallet) => sum + pallet.totalWeight, 0) +
			// 	items.reduce((sum, item) => sum + item.weight * item.qty, 0);
			// const totalVolume = calculatedPallets.reduce(
			// 	(sum, pallet) =>
			// 		sum + pallet.dimensions.length * pallet.dimensions.width * pallet.dimensions.height,
			// 	0,
			// );

			// 计算托盘的总重量
			// const palletWeight = calculatedPallets.length * 18; // 每个托盘的重量为18kg
			// const overallWeight = totalWeight + palletWeight; // 总重量 = 箱子总重量 + 托盘总重量

			// // 更新托盘信息
			// setPalletInfo(
			// 	pallets.map((pallet, index) => {
			// 		const { length, width, height } = pallet.dimensions;
			// 		return {
			// 			palletNumber: index + 1,
			// 			boxes: pallet.items.length,
			// 			dimensions: `${length}x${width}x${height}`,
			// 			totalWeight: `${pallet.totalWeight}kg`,
			// 		};
			// 	}),
			// );

			// 更新货物信息
			// setItemInfo([{ totalVolume, totalWeight: overallWeight }]); // 确保这里是一个有效的数组

			// 计算itemInfo
			// const colorPalette = [
			// 	0x4285f4, 0x34a853, 0xfbbc05, 0xea4335, 0x00acc1, 0xab47bc, 0x26a69a, 0xffa726,
			// ];
			// const itemInfoArray = items.map((item, index) => ({
			// 	color: `#${colorPalette[index % colorPalette.length].toString(16).padStart(6, '0')}`,
			// 	dimensions: `${item.length}x${item.width}x${item.height}`,
			// 	weight: item.weight,
			// }));
			// setItemInfo(itemInfoArray);
			setActiveTab('2'); // Switch to Output Results tab after calculation
		} catch (err) {
			message?.error(err.message || err.error);
		} finally {
			setCalculating(false);
		}
	};

	// use by pallet-3d-visualization
	const [currentBinIndex, setCurrentBinIndex] = useState(0);
	const [showVisualization, setShowVisualization] = useState(false);
	const [packingResult, setPackingResult] = useState<PackingResult | null>(null);
	const [showKeepClickTip, setShowKeepClickTip] = useState<showHiddenClickTip>({
		clickTip: 0,
		timestamp: 0,
	});

	const handleKeepClickThead = (e) => {
		e.stopPropagation();
		const now = Date.now();
		const clickTip = now - showKeepClickTip.timestamp < 1000 ? showKeepClickTip.clickTip + 1 : 1;
		setShowKeepClickTip({ clickTip, timestamp: now });
		if (clickTip === 5) {
			setShowVisualization(true);
		}
	};

	const handleOpenModal = () => setShowModal(true);
	const handleCloseModal = () => setShowModal(false);

	const setAutofillItems = () => {
		try {
			startLoading();
			const itemsMap = new Map();

			// 如果是Common Size Estimate，则使用palletInfo
			if (activeMenu === 'common') {
				palletInfo.forEach((info) => {
					const [length, width] = info.dimensions.split('x').map((dim: string) => parseFloat(dim));
					const totalHeight = parseFloat(info.totalHeight.replace('cm', '')); // add pallet height
					const totalWeightKg = parseFloat(info.totalWeight.replace('kg', '')) + 18;
					const totalWeight = Math.ceil(totalWeightKg * 2.20462); // 转换为磅
					const key = `${length}x${width}x${totalHeight}}`;
					const length_in = Math.floor(length / 2.54);
					const width_in = Math.floor(width / 2.54);
					const totalHeight_in = Math.ceil(totalHeight / 2.54) + 6;

					if (itemsMap.has(key)) {
						const existingItem = itemsMap.get(key);
						existingItem.units += 1;
						existingItem.total_weight += totalWeight;
						existingItem.pieces += info.boxes;
					} else {
						itemsMap.set(key, {
							description: null,
							units: 1,
							package_type: 'Pallet',
							total_weight: totalWeight,
							weight_unit: 'lbs',
							length: length_in,
							width: width_in,
							height: totalHeight_in,
							dimension_unit: 'in',
							class: null,
							pieces: info.boxes,
						});
					}
				});
			} else if (activeMenu === 'quick') {
				// 如果是Quick Estimate，则使用quickEstimateData
				const { N, W, H_in, L_in, W_in } = quickEstimateResult;
				itemsMap.set('key', {
					description: null,
					units: N,
					package_type: 'Pallet',
					total_weight: Math.ceil(W * N),
					weight_unit: 'lbs',
					length: L_in,
					width: W_in,
					height: H_in,
					dimension_unit: 'in',
					class: null,
					// pieces: null,
				});
			}
			const existItems = toJS(ltlContext.stepOneFormValues.items);
			// console.log('existItems:', existItems);
			const items = Array.from(itemsMap.values());
			// if exist , merge data
			if (existItems.length === items.length) {
				items.forEach((v, index) => {
					if (existItems[index]?.description != null && existItems[index]?.description !== '') {
						items[index].description = existItems[index].description;
					}
					if (
						existItems[index]?.pieces != null &&
						existItems[index]?.pieces !== 0 &&
						v?.pieces == null
					) {
						items[index].pieces = existItems[index].pieces;
					}
				});
			}
			// console.log('items:', items);

			ltlContext.stepOneFormValues = {
				...ltlContext.stepOneFormValues,
				items,
			};

			ltlContext.calcItemFreightClass();
		} catch (err) {
			message?.error(err.message || err.error);
		} finally {
			endLoading();
		}
	};

	const activeClasses = 'border-[#007BFF] text-[#007BFF]';
	const inactiveClasses = 'border-[#ADAEAF] text-[#ADAEAF]';

	return (
		<>
			<Button className='flex gap-[4px] items-center' onClick={handleOpenModal} color='primary'>
				{t('Pallet Calculator')}
			</Button>
			<Modal
				open={showModal}
				title={<div className='text-xl'>{t('Pallet Calculator')}</div>} // 使用t函数
				closable
				width={800} // Increase the size of the modal
				footer={null}
				onCancel={handleCloseModal}
			>
				<Tabs activeKey={activeTab} onChange={setActiveTab} className={'step-custom-tabs'}>
					<Tabs.TabPane key='1'>
						{/* Top Menu */}
						<div className='flex justify-start mb-5 mt-5'>
							<Button
								type='link'
								className={`w-[146px] h-[42px] px-3.5 py-2.5 flex items-center justify-center gap-2.5 rounded-r-none border border-r-0 transition-colors duration-200 ease-in-out ${
									activeMenu === 'quick' ? activeClasses : inactiveClasses
								}`}
								onClick={() => setActiveMenu('quick')}
							>
								<div
									className={`w-[118px] h-[22px] gap-0 text-base font-semibold ${
										activeMenu === 'quick' ? 'text-[#007BFF]' : 'text-[#212A42]'
									}`}
								>
									{t('Quick Estimate')}
								</div>
							</Button>
							<Button
								type='link'
								className={`w-[207px] h-[42px] px-3.5 py-2.5 flex items-center justify-center gap-2.5 rounded-l-none border transition-colors duration-200 ease-in-out ${
									activeMenu === 'common' ? activeClasses : inactiveClasses
								}`}
								onClick={() => setActiveMenu('common')}
							>
								<div
									className={`w-[191px] h-[22px] gap-0 text-base font-semibold ${
										activeMenu === 'common' ? 'text-[#007BFF]' : 'text-[#212A42]'
									}`}
								>
									{t('Common Size Estimate')}
								</div>
							</Button>
						</div>

						{activeMenu === 'quick' ? (
							// Quick Estimate 输入表单
							<form
								onSubmit={(e) => {
									e.preventDefault();
									calculateQuickEstimate();
								}}
								className='w-full'
							>
								<div className='text-xl font-semibold mb-3'>{t('Cargo Information')}</div>
								<div className='flex flex-row text-base mb-5 gap-8'>
									{[
										{
											label: t('Total Volume'),
											name: 'totalVolume',
											value: quickEstimateData.totalVolume,
											unit: 'm³',
											step: '0.01',
											min: '0.01',
											max: '99.99',
										},
										{
											label: t('Total Weight'),
											name: 'totalWeight',
											value: quickEstimateData.totalWeight,
											unit: 'kg',
											step: '1',
											min: '1',
											max: '9999',
										},
									].map(({ label, name, value, unit, step, min, max }) => (
										<div key={name} className='flex flex-col mb-1.5 w-1/4'>
											<span className={'mb-2'}>{label}</span>
											<div className='flex flex-row items-center gap-1'>
												<Input
													type='number'
													name={name}
													value={value}
													onChange={handleQuickEstimateChange}
													placeholder={label}
													className='w-full'
													step={step}
													min={min}
													max={max}
													required
												/>
												<span>{unit}</span>
											</div>
										</div>
									))}
								</div>

								<PalletInputComponent
									inputData={palletData}
									onInputChange={handleInputPalletDataChange}
								/>

								<Button type='primary' onClick={calculateQuickEstimate} className='mt-5'>
									{t('Calculate')}
								</Button>
							</form>
						) : (
							// Common Size Estimate 输入表单
							<form onSubmit={handleSubmit} className='w-full'>
								<h3 className='text-xl font-semibold mb-3'>{t('Cargo Information')}</h3>
								{/* Cargo Info Section */}
								<div className='flex flex-col'>
									<div className='grid grid-cols-6 gap-0 mb-2 text-base'>
										{['L', 'W', 'H', 'Weight', 'Qty', 'Allow Flipped'].map((label) => (
											<span key={label}>{t(label)}</span>
										))}
									</div>
									{inputData.items.map((item, index) => (
										<div
											key={index}
											className='grid grid-cols-6 gap-0 items-center mb-2 text-base '
										>
											{['length', 'width', 'height', 'weight', 'qty'].map((field, idx) => (
												<div key={idx} className='flex items-center'>
													<Input
														type='number'
														name={field}
														value={item[field]}
														onChange={(e) => handleInputChange(e, index, 'items')}
														placeholder={t(field.charAt(0).toUpperCase() + field.slice(1))}
														max={9999}
														className={'w-20'}
													/>
													<span className='ml-1'>
														{field !== 'qty' && t(field === 'weight' ? 'kg' : 'cm')}
													</span>
												</div>
											))}
											<div className='flex items-center justify-between'>
												<Input
													type='checkbox'
													name='flippable'
													checked={item.flippable}
													onChange={(e) => handleInputChange(e, index, 'items')}
													className='w-4 h-4'
												/>
												<Button ghost onClick={() => removeItem(index)}>
													<svg
														width='18'
														height='18'
														viewBox='0 0 18 18'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'
													>
														<g clip-path='url(#clip0_3452_40276)'>
															<path
																d='M14.25 4.8075L13.1925 3.75L9 7.9425L4.8075 3.75L3.75 4.8075L7.9425 9L3.75 13.1925L4.8075 14.25L9 10.0575L13.1925 14.25L14.25 13.1925L10.0575 9L14.25 4.8075Z'
																fill='#747578'
															/>
														</g>
														<defs>
															<clipPath id='clip0_3452_40276'>
																<rect width='18' height='18' fill='white' />
															</clipPath>
														</defs>
													</svg>
												</Button>
											</div>
										</div>
									))}
								</div>

								{/* Total Volume and Total Weight Display */}
								<div className='flex justify-between mt-5 mb-5'>
									<Button onClick={addItem}>{t('Add Items')}</Button>
									<p className='mt-1.5 text-base'>
										{t('Total Cargo Volume')}:{' '}
										{inputData.items
											.reduce(
												(sum, item) =>
													sum + (item.length * item.width * item.height * item.qty) / 1000000,
												0,
											)
											.toFixed(2)}{' '}
										{t('cubic meters')}
									</p>
									<p className={'text-base'}>
										{t('Total Cargo Weight')}:{' '}
										{inputData.items.reduce((sum, item) => sum + item.weight * item.qty, 0)} kg
									</p>
								</div>

								{/* Pallet Spec Section */}
								<PalletInputComponent
									inputData={palletData}
									onInputChange={handleInputPalletDataChange}
								/>
								{/* Calculate Button */}
								<Button
									onClick={handleSubmit}
									type='primary'
									disabled={calculating}
									className='mt-5'
								>
									{t('Calculate')}
								</Button>
							</form>
						)}
					</Tabs.TabPane>

					<Tabs.TabPane key='2'>
						{showResults && (
							<div className='pt-2.5 pb-2.5 overflow-y-auto bg-white text-base'>
								{activeMenu === 'quick' ? (
									// Quick Estimate 结果显示
									<>
										<h3 className='text-xl font-semibold mb-3'>{t('Cargo Info')}</h3>
										{/* 横向排列，两个列都靠左边 */}
										<div className='flex flex-row justify-between mb-5 '>
											<div className='flex flex-col items-left mt-2.5 '>
												<div className='mb-1.5 opacity-70 font-semibold'>
													{t('Total Cargo Volume')}
												</div>
												<div className={'!text-base'}>
													<span className={'mr-1'}>{quickEstimateData.totalVolume}</span>
													<span>{quickEstimateData.totalVolume ? t('cubic meters') : '/'}</span>
												</div>
											</div>
											{/* 靠左边的列 */}
											<div className='flex flex-col mr-[400px] items-left'>
												<div className='mb-1.5 mt-2.5 opacity-70 font-semibold'>
													{t('Total Cargo Weight')}
												</div>
												<div>
													<span className={'mr-1'}>{quickEstimateData.totalWeight}</span>
													<span>{quickEstimateData.totalWeight ? 'kg' : '/'}</span>
												</div>
											</div>
										</div>
										<h3 className='text-lg font-semibold mb-3'>{t('Estimate Result')}</h3>
										<div className='flex flex-row justify-start mb-5'>
											<div className='flex flex-col items-left'>
												<p className={'font-semibold opacity-80'}>{t('Pallet Qty')}</p>
												<p>{quickEstimateResult?.N}</p>
											</div>
											<div className='flex flex-col items-left ml-[50px]'>
												<p className={'font-semibold opacity-80'}>{t('Weight per Pallet')}</p>
												<p>
													{quickEstimateResult?.W}
													{' lb'}
												</p>
											</div>
											<div className='flex flex-col items-left ml-[50px]'>
												<p className={'font-semibold opacity-80'}>{t('Pallet Dimensions')}</p>
												<p>
													{quickEstimateResult?.L_in} * {quickEstimateResult?.W_in} *{' '}
													{quickEstimateResult?.H_in}
													{' in'}
												</p>
											</div>
										</div>
									</>
								) : (
									// Common Size Estimate 结果显示 (保持不变)
									<>
										<h3 className='text-xl font-semibold mb-3 mt-3'>{t('Cargo Information')}</h3>
										{/* 横向排列，两个列都靠左边 */}
										<div className='flex flex-row  gap-12'>
											<div className='flex flex-col items-left mb-2.5'>
												<div className='mb-1.5 font-semibold opacity-70'>
													{t('Total Cargo Volume')}
												</div>
												<div>
													{inputData.items
														.reduce(
															(sum, item) =>
																sum + (item.length * item.width * item.height * item.qty) / 1000000,
															0,
														)
														.toFixed(2)}{' '}
													{t('cubic meters')}
												</div>
											</div>
											{/* 靠左边的列 */}
											<div className='flex flex-col mb-2.5 items-left'>
												<div className='mb-1.5 font-semibold opacity-70'>
													{t('Total Cargo Weight')}
												</div>
												<div>
													{inputData.items.reduce((sum, item) => sum + item.weight * item.qty, 0)}{' '}
													kg
												</div>
											</div>
										</div>
										<h3 className='text-xl font-semibold mb-3 mt-5'>{t('Estimate Result')}</h3>
										<p className='mb-3'>
											<span className={'opacity-70 mr-2'}>{t('Number of Pallets Required')}:</span>
											{pallets.length}
										</p>

										<table className='w-full border-collapse mb-5 border-2 border-gray-300'>
											<thead onClick={handleKeepClickThead}>
												<tr>
													{[
														'Pallet',
														'Boxes',
														'L (in)',
														'W (in)',
														'H (in)',
														'Total Weight (lb)',
													].map((header) => (
														<th
															key={header}
															className='border-2 border-gray-300 p-2'
															style={{ border: '1px solid #d1d5db' }}
														>
															{t(header)}
														</th>
													))}
												</tr>
											</thead>
											<tbody>
												{palletInfo.map((info, index) => {
													const [length, width] = info.dimensions.split('x');
													const length_in = Math.floor(length / 2.54);
													const width_in = Math.floor(width / 2.54);
													// const height_in = Math.floor(height / 2.54);
													const totalWeight = parseFloat(info.totalWeight.replace('kg', '')) + 18; // add pallet weight
													const totalWeight_lb = Math.ceil(totalWeight * 2.20462);
													const totalHeight = info.totalHeight.replace('cm', '');
													const totalHeight_in = Math.ceil(totalHeight / 2.54) + 6;
													return (
														<tr key={index}>
															{[
																info.palletNumber,
																info.boxes,
																length_in,
																width_in,
																totalHeight_in,
																totalWeight_lb,
															].map((value, idx) => (
																<td
																	key={idx}
																	className='border-2 border-gray-300 p-2 text-center'
																	style={{ border: '1px solid #d1d5db' }}
																>
																	{value}
																	{idx === 5 && showVisualization && (
																		<Button
																			type={'default'}
																			className={'ml-2'}
																			onClick={() => {
																				setCurrentBinIndex(index);
																			}}
																		>
																			3D
																		</Button>
																	)}
																</td>
															))}
														</tr>
													);
												})}
											</tbody>
										</table>

										{showVisualization && (
											<div className='w-full h-[500px]'>
												<h3 className='text-xl font-semibold mb-3 mt-5'>
													{t('Pallet')} {currentBinIndex + 1}
												</h3>
												<Suspense fallback={<div>loading...</div>}>
													<ThreeJSVisualization
														packingResult={packingResult}
														palletSize={{
															length: Math.round(palletData.palletLengthInches * 2.54),
															width: Math.round(palletData.palletWidthInches * 2.54),
															height: Math.round(palletData.palletHeightMaxInches * 2.54) - 15,
														}}
														currentBinIndex={currentBinIndex}
													/>
												</Suspense>
											</div>
										)}
									</>
								)}

								{/* 返回和填充按钮 */}
								<Space className='mt-5'>
									<Button onClick={() => setActiveTab('1')}>{t('Back')}</Button>
									<Button
										onClick={() => {
											setAutofillItems();
											setShowModal(false);
										}}
										type='primary'
									>
										{t('Fill In')}
									</Button>
								</Space>
							</div>
						)}
					</Tabs.TabPane>
				</Tabs>
			</Modal>
		</>
	);
}
