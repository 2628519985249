import { GetProjectCalendarProgressProps, GetProjectCalendarProgressRes } from '@/services/apis';
import { useMemoizedFn, useSafeState, useSetState } from 'ahooks';
import { ProgressMapKeysEnum } from '@/pages/project/use-progress-map';
import { apiHooks } from '@/hooks/apis';

export const useProjectCalendarProgress = (
	progressKey: ProgressMapKeysEnum,
	projectId: number,
	date?: string,
) => {
	const [data, setData] = useSafeState<GetProjectCalendarProgressRes['data']>([]);

	const [pagination, setPagination] = useSetState<{
		page: number;
		per_page: number;
		total: number;
	}>({
		page: 1,
		per_page: 20,
		total: 0,
	});

	const { runAsync, loading } = apiHooks.useGetProjectCalendarProgress();

	const requestNextPage = useMemoizedFn(async () => {
		try {
			const invalid =
				pagination.page !== 1 && pagination.page * pagination.per_page > pagination.total;
			if (invalid) return;
			const res = await runAsync({
				path: {
					projectId,
					type: progressKey as GetProjectCalendarProgressProps['path']['type'],
				},
				params: {
					date: date,
					page: pagination.page,
					per_page: pagination.per_page,
				},
			});
			setData((data) => [...data, ...res.data.data]);
			setPagination((pagination) => {
				const newPagination = {
					page: pagination.page + 1,
					per_page: Number(res.data.meta.per_page),
					total: res.data.meta.total,
				};
				return newPagination;
			});
		} catch (error) {
			console.error(error);
		}
	});

	return {
		data,
		pagination,
		loading,
		requestNextPage,
	};
};
