import { baseURL } from '..';
import { downloadFactory } from '../download-factory';
import { DownloadLtlInvoiceFileProps } from './interface';

export const downloadLtlInvoiceFile = async (
	props: DownloadLtlInvoiceFileProps,
	config?: { preview?: boolean },
) => {
	const { invoiceId } = props.path;
	const { fileName } = props.other;
	const url = baseURL + `tl/ltl/${invoiceId}/inovicePdf`;

	downloadFactory(url, fileName, { preview: config?.preview });
};
