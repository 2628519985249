import { message as _message } from 'antd';
import { JointContent } from 'antd/es/message/interface';

const info = (content: JointContent, duration?: number | VoidFunction, onClose?: VoidFunction) => {
	_message.info(content, duration, onClose);
};

const error = (content: JointContent, duration?: number | VoidFunction, onClose?: VoidFunction) => {
	_message.error(content, duration, onClose);
};

export const message = {
	info,
	error,
};
