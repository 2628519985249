import { useCallback, useEffect } from 'react';
import { GetProjectMyFocusProps, GetProjectMyFocusRes } from '@/services/apis';
import { useMemoizedFn, useSafeState, useSetState } from 'ahooks';
import { apiHooks } from '@/hooks/apis';

export const useGetProjectMyFocusPagination = ({
	type,
	projectId,
	date: _date,
}: {
	type: GetProjectMyFocusProps['path']['type'];
	projectId: string;
	date: string;
}) => {
	const [selectedIR, setSelectedIR] = useSafeState<number | null>(null);

	const [date, setDate] = useSafeState<string>(_date);

	const [data, setData] = useSafeState<GetProjectMyFocusRes['data']>([]);

	const [total, setTotal] = useSetState<{ total: number; actual_total: number; est_total: number }>(
		{
			total: 0,
			actual_total: 0,
			est_total: 0,
		},
	);

	const {
		data: mapData,
		loading: mapLoading,
		run: getProjectMyFocusMap,
	} = apiHooks.useGetProjectMyFocusMap({
		debounceWait: 300,
		defaultParams: [
			{
				path: {
					projectId,
					type,
				},
				params: {
					date,
				},
			},
		],
	});

	const [pagination, setPagination] = useSetState<{
		page: number;
		per_page: number;
		total: number;
	}>({
		page: 1,
		per_page: 20,
		total: 0,
	});

	const { runAsync, loading } = apiHooks.useGetProjectMyFocus({
		debounceWait: 300,
	});

	const handleInit = useMemoizedFn(async (_setSelectedIR?: number | null) => {
		if (!date) {
			return;
		}

		try {
			getProjectMyFocusMap({
				path: {
					projectId,
					type,
				},
				params: {
					date,
				},
			});
			const res = await runAsync({
				path: {
					projectId,
					type,
				},
				params: {
					date: date,
					// page: 1,
					// per_page: pagination.per_page,
					intermodal_region_id: _setSelectedIR === undefined ? selectedIR : _setSelectedIR,
				},
			});
			// setTotal({
			// 	// total: res.data.meta.total,
			// 	actual_total: res.data.actual_total,
			// 	est_total: res.data.est_total,
			// });
			setData(res.data.data);
			setPagination((pagination) => ({
				page: pagination.page + 1,
				// total: res.data.meta.total,
			}));
		} catch (error) {
			console.error(error);
		}
	});

	const requestNextPage = useMemoizedFn(async () => {
		if (!date) {
			return;
		}
		try {
			if (pagination.page * pagination.per_page > pagination.total) return;
			const res = await runAsync({
				path: {
					projectId,
					type,
				},
				params: {
					date,
					// page: pagination.page,
					// per_page: pagination.per_page,
					intermodal_region_id: selectedIR,
				},
			});
			setData((data) => [...data, ...res.data.data]);
			// setPagination((pagination) => ({
			// 	page: pagination.page + 1,
			// 	total: res.data.meta.total,
			// }));
		} catch (error) {
			console.error(error);
		}
	});

	const selectIR = useCallback(
		(IRId: number | undefined) => {
			if (IRId === selectedIR) {
				setSelectedIR(null);
				handleInit(null);
			} else {
				setSelectedIR(IRId);
				handleInit(IRId);
			}
		},
		[setSelectedIR, handleInit],
	);

	const reset = useCallback(
		(time?: string) => {
			setSelectedIR(null);
			setData([]);
			// setTotal({ total: 0, actual_total: 0 });
			// setPagination({ page: 1, per_page: 20, total: 0 });
			setDate(time);
		},
		[
			setSelectedIR,
			setData,
			// setTotal,
			// setPagination,
			setDate,
		],
	);

	useEffect(() => {
		handleInit();
	}, [date]);

	return {
		data,
		loading,
		mapData,
		mapLoading,
		// total,
		selectedIR,
		// requestNextPage,
		selectIR,
		reset,
	};
};
