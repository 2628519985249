import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { Spin } from 'antd';
import { baseURL } from '@/services/apis';
import accessTokenManager from '@/services/contexts/user/access-token-manager.ts';
import InvalidUrl from './invalid-url.tsx';

const BOL = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const shipmentId = searchParams.get('id') ? parseInt(searchParams.get('id')) : null;
	const [fileUrl, setFileUrl] = useState<string | null>(null);

	const downloadBol = () => {
		const url = baseURL + `ftl/shipments/${shipmentId}/bol`;
		fetch(url, { headers: { Authorization: accessTokenManager.getAccessToken() } })
			.then((response) => response.blob())
			.then((blob) => {
				const fileUrl = URL.createObjectURL(blob);
				setFileUrl(fileUrl);
			});
	};

	useEffect(() => shipmentId && downloadBol(), [shipmentId]);

	if (!shipmentId) {
		return <InvalidUrl />;
	}

	return (
		<div
			className='w-full h-full flex justify-center items-center'
			style={{ margin: 0, padding: 0 }}
		>
			{fileUrl ? (
				<iframe
					src={fileUrl}
					width='100%'
					height='100%'
					title='file'
					style={{ border: 'none', margin: 0, padding: 0 }}
				/>
			) : (
				<Spin spinning={true} />
			)}
		</div>
	);
};

export default BOL;
