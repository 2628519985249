import { useTranslation } from 'react-i18next';
import { Button, Popover, Select, Space, TreeSelect } from 'antd';
import Close16pxActiveIcon from '@/icons/close-16px-active-icon';
import Close16pxIcon from '@/icons/close-16px-icon';
import Filter16pxActiveIcon from '@/icons/filter-16px-active-icon';
import Filter16pxIcon from '@/icons/filter-16px-icon';
import { useBoolean, useDynamicList } from 'ahooks';
import { Gap } from '@/components/gap';
import { FormState } from '../type';
import { FilterOptionKeys, useOptions } from '../use-options';

export interface FilterProps {
	value: FormState['filter'];
	onChange: (value: FormState['filter']) => void;
}

export const Filter = ({ value, onChange }: FilterProps) => {
	const { t } = useTranslation();

	const { list, remove, getKey, replace, push, resetList } =
		useDynamicList<[FilterOptionKeys | undefined, any]>(value);

	const hasValue = value.filter((v) => !!v[0] && !!v[1]).length > 0;

	const [open, { set }] = useBoolean();

	const options = useOptions([
		FilterOptionKeys.progress,
		FilterOptionKeys.mbl,
		FilterOptionKeys.final_port_ids,
		FilterOptionKeys.warehouse_ids,
		FilterOptionKeys.final_port_eta,
		FilterOptionKeys.lfd,
		FilterOptionKeys.schedule_terminal_pickup_at,
		FilterOptionKeys.actual_terminal_pickuped_at,
	]);

	const selectedOptions = list.map((l) => l[0]).filter((v) => !!v);

	const confirm = () => {
		onChange(list.filter((item) => !!item[0] && !!item[1]));
		set(false);
	};

	const content = (
		<Space size={16} direction='vertical' className='w-[520px]'>
			{list.map((item, index) => {
				const Selector = options.find((o) => o.value === item[0])?.selector;

				console.log('options', options);

				return (
					<div key={getKey(index)} className='w-full flex items-center'>
						{/* key */}
						<Select
							className=' grow-0 shrink-0 w-[160px]'
							placeholder={t(`Select`)}
							value={item[0]}
							options={options.filter(
								(o) => o.value === item[0] || !selectedOptions.includes(o.value),
							)}
							onChange={(v) => replace(index, [v, item[1]])}
						/>

						<Gap width='12px' />

						{/* value */}
						<>
							{item[0] ? (
								<Selector
									className='grow shrink'
									value={item[1]}
									onChange={(value) => replace(index, [item[0], value])}
								/>
							) : (
								<TreeSelect className='grow shrink' placeholder={t(`Select`)} />
							)}
						</>

						<Gap width='12px' />

						{/* close */}
						<Close16pxIcon
							className='grow-0 shrink-0 cursor-pointer'
							onClick={() => remove(index)}
						/>
					</div>
				);
			})}
			<div className='flex justify-between items-center'>
				<Button onClick={() => push([undefined, undefined])}>{t(`Add filter`)}</Button>
				<Button type='primary' onClick={confirm}>
					{t(`Confirm`)}
				</Button>
			</div>
		</Space>
	);

	return (
		<Popover
			title=''
			trigger={'click'}
			placement='bottomLeft'
			autoAdjustOverflow
			arrow={false}
			content={content}
			open={open}
			overlayInnerStyle={{ padding: '24px' }}
			onOpenChange={set}
		>
			<div>
				{hasValue ? (
					<div className='box-border p-[0_12px] h-[32px] flex items-center rounded-[16px] bg-primary-light-04 body-4-m text-primary-regular'>
						<Filter16pxActiveIcon />
						<Gap width='8px' />
						<div>{t(`Filter`)}</div>
						<Gap width='8px' />
						<Close16pxActiveIcon
							className='cursor-pointer'
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								onChange([]);
								resetList([]);
							}}
						/>
					</div>
				) : (
					<div
						style={{ backgroundColor: open ? '#E7E7E7' : 'white' }}
						className='box-border p-[0_12px] h-[32px] flex items-center border-[1px] border-solid border-grey-04 rounded-[16px] body-4-m text-grey-01'
					>
						<Filter16pxIcon />
						<Gap width='8px' />
						<div>{t(`Filter`)}</div>
					</div>
				)}
			</div>
		</Popover>
	);
};
