import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Progress, Upload } from 'antd';
import { DraggerProps, UploadFile, UploadProps } from 'antd/es/upload';
import DeleteOneIcon from '@/icons/delete-one-icon';
import DownloadBoldIcon from '@/icons/download-bold-icon';
import InboxOutlinedIcon from '@/icons/inbox-oulined-icon';
import { OrderFile } from '@/interfaces/file.interface';
import { baseURL } from '@/services/apis';
import { deleteFile } from '@/services/apis';
import { downloadFile } from '@/services/apis/download-file';
import { useBookContext } from '@/services/contexts';
import accessTokenManager from '@/services/contexts/user/access-token-manager';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { convert } from '@/utils/convert-units';
import { FileRes } from './type';

const { Dragger } = Upload;

// 5. render item
const ItemRender: UploadProps<FileRes>['itemRender'] = (_, file, __, actions) => {
	const { t } = useTranslation();
	const { download, remove } = actions;

	const data: OrderFile | undefined = file.response?.data?.[0];

	const size = data?.size ?? file?.size ?? 0;

	const getFileStatusText = () => {
		// @ts-ignore
		if (file.status === 'error' || file.status === 'removed' || file.percent === 0) return '';
		// @ts-ignore
		if (file.status === 'done' || file.status === 'success' || file?.percent === 100)
			return `uploaded at ${dayjs(data?.created_at).format('M/D h:m')}`;
		// @ts-ignore
		if (file.status === 'uploading' || file.percent > 0) return t('uploading');
		return '';
	};

	const getSize = () => {
		if (typeof size === 'string') return size;
		const convertedSize = convert(size).from('b').toBest() ?? { val: 0, unit: 'B' };
		return `${convertedSize.val} ${convertedSize.unit}`;
	};

	return (
		<div className='flex mt-[16px] p-[10px_16px_9px_7px] border-[2px] border-solid border-[#747578] rounded-[6px] text-neutral-dark-grey'>
			<div className='flex-grow mr-[14px]'>
				{/* File Name */}
				<div className='font-[500] text-[16px] '>{data?.file_name ?? file.name}</div>
				{/* progress */}
				<div>
					{/* @ts-ignore */}
					<Progress size={['100%', 1]} percent={file.percent} showInfo={false} />
				</div>

				<div className='flex items-center font-[400] text-[14px]'>
					{/* size */}
					<div>{`${getSize()}`}</div>

					<div className='ml-[11px] mr-[11px] w-[2px] h-[2px] bg-neutral-dark-grey rounded-[50%]' />
					{/* update time */}
					<div>{getFileStatusText()}</div>
				</div>
			</div>

			{/* buttons */}
			<div className='flex justify-between items-center w-[46px]'>
				<DownloadBoldIcon className='cursor-pointer w-[16px] h-[16px]' onClick={download} />

				<DeleteOneIcon className='cursor-pointer w-[16px] h-[16px]' onClick={remove} />
			</div>
		</div>
	);
};

const turnOrderFileIntoUploadFile = (fileList: OrderFile[]): UploadFile<FileRes>[] => {
	// @ts-ignore
	return fileList
		.filter((file) => !!file)
		.map((file) => {
			return {
				uid: String(-file.id),
				name: file.name,
				fileName: file.file_name,
				status: 'success',
				percent: 100,
				response: {
					data: [file],
				},
			};
		});
};

export default observer(({ ...props }: DraggerProps) => {
	const { t } = useTranslation();

	const form = Form.useFormInstance();

	const bookContext = useBookContext();

	const draftOrderId = bookContext.draftOrderId;

	// 1. 从 from 获得初始化 form list
	const _fileList: OrderFile[] = form.getFieldValue('files') ?? [];
	// 2. 把 order file 转成 upload file
	const [fileList, setFileList] = useState<UploadFile<FileRes>[]>(() =>
		turnOrderFileIntoUploadFile(_fileList),
	);

	const getPath = (file: UploadFile<FileRes>) => {
		if (draftOrderId) {
			return {
				documentId: file.response?.data?.[0]?.id,
				orderId: draftOrderId,
			};
		}

		return {
			documentId: file.response?.data[0]?.id,
			draftDocumentId: file.response?.data[0]?.draft_document_id,
		};
	};

	const handleChange: UploadProps['onChange'] = (info) => {
		// 3. 处理上传等
		if (info.file.status === 'done') {
			const newFileList = turnOrderFileIntoUploadFile(info.file.response.data);
			setFileList(newFileList);
			return;
		}
		const newFileList = [...info.fileList];
		setFileList(newFileList);
	};

	// 6. download
	const handleDownloadFile: UploadProps['onDownload'] = (file) => {
		const path = getPath(file);
		// @ts-ignore
		downloadFile({ path, other: { fileName: file.name } }, { preview: true });
	};

	// 7. delete
	const handleDeleteFile: UploadProps['onRemove'] = async (file) => {
		// 判断 order id
		const path = getPath(file);
		// @ts-ignore
		await deleteFile({ path });
		form.setFieldValue(
			'files',
			fileList.filter((f) => f.uid !== file.uid),
		);
	};

	// 4. 同步到 form
	useEffect(() => {
		form.setFieldValue(
			'files',
			fileList.map((file) => file.response?.data?.[0]),
		);
	}, [fileList]);

	return (
		<Dragger
			{...props}
			fileList={fileList}
			onChange={handleChange}
			multiple
			itemRender={ItemRender}
			action={`${baseURL}${
				draftOrderId ? `newDocuments/order_customer/${draftOrderId}` : 'draftDocuments'
			}`}
			headers={{
				Authorization: accessTokenManager.getAccessToken() ?? '',
			}}
			name={draftOrderId ? 'documents' : 'files'}
			onDownload={handleDownloadFile}
			onRemove={handleDeleteFile}
		>
			<p className='ant-upload-drag-icon'>
				<InboxOutlinedIcon />
			</p>
			<p className='ant-upload-text'>
				<span className='text-[#367cf2] underline cursor-pointer'>{t('Choose File')}</span>
				{t('or drag file to this area to upload')}
			</p>
			<p className='ant-upload-hint'>{t('Support for a single or bulk upload.')}</p>
		</Dragger>
	);
});
