import React from 'react';
import WinterActiveSvg from '@/assets/winter-active.svg';
import WinterSvg from '@/assets/winter.svg';

const WinterIcon = ({
	active,
	...props
}: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
	active: boolean;
}) => {
	return <img {...props} src={active ? WinterActiveSvg : WinterSvg} />;
};

export default WinterIcon;
