import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Space, Table } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';
import { Quote, quoteBusinessStatusMap } from '@/interfaces/quote.interface';
import {
	calcTotalVolume,
	calcTotalWeight,
	generateNumberOfUnits,
} from '@/services/contexts/ltl/ltl.context';
import '@/styles/no-cell-border-table.less';
import { LoadingOutlined } from '@ant-design/icons';
import { useMemoizedFn, useMount } from 'ahooks';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { FT3 } from '@/components/ft3';
import { Gap } from '@/components/gap';
import { apiHooks } from '@/hooks/apis';
import { LTLSearchRate } from '../ltl-search-rate';
import useLtlQuoteHistoryParams from './use-ltl-quote-history-params';

const LTLQuoteHistory = observer(() => {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const { parsedSearchParams, changeSearchParams } = useLtlQuoteHistoryParams();

	const {
		runAsync: getQuoteList,
		data: quoteList,
		loading: quoteListLoading,
		pagination,
	} = apiHooks.useGetQuoteList();

	const { current, total, pageSize, onChange: changePagination } = pagination;

	const quoteColumns: ColumnsType<Quote> = [
		{
			title: t('Quote#'),
			dataIndex: 'uid',
			width: 120,
			fixed: 'left',
		},
		{
			title: t('Origin'),
			dataIndex: ['pickup_zipcode'],
			width: 200,
			render: (_, record) => (
				<div>
					{record.pickup_zipcode && <div>{record.pickup_zipcode}</div>}
					<div>
						<span>{record.pickup_city_name ?? ''}</span>
						{record.pickup_city_name && record.pickup_state_name && <span>{`, `}</span>}
						<span>{record.pickup_state_name}</span>
					</div>
				</div>
			),
		},
		{
			title: t('Destination'),
			dataIndex: ['destination_zipcode'],
			width: 200,
			render: (_, record) => (
				<div>
					{record.destination_zipcode && <div>{record.destination_zipcode}</div>}
					<div>
						<span>{record.destination_city_name ?? ''}</span>
						{record.destination_city_name && record.destination_state_name && <span>{`, `}</span>}
						<span>{record.destination_state_name}</span>
					</div>
				</div>
			),
		},
		{
			title: t('Pallets/Volume/Weight'),
			dataIndex: ['items'],
			width: 200,
			render: (items: Quote['items']) => (
				<div>
					<div>{`${generateNumberOfUnits(items)}`}</div>
					<div>
						<span>{`${calcTotalVolume(items)} `}</span>
						<FT3 />
						<span>{`${calcTotalWeight(items)} lbs`}</span>
					</div>
				</div>
			),
		},
		{
			title: t('Status'),
			dataIndex: 'business_status',
			width: 120,
			render: (value) => t(quoteBusinessStatusMap[value]) ?? '',
		},
		{
			title: t('Quote Date'),
			dataIndex: ['quote_at'],
			width: 140,
			render: (value) => (value ? dayjs(value).format('YYYY-MM-DD') : ''),
		},
		{
			title: t(`Actions`),
			key: 'actions',
			width: 140,
			fixed: 'right',
			render: (_, record) => (
				<Space>
					<Button
						type='link'
						className='px-0'
						onClick={() => {
							changeSearchParams({ ...parsedSearchParams, quote_id: record.uid });
						}}
					>
						{t('View')}
					</Button>
					<Button
						className='px-0'
						onClick={() => {
							navigate(`/ltl-search-rate?quote_id=${record.uid}&type=copy`);
						}}
					>
						{t('Copy')}
					</Button>
				</Space>
			),
		},
	];

	const handleChange: TableProps<Quote>['onChange'] = useMemoizedFn((pagination) => {
		const { current, pageSize } = pagination;
		changePagination(current, pageSize);

		changeSearchParams({
			page: current,
			per_page: pageSize,
		});
	});

	useMount(async () => {
		await getQuoteList(
			{ pageSize: parsedSearchParams.per_page ?? 20, current: parsedSearchParams.page ?? 1 },
			{ params: {} },
		);
		changePagination(parsedSearchParams.page ?? 1, parsedSearchParams.per_page ?? 20);
	});

	if (parsedSearchParams.quote_id) {
		return <LTLSearchRate />;
	}

	return (
		<div className='h-screen w-full p-[48px] flex flex-col bg-white overflow-hidden'>
			{/* header */}
			<div className='grow-0 shrink-0 w-full h-[44px] flex items-center gap-[8px]'>
				<div className='h3-b text-black'>{t('LTL Quote History')}</div>
				{quoteListLoading && <LoadingOutlined />}
			</div>

			<Gap height='24px' />

			{/* table */}
			<Table
				className='no-cell-border-table'
				rowKey='id'
				columns={quoteColumns}
				dataSource={quoteList}
				bordered
				pagination={{
					position: ['bottomCenter'],
					showSizeChanger: true,
					total,
					current,
					pageSize,
				}}
				scroll={{ scrollToFirstRowOnChange: true, x: 'max-content', y: 'calc(100vh - 259px)' }}
				onChange={handleChange}
			/>
		</div>
	);
});

export default LTLQuoteHistory;
