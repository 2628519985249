import { useTranslation } from 'react-i18next';
import { Tabs, TabsProps } from 'antd';
import { Order } from '@/interfaces/order.interface';
import ContainersTab from './container-tab.tsx';
import DocumentsTab from './documents-tab/index.tsx';
import InvoicesTab from './invoices-tab.tsx';
import MessagesTab from './messages-tab.tsx';
import OrderDetailsTab from './order-details-tab/index.tsx';

const TableExpandedRow = ({ order, refresh }: { order: Order; refresh: () => void }) => {
	const { t } = useTranslation();

	const tabs: TabsProps['items'] = [
		{
			key: 'Containers',
			label: t('Containers'),
			children: <ContainersTab order={order} />,
		},
		{
			key: 'Order Details',
			label: t('Order Details'),
			children: <OrderDetailsTab order={order} />,
		},
		{
			key: 'Invoices',
			label: t('Invoices'),
			children: <InvoicesTab order={order} />,
		},
		{
			key: 'Documents',
			label: t('Documents'),
			children: <DocumentsTab order={order} refresh={refresh} />,
		},
		{
			key: 'Messages',
			label: t('Messages'),
			children: <MessagesTab order={order} refresh={refresh} />,
		},
	];

	return <Tabs items={tabs} />;
};

export default TableExpandedRow;
