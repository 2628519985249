import { useEffect } from 'react';
import { Button, Select, Space } from 'antd';
import { useAgentContext } from '@/services/contexts/agent';
import { EditOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import useCompanyModal from '../use-company-modal';

const CompanySelect = observer(({ onSelect, selected }: { onSelect: any; selected: number }) => {
	const agentContext = useAgentContext();
	const { CompanyModal, openCompanyModal, closeCompanyModal } = useCompanyModal();

	return (
		<>
			<Space.Compact className='w-full'>
				<Button
					type='default'
					onClick={() =>
						openCompanyModal(selected ? agentContext.companies.find((c) => c.id == selected) : null)
					}
				>
					<EditOutlined />
				</Button>
				<Select
					className='w-full'
					value={selected}
					placeholder='Select a company'
					options={agentContext.companies.map((company) => ({
						label: company.name,
						value: company.id,
					}))}
					onSelect={onSelect}
				/>
			</Space.Compact>
			<CompanyModal onAfterSave={() => agentContext.fetchCompanies()} />
		</>
	);
});

export default CompanySelect;
