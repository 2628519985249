// const CUSTOMER_STATUS_MAP = [
//     self::CUSTOMER_STATUS_DRAFT => 'Draft',
//     self::CUSTOMER_STATUS_ORDER_CONFIRMED => 'Order Confirmed',
//     self::CUSTOMER_STATUS_PREDELIVERY_APPOINTMENT_SENT => 'Predelivery Appointment sent',
//     self::CUSTOMER_STATUS_PREDELIVERY_APPOINTMENT_CONFIRMED => 'Predelivery Appointment confirmed',
//     self::CUSTOMER_STATUS_EXAM_DO_RECEIVED => 'Exam DO received',
//     self::CUSTOMER_STATUS_AVAILABLE => 'Available',
//     self::CUSTOMER_STATUS_CET_EXAM_SITE_PICKUP_APT_CONFIRMED => 'CET Exam site pickup Apt confirmed',
//     self::CUSTOMER_STATUS_DELIVERY_PLAN_SET => 'Delivery plan Set',
//     self::CUSTOMER_STATUS_WHS_APT_MADE_BY_CUSTOMER => 'WHS Apt Made by Customer',
//     // self::CUSTOMER_STATUS_WHS_APT_CONFIRMED => 'WHS Apt Confirmed',
//     self::CUSTOMER_STATUS_OUTGATED => 'Outgated',
//     self::CUSTOMER_STATUS_PREPULL_TO_YARD => 'Prepull to yard',
//     self::CUSTOMER_STATUS_MET_EXAM_SITE_PICKUP_APT_CONFIRMED => 'MET Exam site pickup Apt confirmed',
//     self::CUSTOMER_STATUS_WHS_APT_CONFIRMED_MET => 'WHS Apt Confirmed(MET)',
//     self::CUSTOMER_STATUS_DELIVER_TO_DESTINATION => 'Deliver to Destination',
//     self::CUSTOMER_STATUS_CONTAINER_DROPPED_TO_DESTINATION => 'Container Dropped to Destination',
//     // self::CUSTOMER_STATUS_POD_SENT => 'POD Sent',
//     self::CUSTOMER_STATUS_CNTR_EMPTIED_INFORMED_FROM_WHS => 'CNTR Emptied informed from WHS',
//     // self::CUSTOMER_STATUS_EMPTY_RETURNED_TO_YARD => 'Empty returned to yard',
//     self::CUSTOMER_STATUS_EMPTY_RETURNED_TO_TERMINAL_DEPOT => 'Empty Returned to terminal/depot',
//     self::CUSTOMER_STATUS_CANCELED => 'Canceled'

// ];

export const CONTAINER_STATUS_DRAFT = -2;
export const CONTAINER_STATUS_ORDER_CONFIRMED = 0;
export const CONTAINER_STATUS_PREDELIVERY_APPOINTMENT_SENT = 1;
export const CONTAINER_STATUS_PREDELIVERY_APPOINTMENT_CONFIRMED = 2;
export const CONTAINER_STATUS_EXAM_DO_RECEIVED = 8;
export const CONTAINER_STATUS_WHS_APT_CONFIRMED_MET = 61;
export const CONTAINER_STATUS_AVAILABLE = 34;
export const CONTAINER_STATUS_CET_EXAM_SITE_PICKUP_APT_CONFIRMED = 36;
export const CONTAINER_STATUS_DELIVERY_PLAN_SET = 30;
export const CONTAINER_STATUS_WHS_APT_MADE_BY_CUSTOMER = 55;
export const CONTAINER_STATUS_OUTGATED = 56;
export const CONTAINER_STATUS_PREPULL_TO_YARD = 38;
export const CONTAINER_STATUS_MET_EXAM_SITE_PICKUP_APT_CONFIRMED = 50;
export const CONTAINER_STATUS_DELIVER_TO_DESTINATION = 41;
export const CONTAINER_STATUS_CONTAINER_DROPPED_TO_DESTINATION = 42;
export const CONTAINER_STATUS_CNTR_EMPTIED_INFORMED_FROM_WHS = 44;
export const CONTAINER_STATUS_EMPTY_RETURNED_TO_TERMINAL_DEPOT = 46;
export const CONTAINER_STATUS_CANCELED = -5;

export const CONTAINER_STATUS_MAP = {
	// [CONTAINER_STATUS_DRAFT]: 'Draft',
	[CONTAINER_STATUS_ORDER_CONFIRMED]: 'Order Confirmed',
	[CONTAINER_STATUS_PREDELIVERY_APPOINTMENT_SENT]: 'Predelivery Appointment sent',
	[CONTAINER_STATUS_PREDELIVERY_APPOINTMENT_CONFIRMED]: 'Predelivery Appointment confirmed',
	[CONTAINER_STATUS_EXAM_DO_RECEIVED]: 'Exam DO received',
	[CONTAINER_STATUS_AVAILABLE]: 'Available',
	[CONTAINER_STATUS_CET_EXAM_SITE_PICKUP_APT_CONFIRMED]: 'CET Exam site pickup Apt confirmed',
	[CONTAINER_STATUS_DELIVERY_PLAN_SET]: 'Delivery plan Set',
	[CONTAINER_STATUS_WHS_APT_MADE_BY_CUSTOMER]: 'WHS Apt Made by Customer',
	[CONTAINER_STATUS_OUTGATED]: 'Outgated',
	[CONTAINER_STATUS_PREPULL_TO_YARD]: 'Prepull to yard',
	[CONTAINER_STATUS_MET_EXAM_SITE_PICKUP_APT_CONFIRMED]: 'MET Exam site pickup Apt confirmed',
	[CONTAINER_STATUS_WHS_APT_CONFIRMED_MET]: 'WHS Apt Confirmed(MET)',
	[CONTAINER_STATUS_DELIVER_TO_DESTINATION]: 'Deliver to Destination',
	[CONTAINER_STATUS_CONTAINER_DROPPED_TO_DESTINATION]: 'Container Dropped to Destination',
	[CONTAINER_STATUS_CNTR_EMPTIED_INFORMED_FROM_WHS]: 'CNTR Emptied informed from WHS',
	[CONTAINER_STATUS_EMPTY_RETURNED_TO_TERMINAL_DEPOT]: 'Empty Returned to terminal/depot',
	[CONTAINER_STATUS_CANCELED]: 'Canceled',
};
