import { useTranslation } from 'react-i18next';
import { Breadcrumb, BreadcrumbProps, Modal, ModalProps, Tabs } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useSafeState } from 'ahooks';
import Billing from '@/pages/order-detail/order-detail-container/components/billing';
import Documents from '@/pages/order-detail/order-detail-container/components/documents';
import Rate from '@/pages/order-detail/order-detail-container/components/rate';
import Tracking from '@/pages/order-detail/order-detail-container/components/tracking';
import ContainerHeader from '@/pages/order-detail/order-detail-container/container-header';
import { apiHooks } from '@/hooks/apis';
import CenterSpin from '../center-spin';
import { Gap } from '../gap';

export * from './use-container-detail-modal';

interface ContainerDetailModalProps extends ModalProps {
	projectId: string | number;
	orderId: string | number;
	containerId: string | number;
	onClose: () => void;
}

enum TabIndex {
	tracking = 'tracking',
	billing = 'billing',
	documents = 'documents',
	rates = 'rates',
}

export const ContainerDetailModal = ({
	projectId,
	orderId,
	containerId,
	onClose,
	...modalProps
}: ContainerDetailModalProps) => {
	const { t } = useTranslation();

	const { data: project, loading: projectLoading } = apiHooks.useGetProject({
		defaultParams: [{ path: { projectId } }],
	});

	const {
		data: order,
		loading: orderLoading,
		refresh: orderRefresh,
	} = apiHooks.useGetOrder({ defaultParams: [{ path: { orderId } }] });

	const {
		data: container,
		loading: containerLoading,
		refresh: containerRefresh,
	} = apiHooks.useGetContainer({ defaultParams: [{ path: { containerId } }] });

	const [selectedIndex, setSelectedIndex] = useSafeState<TabIndex>(TabIndex.tracking);

	const breadcrumbItems: BreadcrumbProps['items'] = [
		{
			title: project?.name,
		},
		{
			title: <div className='body-4-b text-primary-regular'>Container #{container?.number}</div>,
		},
	];
	return (
		<Modal
			title={null}
			footer={null}
			width={1300}
			centered
			closeIcon={null}
			styles={{
				mask: {
					background: '#00000099',
				},
				content: {
					padding: 0,
				},
			}}
			onCancel={onClose}
			{...modalProps}
		>
			<div className='w-[1300px] h-[933px] flex flex-col overflow-hidden rounded-[16px] bg-white'>
				{projectLoading || orderLoading || containerLoading ? (
					<CenterSpin />
				) : (
					<>
						<div style={{ borderBottom: '1px solid #C8C8C8' }} className='grow-0 shrink-0'>
							{/* header */}
							<div
								style={{ borderBottom: '1px solid #ADAEAF' }}
								className='grow-0 shrink-0 box-border p-[0_24px] h-[40px] flex justify-between items-center bg-grey-06'
							>
								{/* Breadcrumb */}
								<Breadcrumb separator='>' items={breadcrumbItems} />
								<CloseOutlined width={16} className=' cursor-pointer' onClick={onClose} />
							</div>

							<Gap height='24px' />

							<div className='p-[0_24px]'>
								{/* left */}
								<div>
									{/* <div className='body-4-b text-primary-regular'>{container.number}</div> */}
									<ContainerHeader
										number={container?.number}
										status_text={container?.status_text}
										risks={container?.risks}
										order_uid={order?.uid}
									/>
									<div></div>
								</div>

								{/* right */}
								<div></div>
							</div>

							<Tabs
								activeKey={selectedIndex}
								tabBarStyle={{
									margin: '0 24px',
								}}
								items={[
									{
										key: TabIndex.tracking,
										label: t('Tracking'),
									},
									{
										key: TabIndex.billing,
										label: t('Billing'),
									},
									{
										key: TabIndex.documents,
										label: t('Documents'),
									},
									{
										key: TabIndex.rates,
										label: t('Rates'),
									},
								]}
								onChange={(k: TabIndex) => setSelectedIndex(k)}
							/>
						</div>

						<div className='grow shrink overflow-y-scroll p-[24px] bg-[#F3F6F9]'>
							{selectedIndex === TabIndex.tracking && (
								<Tracking
									order={order}
									container={container}
									boxProps={{
										style: {
											boxShadow: `
                        0px 9px 28px 8px rgba(0, 0, 0, 0.05), 
                        0px 3px 6px -4px rgba(0, 0, 0, 0.12), 
                        0px 6px 16px 0px rgba(0, 0, 0, 0.08)
                      `,
										},
										className: 'bg-white',
									}}
								/>
							)}
							{selectedIndex === TabIndex.billing && <Billing invoices={container.invoices} />}
							{selectedIndex === TabIndex.documents && (
								<Documents
									order={order}
									fetchData={() => {
										orderRefresh();
										containerRefresh();
									}}
								/>
							)}
							{selectedIndex === TabIndex.rates && (
								<Rate container={container} feeTableProps={{ wrapperClassName: 'bg-white' }} />
							)}
						</div>
					</>
				)}
			</div>
		</Modal>
	);
};
