import { Popover } from 'antd';
import { Gap } from '../gap';

interface ProjectExceptionsProps {
	exceptions: string[];
}

export const ExceptionTag = ({ reason }: { reason: string }) => (
	<div className='h-[20px] w-fit rounded-[12px] p-[2px_8px] foot-note-1-m bg-secondary-light-02 text-updated-red'>
		{reason}
	</div>
);

export const ProjectExceptions = ({ exceptions }: ProjectExceptionsProps) => {
	const isOneException = exceptions?.length === 1;

	if (isOneException) return <ExceptionTag reason={exceptions[0]} />;

	return (
		<Popover
			trigger='hover'
			title=''
			content={
				<div>
					{exceptions?.slice(1).map((e) => (
						<ExceptionTag key={e} reason={e} />
					))}
				</div>
			}
		>
			<div className='flex'>
				<ExceptionTag reason={exceptions[0]} />
				<Gap width='4px' />
				<ExceptionTag reason={`+${exceptions?.length - 1}`} />
			</div>
		</Popover>
	);
};
