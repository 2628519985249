import iconFactory from './icon-factory';

const _Svg = () => (
	<svg width='28' height='33' viewBox='0 0 28 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M14.0001 29.25C17.389 26.1389 19.9029 23.3125 21.5417 20.7708C23.1806 18.2291 24.0001 15.9722 24.0001 14C24.0001 10.9722 23.0348 8.49304 21.1042 6.56248C19.1737 4.63192 16.8056 3.66665 14.0001 3.66665C11.1945 3.66665 8.82647 4.63192 6.89591 6.56248C4.96536 8.49304 4.00008 10.9722 4.00008 14C4.00008 15.9722 4.81953 18.2291 6.45841 20.7708C8.0973 23.3125 10.6112 26.1389 14.0001 29.25ZM14.0001 32.5416C13.6112 32.5416 13.2223 32.4722 12.8334 32.3333C12.4445 32.1944 12.0973 31.9861 11.7917 31.7083C9.98619 30.0417 8.38897 28.4166 7.00008 26.8333C5.61119 25.25 4.45147 23.7153 3.52091 22.2291C2.59036 20.743 1.88203 19.3125 1.39591 17.9375C0.909804 16.5625 0.666748 15.25 0.666748 14C0.666748 9.83331 2.00703 6.51387 4.68758 4.04165C7.36814 1.56942 10.4723 0.333313 14.0001 0.333313C17.5279 0.333313 20.632 1.56942 23.3126 4.04165C25.9931 6.51387 27.3334 9.83331 27.3334 14C27.3334 15.25 27.0904 16.5625 26.6042 17.9375C26.1181 19.3125 25.4098 20.743 24.4792 22.2291C23.5487 23.7153 22.389 25.25 21.0001 26.8333C19.6112 28.4166 18.014 30.0417 16.2084 31.7083C15.9029 31.9861 15.5556 32.1944 15.1667 32.3333C14.7779 32.4722 14.389 32.5416 14.0001 32.5416ZM14.0001 17C14.9167 17 15.7015 16.6736 16.3542 16.0208C17.007 15.368 17.3334 14.5833 17.3334 13.6666C17.3334 12.75 17.007 11.9653 16.3542 11.3125C15.7015 10.6597 14.9167 10.3333 14.0001 10.3333C13.0834 10.3333 12.2987 10.6597 11.6459 11.3125C10.9931 11.9653 10.6667 12.75 10.6667 13.6666C10.6667 14.5833 10.9931 15.368 11.6459 16.0208C12.2987 16.6736 13.0834 17 14.0001 17Z'
			fill='url(#paint0_linear_919_18442)'
		/>
		<defs>
			<linearGradient
				id='paint0_linear_919_18442'
				x1='-1.6468'
				y1='16.4375'
				x2='27.3334'
				y2='16.4375'
				gradientUnits='userSpaceOnUse'
			>
				<stop stop-color='#0094FF' />
				<stop offset='1' stop-color='#0165FA' />
			</linearGradient>
		</defs>
	</svg>
);

export default iconFactory(_Svg);
