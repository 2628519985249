import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'antd';
import { Order } from '@/interfaces/order.interface';
import { isOrderExpired } from '@/services/apis';

const DuplicateOrderButton = ({ order }: { order: Order }) => {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const handleNavigate = () => {
		return navigate(
			`/search-rate?order_id=${order.id}&to_city_id=${order.warehouse.city.id}&intermodal_region_id=${order.final_port.id}`,
		);
	};

	const [isModalOpen, setIsModalOpen] = useState(false);
	const handleOpenModal = () => setIsModalOpen(true);
	const handleCloseModal = () => setIsModalOpen(false);
	const handleModalOk = () => handleNavigate();

	const [isLoading, setIsLoading] = useState(false);
	const handleClick = () => {
		setIsLoading(true);
		isOrderExpired({ path: { orderId: order.id } })
			.then((response) => {
				const isExpired = response.data.is_expired;
				if (!isExpired) {
					return handleNavigate();
				}
				handleOpenModal();
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<>
			<Button type='link' className='px-0' loading={isLoading} onClick={handleClick}>
				{t('Duplicate Order')}
			</Button>
			<Modal
				centered
				open={isModalOpen}
				title={t('rate expired')}
				onCancel={handleCloseModal}
				onOk={handleModalOk}
				okText={t('Confirm')}
			>
				<p>
					{t(
						'The previous rate for this order has expired. Please confirm if you accept the new rate, which might have a slight change.',
					)}
				</p>
			</Modal>
		</>
	);
};

export default DuplicateOrderButton;
