import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Space } from 'antd';
import { GetProjectContainersMapRes, GetProjectContainersProps } from '@/services/apis';
import { useSafeState, useSetState } from 'ahooks';
import CenterSpin from '@/components/center-spin';
import { Gap } from '@/components/gap';
import { TabSwitcher, useTabSwitcher } from '@/components/tab-switcher';
import { apiHooks } from '@/hooks/apis';
import { ArrElement } from '@/utils/array-element.type';
import { ContainersMap } from './containers-map';
import { Filter, FormState, Group, Search, Sort } from './form';
import { FilterOptionKeys } from './form/use-options';
import { GroupTable } from './group-table';

export const ContainersPanel = () => {
	const { t } = useTranslation();

	const { projectId } = useParams();

	const { item1, item2, tabSwitcherValue, onTabSwitcherChange } = useTabSwitcher(
		{
			label: t(`List`),
			value: 'list',
		},
		{
			label: t(`Map`),
			value: 'map',
		},
	);

	const [formState, setFormState] = useSetState<FormState>({
		group: undefined,
		filter: [],
		sort: { sort_by: undefined, sort_value: undefined },
		search: '',
	});

	const [selectedCity, setSelectedCity] = useSafeState<
		ArrElement<GetProjectContainersMapRes['arrived_at_port']>['city'] | undefined
	>();

	const { data, runAsync, loading } = apiHooks.useGetProjectContainers();

	const { data: mapData, loading: mapLoading } = apiHooks.useGetProjectContainersMap({
		defaultParams: [{ path: { projectId } }],
	});

	const mapPoints = mapData.arrived_at_port.map((i) => ({
		id: i.city.id,
		longitude: i.city.longitude,
		latitude: i.city.latitude,
		total: i.total,
	}));

	useEffect(() => {
		const params: GetProjectContainersProps['params'] = {};

		const { group, filter, sort, search } = formState;

		if (group) params.group = group;

		if (filter.length > 0) {
			params.filters = {
				progress: {
					arriving_total: false,
					avaliable_total: false,
					pickup: false,
					delivered: false,
					empty_returned: false,
				},
			};
			filter.forEach((item) => {
				if (item[0] === FilterOptionKeys.progress) {
					item[1].forEach((i) => (params.filters.progress[i] = true));
					return;
				}

				if ([FilterOptionKeys.mbl].includes(item[0])) {
					params.filters[item[0]] = item[1];
					return;
				}

				if ([FilterOptionKeys.final_port_ids, FilterOptionKeys.warehouse_ids].includes(item[0])) {
					params.filters[item[0]] = item[1].map((i) => i.value);
					return;
				}

				if (
					[
						FilterOptionKeys.final_port_eta,
						FilterOptionKeys.lfd,
						FilterOptionKeys.schedule_terminal_pickup_at,
						FilterOptionKeys.actual_terminal_pickuped_at,
					].includes(item[0])
				) {
					params.filters[item[0]] = item[1].map((i) => i.format('YYYY-MM-DD'));
					return;
				}
			});
		}

		if (!!sort.sort_by && !!sort.sort_value) {
			params.sort = sort;
		}

		if (!!search && search !== '') params.search = search;

		if (selectedCity) params.map_city_id = selectedCity.id;

		runAsync({
			path: {
				projectId,
			},
			params,
		});
		// .then(data => console.log('[ContainersPanel] data', data.data.data))
	}, [formState, projectId, selectedCity, runAsync]);

	return (
		<div className='h-full w-full flex flex-col overflow-hidden bg-white'>
			{/* header */}
			<div
				style={{
					boxShadow: '0px 4px 10px 0px #0000000D',
					borderBottom: '1px solid #E6F0FA',
				}}
				className=' grow-0 shrink-0 box-border p-[0_36px] h-[56px] w-full flex items-center justify-between '
			>
				<Space size={12}>
					<Group value={formState.group} onChange={(value) => setFormState({ group: value })} />
					<Filter value={formState.filter} onChange={(value) => setFormState({ filter: value })} />
					<Sort value={formState.sort} onChange={(value) => setFormState({ sort: value })} />
					<Search value={formState.search} onChange={(value) => setFormState({ search: value })} />
				</Space>

				<TabSwitcher
					item1={item1}
					item2={item2}
					value={tabSwitcherValue}
					onChange={onTabSwitcherChange}
				/>
			</div>

			<Gap height='12px' />

			{/* table */}
			{tabSwitcherValue === 'map' && (
				<div className=' grow-0 shrink-0 w-full h-[460px]'>
					<ContainersMap
						points={mapPoints}
						selectedPointId={selectedCity?.id}
						mapLoading={mapLoading}
						onMarkClick={(id) => {
							const city = mapData.arrived_at_port.find((i) => i.city.id === id);
							if (city) {
								setSelectedCity(city.city);
							}
						}}
					/>
				</div>
			)}

			{!!data && (
				<div className='grow shrink w-full overflow-y-scroll flex flex-col'>
					{loading ? (
						<CenterSpin />
					) : Array.isArray(data) ? (
						<GroupTable projectId={projectId} containers={data} />
					) : (
						<>
							{Object.entries(data).map(([k, v]) => (
								<GroupTable
									projectId={projectId}
									group={{
										label: k,
										total: v.total,
										completed: v.completed,
									}}
									containers={v.containers}
								/>
							))}
						</>
					)}
				</div>
			)}
		</div>
	);
};
