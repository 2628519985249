import { useTranslation } from 'react-i18next';

const statusStyleMap: Record<number, React.CSSProperties> = {
	[10]: {
		borderColor: '#007BFF',
		backgroundColor: '#FFFFFF',
		color: '#007BFF',
	},
	[20]: {
		borderColor: '#C77B01',
		backgroundColor: '#FDF4E6',
		color: '#EA9101',
	},
	[30]: {
		borderColor: '#007BFF',
		backgroundColor: '#007BFF26',
		color: '#007BFF',
	},
	[40]: {
		borderColor: '#2A9928',
		backgroundColor: '#D6E7D6',
		color: '#2A9928',
	},
	[50]: {
		borderColor: '#747578',
		backgroundColor: '#74757826',
		color: '#747578',
	},
	[60]: {
		borderColor: '#2A9928',
		backgroundColor: '#D6E7D6',
		color: '#2A9928',
	},
};

export const StatusLabel = ({ status, text }: { status: number | string; text: string }) => {
	const { t } = useTranslation();

	return (
		<div
			style={{ ...(statusStyleMap[status] ?? {}) }}
			className='box-border w-[108px] h-[28px] border-[1px] border-solid rounded-[30px] flex justify-center items-center'
		>
			{t(text)}
		</div>
	);
};
