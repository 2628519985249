import { useCallback, useEffect } from 'react';
import { GetProjectMyFocusTotalResDataItem } from '@/services/apis';
import { useMemoizedFn, useSafeState } from 'ahooks';
import { apiHooks } from '@/hooks/apis';
import { FOCUS_TYPE_DELIVERED, FOCUS_TYPE_EMPTY_RETURNED, FOCUS_TYPE_PICKUP } from '.';

export const useGetProjectMyFocusTotal = ({
	projectId,
	date: _date,
}: {
	projectId: string;
	date: string;
}) => {
	const [selectedIR, setSelectedIR] = useSafeState<number | null>(null);

	const [date, setDate] = useSafeState<string>(_date);

	const [pickup, setPickup] = useSafeState<GetProjectMyFocusTotalResDataItem>({
		focus_type: FOCUS_TYPE_PICKUP,
		actual_total: 0,
		est_total: 0,
	});

	const [delivery, setDelivery] = useSafeState<GetProjectMyFocusTotalResDataItem>({
		focus_type: FOCUS_TYPE_DELIVERED,
		actual_total: 0,
		est_total: 0,
	});

	const [emptyReturn, setEmptyReturn] = useSafeState<GetProjectMyFocusTotalResDataItem>({
		focus_type: FOCUS_TYPE_EMPTY_RETURNED,
		actual_total: 0,
		est_total: 0,
	});

	const { runAsync, loading } = apiHooks.useGetProjectMyFocusTotal({
		debounceWait: 300,
	});

	const handleInit = useMemoizedFn(async (_setSelectedIR?: number | null) => {
		if (!date) {
			return;
		}

		try {
			const res = await runAsync({
				path: {
					projectId,
				},
				params: {
					date: date,
					// intermodal_region_id: _setSelectedIR === undefined ? selectedIR : _setSelectedIR,
				},
			});

			setPickup(res.data?.find((item) => item.focus_type === FOCUS_TYPE_PICKUP));
			setDelivery(res.data?.find((item) => item.focus_type === FOCUS_TYPE_DELIVERED));
			setEmptyReturn(res.data?.find((item) => item.focus_type === FOCUS_TYPE_EMPTY_RETURNED));
		} catch (error) {
			console.error(error);
		}
	});

	const selectIR = useCallback(
		(IRId: number | undefined) => {
			if (IRId === selectedIR) {
				setSelectedIR(null);
				handleInit(null);
			} else {
				setSelectedIR(IRId);
				handleInit(IRId);
			}
		},
		[setSelectedIR, handleInit],
	);

	const reset = useCallback(
		(time?: string) => {
			setSelectedIR(null);
			setPickup({
				focus_type: FOCUS_TYPE_PICKUP,
				actual_total: 0,
				est_total: 0,
			});

			setDelivery({
				focus_type: FOCUS_TYPE_DELIVERED,
				actual_total: 0,
				est_total: 0,
			});

			setEmptyReturn({
				focus_type: FOCUS_TYPE_EMPTY_RETURNED,
				actual_total: 0,
				est_total: 0,
			});

			setDate(time);
		},
		[setSelectedIR, setDate],
	);

	useEffect(() => {
		console.log('total useEffect date', date);
		handleInit();
	}, [date]);

	return {
		pickup,
		delivery,
		emptyReturn,
		loading,
		selectedIR,
		selectIR,
		reset,
	};
};
