import { getQuoteList, GetQuoteListProps } from '@/services/apis';
import { usePagination } from 'ahooks';
import { message } from '@/utils/message';

export const useGetQuoteList = (options?: Record<any, any>) => {
	const res = usePagination(
		async ({ current, pageSize }, props: GetQuoteListProps) => {
			if (!props) {
				return {
					list: [],
					total: 0,
				};
			}

			const res = await getQuoteList({
				params: {
					page: current,
					per_page: pageSize,
				},
			});

			return {
				list: res?.data?.data ?? [],
				total: res?.data?.meta?.total,
			};
		},
		{
			manual: true,
			onError: (error) => {
				// @ts-ignore
				message.error(`${error.response?.data?.message ?? error.message}`);
			},
			...options,
		},
	);

	return {
		...res,
		data: res.data?.list ?? [],
	};
};
