import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { FTLPullQuoteRatesReq, FTLPullQuoteRatesRes } from './interface';

export * from './interface';

export const ftlPullQuoteRates = async (props: FTLPullQuoteRatesReq) => {
	return await axios.get<FTLPullQuoteRatesRes>(`/ftl/quotes/${props.path.quote_id}/rates`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
