import { useTranslation } from 'react-i18next';
import { Shipment } from '@/interfaces/shipment.interface';
import { useShipmentOrderContext } from '@/services/contexts';
import { Item } from '../item';

export const LTLShipmentItemList = () => {
	const { t } = useTranslation();

	const shipmentOrderContext = useShipmentOrderContext();

	const data = shipmentOrderContext.shipmentOrder as Shipment;

	return (
		<>
			{data.items.map((item, index) => {
				return (
					<div className='flex flex-col gap-[24px]'>
						<div className='h4-b text-[#333333]'>
							{t(`Item`)} {index + 1}
						</div>

						<div className='grid grid-cols-2 grid-rows-[repeat(4,auto)] gap-x-[160px] gap-y-[16px]'>
							<Item title={t(`Commodity description`)} content={item.description} />
							<div></div>
							<Item title={t(`Number of units`)} content={`${item.units} ${item.package_type}`} />
							<Item
								title={t(`Total weight`)}
								content={`${item.total_weight} ${item.weight_unit}`}
							/>
							<Item
								title={t(`Size`)}
								content={`${item.length}L X ${item.width}W X ${item.height}H ${item.dimension_unit}`}
							/>
							<Item title={t(`Freight class`)} content={item.class} />
							<Item title={t(`NMFC code`)} content={item.nmfc ?? ''} />
							<Item title={t(`Total pieces`)} content={item.pieces} />
						</div>
					</div>
				);
			})}
		</>
	);
};
