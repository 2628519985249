import { useTranslation } from 'react-i18next';
import { Modal, ModalProps } from 'antd';
import { GetProjectExceptionsRes } from '@/services/apis';
import { ProjectExceptions } from '@/components/project-exceptions';
import { StatusCircle } from '@/components/status-circle';
import { Table, TableProps } from '@/components/table';
import { ArrElement } from '@/utils/array-element.type';

type Record = ArrElement<GetProjectExceptionsRes['history_exceptions']['containers']>;

export const ResolvedExceptionModal = ({
	dataSource,
	onClose,
	...options
}: ModalProps & { dataSource: Record[]; onClose?: () => void }) => {
	const { t } = useTranslation();

	const columns: TableProps<Record>['columns'] = [
		{
			title: '',
			dataIndex: 'number',
			width: '20px',
			CellRender({
				value,
				style,
				className,
			}: {
				value: any;
				style?: React.CSSProperties;
				className?: string;
			}) {
				return (
					<div style={{ ...(style ?? {}) }} className={`${className}`}>
						<StatusCircle />
					</div>
				);
			},
		},
		{
			title: t('Container #'),
			dataIndex: 'number',
		},
		{
			title: t('IR'),
			dataIndex: ['final_port', 'name'],
		},
		{
			title: t('Time'),
			dataIndex: 'final_port_eta',
		},
		{
			title: t('Exception'),
			dataIndex: ['trouble_status'],
			CellRender({
				value,
				style,
				className,
			}: {
				value: any;
				style?: React.CSSProperties;
				className?: string;
			}) {
				return (
					<div style={{ ...(style ?? {}) }} className={`${className}`}>
						<ProjectExceptions exceptions={value as string[]} />
					</div>
				);
			},
		},
	];

	return (
		<Modal
			width={1248}
			footer={null}
			onCancel={onClose}
			title={t(`Resolved Exception`)}
			{...options}
		>
			<Table
				columns={columns}
				dataSource={dataSource}
				className={`grow shrink overflow-scroll`}
				commonCellStyle={{ borderBottom: '1px solid #e7e7e7' }}
				commonCellClass={` box-border p-[0_10px] flex items-center box-border h-[44px]`}
				commonHeaderClass={`foot-note-1-m text-[#777777]`}
				commonBodyClass={`foot-note-1-r text-black whitespace-nowrap`}
			/>
		</Modal>
	);
};
