import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerDetail } from '@/interfaces/container-detail.interface';
import { Order } from '@/interfaces/order.interface';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import FeeTable from '@/components/fee-table';
import { PossibleCharges } from '@/pages/search-rate/search-rate-result/components/possible-charges';

interface RateProps {
	container: ContainerDetail;
	feeTableProps?: {
		wrapperClassName?: string;
		className?: string;
	};
}

export default observer(({ container, feeTableProps }: RateProps) => {
	const { t } = useTranslation();

	const scenarios = container.scenarios;
	const possibleCharges = container.possible_charges;

	return (
		<div className='flex'>
			<div
				className={clsx(
					'w-[40vw] border-box pt-[26px] pl-[24px] pr-[24px] mr-[20px] border-[1px] border-solid border-[#E7E7E7] rounded-[10px]',
					feeTableProps?.wrapperClassName,
				)}
			>
				<FeeTable scenarios={scenarios} {...feeTableProps} />
			</div>

			<div className='w-[30vw] h-fit max-h-[55vh] overflow-scroll'>
				<PossibleCharges
					title={t('Accessorial Charges')}
					possible_charges={possibleCharges}
					showProbability
				/>
			</div>
		</div>
	);
});
