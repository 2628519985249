import { LeftOutlined, RightOutlined } from '@ant-design/icons';

export interface SimplePaginationProps {
	label: React.ReactNode;
	onPre: () => void;
	onNext: () => void;
}

export const SimplePagination = ({ label, onPre, onNext }: SimplePaginationProps) => {
	return (
		<div className='flex text-grey-01'>
			<LeftOutlined onClick={onPre} />
			<div className='m-[0_12px] body-4-b'>{label}</div>
			<RightOutlined onClick={onNext} />
		</div>
	);
};
