import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { CreateDepotProps, CreateDepotRes } from './interface';

export const createDepot = async (props: CreateDepotProps) => {
	return await axios.post<CreateDepotRes>(`/newDepots`, props.data, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
