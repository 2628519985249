import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Divider, Modal } from 'antd';
import { ContainerDetail } from '@/interfaces/container-detail.interface';
import { Order } from '@/interfaces/order.interface';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ArrElement } from '@/utils/array-element.type';
import DocumentsTable from './documents-table';

type Invoice = ArrElement<ContainerDetail['invoices']>;

type Charge = ArrElement<Invoice['charges']>;

interface BillChargeExplanationProps {
	orderInvoiceId: number;
	charge: Charge;
}

export default ({ orderInvoiceId, charge }: BillChargeExplanationProps) => {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);

	const navigate = useNavigate();

	const openModal = () => {
		setOpen(true);
	};

	const closeModal = () => {
		setOpen(false);
	};

	return (
		<>
			{charge.has_explanation && (
				<span
					// className={`cursor-pointer ${charge.has_explanation ? 'text-[blue]' : 'text-gray'}`}
					className={`cursor-pointer text-gray'}`}
					onClick={openModal}
				>
					<InfoCircleOutlined />
				</span>
			)}
			{open && (
				<Modal
					centered
					width={700}
					open={open}
					title={t('Explanation')}
					onCancel={closeModal}
					footer={<Button onClick={closeModal}>{t('Close')}</Button>}
				>
					<Divider className='mt-[16px] mb-[16px]' />

					<div className='h-fit max-h-[80vh] overflow-scroll'>
						<div className='leading-relaxed'>
							<strong>{t('Reason')} </strong>
							<div style={{ whiteSpace: 'pre-line' }}>{charge.reason}</div>
						</div>
						{charge.documents.length > 0 && (
							<>
								<Divider className='mt-[16px] mb-[16px]' />
								<div className='leading-relaxed'>
									<strong>{t('Documents')} </strong>
									<DocumentsTable orderInvoiceId={orderInvoiceId} files={charge.documents} />
								</div>
							</>
						)}
					</div>
				</Modal>
			)}
		</>
	);
};
