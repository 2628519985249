import { useCallback } from 'react';
import { useSafeState } from 'ahooks';
import { ProgressMapKeysEnum } from '@/pages/project/use-progress-map';

export const useProcessTrackingModal = () => {
	const [openingProcessTrackingModal, setOpeningProcessTrackingModal] = useSafeState(false);

	const [progressKey, setProgressKey] = useSafeState<ProgressMapKeysEnum>();

	const handleTabChange = useCallback(
		(key: ProgressMapKeysEnum) => {
			setProgressKey(key);
		},
		[setProgressKey],
	);

	const openProcessTrackingModal = useCallback(
		(progressKey: ProgressMapKeysEnum) => {
			handleTabChange(progressKey);
			setOpeningProcessTrackingModal(true);
		},
		[handleTabChange, setOpeningProcessTrackingModal],
	);

	const closeProcessTrackingModal = useCallback(() => {
		setOpeningProcessTrackingModal(false);
	}, [setOpeningProcessTrackingModal]);

	return {
		progressKey,
		openingProcessTrackingModal,
		handleTabChange,
		openProcessTrackingModal,
		closeProcessTrackingModal,
	};
};
