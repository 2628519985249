import { useTranslation } from 'react-i18next';
import { Button, Divider } from 'antd';
import { Invoice } from '@/interfaces/invoice.interface';
import { downloadFtlInvoiceFile, downloadLtlInvoiceFile } from '@/services/apis';
import { useShipmentOrderContext } from '@/services/contexts';
import { DownloadOutlined } from '@ant-design/icons';
import { useMemoizedFn } from 'ahooks';
import clsx from 'clsx';
import { Gap } from '@/components/gap';
import { Table, TableProps } from '@/components/table';
import { ArrElement } from '@/utils/array-element.type';
import { formatMMMDYYYYDate } from '@/utils/format-MMDDYYYY-date';
import useShipmentOrderPath from '../../use-shipment-order-path';
import { formatMMDDYYYYToMMMDDYYYY } from '../../utils';

export const InvoiceTable = ({ invoice }: { invoice: Invoice }) => {
	const { t } = useTranslation();

	const { type } = useShipmentOrderPath();

	const shipmentOrderContext = useShipmentOrderContext();

	const data = shipmentOrderContext.shipmentOrder;

	const downloadInvoice = type === 'ltl' ? downloadLtlInvoiceFile : downloadFtlInvoiceFile;

	const columns: TableProps<ArrElement<Invoice['charges']>>['columns'] = [
		{
			title: t(`Description`),
			dataIndex: 'name',
			HeaderRender: ({ title, style, className }) => (
				<div
					style={{ ...style, justifyContent: 'flex-start' }}
					className={className}
				>{`${title}`}</div>
			),
			CellRender: ({ value, style, className }) => (
				<div
					style={{ ...style, justifyContent: 'flex-start' }}
					className={className}
				>{`${value}`}</div>
			),
		},
		{
			title: t(`Rate`),
			width: '150px',
			dataIndex: 'rate',
			CellRender: ({ value, style, className }) => (
				<div style={style} className={className}>
					${value}
				</div>
			),
		},
		{
			title: t(`Qty`),
			width: '150px',
			dataIndex: 'qty',
			CellRender: ({ value, style, className }) => (
				<div style={style} className={className}>
					{value}
				</div>
			),
		},
		{
			title: t(`Amount`),
			width: '150px',
			dataIndex: 'amount',
			CellRender: ({ value, style, className }) => (
				<div style={style} className={clsx(className, 'font-[700]')}>
					${value}
				</div>
			),
		},
	];

	const handleDownloadFile = useMemoizedFn((invoice) => {
		let fileName = `Invoice-${data.uid}`;
		if (data.customer_reference_number) {
			fileName += `-${data.customer_reference_number}`;
		}
		fileName += '.pdf';

		downloadInvoice({ path: { invoiceId: invoice.id }, other: { fileName } }, { preview: false });
	});

	return (
		<div>
			{/* header */}
			<div className='w-full flex justify-between items-center'>
				<div>
					<div className='h4-b text-theme-1-1-10 font-[700]'>{t('Invoice')}</div>
					<div className='body-3-r text-[#555770] font-[400]'>{`${t(
						`Issued on`,
					)} ${formatMMMDYYYYDate(invoice.invoiced_at)}`}</div>
				</div>

				<Button icon={<DownloadOutlined />} onClick={() => handleDownloadFile(invoice)}>
					<span className='body-4-m text-black'>{t(`Download`)}</span>
				</Button>
			</div>

			<Gap height='16px' />

			<Table
				dataSource={invoice.charges ?? []}
				columns={columns}
				commonHeaderClass='h-[32px] flex items-center body-4-b text-grey-01 bg-grey-05 font-[700]'
				commonCellClass='px-[16px] justify-end'
				commonBodyClass='h-[40px] flex items-center  body-4-r text-black font-[400]'
			/>

			<Divider />

			<div className='w-full flex flex-col items-end'>
				<div className='flex justify-end'>
					<div className='body-3-b text-grey-01 font-[700]'>{t('Total Amount')}</div>
					<Gap width='80px' />
					<div className='body-3-b text-black font-[700]'>${invoice.amount_total}</div>
				</div>
				<div className='foot-note-1-b text-primary-dark-02 font-[700]'>{`${t(
					'Due by',
				)} ${formatMMDDYYYYToMMMDDYYYY(invoice.due_at)}`}</div>
			</div>
		</div>
	);
};
