import axios from '../axios';
import { GetDirectionsProps } from './interface';

export const getDirections = async (props: GetDirectionsProps) => {
	const path = props.path;

	return await axios.get<any>(`https://api.mapbox.com/directions/v5/mapbox/driving/${path}`, {
		params: props.params,
	});
};
