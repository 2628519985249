import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';

export const viewApiDoc = (version) => {
	return axios.get(`/users/apidoc/` + version, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
