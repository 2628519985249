import html2canvas from 'html2canvas';

export const takeScreenShot = async (fileName: string, ele?: HTMLElement) => {
	const canvas = await html2canvas(ele ?? document.getElementById('main'));

	// 创建一个 a 标签，并设置 href 和 download 属性
	const el = document.createElement('a');
	// 设置 href 为图片经过 base64 编码后的字符串，默认为 png 格式
	el.href = canvas.toDataURL();
	el.download = fileName ?? '文件名称';

	// 创建一个点击事件并对 a 标签进行触发
	const event = new MouseEvent('click');
	el.dispatchEvent(event);
};
