import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

export const LiftGateTooltip = () => {
	const { t } = useTranslation();

	return (
		<Tooltip
			title={t(
				`The size or weight of your shipment exceeds the maximum limit of the Liftgate service. The service provider may not be able to provide the service or charge additional fees.`,
			)}
		>
			<QuestionCircleOutlined />
		</Tooltip>
	);
};
