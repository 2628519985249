import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetProjectCalendarProgressProps, GetProjectCalendarProgressRes } from './interface';

export * from './interface';

export const getProjectCalendarProgress = async (props: GetProjectCalendarProgressProps) => {
	return await axios.get<GetProjectCalendarProgressRes>(
		`/projects/${props.path.projectId}/calendar/${props.path.type}`,
		{
			headers: {
				Authorization: accessTokenManager.getAccessToken(),
			},
			params: props.params,
		},
	);
};
