import ErrorIcon from '@/icons/error-icon';
import clsx from 'clsx';

export default ({
	msg,
	className,
	...props
}: { msg: string } & React.DetailedHTMLProps<
	React.HTMLAttributes<HTMLDivElement>,
	HTMLDivElement
>) => (
	<div {...props} className={clsx('p-[4px_8px_4px_8px] rounded-[4px] light-border', className)}>
		<span className='mr-[6px] text-[red]'>{msg}</span>
		<ErrorIcon />
	</div>
);
