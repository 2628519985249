import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal } from 'antd';
import PlusIcon from '@/icons/plus-icon';
import { createDepot } from '@/services/apis/create-depot';
import Input from '@/components/input';
import TextArea from '@/components/text-area';

export const AddForm = ({ onSaved }) => {
	const { t } = useTranslation();

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleFinish = async () => {
		const values = form.getFieldsValue();
		setLoading(true);
		await createDepot({ data: values });
		onSaved && onSaved();
		form.resetFields();
		setIsModalOpen(false);
		setLoading(false);
	};

	const validateEmail = (_, value) => {
		const v = form.getFieldValue('phone');

		if (!v && !value) {
			return Promise.reject('Phone is required if Email is blank');
		}

		return Promise.resolve();
	};

	const validatePhone = (_, value) => {
		const v = form.getFieldValue('email');

		if (!v && !value) {
			return Promise.reject('Email is required if Phone is blank');
		}

		return Promise.resolve();
	};

	return (
		<>
			<Button onClick={showModal}>
				<PlusIcon />
			</Button>
			{isModalOpen && (
				<Modal
					title={t('Add New Depot')}
					onCancel={() => setIsModalOpen(false)}
					footer={false}
					open={isModalOpen}
				>
					<Form form={form} layout='vertical' onFinish={handleFinish}>
						<Form.Item label={t('Name')} name='name' rules={[{ required: true }]}>
							<Input />
						</Form.Item>

						<Form.Item label={t('Address')} name='address' rules={[{ required: true }]}>
							<Input />
						</Form.Item>

						<Form.Item label={t('Contact')} name='contact' rules={[{ required: true }]}>
							<Input />
						</Form.Item>

						<Form.Item label={t('Email')} name='email' rules={[{ validator: validateEmail }]}>
							<Input />
						</Form.Item>
						{/* phone is required if email is missing */}
						<Form.Item label={t('Phone')} name='phone' rules={[{ validator: validatePhone }]}>
							<Input />
						</Form.Item>

						<Form.Item
							label={t('Return Instruction')}
							name='return_instruction'
							rules={[{ required: true }]}
						>
							<TextArea />
						</Form.Item>

						<Form.Item>
							<Button loading={loading} type='primary' htmlType='submit'>
								{t('Save')}
							</Button>
						</Form.Item>
					</Form>
				</Modal>
			)}
		</>
	);
};
