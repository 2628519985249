import { useTranslation } from 'react-i18next';
import { Flex } from 'antd';
import { LiveOrDrop } from '@/interfaces/live-or-drop.enum';
import { Order } from '@/interfaces/order.interface';
import Card from './card.tsx';

const TableExpandedRowOrderDetailsTab = ({ order }: { order: Order }) => {
	const { t } = useTranslation();

	const orderInfo = {
		[t('Order #')]: order.id,
		[t('MBL #')]: order.mbl_number,
		[t('Commodity')]: order.commodity,
		[t('Customer Memo')]: order.customer_memo,
	};

	const routeInfo = {
		[t('Pickup from')]: order.terminal?.delivery_order_address ?? '',
		[t('Delivery to')]: order.warehouse?.delivery_order_address,
		[t('Pod')]: order.port_of_discharge?.name,
		[t('Final Port') + '/' + t('Ramp')]: order.final_port?.name,
		[t('Delivery Type')]: [
			...new Set(
				(order.containers ?? []).map((c) =>
					c.live_or_drop === LiveOrDrop.Empty
						? ''
						: c.live_or_drop === LiveOrDrop.Live
						? t('Live')
						: t('Drop'),
				),
			),
		]
			.filter(Boolean)
			.join(', '),
	};

	return (
		<Flex align='start' justify='space-between'>
			<div className='flex-grow w-1 pr-[50px]'>
				<Card title={t('Order Info')} content={orderInfo} />
			</div>
			<div className='flex-grow w-1'>
				<Card title={t('Route Info')} content={routeInfo} />
			</div>
		</Flex>
	);
};

export default TableExpandedRowOrderDetailsTab;
