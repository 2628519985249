import { baseURL } from '..';
import { downloadFactory } from '../download-factory';
import { DownloadFtlInvoiceFileProps } from './interface';

export const downloadFtlInvoiceFile = async (
	props: DownloadFtlInvoiceFileProps,
	config?: { preview?: boolean },
) => {
	const { invoiceId } = props.path;
	const { fileName } = props.other;
	const url = baseURL + `ftl/${invoiceId}/inovicePdf`;

	downloadFactory(url, fileName, { preview: config?.preview });
};
