import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';

export const getHolidays = async () => {
	return await axios.get<string[]>(`/tools/holidays-from-now`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
