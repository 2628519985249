import iconFactory from './icon-factory';

const Svg = () => (
	<svg
		width='32'
		height='32'
		viewBox='0 0 32 32'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		xmlnsXlink='http://www.w3.org/1999/xlink'
	>
		<rect width='32' height='32' fill='url(#pattern0)' />
		<defs>
			<pattern id='pattern0' patternContentUnits='objectBoundingBox' width='1' height='1'>
				<use xlinkHref='#image0_2140_37447' transform='scale(0.0208333)' />
			</pattern>
			<image
				id='image0_2140_37447'
				width='48'
				height='48'
				xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA7UlEQVR4nO3ZwQ3CMAyFYQ8C544Zjl2FConFANEu8FAkDkioMiR+TlP5G6D2n/QWkRDC3wAcAVwALKi3ALgCGMRx+TvsPfK3PQLyybNMHgEWv82a2SOASiJAETegEDaQSQQo4gYUwgYy+Z439h4A0wiQyfq8sfcAmwiQiT5v7D2gLgI/cjigsfeAsghsKyD1HJDYA4rIb/PKlt9IQCpefgMBqWr5xgGpevmGAclk+UYBJ7PlWwSYiwCFsIFMIkARN6AQNuwgYCHu//QIyA9yLGePgOH9IGftBuBAD/h4qZzym5bB4nM+ebflQ9iZF6CJT23lmuMXAAAAAElFTkSuQmCC'
			/>
		</defs>
	</svg>
);

export default iconFactory(Svg);
