import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetRecommendRatesProps, GetRecommendRatesRes } from './interface';

export const getRecommendRates = async (props: GetRecommendRatesProps) => {
	return await axios.get<GetRecommendRatesRes>(`/rates/recommand`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params: props.params,
	});
};
