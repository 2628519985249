import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { UpdateWarehouseProps, UpdateWarehouseRes } from './interface';

export const updateWarehouse = async (props: UpdateWarehouseProps) => {
	return await axios.put<UpdateWarehouseRes>(
		`/newWarehouses/${props.path.warehouseId}`,
		props.data,
		{
			headers: {
				Authorization: accessTokenManager.getAccessToken(),
			},
		},
	);
};
