import React from 'react';
import popularBlueSvg from '@/assets/popular-blue.svg';

const PopularBlueIcon = (
	props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
) => {
	return <img {...props} src={popularBlueSvg} />;
};

export default PopularBlueIcon;
