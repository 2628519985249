import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetProjectsPickupDeliveryLFDRes } from './interface';

export * from './interface';

export const getProjectsPickupDeliveryLFD = async () => {
	return await axios.get<GetProjectsPickupDeliveryLFDRes>(`/projectHome/pickupAndDeliveryAndLfd`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
