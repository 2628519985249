import { Space } from 'antd';
import Close16pxActiveIcon from '@/icons/close-16px-active-icon';
import { Gap } from '@/components/gap';

export interface ProjectTagProps {
	label: string;
	enableClose?: boolean;
	onClose?: () => void;
}

export interface ProjectTagGroupProps {
	tags: ProjectTagProps[];
}

export const ProjectTag = ({ label, enableClose, onClose }: ProjectTagProps) => {
	return (
		<div
			style={{
				justifyContent: enableClose ? 'space-between' : 'center',
			}}
			className='box-border h-[32px] w-fit p-[0_20px] flex items-center border-[1px] border-solid border-primary-light-02 rounded-[16px] bg-primary-light-05 body-4-r text-primary-regular'
		>
			{label}
			{enableClose && (
				<>
					<Gap width='8px' />
					<Close16pxActiveIcon className=' cursor-pointer' onClick={onClose} />
				</>
			)}
		</div>
	);
};

export const ProjectTagGroup = ({ tags }: ProjectTagGroupProps) => {
	return (
		<Space size={4} wrap>
			{tags.map((tag) => (
				<ProjectTag {...tag} />
			))}
		</Space>
	);
};
