import { useTranslation } from 'react-i18next';
import { Gap } from '@/components/gap';
import { EmptyExceptionPic } from '@/components/pics';

export const NoExceptionEmpty = () => {
	const { t } = useTranslation();

	return (
		<div className='w-full h-full flex flex-col justify-center items-center'>
			<EmptyExceptionPic width={100} />
			<Gap height='16px' />
			<div className='body-4-r text-grey-03'>{t(`Everything Goes Well!`)}</div>
		</div>
	);
};
