import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, BreadcrumbProps, Button, Layout } from 'antd';
import { Container, Order } from '@/interfaces/order.interface';
import { ClockCircleOutlined, EnvironmentOutlined } from '@ant-design/icons';
// import BackButton from './back-button';
import ExitButton from '../search-rate/book/components/exit-button';

const { Header } = Layout;

interface OrderHeaderProps {
	order: Order;
}

export default ({ order }: OrderHeaderProps) => {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const breadcrumbItems: BreadcrumbProps['items'] = [
		{
			title: t('Orders'),
			onClick: () => navigate(`/orders?defaultOrderId=${order.id}`),
			className: 'cursor-pointer',
		},
		{
			title: (
				<a className='body-4-r text-primary-regular'>
					{t('Order')} #{order.uid}
				</a>
			),
		},
	];

	// TODO TESTMIAMI3
	return (
		<Header
			className='pt-[26px] pl-[42px] h-[145px] flex justify-between leading-none bg-[#ffffff]'
			style={{ borderBottom: '1px solid #E6F0FA' }}
		>
			<div>
				<Breadcrumb className='mb-[18px]' separator='>' items={breadcrumbItems} />

				<div className='mb-[8px] leading-[32px] h3-b'>
					<span className='mr-[10px]'>{order?.warehouse?.name ?? ''}</span>
					<span className='body-4-m text-grey-01'>
						{order?.warehouse?.delivery_order_address ?? ''}
					</span>
					{/* <span className='text-grey-01'>
						{t('To')} {order?.warehouse?.city?.full_name ?? ''}
					</span> */}
				</div>
				<div className='leading-[16px] font-1-r'>
					<span className='mr-[12px]'>
						<ClockCircleOutlined className='mr-[5px]' />
						{t('Earliest ETA')}: {order?.earliest_eta}
					</span>
					<span className='mr-[12px]'>
						<EnvironmentOutlined className='mr-[5px]' />
						{t('Intermodal Region')}: {order?.final_port?.name}
					</span>
					<span>
						<EnvironmentOutlined className='mr-[5px]' />
						{t('Destination City')}: {order?.warehouse?.city?.full_name ?? ''}
					</span>
				</div>
			</div>
			<div>
				<ExitButton />
			</div>
		</Header>
	);
};
