import React from 'react';
import ActiveSvg from '@/assets/time-range-48px-active.svg';
import Svg from '@/assets/time-range-48px.svg';

export default function ({
	active,
	...props
}: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
	active?: boolean;
}) {
	return active ? <img {...props} src={ActiveSvg} /> : <img {...props} src={Svg} />;
}
