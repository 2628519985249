import { CargoType } from '@/interfaces/cargo-type';
import _ from 'lodash';
import { cargoTypesMap, CargoTypesMapKeys, getValueByKey } from '@/utils/cargo-types';

export default (types: number[]) => {
	return _.omitBy(
		Object.fromEntries(
			Object.entries(cargoTypesMap).map(([k, v]) => [
				k,
				types.includes(getValueByKey(k as CargoTypesMapKeys)),
			]),
		),
		(v) => !v,
	) as Partial<CargoType>;
};
