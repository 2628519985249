import { useTranslation } from 'react-i18next';
import { Popover } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

export const DeliveryStatement = () => {
	const { t } = useTranslation();

	return (
		<Popover
			title={t('E-commerce warehouse delivery statement')}
			content={
				<div>
					{t(
						'To ensure the smooth dispatch of your goods, please note that if your delivery warehouse is an e-commerce warehouse, such as Walmart, Amazon, TikTok, Wayfair, eBay, etc., then the quotes on this platform are not applicable. Please contact your dedicated account manager for an accurate quote.',
					)}
				</div>
			}
			overlayClassName='max-w-[50vw]'
		>
			<QuestionCircleOutlined className=' cursor-pointer' />
		</Popover>
	);
};
