import { useTranslation } from 'react-i18next';
import { Table, TableProps } from '@/components/table';

interface ServiceFee {
	type: string;
	note: string;
	fee: string;
}

export const ServiceFeeTable = () => {
	const { t } = useTranslation();

	const serviceFees: ServiceFee[] = [
		{
			type: t('Pick up/Delivery Locations Type'),
			note: t('Cannot be residential. Must have dock or self upload/unload tools.'),
			fee: t(''),
		},
		{
			type: t('Load/Unload Type'),
			note: t('All live-live. To change your account to drop, please contact us.'),
			fee: t('-'),
		},
		{ type: t('Commodity Value'), note: t('Up to $100,000.'), fee: t('-') },
		{ type: t('Commodity Type'), note: t('Non-hazmat, non-household.'), fee: t('-') },
		{
			type: t('Detention'),
			note: t('Charged after 2 free hours after check-in at facility.'),
			fee: t('$80 per hour'),
		},
		{
			type: t('Layover'),
			note: t(
				'Charged when a driver must wait overnight due to loading/unloading delays. Per 24h occurrence.',
			),
			fee: t('$300 per 24 hours'),
		},
		{
			type: t('Truck Order Not Moved (TONU)'),
			note: t(
				'Charged when a truck is ordered but canceled without sufficient notice (Less than 24h cancellation notice)',
			),
			fee: t('$250'),
		},
		{
			type: t('Driver Assist'),
			note: t('Charged per stop. Each driver charges very differently.'),
			fee: t('$100-$500. Please consult'),
		},
	];

	const columns: TableProps<ServiceFee>['columns'] = [
		{
			title: t('Type'),
			dataIndex: 'type',
			width: '150px',
		},
		{
			title: t('Note'),
			dataIndex: 'note',
		},
		{
			title: t('Fee'),
			dataIndex: 'fee',
			width: '150px',
		},
	];

	return (
		<Table
			dataSource={serviceFees}
			columns={columns}
			className='border-[1px] border-solid border-grey-05 rounded-[8px] overflow-hidden'
			commonHeaderClass='h-[32px] flex items-center body-4-b text-grey-01  bg-grey-05'
			commonCellClass='px-[16px]'
			commonCellStyle={{
				borderBottom: '1px solid #E6E6E6',
			}}
			commonBodyClass='h-[60px] flex items-center body-4-r text-black'
		/>
	);
};
