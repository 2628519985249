import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';
import { LiveOrDrop } from '@/interfaces/live-or-drop.enum';
import { GetProjectMyFocusDataItem, GetProjectMyFocusProps } from '@/services/apis';
import { WarningOutlined } from '@ant-design/icons';
import { useMemoizedFn, useSafeState } from 'ahooks';
import CenterSpin from '@/components/center-spin';
import { ContainerDetailModal, useContainerDetailModal } from '@/components/container-detail-modal';
import { Gap } from '@/components/gap';
import { ProjectExceptionWarning } from '@/components/project-exceptions/exception-warning';
import { StatusCircle } from '@/components/status-circle';
import { Table, TableProps, TCellRender } from '@/components/table';
import { ProgressLabel } from '@/pages/project/use-progress-map';
import { MyFocusMap, MyFocusMapProps } from '../map';
import { Empty } from './empty';

export interface ItemRenderProps
	extends MyFocusMapProps,
		Omit<TableProps<GetProjectMyFocusDataItem>, 'columns' | 'HeaderRender' | 'CellRender'> {
	enableMap: boolean;
	type: GetProjectMyFocusProps['path']['type'];
	loading: boolean;
}

const KEYMAP_PICKUP = 'pickup';
const KEYMAP_DELIVERED = 'delivered';
const KEYMAP_EMPTY_RETURNED = 'empty_returned';

const keyMap = {
	[KEYMAP_PICKUP]: {
		est: 'schedule_terminal_pickup_at',
		act: 'actual_terminal_pickuped_at',
	},
	[KEYMAP_DELIVERED]: {
		est: 'schedule_delivery_at',
		act: 'actual_delivered_at',
	},
	[KEYMAP_EMPTY_RETURNED]: {
		est: '',
		act: 'actual_empty_returned_at',
	},
};

const PickupRender = ({
	item,
	isSelected,
	projectId,
}: {
	item: GetProjectMyFocusDataItem;
	isSelected: boolean;
	projectId: string | number;
}) => {
	const { t } = useTranslation();

	const { openingContainerDetailModal, openContainerDetailModal, closeContainerDetailModal } =
		useContainerDetailModal();

	const hasACT = !!item.actual_terminal_pickuped_at;

	return (
		<>
			<div
				style={{ borderBottom: '1px solid #e7e7e7' }}
				className={`cursor-pointer box-border p-[16px] w-full hover:bg-primary-light-05 ${
					isSelected && 'bg-primary-light-05'
				}`}
				onClick={openContainerDetailModal}
			>
				<div className='flex justify-between'>
					{/* left */}
					<div className='flex items-center'>
						{hasACT ? <StatusCircle /> : <StatusCircle inactive />}
						<Gap width='12px' />
						<div className='flex foot-note-1-r text-black'>
							{'#'}
							<Gap width='2px' />
							{item.number}
						</div>
					</div>

					<div className='flex items-center'>
						<div className='foot-note-2-b text-grey-02'>
							{hasACT ? t(`ACT`) : t(`EST`)}
							{':'}
						</div>
						<Gap width='4px' />
						<div className='foot-note-1-r text-primary-regular'>
							{hasACT ? item.actual_terminal_pickuped_at : item.schedule_terminal_pickup_at}
						</div>
					</div>
				</div>

				<Gap height='8px' />

				<div className='flex justify-between'>
					{/* left */}
					<div className='flex items-center'>
						<div className='foot-note-2-b text-grey-02'>
							{t(`IR`)}
							{':'}
						</div>
						<Gap width='4px' />
						<div className='foot-note-1-r text-black'>{item.final_port.name}</div>
					</div>

					<div className='flex items-center'>
						<div className='foot-note-2-b text-grey-02'>
							{t(`LFD`)}
							{':'}
						</div>
						<Gap width='4px' />
						<div className='foot-note-1-r text-black'>{item.lfd}</div>
					</div>
				</div>
			</div>
			{openingContainerDetailModal && (
				<ContainerDetailModal
					projectId={projectId}
					orderId={item.order_id}
					containerId={item.id}
					open={openingContainerDetailModal}
					onClose={closeContainerDetailModal}
				/>
			)}
		</>
	);
};

const DeliveryRender = ({
	item,
	isSelected,
	projectId,
}: {
	item: GetProjectMyFocusDataItem;
	isSelected: boolean;
	projectId: string | number;
}) => {
	const { t } = useTranslation();

	const hasACT = !!item.actual_delivered_at;

	const { openingContainerDetailModal, openContainerDetailModal, closeContainerDetailModal } =
		useContainerDetailModal();

	return (
		<>
			<div
				style={{ borderBottom: '1px solid #e7e7e7' }}
				className={`cursor-pointer box-border p-[16px] w-full hover:bg-primary-light-05 ${
					isSelected && 'bg-primary-light-05'
				}`}
				onClick={openContainerDetailModal}
			>
				<div className='flex justify-between'>
					{/* left */}
					<div className='flex items-center'>
						{hasACT ? <StatusCircle /> : <StatusCircle inactive />}
						<Gap width='12px' />
						<div className='flex foot-note-1-r text-black'>
							{'#'}
							<Gap width='2px' />
							{item.number}
						</div>
					</div>

					<div className='flex items-center'>
						<div className='foot-note-2-b text-grey-02'>
							{hasACT ? t(`ACT`) : t(`EST`)}
							{':'}
						</div>
						<Gap width='4px' />
						<div className='foot-note-1-r text-primary-regular'>
							{hasACT ? item.actual_delivered_at : item.schedule_delivery_at}
						</div>
					</div>
				</div>

				<Gap height='8px' />

				<div className='flex items-center'>
					<div className='foot-note-2-b text-grey-02'>
						{t(`POD`)}
						{':'}
					</div>
					<Gap width='4px' />
					<div className='foot-note-1-r text-black'>{item.port_of_discharge.name}</div>
				</div>
			</div>
			{openingContainerDetailModal && (
				<ContainerDetailModal
					projectId={projectId}
					orderId={item.order_id}
					containerId={item.id}
					open={openingContainerDetailModal}
					onClose={closeContainerDetailModal}
				/>
			)}
		</>
	);
};

const EmptyReturnRender = ({
	item,
	isSelected,
	projectId,
}: {
	item: GetProjectMyFocusDataItem;
	isSelected: boolean;
	projectId: string | number;
}) => {
	const { t } = useTranslation();

	const { openingContainerDetailModal, openContainerDetailModal, closeContainerDetailModal } =
		useContainerDetailModal();

	return (
		<>
			<div
				style={{ borderBottom: '1px solid #e7e7e7' }}
				className={`cursor-pointer box-border p-[16px] w-full hover:bg-primary-light-05 ${
					isSelected && 'bg-primary-light-05'
				}`}
				onClick={openContainerDetailModal}
			>
				<div className='flex justify-between'>
					{/* left */}
					<div className='flex foot-note-1-r text-black '>
						{'#'}
						<Gap width='2px' />
						{item.number}
					</div>

					<div className='flex items-center'>
						<div className='foot-note-2-b text-grey-02'>{t(`ACT`)}</div>
						<Gap width='4px' />
						<div className='foot-note-1-r text-primary-regular'>
							{item.actual_empty_returned_at}
						</div>
					</div>
				</div>

				<Gap height='8px' />

				<div className='flex items-center'>
					<div className='foot-note-2-b text-grey-02'>
						{t(`IR`)}
						{':'}
					</div>
					<Gap width='4px' />
					<div className='foot-note-1-r text-black'>{item.final_port.name}</div>
				</div>
			</div>
			{openingContainerDetailModal && (
				<ContainerDetailModal
					projectId={projectId}
					orderId={item.order_id}
					containerId={item.id}
					open={openingContainerDetailModal}
					onClose={closeContainerDetailModal}
				/>
			)}
		</>
	);
};

export const ItemRender = ({
	enableMap,
	type,
	points,
	dataSource,
	icon,
	activeIcon,
	loading,
	selectedPointId,
	mapLoading,
	className,
	commonCellStyle,
	commonCellClass,
	commonHeaderClass,
	commonBodyClass,
	onNextPage,
	onMarkClick,
}: ItemRenderProps) => {
	const { t } = useTranslation();

	const { projectId } = useParams();

	const { openingContainerDetailModal, openContainerDetailModal, closeContainerDetailModal } =
		useContainerDetailModal();

	const [selectedRecord, setSelectedRecord] = useSafeState<GetProjectMyFocusDataItem | null>();

	const ContainerNumberCellRender: TCellRender<GetProjectMyFocusDataItem> = ({
		style,
		className,
		value,
		record,
		onRowClick,
	}) => {
		const troubleStatus = record.trouble_status ?? [];

		return (
			<div
				style={{ ...(style ?? {}) }}
				className={`${className} flex items-center cursor-pointer`}
				onClick={() => onRowClick(record)}
			>
				{value}
				{troubleStatus.length > 0 && (
					<div className='flex items-center text-additional-warning'>
						<Gap width='12px' />
						<WarningOutlined />
						{troubleStatus.length}
					</div>
				)}
				{/* {troubleStatus.length > 0 && (
					<ProjectExceptionWarning exceptions={troubleStatus} />
				)} */}
			</div>
		);
	};

	const getColumns = useMemoizedFn(
		(key: keyof typeof keyMap): TableProps<GetProjectMyFocusDataItem>['columns'] => {
			const baseColumns: TableProps<GetProjectMyFocusDataItem>['columns'] = [
				{
					title: t('Container #'),
					dataIndex: 'number',
					CellRender: ContainerNumberCellRender,
				},
				{
					title: t('Live/Drop'),
					dataIndex: 'live_or_drop',
					CellRender({ style, className, record, onRowClick }) {
						return (
							<div
								style={{ ...(style ?? {}) }}
								className={`${className}`}
								onClick={() => onRowClick(record)}
							>
								{record.live_or_drop === LiveOrDrop.Empty
									? ''
									: record.live_or_drop === LiveOrDrop.Live
									? t('Live')
									: t('Drop')}
							</div>
						);
					},
				},
				{
					title: t('Progress'),
					dataIndex: 'progress',
					CellRender({ style, className, record, onRowClick }) {
						return (
							<div
								style={{ ...(style ?? {}) }}
								className={`${className}`}
								onClick={() => onRowClick(record)}
							>
								<ProgressLabel type={record.progress_type} />
							</div>
						);
					},
				},
				{
					title: t('MBL'),
					dataIndex: ['order', 'mbl_number'],
				},
				{
					title: t('IR'),
					dataIndex: ['final_port', 'name'],
				},
				{
					title: t('Destination'),
					dataIndex: ['warehouse', 'city', 'full_name'],
				},
				{
					title: t('ETA'),
					dataIndex: ['port_of_discharge_eta'],
				},
				{
					title: t('LFD'),
					dataIndex: 'lfd',
				},
			];

			switch (key) {
				case KEYMAP_PICKUP:
					baseColumns.push(
						{
							title: t('Est. Pickup'),
							dataIndex: 'schedule_terminal_pickup_at',
						},
						{
							title: t('Pickup'),
							dataIndex: 'actual_terminal_pickuped_at',
						},
						{
							title: t('Est. Delivery'),
							dataIndex: 'schedule_delivery_at',
						},
					);
					break;
				case KEYMAP_DELIVERED:
					baseColumns.push(
						{
							title: t('Pickup'),
							dataIndex: 'actual_terminal_pickuped_at',
						},
						{
							title: t('Est. Delivery'),
							dataIndex: 'schedule_delivery_at',
						},
						{
							title: t('Delivery'),
							dataIndex: 'actual_delivered_at',
						},
					);
					break;
				case KEYMAP_EMPTY_RETURNED:
					baseColumns.push(
						{
							title: t('Pickup'),
							dataIndex: 'actual_terminal_pickup_at',
						},
						{
							title: t('Delivery'),
							dataIndex: 'actual_delivered_at',
						},
						{
							title: t('Return'),
							dataIndex: 'actual_empty_returned_at',
						},
					);
					break;
			}

			return baseColumns;
		},
	);

	return (
		<div className='w-full h-full overflow-hidden flex flex-col'>
			<Gap height='16px' />
			{enableMap ? (
				<div className='flex h-full'>
					<Spin spinning={loading}>
						<div className='w-[360px] h-full grow-0 shrink-0 overflow-y-scroll'>
							{dataSource.length === 0 ? (
								<Empty />
							) : (
								<>
									{dataSource.map((item) => (
										<div key={item.id}>
											{type === 'pickup' && (
												<PickupRender item={item} isSelected projectId={projectId} />
											)}
											{type === 'delivered' && (
												<DeliveryRender item={item} isSelected projectId={projectId} />
											)}
											{type === 'empty_returned' && (
												<EmptyReturnRender item={item} isSelected projectId={projectId} />
											)}
										</div>
									))}
								</>
							)}
						</div>
					</Spin>
					<MyFocusMap
						points={points}
						icon={icon}
						activeIcon={activeIcon}
						selectedPointId={selectedPointId}
						mapLoading={mapLoading}
						onMarkClick={onMarkClick}
					/>
				</div>
			) : (
				<>
					{loading ? (
						<CenterSpin />
					) : dataSource.length === 0 ? (
						<Empty />
					) : (
						<>
							<Table
								columns={getColumns(type)}
								dataSource={dataSource}
								className={` overflow-scroll ${className}`}
								commonCellStyle={{ borderBottom: '1px solid #e7e7e7', ...(commonCellStyle ?? {}) }}
								commonCellClass={`flex items-center box-border h-[44px] p-[8px] ${commonCellClass}`}
								commonHeaderClass={` sticky top-0 z-100 bg-white foot-note-1-m text-[#777777] ${commonHeaderClass}`}
								commonBodyClass={`foot-note-1-r text-black whitespace-nowrap ${commonBodyClass}`}
								onNextPage={onNextPage}
								onRowClick={(record) => {
									setSelectedRecord(record);
									openContainerDetailModal();
								}}
							/>
							{openingContainerDetailModal && (
								<ContainerDetailModal
									mask={true}
									projectId={projectId}
									orderId={selectedRecord.order_id}
									containerId={selectedRecord.id}
									open={openingContainerDetailModal}
									onClose={() => {
										setSelectedRecord(null);
										closeContainerDetailModal();
									}}
								/>
							)}
						</>
					)}
				</>
			)}
		</div>
	);
};
