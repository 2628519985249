import { useCallback } from 'react';
import { useSafeState } from 'ahooks';

export const useResolvedExceptionModal = () => {
	const [openingResolvedExceptionModal, setOpeningResolvedExceptionModal] = useSafeState(false);

	const openResolvedExceptionModal = useCallback(() => {
		setOpeningResolvedExceptionModal(true);
	}, [setOpeningResolvedExceptionModal]);

	const closeResolvedExceptionModal = useCallback(() => {
		setOpeningResolvedExceptionModal(false);
	}, [setOpeningResolvedExceptionModal]);

	return {
		openingResolvedExceptionModal,
		openResolvedExceptionModal,
		closeResolvedExceptionModal,
	};
};
