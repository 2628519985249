import { useEffect, useMemo, useState } from 'react';
import { Space } from 'antd';
import IndustryNewsActiveIcon from '@/icons/industry-news-active-icon';
import IndustryNewsInActiveIcon from '@/icons/industry-news-inactive-icon';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { t, use } from 'i18next';
import CenterSpin from '@/components/center-spin';
import { Gap } from '@/components/gap';
import { SimplePagination } from '@/components/simple-pagenation';
import { apiHooks } from '@/hooks/apis';
import { useWindowSize } from '@/hooks/use-window-size';
import { ActiveTag } from './ActiveTag';

export const IndustryNews = () => {
	const { isWidth } = useWindowSize();

	const expectedPageSize = isWidth ? 3 : 3;

	const { runAsync, data, pagination, loading, refresh } = apiHooks.useGetIndustryNews({
		defaultPageSize: expectedPageSize,
		defaultCurrent: 1,
	});

	const [openingNews, setOpeningNews] = useState(false);

	const active = data?.find((item) => item.active);

	const { current, total, totalPage, changeCurrent } = pagination;

	const heightPx = useMemo(() => {
		if (!openingNews) {
			return '35px';
		} else if (!isWidth) {
			return '300px';
		} else {
			return '369px';
		}
	}, [openingNews, isWidth]);

	useEffect(() => {
		runAsync({
			current,
			pageSize: expectedPageSize,
		});
	}, [current, expectedPageSize, runAsync]);

	useEffect(() => {
		active && setOpeningNews(true);
	}, [active]);

	return (
		<div
			className={`absolute top-0 right-[19px] z-10 box-border p-[5px_25px] w-[359px] flex flex-col border-[1px] border-solid border-[#00000026] rounded-[10px] bg-[#ffffff]`}
		>
			<div
				className='grow-0 shrink-0 flex justify-center items-center cursor-pointer'
				onClick={() => setOpeningNews(!openingNews)}
			>
				<Space></Space>
				{active ? <IndustryNewsActiveIcon /> : <IndustryNewsInActiveIcon />}
				<span className='foot-note-1-r text-grey-01'>
					{t('Industry News')}{' '}
					{openingNews ? (
						<UpOutlined className='text-[12px]' />
					) : (
						<DownOutlined className='text-[12px]' />
					)}
				</span>
			</div>

			{openingNews && (
				<>
					<Gap height='12px' />

					<div className='grow shrink flex flex-col justify-between'>
						{loading ? (
							<CenterSpin />
						) : (data ?? []).length === 0 ? (
							<>No Data</>
						) : (
							<Space size={8} direction='vertical'>
								{(data ?? []).map((item, i) => (
									<div
										key={item.id}
										className=' box-border w-full h-[68px] overflow-hidden flex border-[1px] border-solid border-grey-05 rounded-[8px] bg-white hover:bg-primary-light-04 hover:border-primary-regular'
									>
										<div className='grow shrink box-border p-[4px_20px_4px_12px] h-full flex flex-col '>
											{/* top */}
											<div className='grow shrink body-4-m font-[500] text-black'>
												<span className='line-clamp-1'>{item.name}</span>
											</div>

											{/* bottom */}
											<div className='grow-0 shrink-0 flex justify-between text-center'>
												<ActiveTag isActive={item.active} />

												<span className='font-size[12px] font-weight[400] line-height[14.24px]'>
													{item.start_at && dayjs(item.start_at).format('MM/DD')} -{' '}
													{item.end_at && dayjs(item.end_at).format('MM/DD')}
												</span>

												<a target='blank' href={item.link}>
													<span className='underline cursor-pointer'>{t('See Details')}</span>
												</a>
											</div>
										</div>
									</div>
								))}
							</Space>
						)}
					</div>

					<Gap height='14px' />

					<div className='grow-0 shrink-0 flex justify-center'>
						<SimplePagination
							label={`${current} - ${totalPage}`}
							onPre={() => changeCurrent(current > 2 ? current - 1 : 1)}
							onNext={() => changeCurrent(current < total ? current + 1 : total)}
						/>
					</div>
				</>
			)}
		</div>
	);
};
