import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { UnFavoriteProjectProps, UnFavoriteProjectRes } from './interface';

export const unfavoriteProject = async (props: UnFavoriteProjectProps) => {
	return await axios.patch<UnFavoriteProjectRes>(
		`/projects/${props.path.projectId}/unfavorite`,
		null,
		{
			headers: {
				Authorization: accessTokenManager.getAccessToken(),
			},
		},
	);
};
