import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetAgentCustomerPrepayOrders } from './interface';

export const getAgentCustomerPrepayOrders = async (params) => {
	return await axios.get<GetAgentCustomerPrepayOrders>(`agent/customers/prepayOrders`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params,
	});
};
