import accessTokenManager from '@/services/contexts/user/access-token-manager';

export const unauthedNavigate = (isLogout?: boolean) => {
	accessTokenManager.clearAccessToken();

	const loginPath = '/login';

	if (window.location.pathname.startsWith(loginPath)) {
		return;
	}

	if (isLogout) {
		window.location.pathname = loginPath;
		return;
	}

	const currentURL = window.location.pathname + window.location.search;

	const loginURL = `/login?redirect=${encodeURIComponent(currentURL)}`;

	window.history.replaceState(null, '', loginURL);
	window.location.assign(loginURL);
};
