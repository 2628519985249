import { baseURL } from '..';
import { downloadFactory } from '../download-factory';
import { DownloadFileProps } from './interface';

export const downloadFile = async (props: DownloadFileProps, config?: { preview?: boolean }) => {
	const { documentId, orderInvoiceId } = props.path;
	const url = baseURL + `newDocuments/${documentId}/order_invoice/${orderInvoiceId}`;

	downloadFactory(url, props.other.fileName, { preview: config?.preview });
};
