import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetProjectContainersProps, GetProjectContainersRes } from './interface';

export * from './interface';

export const getProjectContainers = async (props: GetProjectContainersProps) => {
	return await axios.get<GetProjectContainersRes>(`/projects/${props.path.projectId}/containers`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params: props.params,
	});
};
