import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';

export const addCustomerPrepayOrders = async (values) => {
	return await axios.post(`agent/customers/prepayOrders`, values, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
