import { useCallback, useState } from 'react';
import { ModalProps } from 'antd';
import { Order } from '@/interfaces/order.interface';
import UploadFileModal from './upload-file-modal';

export default () => {
	const [open, setOpen] = useState(false);

	const [connectedOrder, setConnectedOrder] = useState<Order | null>(null);

	const openModal = useCallback((order: Order) => {
		setConnectedOrder(order);
		setOpen(true);
	}, []);

	const closeModal = useCallback(() => {
		setOpen(false);
		setConnectedOrder(null);
	}, []);

	const Modal = (props: ModalProps & { onOk: () => void }) =>
		connectedOrder ? (
			<UploadFileModal
				{...props}
				open={open}
				order={connectedOrder}
				onCancel={closeModal}
				onOk={() => {
					props.onOk && props.onOk();
					closeModal();
				}}
			/>
		) : null;

	return {
		openModal,
		closeModal,
		Modal,
	};
};
