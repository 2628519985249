import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetBillingCodeDefinitionProps, GetBillingCodeDefinitionRes } from './interface';

export const getBillingCodeDefinition = async (props: GetBillingCodeDefinitionProps) => {
	return await axios.get<GetBillingCodeDefinitionRes>(
		`/billingCodes/definition/${props.path.billingCode}`,
		{
			headers: {
				Authorization: accessTokenManager.getAccessToken(),
			},
		},
	);
};
