import { useState } from 'react';
import { searchTerminals } from '@/services/apis/search-terminals';
import { useDebounceFn } from 'ahooks';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import CenterSpin from '@/components/center-spin';
import Select from '@/components/select';
import { PickupTerminalSelectProps } from './type';

export const PickupTerminalSelect = observer(
	({ defaultTerminals, ...props }: PickupTerminalSelectProps) => {
		const [terminals, setTerminals] = useState<
			{ id: number; name: string; firms_code: string; firms?: string }[]
		>(defaultTerminals ?? []);
		const [fetching, setFetching] = useState(false);

		const { run: handleSearch } = useDebounceFn(
			async (searchText: string) => {
				setFetching(true);
				const res = await searchTerminals({ params: { limit: 50, name: searchText } });
				setTerminals(res.data.data);
				setFetching(false);
			},
			{ wait: 500 },
		);

		// @ts-ignore
		const options = terminals.map((t) => ({
			label: `${t.firms_code ?? t?.firms ?? ''}-${t.name}`,
			value: t.id,
		}));

		return (
			<Select
				{...props}
				notFoundContent={fetching ? <CenterSpin size='small' /> : null}
				filterOption={false}
				options={options}
				onSearch={handleSearch}
				showSearch
			/>
		);
	},
);
