import ContainerReleaseStatusIcon from '@/components/container-release-status-icon';

interface avaliableToPickupProps {
	actual_empty_returned_at: string | undefined | null;
	actual_terminal_pickuped_at: string | undefined | null | boolean;
	ready_to_pickup_at: string | undefined | null | boolean;
	port_of_discharge_eta: string | undefined | null;
}

export default ({
	actual_empty_returned_at,
	actual_terminal_pickuped_at,
	ready_to_pickup_at,
	port_of_discharge_eta,
}: avaliableToPickupProps) => {
	return (
		<ContainerReleaseStatusIcon
			time={actual_empty_returned_at || actual_terminal_pickuped_at ? true : ready_to_pickup_at}
			eta={port_of_discharge_eta}
		/>
	);
};
