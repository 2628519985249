import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetProjectProps, GetProjectRes } from './interface';

export * from './interface';

export const getProject = async (props: GetProjectProps) => {
	return await axios.get<GetProjectRes>(`/projects/${props.path.projectId}`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
