import { useTranslation } from 'react-i18next';
import { Checkbox, Form, FormInstance, Input, Space } from 'antd';
import * as _ from 'lodash';
import { useFTLContext } from '@/services/contexts';
import { observer } from 'mobx-react-lite';
import { Gap } from '@/components/gap';
import { FormItem } from '@/pages/ltl-search-rate/form-item';
import { formItemWrapper } from '@/pages/ltl-search-rate/form-item-wrapper';
import { formatePhoneNumber } from '@/utils/formate-phone-number';

interface ShipmentDetailItemProps {
	type: 'pickup' | 'destination';
	titleIcon: React.ReactNode;
	borderColor: string;
	form: FormInstance;
	extra?: React.ReactNode;
	disabled?: boolean;
}

export const ShipmentDetailItem = observer(
	({ type, titleIcon, borderColor, form, extra, disabled }: ShipmentDetailItemProps) => {
		const { t } = useTranslation();

		const ftlContext = useFTLContext();

		const title = type === 'pickup' ? t(`Pickup`) : t(`Destination`);

		// 根据 warehouse 的字段对应

		return (
			<div className='w-full h-full'>
				{/* pick up */}
				<div
					style={{
						borderLeft: `2px dashed ${borderColor}`,
					}}
					className='flex flex-col pl-[12px] w-[710px]'
				>
					{/* title */}
					{/* left */}
					<div className='flex justify-between items-center bg-white'>
						<div className='translate-x-[-25px] flex items-center'>
							{titleIcon}
							<span className='body-3-sb text-[#545F71]'>{title}</span>
						</div>

						{/* right */}
						<div className='flex items-center'>
							<Checkbox
								disabled={disabled}
								className='body-4-m text-black'
								value={
									type === 'pickup'
										? ftlContext.savePickupAddress
										: ftlContext.saveDestinationAddress
								}
								onChange={(value) => {
									type === 'pickup'
										? (ftlContext.savePickupAddress = value.target.checked)
										: (ftlContext.saveDestinationAddress = value.target.checked);
								}}
							>
								{t(`Save to Address Book`)}
							</Checkbox>
						</div>
					</div>

					<Gap height='24px' />

					{/* form content */}
					<div className='flex flex-col gap-[24px]'>
						<div className='flex gap-[12px]'>
							<FormItem titleProps={{ content: t('Street Name'), required: true }}>
								<Form.Item name='address' rules={[{ required: true }]}>
									<Input
										disabled={disabled}
										maxLength={255}
										style={formItemWrapper.style}
										className={`${formItemWrapper.className} w-[222px] h-[42px]`}
									/>
								</Form.Item>
							</FormItem>

							<FormItem titleProps={{ content: t('Apt, Suite') }}>
								<Form.Item name='address2'>
									<Input
										disabled={disabled}
										style={formItemWrapper.style}
										className={`${formItemWrapper.className} w-[120px] h-[42px]`}
									/>
								</Form.Item>
							</FormItem>

							<FormItem titleProps={{ content: t('City, State & Country'), required: true }}>
								<Form.Item name={['city', 'full_name']}>
									<Input
										disabled
										style={formItemWrapper.style}
										className={`${formItemWrapper.className} w-[194px] h-[42px]`}
									/>
								</Form.Item>
							</FormItem>

							<FormItem titleProps={{ content: t('Zip Code'), required: true }}>
								<Form.Item name='zipcode' rules={[{ required: true }]}>
									<Input
										disabled
										style={formItemWrapper.style}
										className={`${formItemWrapper.className} w-[120px] h-[42px]`}
									/>
								</Form.Item>
							</FormItem>
						</div>

						<div className='flex gap-[12px]'>
							<FormItem titleProps={{ content: t('Facility Name'), required: true }}>
								<Form.Item name='name' rules={[{ required: true }]}>
									<Input
										disabled={disabled}
										maxLength={255}
										style={formItemWrapper.style}
										className={`${formItemWrapper.className} w-[486px] h-[42px]`}
									/>
								</Form.Item>
							</FormItem>

							<FormItem titleProps={{ content: t('Facility Contact Name'), required: true }}>
								<Form.Item name={['contacts', '0', 'name']} rules={[{ required: true }]}>
									<Input
										disabled={disabled}
										maxLength={255}
										style={formItemWrapper.style}
										className={`${formItemWrapper.className} w-[194px] h-[42px]`}
									/>
								</Form.Item>
							</FormItem>
						</div>

						<div className='flex gap-[12px]'>
							<Space.Compact>
								<FormItem titleProps={{ content: t('Contact Phone'), required: true }}>
									<Form.Item name={['contacts', '0', 'phone']} rules={[{ required: true }]}>
										<Input
											disabled={disabled}
											maxLength={255}
											style={formItemWrapper.style}
											className={`${formItemWrapper.className} w-[222px] h-[42px]`}
											onChange={(e) => {
												const data = e.target.value ?? '';
												const formattedPhone = formatePhoneNumber(data);
												if (type === 'pickup') {
													ftlContext.pickupAddress.contacts[0].phone = formattedPhone;
												} else {
													ftlContext.destinationAddress.contacts[0].phone = formattedPhone;
												}

												form.setFieldValue(['contacts', '0', 'phone'], formattedPhone);
											}}
										/>
									</Form.Item>
								</FormItem>

								<FormItem titleProps={{ content: t('Ext') }}>
									<Form.Item name={['contacts', '0', 'phone_ext']}>
										<Input
											disabled={disabled}
											maxLength={5}
											style={formItemWrapper.style}
											className={`${formItemWrapper.className} w-[100px] h-[42px]`}
										/>
									</Form.Item>
								</FormItem>
							</Space.Compact>

							<FormItem titleProps={{ content: t('Email Address'), required: true }}>
								<Form.Item
									name={['contacts', '0', 'email']}
									rules={[
										{ required: true },
										{
											type: 'email',
											message: t('email is not a valid email'),
										},
									]}
								>
									<Input
										disabled={disabled}
										style={formItemWrapper.style}
										className={`${formItemWrapper.className} w-[252px] h-[42px]`}
									/>
								</Form.Item>
							</FormItem>
						</div>

						<div className='flex gap-[12px]'>
							<FormItem titleProps={{ content: t('Special Requirement') }}>
								<Form.Item name={'special_request'}>
									<Input
										disabled={disabled}
										maxLength={255}
										style={formItemWrapper.style}
										className={`${formItemWrapper.className} w-[486px] h-[42px]`}
									/>
								</Form.Item>
							</FormItem>
						</div>

						{extra}
					</div>
				</div>
			</div>
		);
	},
);
