import { forwardRef, useMemo } from 'react';
import { SelectProps } from 'antd';
import { Depot } from '@/interfaces/depot';
import { observer } from 'mobx-react-lite';
import Select from '@/components/select';

export const DepotSelect = observer(
	forwardRef((props: { depots: Depot[] } & SelectProps, ref) => {
		const options = useMemo(
			() =>
				props.depots.map((c) => ({
					label: c.name + ' - ' + c.address,
					value: c.id,
				})),
			[props.depots],
		);

		const filterOption = (input: string, option?: { label: string; value: string }) =>
			(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

		return (
			<>
				<Select
					loading={props.loading}
					{...props}
					options={options}
					labelInValue
					onSelect={(value, option) => {
						const depot = props.depots.find((d) => d.id === value.value);
						props.onSelect && props.onSelect({ ...value, address: depot?.address }, option);
					}}
					showSearch
					allowClear
					onClear={() => {
						props.onSelect && props.onSelect(null, null);
					}}
					filterOption={filterOption}
				/>
			</>
		);
	}),
);
