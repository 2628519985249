import { useFTLContext } from '@/services/contexts';
import { useMount, useUnmount } from 'ahooks';
import { observer } from 'mobx-react-lite';
import CenterSpin from '@/components/center-spin';
import { FinalizeShipmentDetails } from './finalize-shipment-details';
import { GetRates } from './get-rates';
import { PlacedOrder } from './placed-order';
import { useInitQuote } from './use-init-quote';

export const FTLGetRates = observer(() => {
	const ftlContext = useFTLContext();

	const { loading: initQuoteLoading } = useInitQuote();

	// const { loading: getFTLParamsLoading } = apiHooks.useGetFTLParams({
	// 	onSuccess(data) {
	// 		const defaultEquipment = Object.entries(data.data.data.equipments)[0] as [string, string];

	// 		ftlContext.selectedEquipment = {
	// 			value: defaultEquipment[0],
	// 			label: defaultEquipment[1],
	// 		};
	// 	},
	// });

	// if (getFTLParamsLoading) return <CenterSpin />;

	useMount(() => {
		ftlContext.init();
	});

	useUnmount(() => {
		ftlContext.init();
	});

	if (initQuoteLoading) {
		return <CenterSpin />;
	}

	if (ftlContext.step === 3) {
		return <PlacedOrder />;
	}

	return (
		<div className='h-screen w-full flex flex-col items-center overflow-hidden bg-white'>
			{ftlContext.step === 1 && (
				<div className='h-full w-full'>
					<GetRates />
				</div>
			)}
			{ftlContext.step === 2 && (
				<div className='box-border p-[0_24px] h-full min-w-[1200px]'>
					<FinalizeShipmentDetails />
				</div>
			)}
		</div>
	);
});
