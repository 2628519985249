import { Tabs } from 'antd';
import { useShipmentOrderContext } from '@/services/contexts';
import { HowToPayMyBill } from './how-to-pay-my-bill';
import { InvoiceTable } from './invoice-table';

export const Billing = () => {
	const shipmentOrderContext = useShipmentOrderContext();

	const data = shipmentOrderContext.shipmentOrder;

	const invoices = data.invoices ?? [];

	return (
		<div className='w-full h-full flex gap-[32px]'>
			{/* left */}
			<div className='grow shrink flex flex-col gap-[16px]'>
				{invoices.length === 1 ? (
					<InvoiceTable invoice={invoices[0]} />
				) : (
					<Tabs
						tabPosition={'top'}
						style={{ height: 220 }}
						items={(data.invoices ?? []).map((invoice) => {
							return {
								label: String(invoice.id),
								key: String(invoice.id),
								children: (
									<div>
										<InvoiceTable invoice={invoice} />
									</div>
								),
							};
						})}
					/>
				)}
			</div>

			{/* right */}
			<div className='w-[225px]'>
				<HowToPayMyBill />
			</div>
		</div>
	);
};
