import { useSafeState } from 'ahooks';

export const useCenterTabs = (defaultActiveKey: string) => {
	const [activeKey, setActiveKey] = useSafeState(defaultActiveKey);

	const onChange = (key: string) => {
		setActiveKey(key);
	};

	return {
		activeKey,
		onChange,
	};
};
