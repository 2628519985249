import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Line, LineConfig } from '@ant-design/charts';
import { useSafeState } from 'ahooks';
import { Gap } from '@/components/gap';
import { TabSwitcher, useTabSwitcher } from '@/components/tab-switcher';
import { apiHooks } from '@/hooks/apis';
import { generatePreMonthIndex } from '@/utils/generate-pre-month-index';
import { generatePreNMonthWeeksIndex } from '@/utils/generate-pre-n-month-weeks-index';
import { IRFilter } from './ir-filter';
import { WarehouseFilter } from './warehouse-filter';

export const DeliveryCountPanel = () => {
	const { t } = useTranslation();

	const { projectId } = useParams();

	const { item1, item2, tabSwitcherValue, onTabSwitcherChange } = useTabSwitcher(
		{
			label: t(`Month`),
			value: 'month',
		},
		{
			label: t(`Week`),
			value: 'week',
		},
		'month',
	);

	const [selectedIRs, setSelectedIRs] = useSafeState<number[]>([]);

	const [selectedWarehouses, setSelectedWarehouses] = useSafeState<
		{ label: string; value: number }[]
	>([]);

	const { data, runAsync } = apiHooks.useGetProjectDeliveryCountStatistics();

	const generateRenderData = useMemo(() => {
		if (!data) return [];

		const timeIndex =
			tabSwitcherValue === 'month'
				? generatePreMonthIndex(12)
				: generatePreNMonthWeeksIndex(3).map((i) => Number(i));

		const finalPortsData = Object.values(data.final_ports ?? {}).flatMap((datas) => {
			const name = datas[0].final_port_name;

			return timeIndex.map((time) => {
				const timeData = datas.find((d) => d[tabSwitcherValue] === time);
				return {
					id: `ir-${name}`,
					name,
					value: timeData?.total_containers ?? 0,
					time,
				};
			});
		});

		const warehousesData = Object.values(data.warehouses ?? {}).flatMap((datas) => {
			const name = datas[0].warehouse_name;

			return timeIndex.map((time) => {
				const timeData = datas.find((d) => String(d[tabSwitcherValue]) === time);
				return {
					id: `warehouse-${name}`,
					name,
					value: timeData?.total_containers ?? null,
					time,
				};
			});
		});

		return [...finalPortsData, ...warehousesData];
	}, [data, tabSwitcherValue]);

	const chartConfig: LineConfig = {
		data: generateRenderData,
		xField: 'time',
		yField: 'value',
		seriesField: 'id',
		// interaction: {
		// 	tooltip: {
		// 		marker: false,
		// 	},
		// },
		legend: {
			itemMarker: 'circle',
		},
	};

	useEffect(() => {
		if (selectedIRs.length + selectedWarehouses.length !== 0) {
			runAsync({
				path: {
					projectId,
					type: tabSwitcherValue,
				},
				params: {
					warehouse_id: selectedWarehouses.map((w) => w.value),
					final_port_id: selectedIRs,
				},
			});
		}
	}, [selectedIRs, selectedWarehouses, tabSwitcherValue, runAsync]);

	return (
		<div className='w-full h-full flex flex-col'>
			<div className='flex justify-between items-center'>
				<div className='flex-grow-0 flex-shrink-0 body-3-b'>{t(`Delivery Count`)}</div>
				<TabSwitcher
					item1={item1}
					item2={item2}
					value={tabSwitcherValue}
					onChange={onTabSwitcherChange}
				/>
			</div>
			<Gap height='16px' />
			<div className='grow shrink overflow-hidden'>
				<div className='w-full h-full flex flex-col relative'>
					<Gap height='30px' />
					<div className=' absolute top-0 z-50 grow-0 shrink-0 flex bg-opacity-100'>
						<IRFilter selectedValue={selectedIRs} setSelectedValue={setSelectedIRs} />
						<WarehouseFilter
							selectedValue={selectedWarehouses}
							setSelectedValue={setSelectedWarehouses}
						/>
					</div>
					<Line className='chart-container' {...chartConfig} />
				</div>
			</div>
		</div>
	);
};
