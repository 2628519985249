import { useCallback, useState } from 'react';

export const useSuccessProjectModal = () => {
	const [openingSuccessProjectModal, setOpeningSuccessProjectModal] = useState(false);

	const openProjectModal = useCallback(() => {
		setOpeningSuccessProjectModal(true);
	}, [setOpeningSuccessProjectModal]);

	const closeProjectModal = useCallback(() => {
		setOpeningSuccessProjectModal(false);
	}, [setOpeningSuccessProjectModal]);

	return {
		openingSuccessProjectModal,
		openProjectModal,
		closeProjectModal,
	};
};
