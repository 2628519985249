import { useTranslation } from 'react-i18next';
import { Gap } from '@/components/gap';
import { ProjectContainersColumnChart } from '@/components/project-containers-column-chart';
import { apiHooks } from '@/hooks/apis';
import { useWindowSize } from '@/hooks/use-window-size';
import { generatePreMonthIndex } from '@/utils/generate-pre-month-index';
import { Summary } from './summary';

export const PastPanel = () => {
	const { t } = useTranslation();

	const { isWidth } = useWindowSize();

	const { data } = apiHooks.useGetProjectsSummary();

	const statistics: typeof data.statistics = generatePreMonthIndex(isWidth ? 12 : 6).map(
		(month) => {
			const targetData = (data?.statistics ?? []).find((d) => d.month === month);
			return {
				year: targetData?.year ?? `${month},`,
				month,
				total_containers: targetData?.total_containers ?? 0,
				increase: targetData?.increase ?? 0,
			};
		},
	);

	return (
		<div className='h-full w-full flex flex-col'>
			<div className='grow-0 shrink-0 h4-sb text-primary-dark-01'>{t(`Overview`)}</div>

			<Gap height='16px' />

			<div className='grow shrink w-full flex'>
				<div className='grow shrink min-w-[390px]'>
					<ProjectContainersColumnChart data={statistics} />
				</div>

				<Gap width='40px' />

				<div className='grow-0 shrink-0 w-[300px]'>
					<Summary
						completed_containers={data?.completed_containers}
						active_containers={data?.active_containers}
					/>
				</div>
			</div>
		</div>
	);
};
