import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { EditProjectProps, EditProjectRes } from './interface';

export const editProject = async (props: EditProjectProps) => {
	return await axios.put<EditProjectRes>(`/projects/${props.path.projectId}`, props.data, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
