import { createContext, useContext } from 'react';
import { order } from './order.context';

export const orderContext = createContext(order);

export const OrderContext: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
	return <orderContext.Provider value={order}>{children}</orderContext.Provider>;
};

export const useOrderContext = () => useContext(orderContext);
