import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import type { PaginationQuery } from '@/services/apis/type';
import { useMemoizedFn } from 'ahooks';
import qs from 'qs';
import valueTransfer from '@/utils/value-transfer';

export default () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const parsedSearchParams = useMemo(() => {
		const searchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });

		const res = {};

		valueTransfer(res, 'per_page', searchParams.per_page, (v) => (v ? Number(v) : 20));
		valueTransfer(res, 'page', searchParams.page, (v) => (v ? Number(v) : 1));

		return res as Partial<PaginationQuery>;
	}, [searchParams]);

	const changeSearchParams = useMemoizedFn((values: any) => {
		setSearchParams(qs.stringify({ ...values }));
	});

	return {
		parsedSearchParams,
		changeSearchParams,
	};
};
