import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetFTLQuoteReq, GetFTLQuoteRes } from './interface';

export * from './interface';

export const getFTLQuote = async (props: GetFTLQuoteReq) => {
	return await axios.get<GetFTLQuoteRes>(`/ftl/quotes/${props.path.id}`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
