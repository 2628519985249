import { createContext, useContext } from 'react';
import { agent } from './agent.context';

export const agentContext = createContext(agent);

export const AgentContext: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
	return <agentContext.Provider value={agent}>{children}</agentContext.Provider>;
};

export const useAgentContext = () => useContext(agentContext);
