import type { UploadProps } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import { DocumentsLocalPros } from './types';

export const DocumentsLocal = ({ files, onFilesChange }: DocumentsLocalPros) => {
	const handleFilesChange: UploadProps['onChange'] = (info) => {
		onFilesChange(info.fileList);
	};

	const props: UploadProps = {
		multiple: true,
		beforeUpload: () => false,
		fileList: files,
		onChange: handleFilesChange,
	};

	return (
		<div>
			<Dragger {...props}>
				<p className='ant-upload-drag-icon'>
					<InboxOutlined />
				</p>
				<p className='ant-upload-text'>Click or drag file to this area to upload</p>
				<p className='ant-upload-hint'>
					Support for a single or bulk upload. Strictly prohibit from uploading company data or
					other band files
				</p>
			</Dragger>
		</div>
	);
};
