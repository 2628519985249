import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Button,
	Col,
	Divider,
	Drawer,
	DrawerProps,
	Form,
	InputNumber,
	message,
	Row,
	Space,
} from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import Dragger from 'antd/es/upload/Dragger';
import { getExchangeRate } from '@/services/apis';
import { usePrepayOrderContext } from '@/services/contexts/prepay-order';
import { InboxOutlined } from '@ant-design/icons';
import { find } from 'lodash';
import { observer } from 'mobx-react-lite';
import { Option } from 'rc-select';
import Input from '@/components/input';
import Select from '@/components/select';

interface PrepayOrderDrawerProps extends DrawerProps {
	banks: any[];
	fetching: boolean;
}

const FIXED = 1;
const FLOATING = 0;

const PrepayOrderDrawer = observer(({ banks, ...props }: PrepayOrderDrawerProps) => {
	const { t } = useTranslation();

	const [form] = useForm();

	const [submitting, setSubmitting] = useState(false);
	const [bank, setBank] = useState<number>();
	const [exchangeRates, setExchangeRates] = useState<{
		[key: string]: number;
	}>({
		USD: 1,
	});
	const [fileList, setFileList] = useState<any[]>([]);

	const orderContext = usePrepayOrderContext();

	const exhcangeType = useWatch('exchange_type', form);
	const exchangeRate = useWatch('exchange_rate', form);
	const amount = useWatch('amount', form);

	const handleUpload = (info: any) => {
		let fileList: any[] = [...info.fileList];

		fileList = fileList.slice(-1);
		setFileList(fileList);
		form.setFieldValue('document', info.file);
	};

	const onFinish = useCallback(async (values: any) => {
		setSubmitting(true);
		try {
			await orderContext.addPrepayOrders(values);
			message.success('Success');
			form.resetFields();
			setFileList([]);
			props.onClose && props.onClose(null);
		} catch (e: any) {
			message.error('Failed');
		} finally {
			setSubmitting(false);
		}
	}, []);

	const bankInfo = useMemo(() => {
		const info = find(banks, { id: bank });
		return info || null;
	}, [bank]);

	const cost = useMemo(() => {
		const info = find(banks, { id: bank });
		return parseFloat(`${info?.custom_properties?.exchange_rate_cost || 0}`);
	}, [bankInfo]);

	const rate = useMemo(() => {
		if (exhcangeType == FIXED) {
			return exchangeRate || 1;
		} else if (exhcangeType == FLOATING) {
			return exchangeRates[bankInfo?.currency] || 1;
		}
	}, [exchangeRate, exhcangeType, bankInfo, exchangeRates]);

	const usdCurrency = useMemo(() => {
		return amount / rate || 0;
	}, [amount, rate]);

	useEffect(() => {
		if (exhcangeType == FLOATING && bankInfo) {
			form.setFieldsValue({ exchange_rate: exchangeRates[bankInfo.currency] || 1 });
		}
	}, [exhcangeType, bankInfo, exchangeRates]);

	useEffect(() => {
		(async () => {
			for (const v of banks) {
				if (v.currency != 'USD') {
					const rate = await getExchangeRate(v.currency);
					setExchangeRates({ ...exchangeRates, [v.currency]: rate.data });
				}
			}
		})();
	}, [banks]);

	return (
		<Drawer {...props} title={t('Add Funds')}>
			<Form
				form={form}
				labelCol={{ span: 4 }}
				wrapperCol={{ span: 20 }}
				onFinish={onFinish}
				autoComplete='off'
			>
				<Form.Item
					labelCol={{ span: 5 }}
					wrapperCol={{ span: 12 }}
					name='bank'
					label={t('Payment method')}
					rules={[{ required: true }]}
				>
					<Select
						placeholder={t('Select Bank Account')}
						options={banks.map((v) => ({ label: v.name, value: v.id }))}
						onChange={(v) => setBank(v)}
					/>
				</Form.Item>
				<Row>
					<Col span={10}>
						<Form.Item
							name='amount'
							labelCol={{ span: 12 }}
							wrapperCol={{ span: 12 }}
							label={t('Amount')}
							rules={[{ required: true }]}
						>
							<InputNumber
								min={0}
								style={{ width: 150 }}
								prefix={bankInfo?.currency}
								// onChange={(value: any) => setAmount(value)}
							/>
						</Form.Item>
					</Col>
					<Col span={10}>
						<Form.Item
							labelCol={{ span: 12 }}
							wrapperCol={{ span: 12 }}
							name='exchange_type'
							label={t('Exchange Type')}
							initialValue={0}
							rules={[{ required: true }]}
						>
							<Select>
								<Option value={FLOATING}>{t('Floating Exchange Rate')}</Option>
								<Option value={FIXED}>{t('Fixed Exchange Rate')}</Option>
							</Select>
						</Form.Item>
					</Col>
				</Row>
				{bankInfo && bankInfo.currency !== 'USD' && (
					<Row>
						<Col span={8}>
							<Form.Item label={t('Currency')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
								<Input
									readOnly
									style={{ width: 150 }}
									prefix='USD'
									value={usdCurrency.toFixed(2)}
								/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								name='exchange_rate'
								label={t('Exchange Rate')}
								labelCol={{ span: 12 }}
								wrapperCol={{ span: 12 }}
							>
								<Input
									readOnly={form.getFieldValue('exchange_type') == FLOATING}
									style={{ width: 150 }}
								/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								label={t('Exchange Loss')}
								labelCol={{ span: 12 }}
								wrapperCol={{ span: 12 }}
							>
								<span className='ant-form-text'> {cost.toFixed(2)}</span>
							</Form.Item>
						</Col>
					</Row>
				)}
				<Form.Item
					name='document'
					label={t('Doc of receipt')}
					rules={[{ required: true }]}
					valuePropName='document'
					getValueFromEvent={(e) => {
						if (Array.isArray(e)) {
							return e;
						}
						return e.fileList;
					}}
				>
					<Dragger
						beforeUpload={() => false}
						name='image'
						listType='picture'
						fileList={fileList}
						onChange={handleUpload}
						maxCount={1}
					>
						<p className='ant-upload-drag-icon'>
							<InboxOutlined />
						</p>
						<p className='ant-upload-text'>{t('Click or drag file to this area to upload')}</p>
						<p className='ant-upload-hint'>
							{t(
								'Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files',
							)}
						</p>
					</Dragger>
				</Form.Item>
				<Divider />

				<div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
					<Space align='end'>
						<Button type='default' onClick={close}>
							{t('Cancel')}
						</Button>
						<Button type='primary' loading={submitting} onClick={() => form.submit()}>
							{t('Submit')}
						</Button>
					</Space>
				</div>
				{/* </Col>
				</Row> */}
			</Form>
		</Drawer>
	);
});

export default PrepayOrderDrawer;
