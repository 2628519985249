import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { useMemoizedFn } from 'ahooks';

export const useCopy = () => {
	const { t } = useTranslation();

	const copy = useMemoizedFn((text: string) => {
		const textarea = document.createElement('textarea');
		textarea.value = text;
		document.body.appendChild(textarea);
		textarea.select();
		document.execCommand('copy');
		document.body.removeChild(textarea);
		message.success(t('Copied to clipboard'));
	});

	return { copy };
};
