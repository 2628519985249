import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetPickupNumberAgentsProps, GetPickupNumberAgentsRes } from './interface';

export const getPickupNumberAgents = async (props: GetPickupNumberAgentsProps) => {
	return await axios.get<GetPickupNumberAgentsRes>(`/pickupNumberAgents`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params: props.params,
	});
};
