import { useLTLContext } from '@/services/contexts';
import { useUnmount } from 'ahooks';
import { observer } from 'mobx-react-lite';
import CenterSpin from '@/components/center-spin';
import { Footer, PlacedOrder, StepOne, StepThree, StepTwo } from './components';
import { useInitQuote } from './use-init-quote';

const Wrapper = ({ children }: { children: React.ReactNode }) => (
	<div className='m-auto box-border p-[0_24px] w-[1200px]'>{children}</div>
);

export const LTLSearchRate = observer(() => {
	const ltlContext = useLTLContext();

	const { loading: initQuoteLoading } = useInitQuote();

	useUnmount(() => {
		ltlContext.init();
	});

	if (initQuoteLoading) {
		return <CenterSpin />;
	}

	if (ltlContext.step === 4) {
		return <PlacedOrder />;
	}

	return (
		<div className='h-screen w-full flex flex-col overflow-hidden bg-white'>
			<div className='grow shrink overflow-x-hidden overflow-y-scroll'>
				{ltlContext.step === 1 && (
					<Wrapper>
						<StepOne />
					</Wrapper>
				)}
				{ltlContext.step === 2 && <StepTwo />}
				{ltlContext.step === 3 && (
					<Wrapper>
						<StepThree />
					</Wrapper>
				)}
			</div>
			<div
				className='box-border pr-[11px] grow-0 shrink-0 w-full overflow-hidden '
				style={{ borderTop: '1px solid #003166' }}
			>
				<Wrapper>
					<Footer />
				</Wrapper>
			</div>
		</div>
	);
});
