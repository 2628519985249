import { createContext, useContext } from 'react';
import { requestedRate } from './requested-rate.context';

export const requestedRateContext = createContext(requestedRate);

export const RequestedRateContext: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
	return (
		<requestedRateContext.Provider value={requestedRate}>{children}</requestedRateContext.Provider>
	);
};

export const useRequestedRateContext = () => useContext(requestedRateContext);
