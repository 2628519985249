import { useTranslation } from 'react-i18next';
import { Modal, ModalProps, Tabs, TabsProps } from 'antd';
import { useBoolean } from 'ahooks';
import { Attentions } from './attentions';
import { CannotShippedTable } from './cannot-shipped-table';
import { CargoInsuranceExcludedCommoditiesTable } from './cargo-insurance-excluded-commodities-table';
import { ServiceFeeTable } from './service-fee-table';

export const ServiceDetailModal = (props: ModalProps) => {
	const { t } = useTranslation();

	const [isOpen, { setTrue: open, setFalse: close }] = useBoolean(false);

	const items: TabsProps['items'] = [
		{
			key: '-1',
			label: t('Attentions'),
			children: <Attentions />,
		},
		{
			key: '1',
			label: t(`Service fees`),
			children: <ServiceFeeTable />,
		},
		{
			key: '2',
			label: t(`what can't be shipped`),
			children: <CannotShippedTable />,
		},
		{
			key: '3',
			label: t(`Cargo Insurance Excluded Commodities`),
			children: <CargoInsuranceExcludedCommoditiesTable />,
		},
	];

	const handleClose = () => close();

	return (
		<>
			<div className='text-primary-regular cursor-pointer' onClick={() => open()}>
				{t(`Service Detail`)}
			</div>
			<Modal {...props} open={isOpen} onCancel={handleClose} footer={null} width={'1000px'}>
				<div className=''>
					<div className='h4-b text-black font-[700]'>{t(`Service Detail`)}</div>

					<Tabs defaultActiveKey='0' items={items} />
				</div>
			</Modal>
		</>
	);
};
