import { useTranslation } from 'react-i18next';
import exp from 'constants';
import { NoContainerPic } from '@/components/pics';

const InvalidUrl = () => {
	const { t } = useTranslation();

	return (
		<div className='w-full h-full flex justify-center items-center'>
			<div className='w-[180px]'>
				<NoContainerPic />
				<div className=' text-center foot-note-1-r text-grey-01 text-[20px]'>
					{t(`There is invalid url`)}
				</div>
			</div>
		</div>
	);
};

export default InvalidUrl;
