import iconFactory from './icon-factory';

const _Svg = () => (
	<svg width='80' height='80' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M11.9965 0.666992V5.70592H12.506C13.3496 5.70592 13.8082 6.36834 13.8535 7.04208C13.8761 7.38178 13.7856 7.69318 13.5874 7.91398C13.3892 8.14045 13.0722 8.3103 12.506 8.3103C12.2229 8.3103 11.8776 8.15178 11.6228 7.89134C11.3623 7.63656 11.2038 7.2912 11.2038 7.00811H10.1847C10.1847 7.6309 10.4791 8.19141 10.8981 8.61604C11.3227 9.035 11.8832 9.32941 12.506 9.32941C13.2987 9.32941 13.9441 9.04633 14.3461 8.59339C14.7537 8.13479 14.9122 7.54031 14.8726 6.97414C14.8047 5.98334 14.1139 4.98235 13.0156 4.74229V0.666992H11.9965ZM9.2845 8.6217L1.48492 13.7455H3.3363L9.83934 9.47095L9.2845 8.6217ZM15.7275 8.6217L15.1727 9.47095L21.678 13.7455H23.5237L15.7275 8.6217ZM0.333328 14.7647V27.3337H24.6787V14.7647H0.333328Z'
			fill='url(#paint0_linear_775_18181)'
		/>
		<defs>
			<linearGradient
				id='paint0_linear_775_18181'
				x1='-1.77883'
				y1='14.0003'
				x2='24.6787'
				y2='14.0003'
				gradientUnits='userSpaceOnUse'
			>
				<stop stop-color='#0094FF' />
				<stop offset='1' stop-color='#0165FA' />
			</linearGradient>
		</defs>
	</svg>
);

export default iconFactory(_Svg);
