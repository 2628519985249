import { useTranslation } from 'react-i18next';
import { Button, Empty, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { OrderFile } from '@/interfaces/file.interface';
import { Order } from '@/interfaces/order.interface';
import { downloadFile } from '@/services/apis/download-invoice-file';
import { DownloadOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import PreviewLabel from './preview-label';

interface DocumentsTableProps {
	orderInvoiceId: number;
	files: OrderFile[];
}

export default observer(({ orderInvoiceId, files }: DocumentsTableProps) => {
	const { t } = useTranslation();

	const columns: ColumnsType<OrderFile> = [
		{
			dataIndex: ['file_name'],
			title: t('File Name'),
			render: (_, record) => <PreviewLabel orderInvoiceId={orderInvoiceId} record={record} />,
		},
		{
			dataIndex: ['size'],
			title: t('Size'),
		},
		{
			dataIndex: ['created_at'],
			title: t('Update Time'),
		},
		{
			key: 'Action',
			title: ' ',
			render: (_, record) => (
				<Button
					type='text'
					icon={<DownloadOutlined />}
					onClick={() =>
						downloadFile(
							{
								path: {
									documentId: record.id,
									orderInvoiceId: orderInvoiceId,
								},
								other: {
									fileName: record.file_name,
								},
							},
							{ preview: true },
						)
					}
				/>
			),
		},
	];

	return <Table columns={columns} dataSource={files ?? undefined} pagination={false} />;
});
