import { useEffect, useRef } from 'react';
import { Slider } from 'antd';
import { SliderRangeProps } from 'antd/es/slider';
import './fee-slider.less';

export default function (props: SliderRangeProps) {
	const ref = useRef<HTMLDivElement>(null);

	// 避免 slider marker 重叠
	useEffect(() => {
		// 获取 slider 元素的长度
		const totalWidth = ref.current.clientWidth;

		// 获取最大最小值
		const { max, min, defaultValue } = props;

		// 获得两个 marker 的长度
		const markers = ref.current.querySelectorAll('.ant-slider-mark-text');
		const mark1Width = markers[0].clientWidth;

		// 计算两个marker的偏移量
		const offset1 = (defaultValue[0] / (max - min)) * totalWidth;
		const offset2 = (defaultValue[1] / (max - min)) * totalWidth;
		const distance = offset2 - offset1;

		// 计算是否过近
		const needToOffset = mark1Width - distance;

		// 如果过近，分别加减到合适的位置
		if (needToOffset > 0) {
			// @ts-ignore
			if (markers[0] && markers[0].style) {
				// @ts-ignore
				markers[0].style.left = `${offset1 - needToOffset / 2}px`;
			}
			// @ts-ignore
			if (markers[1] && markers[1].style) {
				// @ts-ignore
				markers[1].style.left = `${offset2 + needToOffset / 2}px`;
			}
		}
	}, [props]);

	return (
		<div ref={ref} className='grow'>
			<Slider {...props} className='custom-slider w-full' />
		</div>
	);
}
