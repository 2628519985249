import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import { viewApiDoc } from '@/services/apis';
import 'github-markdown-css/github-markdown-light.css';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import './index.less';

const ApiDoc = () => {
	const { version } = useParams();
	const [data, setData] = useState();

	const fetchData = async () => {
		try {
			const response = await viewApiDoc(version);
			setData(response.data);
		} catch (e) {
			message.error(e);
		}
	};
	useEffect(() => {
		fetchData();
	}, []);
	return (
		<div style={{ height: '100%', overflowY: 'scroll' }}>
			<ReactMarkdown
				className='markdown-body p-3'
				children={data}
				remarkPlugins={[remarkGfm]}
				rehypePlugins={[rehypeRaw]}
			/>
		</div>
	);
};

export default ApiDoc;
