import iconFactory from './icon-factory';

const Svg = () => (
	<svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<rect x='0.5' y='0.5' width='21' height='21' rx='10.5' fill='white' />
		<rect x='0.5' y='0.5' width='21' height='21' rx='10.5' stroke='#0F255B' />
		<path
			d='M11 5C8.5 5 6 5.31579 6 7.52632V13.5263C6 14.1126 6.23047 14.6748 6.6407 15.0894C7.05094 15.5039 7.60734 15.7368 8.1875 15.7368L7.25 16.6842V17H8.64375L9.89375 15.7368H12.25L13.5 17H14.75V16.6842L13.8125 15.7368C14.3927 15.7368 14.9491 15.5039 15.3593 15.0894C15.7695 14.6748 16 14.1126 16 13.5263V7.52632C16 5.31579 13.7625 5 11 5ZM8.1875 14.4737C7.93886 14.4737 7.7004 14.3739 7.52459 14.1962C7.34877 14.0185 7.25 13.7776 7.25 13.5263C7.25 13.2751 7.34877 13.0341 7.52459 12.8564C7.7004 12.6788 7.93886 12.5789 8.1875 12.5789C8.43614 12.5789 8.6746 12.6788 8.85041 12.8564C9.02623 13.0341 9.125 13.2751 9.125 13.5263C9.125 13.7776 9.02623 14.0185 8.85041 14.1962C8.6746 14.3739 8.43614 14.4737 8.1875 14.4737ZM10.375 10.0526H7.25V7.52632H10.375V10.0526ZM11.625 10.0526V7.52632H14.75V10.0526H11.625ZM13.8125 14.4737C13.5639 14.4737 13.3254 14.3739 13.1496 14.1962C12.9738 14.0185 12.875 13.7776 12.875 13.5263C12.875 13.2751 12.9738 13.0341 13.1496 12.8564C13.3254 12.6788 13.5639 12.5789 13.8125 12.5789C14.0611 12.5789 14.2996 12.6788 14.4754 12.8564C14.6512 13.0341 14.75 13.2751 14.75 13.5263C14.75 13.7776 14.6512 14.0185 14.4754 14.1962C14.2996 14.3739 14.0611 14.4737 13.8125 14.4737Z'
			fill='#0F255B'
		/>
	</svg>
);

export default iconFactory(Svg);
