import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Segmented } from 'antd';
import { useShipmentOrderContext } from '@/services/contexts';
import { useUnmount } from 'ahooks';
import ExitButton from '../search-rate/book/components/exit-button';
import CenterSpin from '@/components/center-spin';
import { Billing, Documents, OrderInformation, Tracking } from './components';
import { useShipmentOrder } from './use-shipment-order';

enum OrderIndex {
	'Tracking' = 'Tracking',
	'OrderInformation' = 'Order Information',
	'Billing' = 'Billing',
	'Documents' = 'Documents',
}

/**
 * LTL 和 FTL 的 order 详情
 */
export const ShipmentOrder = () => {
	const { t } = useTranslation();

	const shipmentOrderContext = useShipmentOrderContext();

	const { data: order, loading } = useShipmentOrder({
		onSuccess: (data) => {
			shipmentOrderContext.shipmentOrder = data.data.data;
		},
	});

	const [orderIndex, setOrderIndex] = useState<OrderIndex>(OrderIndex.Tracking);

	useUnmount(() => {
		shipmentOrderContext.shipmentOrder = undefined;
	});

	if (loading) return <CenterSpin />;

	return (
		<div className='box-border p-[48px] h-screen w-full flex flex-col gap-[48px] overflow-y-scroll bg-white'>
			{/* header */}
			<div className='w-full flex items-center'>
				<div className='grow shrink flex items-center gap-[36px]'>
					<div className='h3-b text-black'>{`#${order.uid}`}</div>

					<div className='w-[656px]'>
						<Segmented<OrderIndex>
							block
							options={[
								{ label: t(OrderIndex.Tracking), value: OrderIndex.Tracking },
								{ label: t(OrderIndex.OrderInformation), value: OrderIndex.OrderInformation },
								{ label: t(OrderIndex.Billing), value: OrderIndex.Billing },
								{ label: t(OrderIndex.Documents), value: OrderIndex.Documents },
							]}
							onChange={(value) => {
								setOrderIndex(value);
							}}
						/>
					</div>
				</div>

				<ExitButton />
			</div>

			{/* content */}
			<div className='grow shrink w-full'>
				{orderIndex === OrderIndex.Tracking && <Tracking />}
				{orderIndex === OrderIndex.OrderInformation && <OrderInformation />}
				{orderIndex === OrderIndex.Billing && <Billing />}
				{orderIndex === OrderIndex.Documents && <Documents />}
			</div>
		</div>
	);
};
