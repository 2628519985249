import { useState } from 'react';
import { SelectProps } from 'antd';
import { useGlobalContext } from '@/services/contexts';
import Select from '@/components/select';

export const IntermodalRegionSelect = (props: SelectProps) => {
	const { cacheContext } = useGlobalContext();

	const [searchText, setSearchText] = useState('');

	const intermodalRegions = cacheContext.intermodalRegions;

	const options = intermodalRegions
		.map((i) => ({
			label: i.name,
			value: i.id,
		}))
		.filter((i) => i.label.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));

	return (
		<Select
			{...props}
			options={options}
			showSearch
			filterOption={(input, option) => {
				const originIntermodalRegion = intermodalRegions.find((i) => i.id === option?.value);
				return (originIntermodalRegion?.name ?? '').toLowerCase().includes(input.toLowerCase());
			}}
			onSearch={(t) => setSearchText(t)}
		/>
	);
};
