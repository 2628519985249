import { Gap } from '../gap';

export interface CenterTabsItemProps {
	key: string;
	label: string | React.ReactNode;
	children?: React.ReactNode;
}

export interface CenterTabsProps {
	activeKey: string;
	items: CenterTabsItemProps[];
	gap?: string;
	wrapperClassName?: string;
	tabsWrapperClassName?: string;
	bodyWrapperClassName?: string;
	onChange: (key: string) => void;
}

export const CenterTabs = ({
	activeKey,
	items,
	gap,
	wrapperClassName,
	tabsWrapperClassName,
	bodyWrapperClassName,
	onChange,
}: CenterTabsProps) => {
	return (
		<div className={`flex flex-col ${wrapperClassName}`}>
			{/* tabs */}
			<div className={`w-[full] flex flex-grow-0 flex-shrink-0 ${tabsWrapperClassName}`}>
				{items.map(({ key, label }) => (
					<div
						key={key}
						style={{
							color: key === activeKey ? '#007bff' : '#747578',
							borderBottom: key === activeKey ? '2px solid #007bff' : '1px solid #e3e3e3',
						}}
						className='box-border h-[36px] flex justify-center items-center flex-grow flex-shrink cursor-pointer'
						onClick={() => onChange(key)}
					>
						{label}
					</div>
				))}
			</div>

			{gap && <Gap height={gap} />}

			{/* body */}
			<div className={`flex-grow flex-shrink ${bodyWrapperClassName}`}>
				{items.map((item) => item.key === activeKey && <>{item.children}</>)}
			</div>
		</div>
	);
};
