import { observer } from 'mobx-react-lite';
import PrepayOrderTable from './components/table';

const PrepayOrder = observer(() => {
	return (
		<div className='p-[51px_40px_0_40px] h-screen flex flex-col'>
			<div className='flex-grow overflow-hidden'>
				<PrepayOrderTable />
			</div>
		</div>
	);
});

export default PrepayOrder;
