import { useMemoizedFn, useSetState } from 'ahooks';

export const useRule = () => {
	const [status, setStatus] = useSetState<Record<string, { rule: string; reason: string }[]>>({});

	const getFirstError = useMemoizedFn((key: string) => {
		const error = (status[key] ?? [])[0];

		return error
			? {
					status: 'error' as const,
					...error,
			  }
			: {
					status: '' as const,
					rule: undefined,
					reason: undefined,
			  };
	});

	const trigger = useMemoizedFn((key: string, rule: string, reason: string) => {
		setStatus((status) => ({
			[key]: [
				...(status[key] ?? []),
				{
					rule,
					reason,
				},
			],
		}));
	});

	const removeError = useMemoizedFn((key: string, rule: string) => {
		setStatus((status) => ({
			[key]: (status[key] ?? []).filter((r) => r.rule !== rule),
		}));
	});

	return {
		status,
		getFirstError,
		trigger,
		removeError,
	};
};
