import { useTranslation } from 'react-i18next';
import { Button, Popover, Select, Space } from 'antd';
import Close16pxActiveIcon from '@/icons/close-16px-active-icon';
import Close16pxIcon from '@/icons/close-16px-icon';
import Sort16pxActiveIcon from '@/icons/sort-16px-active-icon';
import Sort16pxIcon from '@/icons/sort-16px-icon';
import { useBoolean, useSetState } from 'ahooks';
import { Gap } from '@/components/gap';
import { FormState } from '../type';

export enum SortOptionKeys {
	final_port_id = 'final_port_id',
	warehouse_id = 'warehouse_id',
	final_port_eta = 'final_port_eta',
	lfd = 'lfd',
	schedule_terminal_pickup_at = 'schedule_terminal_pickup_at',
	actual_terminal_pickuped_at = 'actual_terminal_pickuped_at',
}

export interface SortProps {
	value: FormState['sort'];
	onChange: (value: FormState['sort']) => void;
}

export const Sort = ({ value, onChange }: SortProps) => {
	const { t } = useTranslation();

	const hasValue = !!value.sort_by && !!value.sort_value;

	const [localValue, setLocalValue] = useSetState<SortProps['value']>(value);

	const [open, { set }] = useBoolean();

	const options: { label: string; value: SortOptionKeys }[] = [
		{
			label: t(`IR`),
			value: SortOptionKeys.final_port_id,
		},
		{
			label: t(`Destination`),
			value: SortOptionKeys.warehouse_id,
		},
		{
			label: t(`Final Port ETA`),
			value: SortOptionKeys.final_port_eta,
		},
		{
			label: t(`LFD`),
			value: SortOptionKeys.lfd,
		},
		{
			label: t(`Est. Pickup`),
			value: SortOptionKeys.schedule_terminal_pickup_at,
		},
		{
			label: t(`Actual Pickup`),
			value: SortOptionKeys.actual_terminal_pickuped_at,
		},
	];

	const confirm = () => {
		const isValidLocalValue = !!localValue.sort_by && !!localValue.sort_value;
		onChange(isValidLocalValue ? localValue : { sort_by: undefined, sort_value: undefined });
		set(false);
	};

	const content = (
		<Space size={16} direction='vertical' className='w-[520px]'>
			<div className='flex items-center'>
				{/* key */}
				<Select
					value={localValue.sort_by}
					placeholder={t(`Select`)}
					options={options}
					className='w-[160px]'
					onChange={(_v) => setLocalValue({ sort_by: _v })}
				/>

				<Gap width='12px' />

				{/* value */}
				<Select
					value={localValue.sort_value}
					placeholder={t(`Select`)}
					options={[
						{
							label: t(`Ascending`),
							value: 'asc',
						},
						{
							label: t(`Descending`),
							value: 'desc',
						},
					]}
					className='w-[320px]'
					onChange={(_v: 'asc' | 'desc') => setLocalValue({ sort_value: _v })}
				/>

				<Gap width='12px' />

				{/* close */}
				<Close16pxIcon
					className=' cursor-pointer'
					onClick={() => setLocalValue({ sort_by: undefined, sort_value: undefined })}
				/>
			</div>
			<div className='flex justify-between items-center'>
				<div></div>
				<Button type='primary' onClick={confirm}>
					{t(`Confirm`)}
				</Button>
			</div>
		</Space>
	);

	return (
		<Popover
			title=''
			trigger={'click'}
			placement='bottomLeft'
			autoAdjustOverflow
			arrow={false}
			content={content}
			open={open}
			overlayInnerStyle={{ padding: '24px' }}
			onOpenChange={set}
		>
			<div>
				{hasValue ? (
					<div className='box-border p-[0_12px] h-[32px] flex items-center rounded-[16px] bg-primary-light-04 body-4-m text-primary-regular'>
						<Sort16pxActiveIcon />
						<Gap width='8px' />
						<div>{t(`Sort`)}</div>
						<Gap width='8px' />
						<Close16pxActiveIcon
							className='cursor-pointer'
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								onChange({ sort_by: undefined, sort_value: undefined });
								setLocalValue({ sort_by: undefined, sort_value: undefined });
							}}
						/>
					</div>
				) : (
					<div
						style={{ backgroundColor: open ? '#E7E7E7' : 'white' }}
						className='box-border p-[0_12px] h-[32px] flex items-center border-[1px] border-solid border-grey-04 rounded-[16px] body-4-m text-grey-01'
					>
						<Sort16pxIcon />
						<Gap width='8px' />
						<div>{t(`Sort`)}</div>
					</div>
				)}
			</div>
		</Popover>
	);
};
