import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { SendMessageProps, SendMessageRes } from './interface';

export const sendMessage = async (props: SendMessageProps) => {
	return await axios.post<SendMessageRes>(`/newOrders/${props.path.orderId}/messages`, props.data, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
