import { forwardRef } from 'react';
import { Select, SelectProps } from 'antd';
import clsx from 'clsx';
import { BaseSelectRef } from 'rc-select';
import './index.less';

export default forwardRef<BaseSelectRef, SelectProps>(({ className, ...props }, ref) => (
	<Select
		{...props}
		ref={ref}
		className={clsx(props.variant !== 'borderless' && 'custom-select', className)}
	/>
));
