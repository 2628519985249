import { useTranslation } from 'react-i18next';

export const QuoteId = ({ quoteId }: { quoteId: number | string | undefined }) => {
	const { t } = useTranslation();

	return (
		<div className='foot-note-1-m'>
			{quoteId ? (
				<>
					<span>{`${t(`Quote ID`)}: `}</span>
					<span>{quoteId}</span>
				</>
			) : (
				<></>
			)}
		</div>
	);
};
