import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { SearchWarehouseProps, SearchWarehouseRes } from './interface';

export * from './interface';

export const searchWarehouse = async (props: SearchWarehouseProps) => {
	return await axios.get<SearchWarehouseRes>(`/newWarehouses/search`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params: props.params,
	});
};
