import { I18nextProvider, initReactI18next } from 'react-i18next';
import en from '@/i18n/locales/en-us.json';
import cn from '@/i18n/locales/zh-cn.json';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export const LANGUAGE_ZH_CN = 'zh-CN';
export const LANGUAGE_EN_US = 'en-US';

const resources = {
	[LANGUAGE_ZH_CN]: {
		translation: cn,
	},
	[LANGUAGE_EN_US]: {
		translation: en,
	},
};

i18n
	// 检测用户当前使用的语言
	// 文档: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// 注入 react-i18next 实例
	.use(initReactI18next)
	// 初始化 i18next
	// 配置参数的文档: https://www.i18next.com/overview/configuration-options
	.init({
		debug: !import.meta.env.PROD,
		fallbackLng: 'en-US',
		interpolation: {
			escapeValue: false,
		},
		resources,
		detection: {
			order: ['querystring', 'localStorage', 'navigator'],
			lookupQuerystring: 'lang',
		},
		parseMissingKeyHandler: (key: string, defaultValue: string) => key,
	});

export default i18n;

export const updateLanguagePack = (language, key, value) => {
	const currentLanguagePack = i18n.getResourceBundle(language, 'translation');

	if (currentLanguagePack[key] !== value) {
		const updatedResources = { ...currentLanguagePack };
		updatedResources[key] = value;

		i18n.addResourceBundle(language, 'translation', updatedResources, true, true);
	}
};
