import { InputProps } from 'antd';
import clsx from 'clsx';
import { default as BaseInput } from '@/components/input';

export const Input = ({ className, ...props }: InputProps) => (
	<BaseInput
		{...props}
		className={clsx('p-[4px_8px_4px_8px] min-w-[115px] w-full rounded-[4px]', className)}
	/>
);
