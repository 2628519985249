import { useTranslation } from 'react-i18next';
import UserInterfaceIcon from '@/icons/user-interface-icon';

const RejectionReason = ({ reason }: { reason: string }) => {
	const { t } = useTranslation();

	return (
		<div className='flex h-[32px] w-[100%] leading-[32px]'>
			<div className='h-[32px] w-[5px] rounded-[4px_0_0_4px] bg-[#FFD596]'></div>

			<div className='flex-grow pl-[10px] h-full bg-[#fef8ed]'>
				<UserInterfaceIcon width={13} height={13} className='mr-[5px]' />
				<span className='font-[400] text-[12px] text-[#8F90A6]'>
					{t('Rejection Reason')}: {reason}
				</span>
			</div>
		</div>
	);
};

export default RejectionReason;
