import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { SearchIntermodalRegionsByCityProps, SearchIntermodalRegionsByCityRes } from './interface';

export const searchIntermodalRegionsByCity = async (props: SearchIntermodalRegionsByCityProps) => {
	return await axios.get<SearchIntermodalRegionsByCityRes>(`/intermodalRegions/allByCity`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params: props.params,
	});
};
