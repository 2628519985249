import { useTranslation } from 'react-i18next';
import { Button, Input, message, Modal } from 'antd';
import Shining10pxIcon from '@/icons/shining-10px-icon';
import { useBoolean, useMemoizedFn, useSafeState } from 'ahooks';
import { Gap } from '@/components/gap';

export const AIAutofillBtn = ({
	handleAIAutofill,
}: {
	handleAIAutofill: (content: string) => void | Promise<void>;
}) => {
	const { t } = useTranslation();

	const [content, setContent] = useSafeState('');

	const [openingModal, { setTrue: openModal, setFalse: closeModal }] = useBoolean(false);

	const [loading, { setTrue: startLoading, setFalse: endLoading }] = useBoolean(false);
	const handleContentKeydown = (e) => {
		if (e.key === 'Tab') {
			e.preventDefault();
			if (!content) {
				setContent(t('AUTOFILL_PLACEHOLDER_TEXT'));
			}
		}
	};

	const _handleAIAutofill = useMemoizedFn(async () => {
		try {
			startLoading();
			await handleAIAutofill(content);
		} catch (err) {
			message.error(err.data?.message || err.data?.error);
		} finally {
			endLoading();
			closeModal();
		}
	});

	return (
		<>
			<Button
				style={{ backgroundColor: '#6B33FE', border: 'unset' }}
				className='flex gap-[4px] items-center'
				onClick={() => {
					openModal();
				}}
			>
				<Shining10pxIcon />
				<span className='body-4-m text-white'>AI {t(`Autofill`)}</span>
			</Button>
			{openingModal && (
				<Modal
					open={openingModal}
					title={t(`Autofill`) + ' ' + t(`Information`)}
					okText={t(`Confirm`)}
					cancelText={t(`Cancel`)}
					closable
					confirmLoading={loading}
					okButtonProps={{ className: 'p-[5px_20px] h-fit body-3-sb text-white' }}
					cancelButtonProps={{ className: 'p-[5px_20px] h-fit body-3-sb' }}
					onCancel={() => closeModal()}
					onOk={() => _handleAIAutofill()}
				>
					<Input.TextArea
						rows={16}
						placeholder={t('AUTOFILL_PLACEHOLDER_TEXT')}
						className='mt-[24px]'
						value={content}
						onKeyDown={handleContentKeydown}
						onChange={(e) => setContent(e.target.value)}
					/>
					<Gap height='12px' />
					<div className='foot-note-1-r text-grey-01'>
						<div>{t(`Disclaimer`)}:</div>
						<div>
							{t(
								`Please double-check all entries to ensure accuracy before finalizing any documents or submissions. We appreciate your understanding and encourage you to verify all details provided by the AI autofill feature.`,
							)}
						</div>
					</div>
				</Modal>
			)}
		</>
	);
};
