import React from 'react';
import DropActiveSvg from '@/assets/drop-active.svg';

const DropIcon = (
	props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
) => {
	return <img {...props} src={DropActiveSvg} />;
};

export default DropIcon;
