import { Gap } from '@/components/gap';

export const ItemTitle = ({
	content,
	required,
	value,
}: {
	content: string;
	required?: boolean;
	value: React.ReactNode;
}) => {
	return (
		<>
			<div className='body-3-r text-grey-01'>
				{content}
				{required && <span className=' text-updated-red'>*</span>}
			</div>
			<Gap height='4px' />
			<div className='body-3-m text-[#333333]'>{value}</div>
		</>
	);
};
