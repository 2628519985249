import drayageImg from '@/assets/drayage.png';
import logoImg from '@/assets/logo.png';
import Introduction from './components/introduction';
import LoginForm from './components/login-form';
import './index.less';

const Login = () => {
	return (
		<div
			className='w-screen h-screen'
			style={{
				background: `linear-gradient(118.01deg, #002B5B, #001A31)`,
				// backgroundImage: ``,
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'bottom right',
			}}
		>
			<img src={logoImg} className=' absolute top-[43px] left-[47px]' />
			<img src={drayageImg} className=' absolute right-[60px] bottom-[42px]' />

			<div className='w-screen h-screen flex justify-center pt-[128px]  gap-x-[128px]'>
				<Introduction />
				<LoginForm />
			</div>
		</div>
	);
};

export default Login;
