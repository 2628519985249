import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { FavoriteProjectProps, FavoriteProjectRes } from './interface';

export const favoriteProject = async (props: FavoriteProjectProps) => {
	return await axios.patch<FavoriteProjectRes>(`/projects/${props.path.projectId}/favorite`, null, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
