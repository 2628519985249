import iconFactory from './icon-factory';

const Svg = () => (
	<svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<rect x='0.5' y='0.5' width='21' height='21' rx='10.5' fill='white' />
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M10.4934 7.93743C9.85237 7.68533 9.55951 7.01267 9.83928 6.43501C10.1191 5.85736 10.8655 5.59345 11.5066 5.84556C11.9683 6.02715 12.2667 6.4381 12.2664 6.89208C12.2918 7.35221 11.9848 7.77453 11.5066 7.93743V9.00332L13.0263 9.11744V9.68805L11.5066 9.80217V13.9105C11.4997 14.2886 11.8343 14.6002 12.2539 14.6064C12.304 14.6071 12.354 14.6034 12.4032 14.5952C13.3936 14.4241 14.2725 13.9927 14.7234 13.4791L14.0395 12.8264L15.8125 12.1416C15.8125 15.7935 12.155 15.1088 11 16.25C9.845 15.1088 6.1875 15.7935 6.1875 12.1416L7.96053 12.8264L7.27664 13.4586C7.7275 13.9721 8.60641 14.4035 9.59678 14.5747C10.0096 14.6428 10.4055 14.3965 10.4811 14.0245C10.4901 13.9802 10.4942 13.9351 10.4934 13.89V9.78163L8.97368 9.66751V9.0969L10.4934 8.98278L10.4934 7.93743Z'
			fill='#003166'
			stroke='#003166'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
		<rect x='0.5' y='0.5' width='21' height='21' rx='10.5' stroke='#003166' />
	</svg>
);

export default iconFactory(Svg);
