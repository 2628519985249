import { useTranslation } from 'react-i18next';
import { Modal, ModalProps, Tabs, TabsProps } from 'antd';
import BlueCircle18pxIcon from '@/icons/blue-circle-18px-icon';
import { useBoolean } from 'ahooks';

const ListItem = ({ title, content }: { title: string; content: string }) => (
	<div className='grid grid-cols-[26px_1fr] grid-rows-[26px_1fr]'>
		<BlueCircle18pxIcon />
		<div>{title}</div>
		<div></div>
		<div>{content}</div>
	</div>
);

export const ServiceDetailModal = (props: ModalProps) => {
	const { t } = useTranslation();

	const items: TabsProps['items'] = [
		{
			key: '1',
			label: t(`Attentions`),
			children: (
				<div className='flex flex-col items-start gap-[16px]'>
					<ListItem
						title={t(
							'Please inform the warehouse to print the BOL in advance and hand it over to the driver',
						)}
						content={t(
							'Kindly notify the pickup warehouse to print the Bill of Lading (BOL) in advance and hand it to the driver at the time of pickup. Otherwise, the driver will not collect the goods, which may incur a dry run fee.',
						)}
					/>
					<ListItem
						title={t('Delivery time is estimated')}
						content={t(
							'The delivery time in the order is an estimated time. It may be delayed due to uncontrollable factors such as traffic, weather, loading delays, and transit delays.',
						)}
					/>
					<ListItem
						title={t('When the goods are abnormal, it needs to be noted on the POD')}
						content={t(
							`Please remind the recipient: If there are any issues with the goods, such as damage or missing items, the recipient must note the specific details on the carrier's POD (Proof of Delivery) and keep a copy. Otherwise, no further claims can be processed. If there is only one POD, the recipient should make a note of the issue and take a photo for record-keeping.`,
						)}
					/>
					<ListItem
						title={t('Explanation of the POD aging period')}
						content={t(
							'After the delivery is completed, the POD (Proof of Delivery) will be returned by the carrier, which is expected to take 1-2 business days. We sincerely apologize for any inconvenience this may cause and kindly ask for your patience.',
						)}
					/>
				</div>
			),
		},
	];

	return (
		<Modal
			{...props}
			cancelButtonProps={{ className: 'hidden' }}
			okText={t('Continue')}
			width={'1000px'}
		>
			<div>
				<div className='h4-b text-black font-[700]'>{t(`Service Detail`)}</div>

				<Tabs defaultActiveKey='1' items={items} />
			</div>
		</Modal>
	);
};
