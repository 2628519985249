import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { DraftOrCreateOrderProps, DraftOrCreateOrderRes } from './interface';

export const draftOrCreateOrder = async (props: DraftOrCreateOrderProps) => {
	return await axios.post<DraftOrCreateOrderRes>(`/newOrders`, props.data, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
