import { useTranslation } from 'react-i18next';
import { SingleColumnTable } from './single-column-table';

export const CannotShippedTable = () => {
	const { t } = useTranslation();

	const column1 = [
		{ text: t('Boats and mobile homes') },
		{ text: t('Commodities of extraordinary or unusual value') },
		{ text: t('Firearms, explosives, ammunition') },
		{ text: t('Garbage, refuse, trash') },
		{ text: t('Household goods') },
		{ text: t('Illegal goods') },
		{ text: t('Livestock') },
		{ text: t('Oversize or overweight loads requiring special highway permits and/or escorts') },
	];

	const column2 = [
		{ text: t('Tobacco') },
		{ text: t('Intermodal shipments, but for drayage') },
		{ text: t('Hazardous materials, dangerous goods, chemicals') },
		{ text: t('Glass and glazing products') },
		{ text: t('Shipments brokered to another motor carrier') },
		{ text: t('Shipments not moving entirely by one motor carrier') },
	];

	return (
		<div className='w-full h-full flex items-start gap-[16px]'>
			<SingleColumnTable dataSource={column1} />

			<SingleColumnTable dataSource={column2} />
		</div>
	);
};
