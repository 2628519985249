import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { DeleteDocProps, DocProps, ViewDocProps } from './interface';

export const uploadDocument = async (props: DocProps) => {
	return await axios.post(`/newDocuments/${props.path.target}/${props.path.targetId}`, props.data, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
			'Content-Type': 'multipart/form-data',
		},
	});
};

export const getDocuments = async (props: DocProps) => {
	return await axios.get(`/newDocuments/${props.path.target}/${props.path.targetId}`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};

export const getDocument = async (props: ViewDocProps) => {
	return await axios.get(
		`/newDocuments/${props.path.uid}/${props.path.target}/${props.path.targetId}/url`,
		{
			headers: {
				Authorization: accessTokenManager.getAccessToken(),
			},
		},
	);
};

export const deleteDocument = async (props: DeleteDocProps) => {
	return await axios.delete(
		`/newDocuments/${props.path.uid}/${props.path.target}/${props.path.targetId}`,
		{
			headers: {
				Authorization: accessTokenManager.getAccessToken(),
			},
		},
	);
};
