import { TextAreaProps } from 'antd/es/input';
import { observer } from 'mobx-react-lite';
import { default as BaseInput } from '@/components/input';
import TextArea from '@/components/text-area';
import { InputProps } from './type';

export const Input = observer((props: InputProps) => {
	return <BaseInput {...props} classNames={{ input: ' hover:border-e-2 focus:border-e-2' }} />;
});

export const InputTextArea = observer((props: TextAreaProps) => {
	return <TextArea {...props} classNames={{ textarea: ' hover:border-e-2 focus:border-e-2' }} />;
});
