import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetQuoteProps, GetQuoteRes } from './interface';

export * from './interface';

export const getQuote = async (props: GetQuoteProps) => {
	return await axios.get<GetQuoteRes>(`/tl/ltl/quotes/${props.path.id}`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
