import { baseURL } from '..';
import { downloadFactory } from '../download-factory';
import { DownloadFtlFileProps } from './interface';

export const downloadFtlFile = async (
	props: DownloadFtlFileProps,
	config?: { preview?: boolean },
) => {
	const { documentId, shipmentId, fileName } = props;
	const url = baseURL + `newDocuments/${documentId}/ftl_shipment/${shipmentId}`;

	downloadFactory(url, fileName, { preview: config?.preview });
};
