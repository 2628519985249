import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMemoizedFn } from 'ahooks';
import qs from 'qs';
import valueTransfer from '@/utils/value-transfer';

export interface AgentWalletQuery {
	per_page?: number;
	page?: number;

	sort_by?: string;
	sort_value?: 'desc' | 'asc';

	search_keyword?: string;
	status?: number;
}

export default () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const parsedSearchParams = useMemo(() => {
		const searchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });

		const res = {};

		valueTransfer(res, 'per_page', searchParams.per_page, (v) => (v ? Number(v) : 20));
		valueTransfer(res, 'page', searchParams.page, (v) => (v ? Number(v) : 1));

		valueTransfer(res, 'sort_by', searchParams.sort_by, (v) => v ?? undefined);
		valueTransfer(res, 'sort_value', searchParams.sort_value, (v) => v ?? 'desc');

		valueTransfer(res, 'search_keyword', searchParams.search_keyword, (v) => v ?? '');
		valueTransfer(res, 'status', searchParams.status, (v) => v ?? undefined);

		return res as Partial<AgentWalletQuery>;
	}, [searchParams]);

	const changeSearchParams = useMemoizedFn((values: any) => {
		setSearchParams(qs.stringify({ ...parsedSearchParams, ...values }));
	});

	return {
		parsedSearchParams,
		changeSearchParams,
	};
};
