import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetCommodityProps } from './interface';

export const getCommodities = async () => {
	return await axios.get<GetCommodityProps>(`/commodities`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
