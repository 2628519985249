import iconFactory from './icon-factory';

const _Svg = () => (
	<svg width='80' height='80' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M11.9966 0.666687V5.70561H12.5062C13.3498 5.70561 13.8084 6.36803 13.8537 7.04178C13.8763 7.38148 13.7857 7.69287 13.5876 7.91368C13.3894 8.14015 13.0724 8.31 12.5062 8.31C12.2231 8.31 11.8777 8.15147 11.623 7.89103C11.3625 7.63626 11.204 7.29089 11.204 7.0078H10.1849C10.1849 7.63059 10.4793 8.1911 10.8983 8.61573C11.3229 9.0347 11.8834 9.32911 12.5062 9.32911C13.2988 9.32911 13.9443 9.04602 14.3462 8.59308C14.7539 8.13449 14.9124 7.54001 14.8728 6.97383C14.8048 5.98304 14.1141 4.98204 13.0157 4.74199V0.666687H11.9966ZM9.28467 8.62139L1.48509 13.7452H3.33647L9.83951 9.47065L9.28467 8.62139ZM15.7277 8.62139L15.1728 9.47065L21.6782 13.7452H23.5239L15.7277 8.62139ZM0.333496 14.7644V27.3334H24.6789V14.7644H0.333496ZM2.48495 16.0665H3.50406V26.0312H2.48495V16.0665ZM5.20257 16.0665H6.22168V26.0312H5.20257V16.0665ZM7.92019 16.0665H8.9393V26.0312H7.92019V16.0665ZM10.6378 16.0665H11.6569V26.0312H10.6378V16.0665ZM13.3554 16.0665H14.3745V26.0312H13.3554V16.0665ZM16.0731 16.0665H17.0922V26.0312H16.0731V16.0665ZM18.7907 16.0665H19.8098V26.0312H18.7907V16.0665ZM21.5083 16.0665H22.5274V26.0312H21.5083V16.0665Z'
			fill='#999999'
		/>
	</svg>
);

export default iconFactory(_Svg);
