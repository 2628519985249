import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import Design48pxIcon from '@/icons/design-48px-icon';
import IrCity48pxActiveIcon from '@/icons/ir-city-48px-active-icon';
import IrCity48pxIcon from '@/icons/ir-city-48px-icon';
import Pod48pxActiveIcon from '@/icons/pod-48px-active-icon';
import Pod48pxIcon from '@/icons/pod-48px-icon';
import TimeRange48pxIcon from '@/icons/time-range-48px-icon';
import Warehouse48pxIcon from '@/icons/warehouse-48px-icon';
import { ProjectCriteriaTypeEnum } from '@/interfaces/project-criteria-type.enum';
import { useMemoizedFn, useSafeState } from 'ahooks';
import { Gap } from '@/components/gap';
import { TabSwitcher, useTabSwitcher } from '@/components/tab-switcher';
import { CreateProjectPanelValue } from '..';
import { Buttons, ButtonsProps } from '../buttons';
import { ProjectTagGroup } from '../project-tags';

export interface SecondPanelProps extends Pick<ButtonsProps, 'goPre' | 'goNext'> {
	projectName: string;
	value: Partial<CreateProjectPanelValue>;
	goIndex: (index: number) => void;
	clearData: (type: ProjectCriteriaTypeEnum) => void;
}

export interface ItemProps {
	index: number;
	icon: React.ReactNode;
	activeIcon: React.ReactNode;
	label: string;
	status: string;
	desc: string;
	value: string[];
}

const Render = ({
	index,
	icon,
	activeIcon,
	label,
	status,
	desc,
	value,
	isSelected,
	onSelect,
}: ItemProps & { isSelected: boolean; onSelect: (index: number) => void }) => {
	return (
		<div
			style={{
				border: isSelected ? '2px solid #007BFF' : '1px solid #97999C',
			}}
			className='box-border p-[16px] w-full flex rounded-[8px]'
			onClick={() => onSelect(index)}
		>
			{/* icon */}
			<div className='w-[48px] h-[48px]'>{isSelected ? activeIcon : icon}</div>

			<Gap width='24px' />

			{/* content */}
			<div>
				<div className='flex items-center'>
					<div className='body-3-b text-grey-01'>{label}</div>
					<Gap width='10px' />
					<div className='foot-note-1-b text-additional-green'>{status}</div>
				</div>

				<div className='body-4-r text-grey-01'>{desc}</div>

				<Gap height='6px' />

				{/* tags */}
				<ProjectTagGroup tags={value.map((v) => ({ label: v }))} />
			</div>
		</div>
	);
};

export const SecondPanel = ({
	projectName,
	value,
	goIndex,
	clearData,
	goPre,
	goNext,
}: SecondPanelProps) => {
	const { t } = useTranslation();

	const [selectedIndex, setSelectedIndex] = useSafeState<number>(2);

	const { item1, item2, tabSwitcherValue, onTabSwitcherChange } = useTabSwitcher(
		{
			label: t(`Ongoing Criteria`),
			value: ProjectCriteriaTypeEnum.ongoing,
		},
		{
			label: t(`Manual Criteria`),
			value: ProjectCriteriaTypeEnum.manual,
		},
		ProjectCriteriaTypeEnum.ongoing,
	);

	const [modal, contextHolder] = Modal.useModal();

	const handleSwitchTab = useMemoizedFn(async (type: ProjectCriteriaTypeEnum) => {
		let needConfirm = false;

		const { final_ports, warehouse_cities, warehouses, time_ranges, containers } = value;
		if (tabSwitcherValue === ProjectCriteriaTypeEnum.ongoing) {
			needConfirm =
				final_ports.length > 0 ||
				warehouse_cities.length > 0 ||
				warehouses.length > 0 ||
				Object.keys(time_ranges).length > 0;
		} else {
			needConfirm = containers.length > 0;
		}

		if (needConfirm) {
			const confirmed = await modal.confirm({
				title: null,
				content: t(`It will clear all data. Sure to switch to criteria?`),
				okText: t(`Yes`),
				cancelText: t(`Cancel`),
			});

			if (!confirmed) return;
		}

		clearData(tabSwitcherValue);
		if (type === ProjectCriteriaTypeEnum.ongoing) {
			setSelectedIndex(3);
		} else {
			setSelectedIndex(6);
		}
		onTabSwitcherChange(type);
	});

	const items: (ItemProps & { type: ProjectCriteriaTypeEnum })[] = [
		{
			index: 3,
			type: ProjectCriteriaTypeEnum.ongoing,
			icon: <Pod48pxIcon />,
			activeIcon: <Pod48pxActiveIcon />,
			label: t('POD'),
			status: t('Ongoing Criteria'),
			desc: t('You can choose multiple port.'),
			value: value.port_of_discharges.map((i) => i.name),
		},
		{
			index: 4,
			type: ProjectCriteriaTypeEnum.ongoing,
			icon: <IrCity48pxIcon />,
			activeIcon: <IrCity48pxActiveIcon />,
			label: t('IR City'),
			status: t('Ongoing Criteria'),
			desc: t('You can choose multiple port cities, starting points of truck journey.'),
			value: value.final_ports.map((i) => i.name),
		},
		{
			index: 5,
			type: ProjectCriteriaTypeEnum.ongoing,
			icon: <Warehouse48pxIcon />,
			activeIcon: <Warehouse48pxIcon active />,
			label: t('Destination'),
			status: t('Ongoing Criteria'),
			desc: t('You can choose destination cities or warehouses.'),
			value: value.warehouses.map((i) => i.name),
		},
		{
			index: 6,
			type: ProjectCriteriaTypeEnum.ongoing,
			icon: <TimeRange48pxIcon />,
			activeIcon: <TimeRange48pxIcon active />,
			label: t('Time Range'),
			status: t('Ongoing Criteria'),
			desc: t('You can select multiple time range.'),
			value: Object.values(value.time_ranges).map((i) => `${i.start}-${i.end}`),
		},
		{
			index: 7,
			type: ProjectCriteriaTypeEnum.manual,
			icon: <Design48pxIcon />,
			activeIcon: <Design48pxIcon active />,
			label: t('Your Own MBLs'),
			status: t('Manual Criteria'),
			desc: t('You can choose your own MBLs manually in a project.'),
			value: value.containers.map((i) => i.number),
		},
	];

	useEffect(() => {
		const newIndex = items.find((i) => i.type === tabSwitcherValue);
		setSelectedIndex(newIndex.index);
	}, [tabSwitcherValue]);

	return (
		<>
			<div className='w-full h-full flex flex-col'>
				<div className='flex justify-between items-center'>
					<div className='h2-b text-black'>{projectName}</div>
					<TabSwitcher
						item1={item1}
						item2={item2}
						value={tabSwitcherValue}
						onChange={handleSwitchTab}
					/>
				</div>
				<Gap height='28px' />
				<div className='grow shrink'>
					{items
						.filter((item) => item.type === tabSwitcherValue)
						.map((item) => (
							<>
								<Render
									index={item.index}
									isSelected={item.index === selectedIndex}
									onSelect={() => setSelectedIndex(item.index)}
									{...item}
								/>
								<Gap height='16px' />
							</>
						))}
				</div>

				<Buttons
					preventDefaultClick2
					onClick2={() => goIndex(selectedIndex)}
					goPre={goPre}
					goNext={goNext}
				/>
			</div>
			{contextHolder}
		</>
	);
};
