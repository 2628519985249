import { useLayoutEffect } from 'react';
import { useBoolean, useSize } from 'ahooks';

export const useWindowSize = () => {
	const size = useSize(document.getElementById('root'));

	const [isWidth, { setTrue, setFalse }] = useBoolean(false);

	useLayoutEffect(() => {
		size.width >= 1920 ? setTrue() : setFalse();
	}, [size]);

	return {
		...size,
		isWidth,
	};
};
