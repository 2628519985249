import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Gap } from '@/components/gap';
import { ProjectContainersColumnChart } from '@/components/project-containers-column-chart';
import { TabSwitcher, useTabSwitcher } from '@/components/tab-switcher';
import { apiHooks } from '@/hooks/apis';
import { generatePreMonthIndex } from '@/utils/generate-pre-month-index';

export const MonthlyContainerVolumePanel = () => {
	const { t } = useTranslation();

	const { projectId } = useParams();

	const { item1, item2, tabSwitcherValue, onTabSwitcherChange } = useTabSwitcher(
		{
			label: t(`Newly Ordered`),
			value: 'newly_ordered',
		},
		{
			label: t(`Returned`),
			value: 'returned',
		},
		'newly_ordered',
	);

	const { data } = apiHooks.useGetProjectMonthlyStatistics({
		defaultParams: [{ path: { projectId } }],
	});

	const items: typeof data.returned = generatePreMonthIndex(6).map((month) => {
		const targetData = (data?.[tabSwitcherValue] ?? []).find((d) => d.month === month);
		return {
			year: targetData?.year ?? `${month},`,
			month,
			total_containers: targetData?.total_containers ?? 0,
			increase: targetData?.increase ?? 0,
		};
	});

	return (
		<div className='h-full w-full flex flex-col'>
			<div className='flex-grow-0 flex-shrink-0 flex justify-between items-center '>
				<span className='body-3-b'>{t(`Monthly Container Volume`)}</span>
				<TabSwitcher
					item1={item1}
					item2={item2}
					value={tabSwitcherValue}
					onChange={onTabSwitcherChange}
				/>
			</div>
			<Gap height='16px' />
			<div className='grow shrink overflow-hidden'>
				<ProjectContainersColumnChart data={items} />
			</div>
		</div>
	);
};
