interface Item {
	length: number;
	width: number;
	height: number;
	weight: number;
	flippable: boolean;
	qty: number;
}

interface PackedItem extends Item {
	x: number;
	y: number;
	z: number;
	orientation: number[];
}

interface Bin {
	length: number;
	width: number;
	height: number;
	maxWeight: number;
	items: PackedItem[];
	spaces: Space[]; // 管理空间列表
	remainingWeight: number;
}

interface Space {
	x: number;
	y: number;
	z: number; // 高度坐标
	length: number;
	width: number;
	height: number; // 空间的可用高度
}

export interface PackingResult {
	bins: Bin[];
	totalBins: number;
}

function canFitInSpace(space: Space, _item: Item, orientation: number[], bin: Bin): boolean {
	const [l, w, h] = orientation;

	const fitsInSpace = l <= space.length && w <= space.width && h <= space.height;

	const fitsInBin =
		space.x + l <= bin.length && space.y + w <= bin.width && space.z + h <= bin.height;

	return fitsInSpace && fitsInBin;
}

function getOrientations(
	item: Item,
	binLength: number,
	binWidth: number,
	binHeight: number,
): number[][] {
	const orientations: number[][] = [];

	if (item.flippable) {
		// 可翻转的物品，考虑所有6种摆放方式
		orientations.push(
			[item.length, item.width, item.height],
			[item.length, item.height, item.width],
			[item.width, item.length, item.height],
			[item.width, item.height, item.length],
			[item.height, item.length, item.width],
			[item.height, item.width, item.length],
		);
	} else {
		// 不可翻转的物品，但可以在水平面上旋转
		orientations.push(
			[item.length, item.width, item.height],
			[item.width, item.length, item.height],
		);
	}

	// 过滤能放入容器的摆放方式
	return orientations.filter(
		(orientation) =>
			orientation[0] <= binLength && orientation[1] <= binWidth && orientation[2] <= binHeight,
	);
}

function calculateEfficiency(
	length: number,
	width: number,
	height: number,
	spaceLength: number,
	spaceWidth: number,
	spaceHeight: number,
): number {
	// 计算在各个方向上可以放置的箱子数量
	const lengthCount = Math.floor(spaceLength / length);
	const widthCount = Math.floor(spaceWidth / width);
	const heightCount = Math.floor(spaceHeight / height);

	// 计算总共可以放置的箱子数量
	const totalBoxes = lengthCount * widthCount * heightCount;

	// 计算利用率
	const volumeRatio =
		(totalBoxes * length * width * height) / (spaceLength * spaceWidth * spaceHeight);

	// 考虑平面利用率
	const areaRatio =
		(Math.min(lengthCount * length, spaceLength) * Math.min(widthCount * width, spaceWidth)) /
		(spaceLength * spaceWidth);

	// 综合考虑体积利用率和平面利用率
	return 0.6 * volumeRatio + 0.4 * areaRatio;
}

function findBestFit(spaces: Space[], item: Item, bin: Bin): [Space | null, number[] | null] {
	let bestSpace: Space | null = null;
	let bestOrientation: number[] | null = null;
	let maxEfficiency = 0;

	for (const space of spaces) {
		for (const orientation of getOrientations(item, bin.length, bin.width, bin.height)) {
			if (canFitInSpace(space, item, orientation, bin)) {
				const efficiency = calculateEfficiency(
					orientation[0],
					orientation[1],
					orientation[2],
					space.length,
					space.width,
					space.height,
				);
				if (efficiency > maxEfficiency) {
					maxEfficiency = efficiency;
					bestSpace = space;
					bestOrientation = orientation;
				}
			}
		}
	}

	if (bestSpace && bestOrientation) {
		return [bestSpace, bestOrientation];
	} else {
		return [null, null];
	}
}

function updateSpaces(
	spaces: Space[],
	usedSpace: Space,
	_item: Item,
	orientation: number[],
): Space[] {
	const [l, w, h] = orientation;
	const newSpaces: Space[] = [];

	const dx = usedSpace.length - l;
	const dy = usedSpace.width - w;
	const dz = usedSpace.height - h;

	// 创建新空间时，避免大小为0或负值的空间
	if (dx > 0) {
		newSpaces.push({
			x: usedSpace.x + l,
			y: usedSpace.y,
			z: usedSpace.z,
			length: dx,
			width: usedSpace.width,
			height: usedSpace.height,
		});
	}

	if (dy > 0) {
		newSpaces.push({
			x: usedSpace.x,
			y: usedSpace.y + w,
			z: usedSpace.z,
			length: l,
			width: dy,
			height: usedSpace.height,
		});
	}

	if (dz > 0) {
		newSpaces.push({
			x: usedSpace.x,
			y: usedSpace.y,
			z: usedSpace.z + h,
			length: l,
			width: w,
			height: dz,
		});
	}

	// 移除已使用的空间，添加新的空间
	return [...spaces.filter((s) => s !== usedSpace), ...newSpaces];
}

function createBin(length: number, width: number, height: number, maxWeight: number): Bin {
	return {
		length,
		width,
		height,
		maxWeight,
		items: [],
		spaces: [
			{
				x: 0,
				y: 0,
				z: 0,
				length,
				width,
				height,
			},
		],
		remainingWeight: maxWeight,
	};
}

function addItemToBin(bin: Bin, item: Item, space: Space, orientation: number[]): Bin {
	// const [l, w, h] = orientation;

	const updatedSpaces = updateSpaces(bin.spaces, space, item, orientation);

	const packedItem: PackedItem = {
		...item,
		x: space.x,
		y: space.y,
		z: space.z,
		orientation: orientation,
	};

	return {
		...bin,
		items: [...bin.items, packedItem],
		spaces: updatedSpaces,
		remainingWeight: bin.remainingWeight - item.weight,
	};
}

export function packItems(
	items: Item[],
	binLength: number,
	binWidth: number,
	binHeight: number,
	binMaxWeight: number,
): PackingResult {
	const expandedItems = items.flatMap((item) => Array(item.qty).fill({ ...item, qty: 1 }));

	// 按体积从大到小排序
	expandedItems.sort((a, b) => b.length * b.width * b.height - a.length * a.width * a.height);

	const bins: Bin[] = [];

	for (const item of expandedItems) {
		let packed = false;
		for (let i = 0; i < bins.length; i++) {
			if (bins[i].remainingWeight >= item.weight) {
				const [bestSpace, bestOrientation] = findBestFit(bins[i].spaces, item, bins[i]);
				if (bestSpace && bestOrientation) {
					bins[i] = addItemToBin(bins[i], item, bestSpace, bestOrientation);
					packed = true;
					break;
				}
			}
		}

		if (!packed) {
			const newBin = createBin(binLength, binWidth, binHeight, binMaxWeight);
			const [bestSpace, bestOrientation] = findBestFit(newBin.spaces, item, newBin);
			if (bestSpace && bestOrientation) {
				bins.push(addItemToBin(newBin, item, bestSpace, bestOrientation));
			} else {
				throw new Error('无法将物品放入新的容器');
			}
		}
	}

	return {
		bins,
		totalBins: bins.length,
	};
}
