import { useTranslation } from 'react-i18next';
import { Checkbox, Form, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { InfoCircleOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import { ResidentialAddressCheckboxProps } from './interface';

export const ResidentialAddressCheckbox = observer(
	({ onChange, formItemKey, className, ...props }: ResidentialAddressCheckboxProps) => {
		const { t } = useTranslation();

		const form = Form.useFormInstance();

		const handleClick = (e: CheckboxChangeEvent) => {
			e.preventDefault();
			e.stopPropagation();
			onChange && onChange(e);
			form.setFieldValue(formItemKey, e.target.checked);
		};

		return (
			<Checkbox
				{...props}
				className={className}
				defaultChecked={form.getFieldValue(formItemKey)}
				onChange={handleClick}
			>
				{t('Limited Access Area')}
				<Tooltip
					trigger='hover'
					className='ml-[4px]'
					title={t('Residential, downtown area, farm, military base, plaza etc')}
				>
					<InfoCircleOutlined />
				</Tooltip>
			</Checkbox>
		);
	},
);
