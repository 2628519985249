import iconFactory from './icon-factory';

const Svg = () => (
	<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<circle
			cx='10'
			cy='10'
			r='9.25'
			fill='white'
			stroke='url(#paint0_linear_919_16262)'
			stroke-width='1.5'
		/>
		<path
			d='M17.0711 17.0711C15.9082 18.2339 14.4765 19.092 12.9028 19.5694C11.3291 20.0468 9.662 20.1287 8.04909 19.8079C6.43618 19.487 4.92728 18.7734 3.65606 17.7301C2.38484 16.6868 1.39053 15.3462 0.761201 13.8268C0.131875 12.3075 -0.113038 10.6564 0.0481539 9.01982C0.209346 7.38322 0.771669 5.81165 1.68531 4.44429C2.59895 3.07693 3.83572 1.95599 5.28605 1.18078C6.73638 0.405565 8.35551 -3.15625e-06 10 0L10 10L17.0711 17.0711Z'
			fill='url(#paint1_linear_919_16262)'
		/>
		<defs>
			<linearGradient
				id='paint0_linear_919_16262'
				x1='-1.73516'
				y1='10'
				x2='20'
				y2='10'
				gradientUnits='userSpaceOnUse'
			>
				<stop stop-color='#0094FF' />
				<stop offset='1' stop-color='#0165FA' />
			</linearGradient>
			<linearGradient
				id='paint1_linear_919_16262'
				x1='-1.73516'
				y1='10'
				x2='20'
				y2='10'
				gradientUnits='userSpaceOnUse'
			>
				<stop stop-color='#0094FF' />
				<stop offset='1' stop-color='#0165FA' />
			</linearGradient>
		</defs>
	</svg>
);

export default iconFactory(Svg);
