import { useEffect, useRef } from 'react';
import { useInViewport } from 'ahooks';
import _ from 'lodash';

export type THeaderRender = (props: {
	title?: string;
	dataIndex?: string | string[];
	style?: React.CSSProperties;
	className?: string;
}) => JSX.Element;
export type TCellRender<D> = (props: {
	value: string;
	record: D;
	style?: React.CSSProperties;
	className?: string;
	onRowClick?: (record: D) => void;
}) => JSX.Element;
export interface TableProps<D> {
	columns: {
		key?: string;
		title: string;
		dataIndex: string | string[];
		type?: 'data' | 'extra';
		width?: 'flex' | string;
		CellRender?: TCellRender<D>;
		HeaderRender?: THeaderRender;
	}[];
	dataSource: D[];
	className?: string;
	commonCellStyle?: React.CSSProperties;
	commonCellClass?: string;
	commonHeaderClass?: string;
	commonBodyClass?: string;
	CellRender?: TCellRender<D>;
	HeaderRender?: THeaderRender;
	onNextPage?: () => void;
	onRowClick?: (record: D) => void;
}
const BaseHeaderRender: THeaderRender = ({ title, style, className }) => (
	<div style={style} className={className}>
		{title}
	</div>
);
const BaseCellRender: TCellRender<any> = ({ value, record, style, className, onRowClick }) => (
	<div style={style} className={className} onClick={() => onRowClick(record)}>
		{value}
	</div>
);
export function Table<D>({
	columns,
	dataSource,
	className,
	commonCellStyle,
	commonCellClass,
	commonHeaderClass,
	commonBodyClass,
	CellRender,
	HeaderRender,
	onNextPage,
	onRowClick,
}: TableProps<D>) {
	const ref = useRef(null);
	const [inViewport] = useInViewport(ref);
	useEffect(() => {
		inViewport && onNextPage && onNextPage();
	}, [inViewport]);

	return (
		<div
			style={{
				display: 'grid',
				gridTemplateColumns: columns
					.map((c) =>
						!c.width || c.width === 'flex' ? 'minmax(auto, 1fr)' : `minmax(${c.width}, ${c.width})`,
					)
					.join(' '),
			}}
			className={className ?? ''}
		>
			{/* Header */}
			{columns.map(({ title, dataIndex, HeaderRender: ColumnHeaderRender }) => {
				const Render = ColumnHeaderRender ?? HeaderRender ?? BaseHeaderRender;
				return (
					<Render
						title={title}
						dataIndex={dataIndex}
						style={commonCellStyle ?? {}}
						className={`${commonCellClass ?? ''} ${commonHeaderClass ?? ''}`}
					/>
				);
			})}

			{/* Body */}
			{dataSource.flatMap((record, index) =>
				columns.map(({ dataIndex, type, CellRender: ColumnCellRender }) => {
					const Render = ColumnCellRender ?? CellRender ?? BaseCellRender;

					const value = type === 'extra' ? '' : _.get(record, dataIndex, '');

					return (
						<Render
							value={value}
							record={record}
							style={commonCellStyle ?? {}}
							className={`${commonCellClass ?? ''} ${commonBodyClass ?? ''} ${
								onRowClick ? 'cursor-pointer' : ''
							}`}
							onRowClick={(...args) => !!onRowClick && onRowClick(...args)}
						/>
					);
				}),
			)}

			<div ref={ref}></div>
		</div>
	);
}
