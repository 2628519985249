import { Divider } from 'antd';
import { observer } from 'mobx-react-lite';
import { LinkStyleRadioGroupProps } from './interface';

export const LinkStyleRadioGroup = observer(
	({ options, onClick, value }: LinkStyleRadioGroupProps) => {
		return (
			<div>
				{options.map((o, index) => {
					return (
						<>
							<span
								key={o.value}
								className={`${
									value === o.value ? 'text-[#2D7BF2]' : 'text-[#B5B8D2]'
								} cursor-pointer`}
								onClick={(e) => onClick(e, o.value)}
							>
								{o.label}
							</span>
							{index !== options.length - 1 && <Divider type='vertical' />}
						</>
					);
				})}
			</div>
		);
	},
);
