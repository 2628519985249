import { useTranslation } from 'react-i18next';
import { Col, Form, message, Modal, Row } from 'antd';
import TruckIcon from '@/icons/truck-icon';
import WareHouseIcon from '@/icons/warehouse-icon';
import { ValidErrorField } from '@/interfaces/valid-error-field.interface';
import { useBookContext, useSearchRateContext } from '@/services/contexts';
import { observer } from 'mobx-react-lite';
import AddressSelect from './components/address-select';
import DeliveryTypeSelect, { DeliveryTypeSelectLabel } from './components/delivery-type-select';
import DatePicker from '@/components/date-picker';
import useBookQs from '../../hooks/use-book-qs';
import useValidError from '../../hooks/use-valid-error';
import { dateToLa, isTomorrowOrTodayOrEarlierLa } from '@/utils/today.type';
import BottomButtons from '../bottom-buttons';
import ErrorLabel from '../error-label';
import { IntermodalRegionSelect } from '../intermodal-region-select';
import Panel from '../panel';
import { PickupTerminalSelect } from '../pickup-terminal-select';
import { Title } from '../title';
import { RouteInfoProps } from './type';

const Item = Form.Item;

export const RouteInfo = observer(({ NextButton, SaveDraftButton }: RouteInfoProps) => {
	const { t } = useTranslation();

	const form = Form.useFormInstance();
	const searchRateContext = useSearchRateContext();

	const { parsedSearchParams } = useBookQs();

	const bookContext = useBookContext();

	const { addValidError, clearValidErrors, getError, getOneValidError } = useValidError();

	const validateFinalPortEta = async () => {
		let resolve;
		let reject;
		const p = new Promise((res, rej) => {
			resolve = res;
			reject = rej;
		});

		const finalPortEta = form.getFieldValue('final_port_eta');

		if (finalPortEta && isTomorrowOrTodayOrEarlierLa(finalPortEta)) {
			Modal.confirm({
				content: t(
					`This is a rush order, please confirm with our sales if wecan receive the order`,
				),
				okText: t('Sales Confirmed'),
				cancelText: t('Cancel'),
				onOk() {
					resolve();
				},
				onCancel() {
					reject(
						new Error(
							'This is a rush order, please confirm with our sales if wecan receive the order',
						),
					);
				},
			});
		} else {
			resolve();
		}

		try {
			await p;
		} catch (e: any) {
			throw {
				errorFields: [
					{
						errors: [
							'This is a rush order, please confirm with our sales if wecan receive the order',
						],
						name: ['final_port_eta'],
					},
				],
				outOfDate: false,
				values: { final_port_eta: finalPortEta },
			};
		}
	};
	const validateFields = async () => {
		try {
			const nameLists = [
				'port_of_discharge_id',
				'port_of_discharge_eta',
				'final_port_id',
				'final_port_eta',
				'live_or_drop',
				'warehouse',
			];

			await form.validateFields(nameLists);

			const warehouse = form.getFieldValue('warehouse');
			if (!warehouse) {
				message.error(t('Please Select Delivery Address'));
				throw t('Please Select Delivery Address');
			}
		} catch (error: any) {
			if (error.errorFields.length === 0) {
				clearValidErrors();
				return;
			}
			error.errorFields.forEach((field: ValidErrorField) => {
				const key = field.name.join('_');
				addValidError(key, field);
			});
			throw error;
		}
	};

	const footer = (
		<BottomButtons
			rightButton1={<SaveDraftButton />}
			rightButton2={
				<NextButton
					onClick={async () => {
						await validateFields();
						await validateFinalPortEta();
					}}
				/>
			}
			rightButton2Message={
				getOneValidError() ? (
					<ErrorLabel errorMsg={t('Please complete the highlighted fields')} />
				) : null
			}
		/>
	);

	return (
		<Panel footer={footer}>
			<Row style={{ margin: 0 }} gutter={80} className='h-full'>
				{/* left */}
				<Col span={12} className='flex flex-col' style={{ padding: 0 }}>
					{/* pick up */}
					<div>
						<Title title={t('Pickup')} Icon={TruckIcon} />
						<Row gutter={16}>
							<Col span={12}>
								<Item
									name='port_of_discharge_id'
									label={t('Port of Discharge')}
									rules={[{ required: true, message: t('Please enter Port of Discharge') }]}
									required
								>
									<IntermodalRegionSelect allowClear />
								</Item>
							</Col>
							<Col span={12}>
								<Item
									name='port_of_discharge_eta'
									required
									label={t('ETA')}
									rules={[{ required: true, message: t('Please enter ETA') }]}
								>
									<DatePicker
										className='w-full hover:border-e-1'
										placeholder='MM/DD/YYYY'
										format='YYYY-MM-DD'
									/>
								</Item>
							</Col>
						</Row>

						<Row gutter={16}>
							<Col span={12}>
								<Item
									name='final_port_id'
									label={t('Final port/ramp')}
									rules={[{ required: true, message: t('Please enter Final port/ramp') }]}
								>
									<IntermodalRegionSelect />
								</Item>
							</Col>
							<Col span={12}>
								<Item
									name='final_port_eta'
									label={t('ETA')}
									rules={[{ required: true, message: t('Please enter ETA') }]}
								>
									<DatePicker
										className='w-full hover:border-e-1'
										placeholder='MM/DD/YYYY'
										format='YYYY-MM-DD'
									/>
								</Item>
							</Col>
						</Row>

						{/* Pickup TerminalPickup Terminal */}
						<Item name='terminal_id' label={t('Pickup Terminal')}>
							<PickupTerminalSelect
								// @ts-ignore
								defaultTerminals={
									bookContext.useDraftOrder && !parsedSearchParams.useSearchContext
										? [bookContext.draftOrder?.terminal].filter((t) => !!t)
										: searchRateContext.selectedSearchData?.terminals
								}
							/>
						</Item>
					</div>

					{/* destination */}
					<div>
						<Title title={t('Destination')} Icon={WareHouseIcon} />

						<Item
							label={<DeliveryTypeSelectLabel />}
							name='live_or_drop'
							rules={[{ required: true, message: t('Please enter Delivery Type') }]}
						>
							<DeliveryTypeSelect formItemKey='live_or_drop' />
						</Item>
					</div>
				</Col>

				{/* right */}
				<Col span={12} className='flex flex-col flex-grow'>
					<Item
						noStyle
						name='warehouse'
						label={t('Search For an Address')}
						rules={[{ required: true, message: t('Please Select Delivery Address') }]}
					>
						<AddressSelect formItemKey='warehouse' wrapperClass='h-1 flex-grow flex flex-col' />
					</Item>
					{getError('warehouse') ? (
						<ErrorLabel errorMsg={getError('warehouse')?.errors?.[0]} />
					) : null}
				</Col>
			</Row>
		</Panel>
	);
});
