import { MouseEventHandler, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Input, InputRef } from 'antd';
import { useGlobalContext } from '@/services/contexts';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useBoolean, useSafeState } from 'ahooks';
import clsx from 'clsx';
import { Gap } from '@/components/gap';

export const IRFilter = ({
	selectedValue,
	setSelectedValue,
}: {
	selectedValue: number[];
	setSelectedValue: (v: number[]) => void;
}) => {
	const { t } = useTranslation();

	const { cacheContext } = useGlobalContext();

	const options = cacheContext.intermodalRegions.map((i) => ({
		label: i.name,
		value: i.id,
	}));

	const [isFocusing, { toggle }] = useBoolean(false);

	const [inputValue, setInputValue] = useSafeState('');

	const [isAllSelected, { toggle: toggleAllSelected }] = useBoolean();

	const inputRef = useRef<InputRef>(null);

	const handleChangeInputFocus: MouseEventHandler<HTMLDivElement> = () => {
		if (isFocusing) {
			setInputValue(
				isAllSelected
					? options[0].label
					: options.find((o) => selectedValue.includes(o.value))?.label ?? '',
			);
		} else {
			inputRef.current!.focus();
		}
		toggle();
	};

	const handleChangeInputValue = (v: string) => {
		setInputValue(v);
	};

	const handleItemClick = (value: number) => {
		const isSelected = selectedValue.includes(value);

		if (isSelected) {
			setSelectedValue(selectedValue.filter((v) => v !== value));
		} else {
			setSelectedValue([...selectedValue, value]);
		}
	};

	const handleAllClick = () => {
		if (isAllSelected) {
			setSelectedValue([]);
		} else {
			setSelectedValue(options.map((o) => o.value));
		}
		toggleAllSelected();
	};

	return (
		<div
			className={clsx(
				'w-[178px] h-[30px] bg-white',
				isFocusing && 'border-[1px] border-solid border-primary-regular rounded-[8px]',
			)}
		>
			{/* input */}
			<div className=' box-border p-[0_16px] w-[full] flex items-center body-4-m text-black'>
				<span className=' whitespace-nowrap'>
					{t(`IR`)}
					{`: `}
				</span>
				<Input
					ref={inputRef}
					variant='borderless'
					value={inputValue}
					className='w-[100px] grow'
					onChange={(e) => handleChangeInputValue(e.target.value)}
				/>
				<div className=' cursor-pointer' onClick={handleChangeInputFocus}>
					{isFocusing ? <UpOutlined /> : <DownOutlined />}
				</div>
			</div>

			{/* result */}
			{isFocusing && (
				<div className='z-50 foot-note-1-r max-h-[200px] overflow-y-scroll bg-white'>
					<div
						className={clsx(
							`box-border p-[0_16px] h-[32px] w-[178px] flex items-center hover:bg-primary-light-05 cursor-pointer`,
							isAllSelected && 'bg-primary-light-05',
						)}
						onClick={handleAllClick}
					>
						<Checkbox
							checked={isAllSelected}
							indeterminate={
								!isAllSelected && selectedValue.length > 0 && selectedValue.length < options.length
							}
						/>
						<Gap width='12px' />
						{t(`All`)}
					</div>
					{options
						.filter((o) => o.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()))
						.map((o) => (
							<div
								className={clsx(
									`box-border p-[0_16px] h-[32px] w-[178px] flex items-center hover:bg-primary-light-05 cursor-pointer`,
									(selectedValue.includes(o.value) || isAllSelected) && 'bg-primary-light-05',
								)}
								onClick={() => handleItemClick(o.value)}
							>
								<Checkbox checked={selectedValue.includes(o.value) || isAllSelected} />
								<Gap width='12px' />
								{o.label}
							</div>
						))}
				</div>
			)}
		</div>
	);
};
