import { Gap } from '@/components/gap';
import { ContainerTrackingPanel } from './container-tracking-panel';
import { DeliveryCountPanel } from './delivery-count-panel';
import { MonthlyContainerVolumePanel } from './monthly-container-volume';
import { MyFocusPanel } from './my-focus-panel';

export const OverviewPanel = () => {
	return (
		<div className='w-full h-full flex flex-col'>
			{/* 第一行 */}
			<div className='flex flex-grow flex-shrink overflow-hidden'>
				{/* 左侧 */}
				<div className='h-full flex-grow-0 flex-shrink-0 w-[285px] bg-white rounded-[8px] p-[24px_24px_16px_24px]'>
					<ContainerTrackingPanel />
				</div>

				{/* gap */}
				<Gap width='12px' />

				{/* 右侧 */}
				<div className='h-full w-[1300px] flex-grow flex-shrink overflow-hidden bg-white rounded-[8px] p-[24px]'>
					<MyFocusPanel />
				</div>
			</div>

			{/* gap */}
			<Gap height='12px' />

			{/* 第二行 */}
			<div className='flex-grow-0 flex-shrink-0 h-[348px]  flex'>
				{/* 左侧 */}
				<div className=' box-border h-full w-[554px] flex-grow bg-white rounded-[8px] p-[24px]'>
					<MonthlyContainerVolumePanel />
				</div>

				{/* gap */}
				<Gap width='12px' />

				{/* 右侧 */}
				<div className=' box-border h-full w-[610px] flex-grow bg-white rounded-[8px] p-[24px]'>
					<DeliveryCountPanel />
				</div>
			</div>
		</div>
	);
};
