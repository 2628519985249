import { useTranslation } from 'react-i18next';
import { Gap } from '@/components/gap';
import { CreateProjectPanelValue } from '..';
import { Buttons, ButtonsProps } from '../buttons';
import { ProjectTagGroup } from '../project-tags';
import { IrMultiSelector } from './ir-multi-selector';

export interface IrCityPanelProps extends Pick<ButtonsProps, 'goPre' | 'goNext'> {
	projectName: string;
	value: CreateProjectPanelValue['final_ports'];
	onChange: (value: IrCityPanelProps['value']) => void;
}

export const IrCityPanel = ({ projectName, value, onChange, goPre, goNext }: IrCityPanelProps) => {
	const { t } = useTranslation();

	return (
		<div className='w-full h-full flex flex-col'>
			<div className='h2-b text-black'>{projectName}</div>

			<Gap height='28px' />

			{/* content */}
			<div className=' box-border grow p-[16px] border-[1px] border-solid border-grey-02 rounded-[8px]'>
				{/* title */}
				<div className='body-3-b text-grey-01'>{t('Multi-Select Your IR Cites')}</div>

				<Gap height='16px' />

				{/* search */}
				<IrMultiSelector
					value={value.map((_v) => ({
						label: _v.name,
						value: _v.id,
					}))}
					onChange={(v) => {
						onChange(
							v.map((_v) => ({
								id: _v.value,
								name: _v.label,
							})),
						);
					}}
				/>

				<Gap height='16px' />

				{/* tags */}
				<ProjectTagGroup
					tags={value.map((v) => ({
						label: v.name,
						enableClose: true,
						onClose: () => onChange(value.filter((_v) => _v.id !== v.id)),
					}))}
				/>
			</div>

			<Gap height='22px' />

			<Buttons goPre={goPre} goNext={goNext} />
		</div>
	);
};
