import React from 'react';
import LiveSvg from '@/assets/live.svg';

const LiveIcon = (
	props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
) => {
	return <img {...props} src={LiveSvg} />;
};

export default LiveIcon;
