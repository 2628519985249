import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Spin, Tooltip } from 'antd';
import EmptyAddressImg from '@/assets/man-inside-mark-location.png?url';
import ContactIcon from '@/icons/contact-icon';
import EditIcon from '@/icons/edit-icon';
import HouseIcon from '@/icons/house-icon';
import WareHouseIcon from '@/icons/warehouse-icon';
import { Warehouse } from '@/interfaces/warehouse.interface';
import { getWarehouses } from '@/services/apis';
import { GetWarehousesProps } from '@/services/apis/get-warehouses/interface';
import { useSearchRateContext } from '@/services/contexts';
import { userContext } from '@/services/contexts/user';
import { useDebounceFn } from 'ahooks';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useIntersection } from 'use-intersection';
import Input from '@/components/input';
import { RequiredLabel } from '@/components/required-label';
import { Title } from '../../../title';
import useAddressModal from '../use-address-modal';
import { AddressSelectProps } from './type';

export default observer(({ formItemKey, wrapperClass }: AddressSelectProps) => {
	const { t } = useTranslation();

	const form = Form.useFormInstance();

	const [searchText, setSearchText] = useState(null);

	const [isFetching, setIsFetching] = useState(false);

	const [warehouses, setWarehouses] = useState<Warehouse[]>([]);

	const userInfo = userContext.getUserInfo();

	const rootRef = useRef<HTMLDivElement>(null);

	const triggerRef = useRef<HTMLDivElement>(null);

	const intersecting = useIntersection(triggerRef, { root: rootRef, rootMargin: '-10px' });

	const [currentPage, setCurrentPage] = useState(1);
	const [lastPage, setLastPage] = useState(1);

	const isLoadMoreDone = currentPage >= lastPage;

	const { AddressModal, openAddressModal } = useAddressModal();

	const selectedAddress = Form.useWatch(formItemKey);

	const searchRateContext = useSearchRateContext();

	const isDifference = useMemo(() => {
		if (!selectedAddress || !searchRateContext.selectedToCity) {
			return false;
		}

		return selectedAddress?.city.id != searchRateContext?.selectedToCity?.id;
	}, [selectedAddress, searchRateContext.searchToCity]);

	const { run: handleSearch } = useDebounceFn(
		async (searchText: string) => {
			await fetchWarehouse(1, false);
		},
		{ wait: 500 },
	);

	const fetchWarehouse = async (page?: number, append?: boolean) => {
		page = page ?? currentPage + 1;

		if (page > lastPage) {
			return;
		}

		setIsFetching(true);

		const params: GetWarehousesProps['params'] = {
			per_page: 20,
			page,
		};

		if (searchText) {
			params.sort_by = 'updated_at';
			params.sort_value = 'desc';
			params.keyword = searchText;
		}

		const res = await getWarehouses({ params });

		const warehouses = res.data.data;
		const meta = res.data.meta;

		setCurrentPage(meta.current_page);
		setLastPage(meta.last_page);

		if (append) {
			setWarehouses((_warehouses) => [..._warehouses, ...warehouses]);
		} else {
			setWarehouses((_warehouses) => [...warehouses]);
		}

		setIsFetching(false);
	};

	useEffect(() => {
		// 初始化
		const preSelectedId = form.getFieldValue(formItemKey);
		!preSelectedId && form.setFieldValue(formItemKey, warehouses?.[0]);
		fetchWarehouse(1, false);
	}, []);

	useEffect(() => {
		if (intersecting) {
			fetchWarehouse(undefined, true);
		}
	}, [intersecting]);

	return (
		<div className={wrapperClass}>
			<Title
				title={<RequiredLabel label={t('Delivery Address')} markPosition='left' />}
				Icon={ContactIcon}
				extra={
					<Button type='link' onClick={() => openAddressModal()}>
						+ {t('Add Address')}
					</Button>
				}
			/>

			<div>
				<Input
					placeholder={t('Find a recent or saved address')}
					value={searchText}
					onChange={(v) => {
						setSearchText(v.target.value);
						handleSearch(v.target.value);
					}}
				/>
			</div>
			<div
				ref={rootRef}
				className='flex-grow pt-[10px] pl-[10px] pr-[10px] flex flex-col bg-[#C8E3FF40] overflow-y-scroll releative'
			>
				<Spin
					spinning={isFetching}
					style={{
						position: 'absolute',
						left: '50%',
						top: '26vh',
						transform: 'translate(-50%, -50%)',
					}}
				>
					{warehouses && !!warehouses.length ? (
						<>
							{warehouses.map((warehouse) => {
								const isSelected = selectedAddress?.id === warehouse.id;
								const handleSelect = () => {
									form.setFieldValue(formItemKey, warehouse);
								};
								return (
									<div
										className={`box-border mb-[10px] pt-[9px] pl-[21px] pr-[11px] pb-[20px] rounded-[6px] bg-[#FFFFFF] cursor-pointer ${
											isSelected ? 'border-[3px] border-solid border-[#007BFF]' : 'border-[#FFFFFF]'
										}`}
										onClick={handleSelect}
									>
										{/* header */}
										<div className='flex justify-between'>
											{/* left */}
											<div className='flex items-center'>
												{/* icon */}
												<div
													className={`flex justify-center items-center mr-[18px] w-[40px] h-[40px] border-[2px] border-solid rounded-[50%] ${
														isSelected ? 'border-[#007BFF]' : 'border-[#E2E2E2]'
													}`}
												>
													{isSelected ? (
														<WareHouseIcon active className='h-[18px] w-[18px]' />
													) : (
														<HouseIcon className='h-[18px] w-[18px]' />
													)}
												</div>

												{/* name */}
												<div className='font-[700] text-[16px]'>{warehouse.name}</div>
											</div>

											{/* right */}
											<div className='flex items-center'>
												{/* tag */}
												{/* {isSelected && (
												<div className='border-box mr-[13px] w-[80px] h-[26px] border-[1.5px] border-solid border-[#007BFF] rounded-[4px] text-[#007BFF] font-[700] text-[14px] text-center'>
													{t('Default')}
												</div>
											)} */}

												{/* edit */}

												{warehouse.user_id == userInfo?.id ? (
													<EditIcon
														className='cursor-pointer'
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();
															openAddressModal(warehouse);
														}}
													/>
												) : (
													<Tooltip
														title={`
													created by ${warehouse?.user?.name}`}
													>
														{warehouse?.user?.name}
													</Tooltip>
												)}
											</div>
										</div>

										{/* content */}
										<div className='font-[400] text-[16px] text-[#545454]'>
											<div>
												{warehouse.address},{warehouse.city?.name},{warehouse.city?.state}{' '}
												{warehouse.zipcode}
											</div>
											<div>
												{warehouse.contacts?.[0]?.name},{warehouse.contacts?.[0]?.phone}
											</div>
										</div>
									</div>
								);
							})}
						</>
					) : (
						<div className='flex justify-center items-center h-full w-full'>
							<div className='flex flex-col items-center'>
								<img src={EmptyAddressImg} />
								<div className='font-[400] text-[16px] text-[#003166]'>{t('No address found')}</div>
								<div
									className='font-[700] text-[18px] text-[#003166] underline cursor-pointer'
									onClick={() => openAddressModal()}
								>
									+ {t('Add a new address')}
								</div>
							</div>
						</div>
					)}
					<div ref={triggerRef} className='h-1'>
						{!isLoadMoreDone && !!warehouses.length && (
							<div className='text-center m-xs'>{t('Load More')}...</div>
						)}
					</div>
				</Spin>

				<AddressModal
					onAfterSave={async (warehouse: Warehouse) => {
						setSearchText(warehouse?.address);
						setWarehouses([]);
						handleSearch(warehouse?.address);
					}}
				/>
			</div>
			{isDifference && (
				<div className='mt-[8px]'>
					<span className='text-[#ff4d4f]'>
						{t(
							'The selected delivery warehouse address is different from the quotation city, please note that the rate may vary.',
						)}
					</span>
				</div>
			)}
		</div>
	);
});
