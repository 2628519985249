export interface SideCarouselItemProps {
	title: string;
	picNode: React.ReactNode;
	subTitle: string;
	content: string;
}

export const SideItem = ({ title, picNode, subTitle, content }: SideCarouselItemProps) => {
	return (
		<div className='h-full w-full flex flex-col text-primary-dark-01'>
			<div className='mb-[36px] h2-b '>{title}</div>
			{picNode && <div className='mb-[36px] h-[264px]'>{picNode}</div>}
			<div className='mb-[6px] h3-b text-primary-dark-01'>{subTitle}</div>
			<div className='body-3-r'>{content}</div>
		</div>
	);
};
