import ContainerReleaseStatusIcon from '@/components/container-release-status-icon';

interface customReleasedProps {
	actual_empty_returned_at: string | undefined | null;
	actual_terminal_pickuped_at: string | undefined | null | boolean;
	customs_released_at: string | undefined | null | boolean;
	port_of_discharge_eta: string | undefined | null;
}

export default ({
	actual_empty_returned_at,
	actual_terminal_pickuped_at,
	customs_released_at,
	port_of_discharge_eta,
}: customReleasedProps) => {
	return (
		<ContainerReleaseStatusIcon
			time={actual_empty_returned_at || actual_terminal_pickuped_at ? true : customs_released_at}
			eta={port_of_discharge_eta}
		/>
	);
};
