import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';
import { downloadLtlFile } from '@/services/apis/download-ltl-file';
import { useLTLContext } from '@/services/contexts';
import { CheckCircleOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { ContactUsBtn } from '@/components/contact-us-modal';
import { Gap } from '@/components/gap';
import { formatCamelCaseString } from '@/utils/format-camel-case-string';
import {
	ItemTitle,
	ShipmentInfoMap,
	ShipmentInfoPickupDate,
	ShipmentInfoRoute,
} from '../shipment-info';

export const PlacedOrder = observer(() => {
	const { t } = useTranslation();

	const ltlContext = useLTLContext();

	const {
		id,
		uid,
		estimated_pickup_date,
		create_date,
		sell_rate,
		vendor_carrier_name,
		vendor_documents,
	} = ltlContext.order;

	return (
		<div className='box-border p-[48px] w-full h-full flex flex-col overflow-hidden'>
			{/* header */}
			<div className='flex flex-col gap-[16px]'>
				<div className='flex items-center gap-[8px]'>
					<CheckCircleOutlined style={{ color: '#298540', fontSize: '32px' }} />
					<span className='h3-b text-[#298540]'>{t(`Order placed, thank you!`)}</span>
				</div>
				<div className='body-3-m text-black'>
					{t(
						`The order confirmation will be sent to your email. You can view your order at LTL - orders`,
					)}
				</div>
			</div>

			<Divider />

			<div className='flex items-center gap-[17px]'>
				<ShipmentInfoRoute />
				<ContactUsBtn />
			</div>
			<Gap height='8px' />
			<ShipmentInfoPickupDate value={dayjs(estimated_pickup_date).format('MM/DD/YYYY')} />
			<Gap height='24px' />
			<div className='flex items-center gap-[130px]'>
				<div>
					<ItemTitle content={t(`Order number`)} value={`# ${uid}`} />
				</div>
				<div>
					<ItemTitle content={t(`Created date`)} value={dayjs(create_date).format('MM/DD/YYYY')} />
				</div>
				<div>
					<ItemTitle content={t(`Total amount`)} value={sell_rate ? `$ ${sell_rate}` : '-'} />
				</div>
				<div>
					<ItemTitle content={t(`Carrier`)} value={vendor_carrier_name} />
				</div>
			</div>

			<Gap height='24px' />

			<div className='w-full h-[300px]'>
				<ShipmentInfoMap />
			</div>

			<Divider />

			<div className='h4-b text-theme-1-1-10'>{t(`Documents`)}</div>

			<Gap height='24px' />

			<div className='flex items-center gap-[130px]'>
				{vendor_documents.map((doc) => (
					<div className=' cursor-pointer'>
						<ItemTitle
							content={t(formatCamelCaseString(doc.name))}
							value={
								<div
									className='flex items-center gap-[8px]'
									onClick={() =>
										downloadLtlFile({ documentId: doc.id, fileName: doc.file_name, shipmentId: id })
									}
								>
									<span>{doc.name}</span>
									<CloudDownloadOutlined style={{ fontSize: '18px' }} />
								</div>
							}
						/>
					</div>
				))}
			</div>
		</div>
	);
});
