import { baseURL } from '..';
import { downloadFactory } from '../download-factory';
import { DownloadFileProps } from './interface';

export const downloadFile = async (props: DownloadFileProps, config?: { preview?: boolean }) => {
	const { documentId, draftDocumentId, orderId } = props.path;
	const url =
		baseURL +
		`newDocuments/${documentId}` +
		(orderId ? `/order_customer/${orderId}` : `/draft_documents/${draftDocumentId}`);

	downloadFactory(url, props.other.fileName, { preview: config?.preview });
};
