import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetOrderProps, GetOrderRes } from './interface';

export const getOrder = async (props: GetOrderProps) => {
	return await axios.get<GetOrderRes>(`/newOrders/${props.path.orderId}`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
