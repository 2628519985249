import { CSSProperties, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import BoatColorIcon from '@/icons/boat-color-icon';
import CargoColorIcon from '@/icons/cargo-color-icon';
import craneIcon from '@/icons/crane-icon';
import OrderLocationColorIcon from '@/icons/order-location-color-icon';
import OrderWarehouseColorIcon from '@/icons/order-warehouse-color-icon';
import progressAvaliableIcon from '@/icons/progress-avaliable-icon';
import progressDeliveredIcon from '@/icons/progress-delivered-icon';
import progressEmptyReturnedIcon from '@/icons/progress-empty-returned-icon';
import progressInOceanIcon from '@/icons/progress-in-ocean-icon';
import progressPickedUpIcon from '@/icons/progress-picked-up-icon';
import TrainColorIcon from '@/icons/train-color-icon';
import TruckColorIcon from '@/icons/truck-color-icon';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { Gap } from '@/components/gap';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

export enum ProgressMapKeysEnum {
	in_transit = 'in_transit',
	arriving = 'arriving',
	avaliable = 'avaliable',
	pickup = 'pickup',
	delivered = 'delivered',
	empty_returned = 'empty_returned',
	completed = 'completed',
	canceled = 'canceled',
}

interface ProgressLabelProps {
	type: ProgressMapKeysEnum;
	className?: string;
	style?: CSSProperties;
}

export const ProgressLabel = ({ type, className, style }: ProgressLabelProps) => {
	const { progressMap } = useProgressMap();

	const progressItem = progressMap[type];

	const { title, borderColor, bgColor, textColor, Icon } = progressItem ?? {};

	if (!progressItem) return <></>;

	return (
		<div
			style={{
				border: `1px solid ${borderColor}`,
				backgroundColor: bgColor,
				color: textColor,
				...(style ?? {}),
			}}
			className={clsx(
				'h-[20px] p-[2px_8px] flex justify-between items-center rounded-[10px]',
				className,
			)}
		>
			<Icon style={{ display: 'flex' }} className='w-[13px] h-[13px]' />

			<Gap width='4px' />
			<div className=' leading-[16px]'>{title}</div>
		</div>
	);
};

interface ProgressMapItem {
	title: string;
	borderColor: string;
	bgColor: string;
	textColor: string;
	Icon: (props?: Partial<CustomIconComponentProps>) => JSX.Element;
}

export const useProgressMap = () => {
	const { t } = useTranslation();

	const progressMap: Record<ProgressMapKeysEnum, ProgressMapItem> = useMemo(
		() => ({
			[ProgressMapKeysEnum.in_transit]: {
				title: t('In Transit'),
				borderColor: '#D6E4FF',
				bgColor: '#F0F5FF ',
				textColor: '#007ACC',
				Icon: craneIcon,
			},
			[ProgressMapKeysEnum.arriving]: {
				title: t('Arriving'),
				borderColor: '#D6E4FF',
				bgColor: '#F0F5FF ',
				textColor: '#007ACC',
				Icon: ({ style, ...props }: any) => (
					<BoatColorIcon style={{ height: '20px', overflow: 'hidden', ...style }} {...props} />
				),
			},
			[ProgressMapKeysEnum.avaliable]: {
				title: t('Available'),
				borderColor: '#EFDBFF',
				bgColor: '#F9F0FF',
				textColor: '#7409D7',
				Icon: progressAvaliableIcon,
			},
			[ProgressMapKeysEnum.pickup]: {
				title: t('Picked-Up'),
				borderColor: '#FFD8BF',
				bgColor: '#FFF2E8',
				textColor: '#FA541C',
				Icon: progressPickedUpIcon,
			},
			[ProgressMapKeysEnum.delivered]: {
				title: t('Delivered'),
				borderColor: '#eaff8f',
				bgColor: '#fcffe6',
				textColor: '#7cb305',
				Icon: progressDeliveredIcon,
			},
			[ProgressMapKeysEnum.empty_returned]: {
				title: t('Returned'),
				borderColor: '#B5F5EC',
				bgColor: '#E6FFFB',
				textColor: '#08979C',
				Icon: progressEmptyReturnedIcon,
			},
			[ProgressMapKeysEnum.completed]: {
				title: t('Completed'),
				borderColor: '#9be595',
				bgColor: '#f6ffed',
				textColor: '#1D960A',
				Icon: (props: any) => (
					<span className='font-[18px]'>
						<CheckCircleOutlined
							style={{
								color: '#1D960A',
								// fontSize: '15px',
								width: 'fit-content',
								paddingLeft: '2px',
								margin: 0,
							}}
						/>
					</span>
				),
			},

			[ProgressMapKeysEnum.canceled]: {
				title: t('Canceled'),
				borderColor: '#d1d5dc',
				bgColor: '#e7e7e7',
				textColor: '#78797c',
				Icon: (props: any) => (
					<span className='font-[18px]'>
						<CloseCircleOutlined
							style={{
								color: '#78797c',
								// fontSize: '15px',
								width: 'fit-content',
								paddingLeft: '2px',
								margin: 0,
							}}
						/>
					</span>
				),
			},
		}),
		[t],
	);

	const progressKeys = useMemo(
		() => Object.keys(progressMap) as ProgressMapKeysEnum[],
		[progressMap],
	);

	const progressStatisticMap = useMemo(
		() => Object.fromEntries(Object.entries(progressMap).map(([k, v]) => [k + '_total', v])),
		[progressMap],
	);

	const progressStatisticKeys = useMemo(
		() => Object.keys(progressStatisticMap),
		[progressStatisticMap],
	);

	return {
		progressMap,
		progressKeys,
		progressStatisticMap,
		progressStatisticKeys,
	};
};
