import React from 'react';
import downArrowSvg from '@/assets/down-arrow.svg';

const DownArrowIcon = (
	props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
) => {
	return <img {...props} src={downArrowSvg} />;
};

export default DownArrowIcon;
