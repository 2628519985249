import { createContext, useContext } from 'react';
import { book } from './book.context';

export const bookContext = createContext(book);

export const BookContext: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
	return <bookContext.Provider value={book}>{children}</bookContext.Provider>;
};

export const useBookContext = () => useContext(bookContext);
