import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetProjectMonthlyStatisticsProps, GetProjectMonthlyStatisticsRes } from './interface';

export * from './interface';

export const getProjectMonthlyStatistics = async (props: GetProjectMonthlyStatisticsProps) => {
	return await axios.get<GetProjectMonthlyStatisticsRes>(
		`/projects/${props.path.projectId}/monthlyStatistics`,
		{
			headers: {
				Authorization: accessTokenManager.getAccessToken(),
			},
		},
	);
};
