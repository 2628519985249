import iconFactory from './icon-factory';

const Svg = () => (
	<svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M16.5 10.8044L7.5 5.6615M13.2754 3.81907L19.704 7.49235C20.0978 7.71727 20.425 8.04232 20.6526 8.43453C20.8802 8.82674 21.0001 9.27217 21 9.72564V15.7402C21.0001 16.1937 20.8802 16.6391 20.6526 17.0313C20.425 17.4235 20.0978 17.7486 19.704 17.9735L13.2754 21.6468C12.887 21.8687 12.4474 21.9854 12 21.9854C11.5526 21.9854 11.113 21.8687 10.7246 21.6468L4.296 17.9735C3.90224 17.7486 3.57497 17.4235 3.34736 17.0313C3.11976 16.6391 2.99992 16.1937 3 15.7402V9.72564C2.99992 9.27217 3.11976 8.82674 3.34736 8.43453C3.57497 8.04232 3.90224 7.71727 4.296 7.49235L10.7246 3.81907C11.113 3.59718 11.5526 3.48047 12 3.48047C12.4474 3.48047 12.887 3.59718 13.2754 3.81907Z'
			stroke='#007BFF'
			stroke-width='1.6875'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M2.89987 8.4757C3.12082 8.06543 3.63253 7.91195 4.04281 8.1329L11.181 11.9771C11.1809 11.9771 11.181 11.9771 11.181 11.9771C11.4326 12.1126 11.7141 12.1835 11.9999 12.1835C12.2857 12.1835 12.567 12.1126 12.8187 11.9772C12.8186 11.9772 12.8187 11.9772 12.8187 11.9772L19.957 8.1329C20.3672 7.91195 20.8789 8.06543 21.0999 8.4757C21.3209 8.88598 21.1674 9.39769 20.7571 9.61865L13.6188 13.4629C13.3728 13.5954 13.1123 13.6967 12.8436 13.7652V21.7329C12.8436 22.1989 12.4659 22.5767 11.9999 22.5767C11.5339 22.5767 11.1561 22.1989 11.1561 21.7329V13.7652C10.8875 13.6967 10.6271 13.5954 10.3811 13.463L3.24267 9.61865C2.8324 9.39769 2.67892 8.88598 2.89987 8.4757Z'
			fill='#007BFF'
		/>
	</svg>
);

export default iconFactory(Svg);
