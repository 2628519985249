import { useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, message, Modal, Popconfirm, Row, Space, Typography } from 'antd';
import { deleteApiToken, regenerateApiToken } from '@/services/apis';
import { useGlobalContext } from '@/services/contexts';
import { get } from 'lodash';

export const ModalNewApiToken = () => {
	const { t } = useTranslation();

	const [showApiToken, setShowApiToken] = useState(false);
	const [apiToken, setApiToken] = useState(null);
	const [loading, setLoading] = React.useState(false);
	const [open, setOpen] = React.useState(false);
	const { userContext } = useGlobalContext();

	const handleCancel = () => {
		setOpen(false);
		setApiToken(null);
		setShowApiToken(false);
	};

	const user = userContext.getUserInfo();

	const handleDeleteToken = async () => {
		const userId = get(user, 'id', 0);
		if (!userId) {
			return;
		}

		setLoading(true);

		try {
			await deleteApiToken();

			setShowApiToken(true);
			setApiToken('None');
			userContext.setUserInfo({
				...userContext.getUserInfo(),
				has_token: false,
			});
			message.success('Saved');
			// refreshUser(userId);
		} catch (e: any) {
			message.error(e.data.error || 'System Error');
		} finally {
			setLoading(false);
		}
	};

	const handleRegenerateToken = async (e) => {
		const userId = get(user, 'id', 0);
		if (!userId) {
			return;
		}

		setLoading(true);

		try {
			const resp = await regenerateApiToken();
			setShowApiToken(true);
			setApiToken(get(resp, 'data.apiToken', null));
			userContext.setUserInfo({
				...userContext.getUserInfo(),
				has_token: true,
			});
			message.success('Saved');
			// refreshUser(userId);
		} catch (e: any) {
			message.error(e.data.error || 'System Error');
		}

		setLoading(false);
	};

	return (
		<>
			<Button type='text' onClick={() => setOpen(true)}>
				{t('API Token')}
			</Button>
			<Modal
				cancelText='Close'
				style={{ top: '35%' }}
				width='40%'
				title='Api Token'
				footer={false}
				open={open}
				onCancel={handleCancel}
			>
				<div>
					<Row className='mb-md'>
						<Space>
							<Typography.Text>API Token:</Typography.Text>
							<Typography.Text>
								{showApiToken ? apiToken : get(user, 'has_token', false) ? '*****' : 'None'}
							</Typography.Text>
						</Space>
					</Row>
					<Row>
						<Space>
							<Button disabled={loading} type='primary' onClick={handleRegenerateToken}>
								Regenerate token
							</Button>
							{get(user, 'has_token', false) && (
								<Popconfirm
									placement='top'
									title={'Are you sure to delete?'}
									onConfirm={handleDeleteToken}
									okText='Yes'
									cancelText='No'
								>
									<Button>Delete token</Button>
								</Popconfirm>
							)}
						</Space>
					</Row>
				</div>
			</Modal>
		</>
	);
};
