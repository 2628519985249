import iconFactory from './icon-factory';

const Svg = () => (
	<svg width='28' height='32' viewBox='0 0 28 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M6.37496 31.9583C5.8194 31.8194 5.46524 31.4722 5.31246 30.9166C5.15968 30.3611 5.27774 29.8889 5.66663 29.5L6.49996 28.6666C4.86107 28.6666 3.47913 28.1041 2.35413 26.9791C1.22913 25.8541 0.666626 24.4722 0.666626 22.8333V6.99998C0.666626 5.52776 1.04857 4.35415 1.81246 3.47915C2.57635 2.60415 3.58329 1.93748 4.83329 1.47915C6.08329 1.02081 7.5069 0.715257 9.10413 0.56248C10.7013 0.409702 12.3333 0.333313 14 0.333313C15.8333 0.333313 17.5625 0.409702 19.1875 0.56248C20.8125 0.715257 22.2291 1.02081 23.4375 1.47915C24.6458 1.93748 25.5972 2.60415 26.2916 3.47915C26.9861 4.35415 27.3333 5.52776 27.3333 6.99998V22.8333C27.3333 24.4722 26.7708 25.8541 25.6458 26.9791C24.5208 28.1041 23.1388 28.6666 21.5 28.6666L22.3333 29.5C22.7222 29.8889 22.8402 30.3611 22.6875 30.9166C22.5347 31.4722 22.1805 31.8194 21.625 31.9583C21.375 32.0139 21.1319 32.0139 20.8958 31.9583C20.6597 31.9028 20.4444 31.7778 20.25 31.5833L17.3333 28.6666H10.6666L7.74996 31.5833C7.55551 31.7778 7.34024 31.9028 7.10413 31.9583C6.86801 32.0139 6.62496 32.0139 6.37496 31.9583ZM3.99996 13.6666H12.3333V8.66665H3.99996V13.6666ZM15.6666 13.6666H24V8.66665H15.6666V13.6666ZM8.16663 23.6666C8.88885 23.6666 9.48607 23.4305 9.95829 22.9583C10.4305 22.4861 10.6666 21.8889 10.6666 21.1666C10.6666 20.4444 10.4305 19.8472 9.95829 19.375C9.48607 18.9028 8.88885 18.6666 8.16663 18.6666C7.4444 18.6666 6.84718 18.9028 6.37496 19.375C5.90274 19.8472 5.66663 20.4444 5.66663 21.1666C5.66663 21.8889 5.90274 22.4861 6.37496 22.9583C6.84718 23.4305 7.4444 23.6666 8.16663 23.6666ZM19.8333 23.6666C20.5555 23.6666 21.1527 23.4305 21.625 22.9583C22.0972 22.4861 22.3333 21.8889 22.3333 21.1666C22.3333 20.4444 22.0972 19.8472 21.625 19.375C21.1527 18.9028 20.5555 18.6666 19.8333 18.6666C19.1111 18.6666 18.5138 18.9028 18.0416 19.375C17.5694 19.8472 17.3333 20.4444 17.3333 21.1666C17.3333 21.8889 17.5694 22.4861 18.0416 22.9583C18.5138 23.4305 19.1111 23.6666 19.8333 23.6666Z'
			fill='url(#paint0_linear_919_14433)'
		/>
		<defs>
			<linearGradient
				id='paint0_linear_919_14433'
				x1='-1.64692'
				y1='16.1666'
				x2='27.3333'
				y2='16.1666'
				gradientUnits='userSpaceOnUse'
			>
				<stop stop-color='#0094FF' />
				<stop offset='1' stop-color='#0165FA' />
			</linearGradient>
		</defs>
	</svg>
);

export default iconFactory(Svg);
