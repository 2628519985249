import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetRequestedRatesProps, GetRequestedRatesRes } from './interface';

export * from './interface';

export const getRequestedRates = async (props?: GetRequestedRatesProps) => {
	return await axios.get<GetRequestedRatesRes>(`/requestedRates`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params: props?.params,
	});
};
