import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { UpdatePickupNumberAgentProps, UpdatePickupNumberAgentRes } from './interface';

export const updatePickupNumberAgent = async (props: UpdatePickupNumberAgentProps) => {
	return await axios.put<UpdatePickupNumberAgentProps>(
		`/pickupNumberAgents/${props.path.pickupNumberAgentId}`,
		props.data,
		{
			headers: {
				Authorization: accessTokenManager.getAccessToken(),
			},
		},
	);
};
