const RequiredMark = () => (
	<span className=' me-[4px] ml-[2px] text-[#ff4d4f] font-[SimSun,sans-serif]'>*</span>
);

export const RequiredLabel = ({
	label,
	markPosition = 'right',
}: {
	label: React.ReactNode;
	markPosition?: 'left' | 'right';
}) => (
	<span>
		{markPosition === 'left' && <RequiredMark />}
		{label}
		{markPosition === 'right' && <RequiredMark />}
	</span>
);
