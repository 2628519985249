import { useTranslation } from 'react-i18next';
import { Modal, ModalProps } from 'antd';

export const DeselectLGModal = (props: ModalProps) => {
	const { t } = useTranslation();

	return (
		<Modal
			{...props}
			title={t('Caution')}
			okText={t('Lift gate required')}
			cancelText={t('Not use, can bo loaded and unloaded by facility')}
		>
			<div>
				{t(
					`Your pickup or delivery location is a residential location and if is not possible to load or unload the goods at the location, please use the lift gate service.`,
				)}
			</div>
		</Modal>
	);
};
