import { isFunction } from 'lodash';
import { Item, Status } from '../type';
import Line from './line';
import StatusIcon from './status-icon';

interface ItemRender {
	item: Item;
	items: Item[];
	index: number;
	current: number;
}

const getStatus = (current: number, index: number, value: string | undefined | boolean): Status => {
	if (index === current) return value ? 'finished' : 'processing';
	if (index < current) return 'finished';
	return 'waiting';
};

export default ({ item, index, items, current }: ItemRender) => {
	const status = getStatus(current, index, item.value);

	return (
		<div className=' relative grow'>
			{/* 顶部大 icon */}
			<div className=' pb-[10px] h-[45px] w-full flex justify-center items-end'>
				<item.Icon status={status} />
			</div>

			{/* 中间 icon */}
			<div className='mb-[10px] h-[14px] flex w-[170px]'>
				<div className=' absolute w-[20px] h-[20px] top-[42px] left-[75px] bg-white rounded-[50%]'>
					<StatusIcon status={status} value={item.value} />
				</div>

				{/* left */}
				<Line align='left' status={status} isNone={index === 0} />
				{/* right */}
				<Line align='right' status={status} isNone={index === items.length - 1} />
			</div>

			{/* 底部阶段及时间等 */}
			<div className=' text-black text-center'>
				<div className='mb-[4px] body-4-r'>{item.title}</div>
				<div className='mb-[8px] body-4-m'>
					{isFunction(item.content) ? item.content() : item.content}
				</div>
				{item.extra}
			</div>
		</div>
	);
};
