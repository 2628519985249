import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetDraftOrdersProps, GetDraftOrdersRes } from './interface';

export const getDraftOrders = async (props: GetDraftOrdersProps) => {
	return await axios.get<GetDraftOrdersRes>(`/draftOrders`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params: props.params,
	});
};
