import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetCompanyRes } from './interface';

export const getAgentCompany = async () => {
	return await axios.get<GetCompanyRes>(`agent/company`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
