import DockIcon from '@/icons/dock-icon';
import DockIconActive from '@/icons/dock-icon-active';
import TrainEngineIcon from '@/icons/train-engine-icon';
import TrainEngineIconActive from '@/icons/train-engine-icon-active';

interface TerminalIconProps {
	facility_type: string | undefined;
	isActive: boolean;
}

export const TerminalIcon = ({ facility_type, isActive = false }: TerminalIconProps) => {
	if (facility_type === undefined || facility_type === 'Ocean Port')
		return isActive ? <DockIconActive /> : <DockIcon />;
	return isActive ? <TrainEngineIconActive /> : <TrainEngineIcon />;
};
