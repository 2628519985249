import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import qs from 'qs';
import valueTransfer from '@/utils/value-transfer';

export interface LTLSearchRateQuery {
	quote_id: number;
	type: 'copy';
}

export default () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const parsedSearchParams = useMemo(() => {
		const searchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });

		const res = {};

		valueTransfer(res, 'quote_id', searchParams.quote_id, (v) => v);
		valueTransfer(res, 'type', searchParams.type, (v) => v);

		return res as Partial<LTLSearchRateQuery>;
	}, [searchParams]);

	const changeSearchParams = useCallback((values: any) => {
		setSearchParams(qs.stringify({ ...values }));
	}, []);

	return {
		parsedSearchParams,
		changeSearchParams,
	};
};
