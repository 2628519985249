import React from 'react';
import popularSvg from '@/assets/popular.svg';

const PopularIcon = (
	props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
) => {
	return <img {...props} src={popularSvg} />;
};

export default PopularIcon;
