import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, message, Modal, Row } from 'antd';
import { PickupNumberAgent } from '@/interfaces/pickup-number-agent.interface';
import { createPickupNumberAgent } from '@/services/apis/create-pickup-number-agent';
import { updatePickupNumberAgent } from '@/services/apis/update-pickup-number-agent';
import { find, isEmpty } from 'lodash';
import { observer } from 'mobx-react-lite';
import Select from '@/components/select';
import { CitySelect } from '../../../city-select';
import { Input, InputTextArea } from '../../../input';
import Emails from './emails';
import { PickupNumberModalProps } from './type';

const Item = Form.Item;

export default () => {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);

	const [pickupNumberAgent, setPickupNumberAgent] = useState<PickupNumberAgent>();

	const openPickupNumberAgentModal = useCallback((pickupNumberAgent?: PickupNumberAgent) => {
		setOpen(true);
		setPickupNumberAgent(pickupNumberAgent);
	}, []);

	const closePickupNumberAgentModal = useCallback(() => {
		setOpen(false);
		setPickupNumberAgent(undefined);
	}, []);

	const PickupNumberAgentModal = observer(({ onAfterSave, ...props }: PickupNumberModalProps) => {
		const [confirmLoading, setConfirmLoading] = useState(false);

		const [form] = Form.useForm<PickupNumberAgent>();

		const isCreating = !pickupNumberAgent;

		const handleFinish = async (values: any) => {
			setConfirmLoading(true);

			const action = isCreating ? 'Create' : 'Update';

			let res;
			try {
				if (isCreating) {
					res = await createPickupNumberAgent({ data: values });
				} else {
					res = await updatePickupNumberAgent({
						path: {
							pickupNumberAgentId: pickupNumberAgent.id,
						},
						data: values,
					});
				}
				message.success(t(`${action} pickup number agent success`));
				onAfterSave && onAfterSave(res?.data?.data);
				closePickupNumberAgentModal();
			} catch (error) {
				message.success(t(`${action} pickup number agent error: ${error.response.message}`));
			} finally {
				setConfirmLoading(false);
			}
		};

		useEffect(() => {
			if (!pickupNumberAgent) {
				form.setFieldsValue({
					emails: [''],
				});
			} else {
				form.setFieldsValue({ ...pickupNumberAgent });
			}
		}, [pickupNumberAgent]);

		return (
			<Modal
				{...props}
				open={open}
				width={610}
				title={isCreating ? t('Add Pickup Number Agent') : t('Edit Pickup Number Agent')}
				footer={null}
				onCancel={closePickupNumberAgentModal}
				centered
			>
				<Form form={form} onFinish={handleFinish} layout='vertical'>
					<Item name='name' label={t('Name')} rules={[{ required: true }]}>
						<Input />
					</Item>

					<Item
						name='emails'
						label={t('Emails')}
						rules={[{ required: true, message: t('Please Enter Emails') }]}
					>
						<Emails />
					</Item>

					<div className='flex justify-end'>
						{/* <Button type='primary' ghost onClick={closeAddressModal}>
							{t('Cancel Default')}
						</Button> */}
						<Button type='primary' htmlType='submit' loading={confirmLoading}>
							{t('Save')}
						</Button>
					</div>
				</Form>
			</Modal>
		);
	});

	return {
		PickupNumberAgentModal,
		openPickupNumberAgentModal,
		closePickupNumberAgentModal,
	};
};
