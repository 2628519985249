import { useTranslation } from 'react-i18next';
import { Space } from 'antd';
import ContainerActiveIcon from '@/icons/container-active-icon';
import ProjectIcon from '@/icons/project-icon';

interface SummaryItemProps {
	icon: React.ReactNode;
	value: number;
	des: string;
}

interface SummaryProps {
	completed_containers: number;
	active_containers: number;
}

const SummaryItem = ({ icon, value, des }: SummaryItemProps) => (
	<div className='flex p-[12px_0px]'>
		<div className='mr-[20px] w-[60px] h-[60px] flex justify-center items-center rounded-[8px] bg-primary-light-03'>
			{icon}
		</div>

		<div>
			<div className='h2-sb text-primary-dark-01'>{value}</div>
			<div className='foot-note-1-b text-primary-dark-02'>{des}</div>
		</div>
	</div>
);

export const Summary = ({ completed_containers, active_containers }: SummaryProps) => {
	const { t } = useTranslation();

	const items: SummaryItemProps[] = [
		{
			icon: <ProjectIcon />,
			value: completed_containers,
			des: t(`Total Completed Containers`),
		},
		{
			icon: <ContainerActiveIcon />,
			value: active_containers,
			des: t(`Total Active Containers`),
		},
	];

	return (
		<Space
			direction='vertical'
			size={16}
			className=' box-border w-full h-full p-[16px] rounded-[8px] bg-primary-light-05'
		>
			<div className='body-3-sb text-black'>{t(`Summary`)}</div>
			<div>
				{items.map((item) => (
					<SummaryItem key={item.des} {...item} />
				))}
			</div>
		</Space>
	);
};
