import { isSearchParamValid } from './is-search-params-valid';

export default (
	target: Record<string, any>,
	key: string,
	value: any,
	transfer: (v: any) => any,
) => {
	if (!isSearchParamValid(value)) return;
	const newValue = transfer(value);
	target[key] = newValue;
};
