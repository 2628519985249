import { DraftOrder } from '@/interfaces/draft-order.interface';
import { Order } from '@/interfaces/order.interface';
import { getDraftOrder, getOrder, getRateById } from '@/services/apis';
import { autorun, makeAutoObservable, runInAction } from 'mobx';

class BookContext {
	constructor() {
		makeAutoObservable(this);

		autorun(() => this.draftOrderId && this.fetchDraftOrder(this.draftOrderId, { autoSet: true }));

		autorun(
			() => !this.draftOrderId && this.orderId && this.fetchOrder(this.orderId, { autoSet: true }),
		);
	}

	private _draftOrderId: DraftOrder['id'] | undefined;

	private _draftOrder: DraftOrder | Order | undefined;

	private _orderId: Order['id'] | undefined;

	private _useDraftOrder = false;

	get draftOrderId() {
		return this._draftOrderId;
	}

	set draftOrderId(draftOrderId: typeof this._draftOrderId) {
		runInAction(() => {
			this._draftOrderId = draftOrderId;
		});
	}

	get draftOrder() {
		return this._draftOrder;
	}

	set draftOrder(draftOrder: typeof this._draftOrder) {
		runInAction(() => {
			this._draftOrder = draftOrder;
		});
	}

	get orderId() {
		return this._orderId;
	}

	set orderId(orderId: typeof this._orderId) {
		runInAction(() => {
			this._orderId = orderId;
		});
	}

	get useDraftOrder() {
		return this._useDraftOrder;
	}

	set useDraftOrder(useDraftOrder: typeof this._useDraftOrder) {
		runInAction(() => {
			this._useDraftOrder = useDraftOrder;
		});
	}

	public async fetchDraftOrder(draftOrderId?: DraftOrder['id'], config?: { autoSet?: boolean }) {
		draftOrderId = draftOrderId ?? this.draftOrderId;
		if (!draftOrderId) return;
		const res = await getDraftOrder({ path: { draftOrderId: draftOrderId } });
		if (config?.autoSet) this.draftOrder = res.data.data;
		return res.data.data;
	}

	public async fetchOrder(orderId: Order['id'], config?: { autoSet?: boolean }) {
		const res = await getOrder({ path: { orderId } });
		if (config?.autoSet) this.draftOrder = res.data.data;
		return res.data.data;
	}

	public clear() {
		this.draftOrderId = undefined;
		this.draftOrder = undefined;
		this.orderId = undefined;
		this.useDraftOrder = false;
	}

	public async fetchRate(sid: string) {
		const res = await getRateById(sid);
		return res.data.data;
	}
}

export const book = new BookContext();
