import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { UploadFileProps, UploadFileRes } from './interface';

export const uploadFile = async (props: UploadFileProps) => {
	return await axios.post<UploadFileRes>(
		`/newDocuments/order_customer/${props.path.orderId}`,
		props.data,
		{
			...(props.config ?? {}),
			headers: {
				Authorization: accessTokenManager.getAccessToken(),
				'Content-Type': 'multipart/form-data',
			},
		},
	);
};
