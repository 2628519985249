import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Form, message } from 'antd';
import { useGlobalContext } from '@/services/contexts';
import { SwapOutlined } from '@ant-design/icons';
import EmailInput from './email-input';
import './index.less';
import PasswordInput from './password-input';
import VerificationCodeInput from './verification-code-input';

const Item = Form.Item;

export default () => {
	const { t } = useTranslation();

	const [mode, setMode] = useState<'password' | 'code'>('password');
	const location = useLocation();

	const [submittable, setSubmittable] = useState({
		email: false,
		password: false,
		code: false,
	});

	const { userContext } = useGlobalContext();

	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);

	const [form] = Form.useForm();

	const pass = () => {
		navigate('/');
	};

	const getRedirectUrl = () => {
		const query = new URLSearchParams(location.search);
		const redirect = query.get('redirect');

		if (redirect && !redirect.startsWith('/login')) {
			return redirect;
		} else {
			return '/';
		}
	};

	const onFinish = async (values: any) => {
		// if (mode !== 'password') return;
		try {
			setLoading(true);
			if (mode == 'password') {
				await userContext.fetchUserInfo(values.email, values.password);
			} else if (mode == 'code') {
				await userContext.fetchUserInfoByCode(values.email, values.code);
			}

			const redirectURL = getRedirectUrl();

			if (redirectURL) {
				navigate(redirectURL);
			} else {
				pass();
			}

			// if (
			// 	history.length > 1 &&
			// 	document.referrer &&
			// 	document.referrer.indexOf(window.location.host) !== -1 && document.referrer.indexOf('/login') !== -1
			// ) {
			// 	history.back();
			// }

			// // 导航
			// pass();
		} catch (error) {
			const responseError = error?.response?.data;
			if (responseError && responseError.errors && responseError.message) {
				responseError.errors['email'] = responseError.errors['username'];
				const errorMsg = responseError.message;
				message.error(errorMsg);
				const values = form.getFieldsValue();
				const fields = Object.entries(values).map(([k, v]) => ({
					name: k,
					value: v,
					errors: [responseError.errors[k]],
				}));
				form.setFields(fields);
			} else {
				message.error(t('error'));
			}
		} finally {
			setLoading(false);
		}
	};

	const isSubmittable = () => {
		const { email, password, code } = submittable;
		return email && (mode === 'password' ? password : code);
	};

	return (
		<div className='w-[530px] h-[492px] p-[25px_78px_28px_67px] rounded-[10px] bg-white'>
			<Form
				layout='vertical'
				requiredMark={false}
				form={form}
				className='w-full login-form'
				onFinish={onFinish}
			>
				<div className='mb-[24px] text-center'>
					<div className='mb-[10px] h1-b text-black'>{t('Login with email')}</div>
					{/* <div className='body-4-r text-grey-01'>
						{t('No account?')}
						<span className=' underline'>{t('Create an account')}</span>
					</div> */}
				</div>

				<div>
					<div className='mb-[24px]'>
						<EmailInput
							form={form}
							onValid={(email) => setSubmittable((pre) => ({ ...pre, email }))}
						/>

						{mode === 'code' && (
							<VerificationCodeInput
								form={form}
								onValid={(isValid: boolean) => setSubmittable((pre) => ({ ...pre, code: isValid }))}
							/>
						)}

						{mode === 'password' && (
							<PasswordInput
								form={form}
								onValid={(isValid: boolean) =>
									setSubmittable((pre) => ({ ...pre, password: isValid }))
								}
								// onForgot={onForgot}
							/>
						)}
					</div>

					{/* buttons */}
					{['code', 'password'].includes(mode) && (
						<div>
							<Button
								className='mb-[24px] p-[16px_20px_16px_20px] w-full h-full border-[0] rounded-[8px] bg-primary-regular body-3-sb text-white'
								// disabled={!isSubmittable()}
								disabled={loading}
								htmlType='submit'
								loading={loading}
							>
								{t('Login')}
							</Button>
							<div
								onClick={() => setMode((mode) => (mode === 'code' ? 'password' : 'code'))}
								className='p-[10px] text-grey-01 underline cursor-pointer text-center'
							>
								<SwapOutlined className='mr-[8px]' />
								{t('or login with')}
								{' ' + (mode === 'code' ? t('password') : t('code'))}
							</div>
						</div>
					)}
				</div>
			</Form>
		</div>
	);
};
