import { Dayjs } from 'dayjs';

const hours = Array.from({ length: 24 }, (_, i) => i);

export const disabledStartTime = (end?: Dayjs) => () => ({
	disabledHours: () => {
		if (!end) return [];
		return hours.filter((h) => h > end.hour());
	},
	disabledMinutes: (selectedHour: number) => {
		if (!end) return [];
		const endHour = end.hour();
		const endMin = end.minute();
		if (selectedHour > endHour) return [0, 15, 30, 45];
		if (selectedHour === endHour) return [0, 15, 30, 45].filter((m) => m >= endMin);
		return [];
	},
});

export const disabledEndTime = (start?: Dayjs) => () => ({
	disabledHours: () => {
		if (!start) return [];
		return hours.filter((h) => h < start.hour());
	},
	disabledMinutes: (selectedHour: number) => {
		if (!start) return [];
		const startHour = start.hour();
		const startMin = start.minute();
		if (selectedHour < startHour) return [0, 15, 30, 45];
		if (selectedHour === startHour) return [0, 15, 30, 45].filter((m) => m <= startMin);
		return [];
	},
});
