import { useTranslation } from 'react-i18next';
import { Button, Tabs, TabsProps } from 'antd';
import { FolderOpenOutlined, InboxOutlined, StarOutlined } from '@ant-design/icons';
import { useSafeState } from 'ahooks';
import { CreateProjectPanel, useCreateProjectModal } from '@/components/create-project-panel';
import { apiHooks } from '@/hooks/apis';
import './index.less';
import { ProjectGrid } from './project-grid';

export const AllPanel = () => {
	const { t } = useTranslation();

	const [selectedTab, setSelectedTab] = useSafeState<'Favorite' | 'All Projects' | 'Archived'>(
		'All Projects',
	);

	const { openingCreateProjectModal, openCreateProjectModal, closeCreateProjectModal } =
		useCreateProjectModal();

	const items: TabsProps['items'] = [
		{
			key: 'Favorite',
			label: (
				<div className='flex items-center'>
					<StarOutlined style={{ fontSize: '21px', marginRight: '4px' }} />
					<span className='body-4-sb'>{t(`Favorite`)}</span>
				</div>
			),
			children: <ProjectGrid type='favorite' selectedTab={selectedTab} />,
		},
		{
			key: 'All Projects',
			label: (
				<div className='flex items-center'>
					<FolderOpenOutlined style={{ fontSize: '21px', marginRight: '4px' }} />
					<span className='body-4-sb'>{t(`All Projects`)}</span>
				</div>
			),
			children: <ProjectGrid type='all' selectedTab={selectedTab} />,
		},
		{
			key: 'Archived',
			label: (
				<div className='flex items-center'>
					<InboxOutlined style={{ fontSize: '21px', marginRight: '4px' }} />
					<span className='body-4-sb'>{t(`Archived`)}</span>
				</div>
			),
			children: <ProjectGrid type='archived' selectedTab={selectedTab} />,
		},
	];

	return (
		<>
			<div className='flex flex-col h-full'>
				<div className=' box-border pb-[16px] flex-grow-0 flex-shrink-0 h4-sb text-primary-dark-01'>
					{t(`My Projects`)}
				</div>
				<Tabs
					defaultActiveKey='All Projects'
					items={items}
					tabBarExtraContent={{
						right: (
							<Button
								type='primary'
								className='body-4-m text-white'
								onClick={openCreateProjectModal}
							>
								{t(`Add Project`)}
							</Button>
						),
					}}
					className='all-project-tabs flex-grow flex-shrink'
					onChange={(key) => setSelectedTab(key as 'Favorite' | 'All Projects' | 'Archived')}
				/>
			</div>

			{openingCreateProjectModal && (
				<CreateProjectPanel
					open={openingCreateProjectModal}
					type='create'
					onClose={closeCreateProjectModal}
				/>
			)}
		</>
	);
};
