import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetProjectProgressProps, GetProjectProgressRes } from './interface';

export * from './interface';

export const getProjectProgress = async (props: GetProjectProgressProps) => {
	return await axios.get<GetProjectProgressRes>(`/projects/progress/${props.path.type}`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params: props.params,
	});
};
