import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { useLTLContext } from '@/services/contexts';
import { observer } from 'mobx-react-lite';
import { ItemTitle } from './item-title';

export const ShipmentInfoCommodityName = observer(() => {
	const { t } = useTranslation();

	const ltlContext = useLTLContext();

	const name = ltlContext.stepOneFormValues.items.map((item) => item.description).join(',');

	return (
		<ItemTitle
			content={t(`Commodity Name`)}
			value={<Typography.Text ellipsis>{name}</Typography.Text>}
		/>
	);
});
