import React from 'react';
import IndustryNewsInActiveSvg from '@/assets/industry-news-active.svg';

const IndustryNewsInActiveIcon = (
	props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
) => {
	return <img {...props} src={IndustryNewsInActiveSvg} />;
};

export default IndustryNewsInActiveIcon;
