import clsx from 'clsx';
import { Status } from '../type';

interface LineProps {
	align: 'left' | 'right';
	status: Status;
	isNone: boolean;
}

export default ({ align, status, isNone }: LineProps) => {
	const justify = align === 'left' ? 'justify-start' : 'justify-end';

	const bg = status === 'finished' ? 'bg-primary-regular' : 'bg-grey-04';

	if (isNone) return <div className='h-[14px] w-[85px]' />;

	return (
		<div className={clsx('h-[14px] w-[85px] flex items-center bg-white', justify)}>
			<div className={clsx('h-[2px] w-[70px]', bg)} />
		</div>
	);
};
