import { useCallback, useRef } from 'react';
import Map, { useMap } from 'react-map-gl';
import { useBoolean, useMount } from 'ahooks';
import CenterSpin from '@/components/center-spin';
import { Point, PointMarker, PointMarkerProps } from '@/pages/project/components';
import { fitCoordinates } from '@/utils/fit-coordinates';
import './index.less';

export interface MyFocusMapProps
	extends Pick<PointMarkerProps, 'icon' | 'activeIcon' | 'selectedPointId' | 'onMarkClick'> {
	points: Point[];
	mapLoading: boolean;
}

export const MyFocusMap = ({
	points,
	icon,
	activeIcon,
	selectedPointId,
	mapLoading,
	onMarkClick,
}: MyFocusMapProps) => {
	const { projectMyFocusMap } = useMap();

	const cacheRef = useRef<string>('');

	const [isInit, { setTrue }] = useBoolean(false);

	const autoFit = useCallback(() => {
		cacheRef.current = fitCoordinates(
			projectMyFocusMap,
			points.map((p) => [p.longitude, p.latitude]),
			cacheRef.current,
		);
	}, [projectMyFocusMap, points]);

	useMount(() => {
		setTrue();
	});

	if (mapLoading) return <CenterSpin />;

	return (
		<Map
			id={`projectMyFocusMap`}
			preserveDrawingBuffer={true}
			// 初始化展示完整美国地图
			initialViewState={{
				longitude: -96,
				latitude: 37.8,
				zoom: 4,
			}}
			style={{ width: '100%', height: '100%' }}
			mapStyle='mapbox://styles/drayeasyben/clkhj289k003101pw47sdckps'
			mapboxAccessToken={import.meta.env.VITE_MAPBOX_TOKEN}
			// reuseMaps
			onIdle={() => {
				isInit && autoFit();
			}}
			maxBounds={[
				[-172.298804, 4.84616], // Southwest coordinates
				[-48.90097, 70.52138], // Northeast coordinates
			]}
		>
			{points.map((point) => (
				<PointMarker
					key={point.id}
					point={point}
					selectedPointId={selectedPointId}
					icon={icon}
					activeIcon={activeIcon}
					onMarkClick={onMarkClick}
				/>
			))}
		</Map>
	);
};
