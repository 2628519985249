import { BillingCodeDefinition } from '@/interfaces/billing-code-definition.interface';
import { PossibleCharge } from '@/interfaces/possible-charges.interface';
import { getBillingCodes } from '@/services/apis';
import { getBillingCodeDefinition } from '@/services/apis/get-billing-code-definition';
import { autorun, makeAutoObservable, runInAction } from 'mobx';

class BillingCodeContext {
	constructor() {
		makeAutoObservable(this);
	}

	private _billingCodeDefinition: BillingCodeDefinition = {
		cn_definition: {
			definition: '',
			when_will_it_happen: '',
		},
		en_definition: {
			definition: '',
			when_will_it_happen: '',
		},
	};

	get useBillingCodeDefinition() {
		return this._billingCodeDefinition;
	}

	set setBillingCodeDefinition(billingCodeDefinition: typeof this._billingCodeDefinition) {
		runInAction(() => {
			this._billingCodeDefinition = billingCodeDefinition;
		});
	}

	public async fetchBillingCodeDefinition(billingCode: PossibleCharge['code']) {
		const res = await getBillingCodeDefinition({ path: { billingCode: billingCode } });
		return res.data.data;
	}

	public async fetchBillingCodes() {
		const res = await getBillingCodes();
		return res.data.data;
	}
}

export const billingCode = new BillingCodeContext();
