interface GapProps {
	width?: string;
	height?: string;
}

export const Gap = ({ width, height }: GapProps) => (
	<div
		style={{
			width: width || '100%',
			height: height || '100%',
			flexGrow: 0,
			flexShrink: 0,
		}}
	></div>
);
