import iconFactory from './icon-factory';

const Svg = () => (
	<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M14.9998 3.5V7.5C14.9998 7.63261 14.9471 7.75979 14.8533 7.85355C14.7596 7.94732 14.6324 8 14.4998 8C14.3672 8 14.24 7.94732 14.1462 7.85355C14.0525 7.75979 13.9998 7.63261 13.9998 7.5V4.70687L8.85354 9.85375C8.8071 9.90024 8.75196 9.93712 8.69126 9.96228C8.63056 9.98744 8.5655 10.0004 8.49979 10.0004C8.43408 10.0004 8.36902 9.98744 8.30832 9.96228C8.24762 9.93712 8.19248 9.90024 8.14604 9.85375L5.99979 7.70687L1.85354 11.8538C1.75972 11.9476 1.63247 12.0003 1.49979 12.0003C1.36711 12.0003 1.23986 11.9476 1.14604 11.8538C1.05222 11.7599 0.999512 11.6327 0.999512 11.5C0.999512 11.3673 1.05222 11.2401 1.14604 11.1462L5.64604 6.64625C5.69248 6.59976 5.74762 6.56288 5.80832 6.53772C5.86902 6.51256 5.93408 6.49961 5.99979 6.49961C6.0655 6.49961 6.13056 6.51256 6.19126 6.53772C6.25196 6.56288 6.3071 6.59976 6.35354 6.64625L8.49979 8.79313L13.2929 4H10.4998C10.3672 4 10.24 3.94732 10.1462 3.85355C10.0525 3.75979 9.99979 3.63261 9.99979 3.5C9.99979 3.36739 10.0525 3.24021 10.1462 3.14645C10.24 3.05268 10.3672 3 10.4998 3H14.4998C14.6324 3 14.7596 3.05268 14.8533 3.14645C14.9471 3.24021 14.9998 3.36739 14.9998 3.5Z'
			fill='#747578'
		/>
	</svg>
);

export default iconFactory(Svg);
