import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, InputNumber, Modal, Space, Table, Tooltip } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { ColumnsType, ColumnType } from 'antd/es/table';
import EmptyContainerImg from '@/assets/desk-shipping-package.png';
import DGIcon from '@/icons/dg-icon';
import OWIcon from '@/icons/ow-icon';
import WinterIcon from '@/icons/winter-icon';
import { Depot } from '@/interfaces/depot';
import { ValidErrorField } from '@/interfaces/valid-error-field.interface';
import { WeightUnit } from '@/interfaces/weight-unit.enum';
import { getCommodities } from '@/services/apis/get-commodities';
import { getDepots } from '@/services/apis/get-depots';
import '@/styles/custom-table.less';
import { InfoCircleOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { DefaultOptionType } from 'rc-select/lib/Select';
import Actions from './components/actions';
import ErrorContainer from './components/error-container';
import { RequiredLabel } from '@/components/required-label';
import Select from '@/components/select';
import { containerSizes } from '@/pages/search-rate/container-sizes';
import useValidError from '../../hooks/use-valid-error';
import { ArrElement } from '@/utils/array-element.type';
import BottomButtons from '../bottom-buttons';
import { DepotSelect } from '../depot-select';
import { AddForm } from '../depot-select/add-form';
import Panel from '../panel';
import { Input } from '../table-input';
import { emptyRecord } from './empty-record';
import './index.less';
import { Container, ContainerInfoProps } from './type';

const Item = Form.Item;

export const RETURN_TO_DEPORT = 'Return to deport';
export const STAY_IN_WAREHOUSE = 'Stay in warehouse';
export const TBD = 'TBD';

const SelectIcon = ({
	Icon,
	isSelected,
}: {
	Icon: (props: any) => JSX.Element;
	isSelected: boolean;
}) => {
	return (
		<div
			className={`flex justify-center items-center w-[36px] h-[36px] border-[1px] border-solid border-[${
				isSelected ? '#007BFF' : '#747578'
			}] rounded-[50%]`}
		>
			<Icon className='w-[20px] h-[20px]' active={isSelected} />
		</div>
	);
};

export const ContainerInfo = observer(
	({ NextButton, BackButton, SaveDraftButton }: ContainerInfoProps) => {
		const { t } = useTranslation();

		const specialConditions = [
			{
				key: 'is_dg',
				label: t('DG'),
				Icon: (props: { isSelected: boolean }) => (
					<SelectIcon Icon={DGIcon} isSelected={props.isSelected} />
				),
			},
			{
				key: 'is_overweight',
				label: t('OW'),
				Icon: (props: { isSelected: boolean }) => (
					<SelectIcon Icon={OWIcon} isSelected={props.isSelected} />
				),
			},
			{
				key: 'is_soc',
				label: t('SOC'),
				Icon: (props: { isSelected: boolean }) => (
					<SelectIcon
						Icon={() => (
							<span className={`text-[${props.isSelected ? '#007BFF' : '#747578'}]`}>SOC</span>
						)}
						isSelected={props.isSelected}
					/>
				),
			},
			{
				key: 'is_reefer',
				label: t('RF'),
				Icon: (props: { isSelected: boolean }) => (
					<SelectIcon Icon={WinterIcon} isSelected={props.isSelected} />
				),
			},
		];

		const { validErrors, addValidError, deleteValidError, clearValidErrors, getOneValidError } =
			useValidError();

		const form = Form.useFormInstance();

		const [searchText, setSearchText] = useState('');

		const [options, setOptions] = useState<DefaultOptionType[]>([]);

		const containers = form.getFieldValue('containers') as Container[];

		const addColumn = () =>
			form.setFieldsValue({ containers: [...(containers ?? []), { ...emptyRecord }] });

		/**
		 * 手动触发字段校验
		 * @returns
		 */
		const validateFields = async () => {
			const validProps = ['number', 'type', 'weight', 'package', 'commodity'];
			try {
				await form.validateFields(['containers'], { validateOnly: false });

				const nameLists: NamePath[] = [];
				const containersValue = form.getFieldValue('containers') as Container[];
				containersValue.forEach((_, index) => {
					validProps.forEach((i) => {
						nameLists.push(['containers', index, i]);
					});
				});
				await form.validateFields(nameLists, { validateOnly: false });
			} catch (error: any) {
				console.error('has error', error);
				if (!error.errorFields?.length || error.errorFields.length === 0) {
					clearValidErrors();
					return;
				}
				error.errorFields.forEach((field: ValidErrorField) => {
					const key = field.name.join('_');
					addValidError(key, field);
				});
				throw error;
			}
		};

		const handleRemove = (index: number) => {
			// debugger;
			const preContainers: Container[] = form.getFieldValue('containers') ?? [];
			preContainers.splice(index, 1);
			form.setFieldValue('containers', [...preContainers]);
			// debugger
		};

		const handleFilter = (searchText: string) => {
			setSearchText(searchText);
		};

		const NumberRender: ColumnType<Container>['render'] = (text, _, index) => {
			const namePath = ['containers', index, 'number'];
			const namePathStr = namePath.join('_');
			const validError = validErrors[namePathStr];
			const isInput = !validError;

			return (
				<Item
					noStyle
					name={namePath}
					rules={[
						{ required: true, message: t('required') },
						{
							pattern: /^[a-zA-Z]{4}\d{7}$/,
							message: t(
								'Must be a unique alpha-numeric combination of seven numbers and four letters',
							),
						},
					]}
				>
					{isInput ? (
						<Input defaultValue={text} />
					) : (
						<ErrorContainer
							msg={validError.errors[0]}
							onClick={() => {
								form.setFieldValue(namePath, '');
								deleteValidError(namePathStr);
							}}
						/>
					)}
				</Item>
			);
		};

		const TypeRender: ColumnType<Container>['render'] = (text, _, index) => {
			const form = Form.useFormInstance();
			const namePath = ['containers', index, 'type'];
			const namePathStr = namePath.join('_');
			const validError = validErrors[namePathStr];
			const isInput = !validError;

			return (
				<Item noStyle name={namePath} rules={[{ required: true, message: t('required') }]}>
					{isInput ? (
						<Select
							className='w-[90px]'
							options={containerSizes.map((i) => ({ label: i, value: i }))}
						/>
					) : (
						<ErrorContainer
							msg={validError.errors[0]}
							onClick={() => {
								form.setFieldValue(namePath, '');
								deleteValidError(namePathStr);
							}}
						/>
					)}
				</Item>
			);
		};

		const WeightRender: ColumnType<Container>['render'] = (text, _, index) => {
			const form = Form.useFormInstance();
			const namePath = ['containers', index, 'weight'];
			const namePathStr = namePath.join('_');
			const validError = validErrors[namePathStr];
			const isInput = !validError;

			return (
				<div className='flex gap-[5px] w-full'>
					<Item
						noStyle
						name={['containers', index, 'weight']}
						rules={[{ required: true, message: t('required') }]}
					>
						{isInput ? (
							<InputNumber controls={false} className='w-1 flex-[2] flex-grow light-border' />
						) : (
							<ErrorContainer
								className='w-1 flex-grow'
								msg={validError.errors[0]}
								onClick={() => {
									form.setFieldValue(namePath, '');
									deleteValidError(namePathStr);
								}}
							/>
						)}
					</Item>
					<Item noStyle name={['containers', index, 'weight_unit']}>
						<Select
							className='w-[65px]'
							options={[
								{ label: 'kg', value: WeightUnit.KG },
								{ label: 'lbs', value: WeightUnit.LB },
							]}
						/>
					</Item>
				</div>
			);
		};

		const PackageRender: ColumnType<Container>['render'] = (text, _, index) => {
			const form = Form.useFormInstance();
			const namePath = ['containers', index, 'package'];
			const namePathStr = namePath.join('_');
			const validError = validErrors[namePathStr];
			const isInput = !validError;

			return (
				<Item
					noStyle
					name={namePath}
					rules={[
						{ required: true, message: t('required') },
						{
							validator: (_, value) => {
								return isNaN(value)
									? Promise.resolve()
									: Promise.reject(t('Unit must be filled in'));
							},
						},
					]}
				>
					{isInput ? (
						<Input className='w-[60px] min-w-0' defaultValue={text} />
					) : (
						<ErrorContainer
							msg={validError.errors[0]}
							onClick={() => {
								form.setFieldValue(namePath, '');
								deleteValidError(namePathStr);
							}}
						/>
					)}
				</Item>
			);
		};

		const fetchCommodity = async () => {
			const resp = await getCommodities();
			setOptions(resp.data.data.map((o) => ({ label: o.name, value: o.name })));
		};

		useEffect(() => {
			fetchCommodity();
		}, []);

		const CommodityRender: ColumnType<Container>['render'] = (text, _, index) => {
			const namePath = ['containers', index, 'commodity'];

			return (
				<Item
					noStyle
					name={namePath}
					rules={[
						{
							message: t('Length must be less than 60 characters'),
							validator: (_, value: string[]) => {
								return value.some((v) => {
									return v.length > 60;
								})
									? Promise.reject(t('Length must be less than 60 characters'))
									: Promise.resolve();
							},
						},
					]}
				>
					<Select className='w-[120px]' options={options} mode='tags' />
				</Item>
			);
		};

		const SOCForm = ({ onSelectSOCLocation, defaultSelected, defaultPort }) => {
			if (defaultSelected == -1) {
				defaultSelected = STAY_IN_WAREHOUSE;
			} else if (defaultSelected === 0) {
				defaultSelected = TBD;
			} else if (defaultSelected > 0) {
				defaultSelected = RETURN_TO_DEPORT;
			}

			const [selected, setSelected] = useState(defaultSelected);
			const _deport = defaultPort
				? {
						label: defaultPort.name + ' - ' + defaultPort.address,
						value: defaultPort.id,
						address: defaultPort.address,
				  }
				: null;
			const [deport, setDepot] = useState<DefaultOptionType>(_deport);
			const [depots, setDepots] = useState<Depot[]>([]);

			const options = [TBD, STAY_IN_WAREHOUSE, RETURN_TO_DEPORT];
			const handleSelect = (v) => {
				setSelected(v);
				if (v == STAY_IN_WAREHOUSE || v == TBD) {
					onSelectSOCLocation(null, v);
				}
			};

			const [loading, setLoading] = useState(false);

			const fetchData = async () => {
				setLoading(true);
				const res = await getDepots();
				setDepots(Array.isArray(res.data.data) ? res.data.data : []);
				setLoading(false);
			};

			useEffect(() => {
				fetchData();
			}, []);

			const ref = useRef(null);

			const onSaved = () => {
				ref?.current?.fetchData();
			};

			return (
				<>
					<Form layout='vertical'>
						<Form.Item label={t('Type')}>
							<Select
								className='w-[200px]'
								allowClear
								value={selected}
								onSelect={handleSelect}
								onClear={() => {
									handleSelect(null);
									setDepot(null);
									onSelectSOCLocation(null, null);
								}}
								options={options.map((o) => ({ label: t(o), value: o }))}
							/>
						</Form.Item>
						{selected == RETURN_TO_DEPORT && (
							<>
								<Form.Item label={t('Location')}>
									<Space.Compact className='w-[400px]'>
										{/* <Select options={depots.map((o) => ({ label: t(o.name), value: o.id }))} /> */}
										<DepotSelect
											ref={ref}
											loading={loading}
											value={deport}
											onSelect={(v) => {
												setDepot(v);
												onSelectSOCLocation(v, selected);
											}}
											depots={depots}
											className='w-[400px]'
										/>
										<AddForm onSaved={onSaved} />
									</Space.Compact>
								</Form.Item>
								<Form.Item>
									{deport && (
										<div>
											{t('Address')} : {deport.address}
										</div>
									)}
								</Form.Item>
							</>
						)}
					</Form>
				</>
			);
		};

		const SpecialConditionRender = ({
			c,
			namePath,
		}: {
			c: ArrElement<typeof specialConditions>;
			namePath: NamePath;
		}) => {
			const isSelected = form.getFieldValue(namePath);
			const isOverweightRender = namePath[2] == 'is_overweight';

			const [isModalOpen, setIsModalOpen] = useState(false);

			const showModal = () => {
				setIsModalOpen(true);
			};

			const handleOk = () => {
				setIsModalOpen(false);
			};

			const handleCancel = () => {
				setIsModalOpen(false);
			};

			const onClick = () => {
				if (namePath[2] == 'is_soc') {
					showModal();
					return;
				} else if (isOverweightRender) {
					return;
				}
				form.setFieldValue(namePath, !isSelected);
			};

			const onSelectSOCLocation = (depot, v: string) => {
				if (depot) {
					form.setFieldValue([namePath[0], namePath[1], 'soc_return_location_id'], depot.value);
					form.setFieldValue([namePath[0], namePath[1], 'soc_return_location'], depot);
					form.setFieldValue(namePath, true);
				} else if (v == STAY_IN_WAREHOUSE) {
					form.setFieldValue([namePath[0], namePath[1], 'soc_return_location_id'], -1);
					form.setFieldValue([namePath[0], namePath[1], 'soc_return_location'], null);
					form.setFieldValue(namePath, true);
				} else if (v == TBD) {
					console.log('TBD');
					form.setFieldValue([namePath[0], namePath[1], 'soc_return_location_id'], 0);
					form.setFieldValue([namePath[0], namePath[1], 'soc_return_location'], null);
					form.setFieldValue(namePath, true);
				} else {
					form.setFieldValue([namePath[0], namePath[1], 'soc_return_location_id'], null);
					form.setFieldValue(namePath, false);
				}

				// setIsModalOpen(false);
			};

			return (
				<div>
					<div className={clsx('text-center', isSelected ? 'text-[#007BFF]' : 'text-[#B2B2B2]')}>
						{c.label}
					</div>
					<div
						style={{
							cursor: isOverweightRender ? 'not-allowed' : 'pointer',
						}}
						onClick={onClick}
					>
						<c.Icon isSelected={isSelected} />
					</div>
					{namePath[2] == 'is_soc' && (
						<Modal
							title={t('SOC Return Location')}
							open={isModalOpen}
							onOk={handleOk}
							onCancel={handleCancel}
							footer={null}
						>
							<SOCForm
								onSelectSOCLocation={onSelectSOCLocation}
								defaultSelected={form.getFieldValue([
									namePath[0],
									namePath[1],
									'soc_return_location_id',
								])}
								defaultPort={form.getFieldValue([namePath[0], namePath[1], 'soc_return_location'])}
							/>
						</Modal>
					)}
				</div>
			);
		};

		const columns: ColumnsType<Container> = [
			{
				title: t('No.'),
				width: 60,
				render: (_, __, index) => <div>{index + 1}</div>,
			},
			{
				title: <RequiredLabel label={t('Container #')} />,
				dataIndex: 'number',
				key: 'number',
				width: 140,
				render: NumberRender,
			},
			{
				title: t('Seal #'),
				dataIndex: 'seal_number',
				key: 'seal_number',
				width: 140,
				render: (text, record, index) => (
					<Item noStyle name={['containers', index, 'seal_number']}>
						<Input />
					</Item>
				),
			},
			{
				title: t('Delivery REF #'),
				dataIndex: 'delivery_reference',
				key: 'delivery_reference',
				width: 140,
				render: (text, record, index) => (
					<Item noStyle name={['containers', index, 'delivery_reference']}>
						<Input />
					</Item>
				),
			},
			{
				title: <RequiredLabel label={t('Size')} />,
				dataIndex: 'type',
				key: 'type',
				width: 120,
				render: TypeRender,
			},
			{
				title: <RequiredLabel label={t('Total Weight')} />,
				// dataIndex: 'weight',
				key: 'weight',
				width: 200,
				render: WeightRender,
			},
			{
				title: (
					<RequiredLabel
						label={
							<>
								{t('Package')}{' '}
								<Tooltip title={t('PS_fill_package_unit')}>
									<InfoCircleOutlined />
								</Tooltip>
							</>
						}
					/>
				),
				dataIndex: 'package',
				key: 'package',
				width: 120,
				render: PackageRender,
			},
			{
				title: (
					<>
						{t('Commodity')}{' '}
						<Tooltip title={'Each commodity name length must be less than 60 characters'}>
							<InfoCircleOutlined />
						</Tooltip>
					</>
				),
				dataIndex: 'commodity',
				key: 'commodity',
				width: 120,
				render: CommodityRender,
			},
			{
				title: t('Special Condition'),
				width: 200,
				key: 'Special Condition',
				render: (text, record, index) => (
					<Space>
						{specialConditions.map((c) => {
							const namePath = ['containers', index, c.key];
							const socPath = ['containers', index, 'soc_return_location_id'];
							return (
								<>
									<Item key={c.key} name={namePath} noStyle>
										<SpecialConditionRender c={c} namePath={namePath} />
									</Item>
									<Item key={c.key} name={socPath} noStyle hidden>
										<Input />
									</Item>
								</>
							);
						})}
					</Space>
				),
			},
			{
				title: ' ',
				width: 100,
				key: 'Actions',
				render: (_, record, index) => <Actions onRemove={() => handleRemove(index)} />,
			},
		];

		const footer = (
			<BottomButtons
				leftButton={<BackButton />}
				rightButton1={<SaveDraftButton />}
				rightButton2={<NextButton onClick={validateFields} />}
				rightButton2Message={
					<div className='h-[22px] text-[#FF0000] text-[14px] font-[400]'>
						{getOneValidError() ? t('Please complete the highlighted fields') : ` `}
					</div>
				}
			/>
		);

		return (
			<Panel footer={footer}>
				{/* header */}
				<div className='mb-[36px] flex justify-between'>
					<div className='flex items-center'>
						<span className='mr-[55px] font-[700] text-[24px] w-[6vw]'>{t('Containers')}</span>
						<Input
							className='h-[40px] w-[350px] rounded-[10px] text-[18px] text-[#B2B2B2]'
							placeholder={t('Search for container')}
							allowClear
							onChange={(e) => {
								handleFilter(e.target.value);
							}}
						/>
					</div>
					<Space size={16}>
						<Button
							className='h-[40px] rounded-[10px] font-[700] text-[18px] text-[#FFFFFF]'
							type='primary'
							onClick={addColumn}
						>
							{t('Add Container')}
						</Button>
					</Space>
				</div>

				{containers && containers.length ? (
					<Table
						className='book-container-table custom-table'
						bordered={false}
						columns={columns}
						dataSource={containers.filter((c) => (c.number ?? '').startsWith(searchText))}
						pagination={false}
						scroll={{
							y: 'calc(100% - 54px);',
						}}
					/>
				) : (
					<div className='flex justify-center items-center h-full w-full'>
						<div className='flex flex-col items-center'>
							<img src={EmptyContainerImg} />
							<div className='font-[400] text-[16px] text-[#003166]'>{t('No container here')}</div>
							<div
								className='font-[700] text-[18px] text-[#003166] underline cursor-pointer'
								onClick={addColumn}
							>
								+ {t('Add a new container')}
							</div>
						</div>
					</div>
				)}
			</Panel>
		);
	},
);
