import { createContext, useContext } from 'react';
import { searchRate } from './search-rate.context';

export const searchRateContext = createContext(searchRate);

export const SearchRateContext: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
	return <searchRateContext.Provider value={searchRate}>{children}</searchRateContext.Provider>;
};

export const useSearchRateContext = () => useContext(searchRateContext);
