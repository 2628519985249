import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetAgentUsersRes } from './interface';

export * from './interface';

export const getAgentUsers = async (params) => {
	return await axios.get<GetAgentUsersRes>(`agent/users`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params,
	});
};
