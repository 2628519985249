import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetRateRes } from './interface';

export const getRateById = async (rid: string) => {
	return await axios.get<GetRateRes>(`/rates/getRate`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params: { rid },
	});
};
