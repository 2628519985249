import React from 'react';
import ShippingContainerSvg from '@/assets/shipping-container.svg';

const ShippingContainerIcon = (
	props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
) => {
	return <img {...props} src={ShippingContainerSvg} />;
};

export default ShippingContainerIcon;
