import { useCallback, useState } from 'react';
import { ValidErrorField } from '@/interfaces/valid-error-field.interface';

export default () => {
	const [validErrors, setValidErrors] = useState<Record<string, ValidErrorField>>({});

	const addValidError = useCallback(
		(key: string, field: ValidErrorField) => {
			setValidErrors((_validErrors) => {
				return {
					..._validErrors,
					[key]: field,
				};
			});
		},
		[setValidErrors],
	);

	const deleteValidError = useCallback(
		(key: string) => {
			setValidErrors((_validError) => {
				delete _validError[key];
				return {
					..._validError,
				};
			});
		},
		[setValidErrors],
	);

	const clearValidErrors = useCallback(() => {
		setValidErrors({});
	}, [setValidErrors]);

	const getOneValidError = useCallback(() => {
		if (!validErrors) return;
		return Object.values(validErrors)[0];
	}, [validErrors]);

	const getError = useCallback(
		(key: string) => {
			return validErrors[key];
		},
		[validErrors],
	);

	return {
		validErrors,
		getError,
		addValidError,
		deleteValidError,
		clearValidErrors,
		getOneValidError,
	};
};

// const { validErrors, addValidError, deleteValidError, clearValidErrors, getOneValidError } = useValidError()
