import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import clsx from 'clsx';

export default ({ formItemKey }: { formItemKey: string }) => {
	const { t } = useTranslation();

	const form = Form.useFormInstance();

	const isResidential = form.getFieldValue('is_residential');

	const handleClick = (value: boolean) => {
		form.setFieldValue(formItemKey, value);
	};

	return (
		<div className='flex h-[54px] rounded-[6px] light-border'>
			{[false, true].map((v) => {
				const isSelected = isResidential === v;

				return (
					<ResidentialButton
						label={v ? t('Yes') : t('No')}
						isSelected={isSelected}
						onClick={() => handleClick(v)}
					/>
				);
			})}
		</div>
	);
};

const ResidentialButton = ({
	label,
	isSelected,
	onClick,
}: {
	label: string;
	isSelected: boolean;
	onClick: () => void;
}) => {
	const selectedClass = 'bg-[#EBF3FF]';

	return (
		<button
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				onClick();
			}}
			className={clsx(
				'flex-grow w-[1px] rounded-[6px] ',
				isSelected ? selectedClass : 'bg-[#FFFFFF]',
			)}
		>
			{label}
		</button>
	);
};
