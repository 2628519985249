import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetProjectsProgressStatisticsProps, GetProjectsProgressStatisticsRes } from './interface';

export * from './interface';

export const getProjectsProgressStatistics = async (props: GetProjectsProgressStatisticsProps) => {
	return await axios.get<GetProjectsProgressStatisticsRes>(`/projectHome/progressStatistics`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
