import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import DepartureIcon from '@/icons/departure-icon';
import DestinationIcon from '@/icons/destination-icon';
import DownloadIcon from '@/icons/download-icon';
import PortIcon from '@/icons/port-icon';
import RampIcon from '@/icons/ramp-icon';
import { useGlobalContext, useSearchRateContext } from '@/services/contexts';
import { userContext } from '@/services/contexts/user';
import { LoadingOutlined } from '@ant-design/icons';
import _, { uniq } from 'lodash';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import useSearchRatesParams from '@/pages/search-rate/hooks/use-search-rates-params';
import { takeScreenShot } from '@/utils/take-screen-shot';
import { OriginalRate } from '../fees/original-rate';

export const Recommended = observer(() => {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const searchRateContext = useSearchRateContext();
	const { screenContext } = useGlobalContext();

	const { searchDatas, selectedSearchData, selectedToCity, selectedIntermodalRegion } =
		searchRateContext;

	const { cleanedSearchParams } = useSearchRatesParams();

	const { order_id } = cleanedSearchParams;

	const user = userContext.getUserInfo();

	const city = selectedSearchData?.to_city ?? selectedToCity;

	const intermodalRegion = selectedSearchData?.intermodal_region ?? selectedIntermodalRegion;

	const truckRatesFromSearchData = (searchDatas || []).map((i) => Number(i.truck_rate));

	const truckRates =
		truckRatesFromSearchData.length > 0
			? uniq([Math.min(...truckRatesFromSearchData), Math.max(...truckRatesFromSearchData)])
			: [];
	const fee = selectedSearchData
		? `$${selectedSearchData.truck_rate}`
		: truckRates && truckRates.length !== 0
		? `$${truckRates.join(' ~ ')}`
		: '';

	const originalBaseRatesFromSearchData = (searchDatas || []).map((i) =>
		Number(i.original_base_rate),
	);

	const originalBaseRates =
		originalBaseRatesFromSearchData.length > 0
			? uniq([
					Math.min(...originalBaseRatesFromSearchData),
					Math.max(...originalBaseRatesFromSearchData),
			  ])
			: [];

	const originalBaseRate = selectedSearchData
		? `$${selectedSearchData.original_base_rate}`
		: originalBaseRates && originalBaseRates.length !== 0
		? `$${originalBaseRates.join(' ~ ')}`
		: '';

	let hasTierRate = false;

	if (selectedSearchData) {
		hasTierRate =
			selectedSearchData.has_tier_rate &&
			Number(selectedSearchData.truck_rate) != Number(selectedSearchData.original_base_rate);
	} else if (searchDatas) {
		const tierRateData = searchDatas.find((s) => s?.has_tier_rate);
		if (tierRateData) {
			hasTierRate = !truckRates.every((rate, index) => rate == originalBaseRates[index]);
		}
	}

	// deprecated
	// const hasTierRate =
	// 	selectedSearchData?.has_tier_rate || searchDatas?.find((s) => s?.has_tier_rate);

	// const bestScenario = useMemo(() => {
	// 	return selectedSearchData?.scenarios?.find((s) => s.name.startsWith('Best Scenario'));
	// }, [selectedSearchData]);

	const book = async () => {
		// if (userContext.getUserInfo().is_prepayable && selectedSearchData.vendor_id) {
		// 	setLoading(true);
		// 	try {
		// 		const resp = await getBalance();
		// 		if (+bestScenario.total_rate > resp.data.data.balance) {
		// 			message.error(t('Insufficient wallet balance'));
		// 			return;
		// 		}
		// 	} catch (error: any) {
		// 		message.error(error?.message);
		// 		return;
		// 	} finally {
		// 		setLoading(false);
		// 	}
		// }

		const query = order_id ? `?orderId=${order_id}&useSearchContext=true` : '';
		navigate(`/search-rate/book${query}`);
	};

	if (searchRateContext.isFetching) return <>no data</>;

	return (
		<div
			style={{ background: 'linear-gradient(90deg, #0094FF -8.68%, #0165FA 100%)' }}
			// className={`pt-[8px] h-[184px] text-[12px]`}
			className={`pt-[8px] h-[${hasTierRate ? '214px' : '184px'}] text-[12px]`}
		>
			{/* header */}
			<div className='flex justify-between items-center ml-[24px] mr-[24px] font-[700] text-[#ffffff]'>
				<div>{t('Recommended')}</div>
				{/* Save box */}
				<div
					className='cursor-pointer'
					onClick={() => {
						// let fileName = 'Quote';
						// selectedSearchData.intermodal_region &&
						// 	(fileName += ' from ' + selectedSearchData.intermodal_region.name);
						// selectedSearchData.to_city && (fileName += ' to ' + selectedSearchData.to_city.name);
						// fileName += ` ${selectedSearchData.uid}.pdf`;
						// downloadSellRate(
						// 	{ path: { sellRateId: selectedSearchData.id }, other: { fileName: fileName } },
						// 	{ preview: false },
						// );
						if (screenContext.isScreenShot) {
							return;
						}
						if (!searchRateContext.selectedSearchData) {
							return;
						}
						const { uid, intermodal_region, to_city } = searchRateContext.selectedSearchData;
						const fileName = `${uid}-${intermodal_region.name}-${to_city.name}.png`;
						screenContext.isScreenShot = true;
						setTimeout(() => {
							takeScreenShot(fileName).then(() => {
								screenContext.isScreenShot = false;
							});
						}, 3000);
					}}
				>
					{screenContext.isScreenShot ? <LoadingOutlined /> : <DownloadIcon />}
					<span className='ml-[4px]'>{t('Save')}</span>
				</div>
			</div>

			{/* content */}
			<div className='pt-[17px] pb-[17px] pl-[21px] pr-[21px] border-[3px] border-solid border-[#007BFF] rounded-[10px] font-[700] bg-[#ffffff]'>
				{/* rate id & icons */}
				<div className='flex justify-between'>
					<div className='text-[#747578]'>
						{t('Rate ID')}: {selectedSearchData?.uid}
					</div>
					{/* icons */}
					<div className='flex'>
						<PortIcon />
						<RampIcon />
					</div>
				</div>
				{/* main content */}
				<div className='flex justify-between'>
					{/* timeline */}
					<div className='flex flex-col '>
						<div>
							<DepartureIcon className='ml-[3px] mr-[7px] ' />
							{intermodalRegion?.name}
						</div>
						<div className='ml-[7px] h-[24px] border-l-[2px] border-t-[0px] border-b-[0px] border-r-[0px] border-l-[#000000] border-solid'></div>
						<div>
							<DestinationIcon className='mr-[7px] w-[16px] h-[16px]' />
							{city?.full_name}
						</div>
					</div>
					{/* fee */}
					<div className='flex flex-col font-[700] text-right'>
						<div className='mb-[8px] text-[#004A99]'>{t('Our Base Rate + Fuel')} </div>
						<div>
							<div className='text-[22px] leading-none text-[#007BFF]'>{fee}</div>

							{hasTierRate && (
								<div className='mb-[8px]'>
									<OriginalRate originalRate={originalBaseRate} />
								</div>
							)}
						</div>
					</div>
				</div>
				{/* expire & book button */}
				<div className='flex justify-between items-baseline'>
					<div className='text-[#747578]'>
						{t('Valid til')} {selectedSearchData?.expired_at}
					</div>
					{user && user.can_order ? (
						<Button
							className='w-[112px] h-[28px] border-[0px] text-[#ffffff] text-[14px] font-[700] bg-[#FF6966]'
							disabled={!toJS(selectedSearchData) || loading}
							onClick={book}
						>
							{t('Book')}
						</Button>
					) : (
						<div></div>
					)}
				</div>
			</div>
		</div>
	);
});
