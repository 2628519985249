import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { SearchPickupNumberAgentsProps, SearchPickupNumberAgentsRes } from './interface';

export const searchPickupNumberAgents = async (props: SearchPickupNumberAgentsProps) => {
	return await axios.get<SearchPickupNumberAgentsRes>(`/pickupNumberAgents`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params: {
			per_page: 50,
			page: 1,
			...props.params,
		},
	});
};
