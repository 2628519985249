import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Select, Table, TableProps } from 'antd';
import Search16pxIcon from '@/icons/search-16px-icon';
import { businessStatusMap, Shipment } from '@/interfaces/shipment.interface';
import '@/styles/no-cell-border-table.less';
import { CalendarOutlined, LoadingOutlined, SyncOutlined } from '@ant-design/icons';
import { useMemoizedFn, useMount, useSetState } from 'ahooks';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { Gap } from '@/components/gap';
import { apiHooks } from '@/hooks/apis';
import { StatusLabel } from '../ftl-orders/status-label';
import useLtlOrdersParams, { LTLOrdersQuery } from './use-ltl-orders-params';

const LTLOrders = observer(({ mode }: { mode?: string }) => {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const { parsedSearchParams, changeSearchParams } = useLtlOrdersParams();

	const [queriesWithoutPagination, setQueriesWithoutPagination] = useSetState<
		Pick<LTLOrdersQuery, 'business_status' | 'last_days' | 'query' | 'sort_by' | 'sort_value'>
	>({
		business_status: parsedSearchParams.business_status ?? [],
		last_days: parsedSearchParams.last_days ?? '30',
		query: parsedSearchParams.query ?? '',
		sort_by: parsedSearchParams.sort_by ?? '',
		sort_value: parsedSearchParams.sort_value ?? 'desc',
	});

	const {
		runAsync: getShipmentList,
		data: shipmentList,
		loading: shipmentListLoading,
		pagination,
	} = mode == 'agent'
		? apiHooks.useGetAgentShipmentList(
				{
					refreshDeps: [queriesWithoutPagination],
					refreshDepsAction: () => {
						getShipmentList(
							{ current: 1, pageSize },
							{
								params: {
									...queriesWithoutPagination,
									business_status: (queriesWithoutPagination.business_status ?? [])
										.flatMap((i) => (i === '40' ? ['40', '60'] : [i]))
										.join(','),
									last_days:
										queriesWithoutPagination.last_days == 'all'
											? ''
											: queriesWithoutPagination.last_days,
								},
							},
						);
					},
				},
				'ltl',
		  )
		: apiHooks.useGetShipmentList({
				refreshDeps: [queriesWithoutPagination],
				refreshDepsAction: () => {
					getShipmentList(
						{ current: 1, pageSize },
						{
							params: {
								...queriesWithoutPagination,
								business_status: (queriesWithoutPagination.business_status ?? [])
									.flatMap((i) => (i === '40' ? ['40', '60'] : [i]))
									.join(','),
								last_days:
									queriesWithoutPagination.last_days == 'all'
										? ''
										: queriesWithoutPagination.last_days,
							},
						},
					);
				},
		  });

	const { current, total, pageSize, onChange: changePagination } = pagination;

	const columns: TableProps<Shipment>['columns'] = [
		{
			title: t(`Order & Reference`),
			dataIndex: 'uid',
			width: 180,
			fixed: 'left',
			render: (value, record) => (
				<div>
					<div>{value}</div>
					<div className='text-grey-01'>{record.customer_reference_number ?? ''}</div>
				</div>
			),
		},
		{
			title: t('User'),
			dataIndex: ['user', 'name'],
			width: 120,
		},
		{
			title: t(`Carrier & Pro Number`),
			dataIndex: 'vendor_carrier_name',
			width: 180,
			render: (_, record) => (
				<div>
					<div>{record.vendor_carrier_name}</div>
					<div className='text-grey-01'>{record.pro_number ? `PRO ${record.pro_number}` : ''}</div>
				</div>
			),
		},
		{
			title: t(`Status`),
			dataIndex: 'business_status',
			width: 120,
			render: (value) => <StatusLabel status={value} text={businessStatusMap[value]} />,
		},
		{
			title: t(`Delivery Date`),
			dataIndex: 'actual_delivery_date',
			width: 140,
			render: (_, record) =>
				dayjs(record.actual_delivery_date ?? record.estimated_delivery_date).format('YYYY-MM-DD'),
		},
		{
			title: t(`Pickup City`),
			dataIndex: 'pickup_zipcode',
			width: 200,
			render: (_, record) => (
				<div>
					<div>{record.pickup_city.name}</div>
					<div className='text-grey-01'>{`${record.pickup_city.state} - ${record.pickup_zipcode}`}</div>
				</div>
			),
		},
		{
			title: t(`Pickup Date`),
			dataIndex: 'actual_pickup_date',
			width: 140,
			render: (_, record) =>
				dayjs(record.actual_pickup_date ?? record.estimated_pickup_date).format('YYYY-MM-DD'),
		},
		{
			title: t(`Destination`),
			dataIndex: 'destination_zipcode',
			width: 200,
			render: (_, record) => (
				<div>
					<div>{record.destination_city.name}</div>
					<div className='text-grey-01'>{`${record.destination_city.state} - ${record.destination_zipcode}`}</div>
				</div>
			),
		},
		{
			title: t(`Actions`),
			key: 'actions',
			width: 120,
			fixed: 'right',
			render: (_, record) => (
				<Button type='link' className='px-0' onClick={() => navigate(`/ltl-orders/${record.uid}`)}>
					{t(`View`)}
				</Button>
			),
		},
	];

	const _columns = columns.filter((column) => !(mode != 'agent' && column.title === t('User')));

	const handleChange = useMemoizedFn((newQueries: LTLOrdersQuery) => {
		console.log(`[handleChange] newQueries`, newQueries);

		if (Number.isInteger(newQueries.page) && Number.isInteger(newQueries.per_page)) {
			changePagination(newQueries.page, newQueries.per_page);
		} else if (
			Object.keys(newQueries).some((k) =>
				['sort_by', 'sort_value', 'query', 'business_status', 'last_days'].includes(k),
			)
		) {
			setQueriesWithoutPagination(newQueries);
		}

		changeSearchParams(newQueries);
	});

	const handleChangePagination = useMemoizedFn((page: number, pageSize: number) => {
		handleChange({
			page,
			per_page: pageSize,
		});
	});

	useMount(async () => {
		await getShipmentList(
			{ pageSize: parsedSearchParams.per_page ?? 20, current: parsedSearchParams.page ?? 1 },
			{
				params: {
					...queriesWithoutPagination,
					business_status: (queriesWithoutPagination.business_status ?? []).join(','),
					last_days:
						queriesWithoutPagination.last_days == 'all' ? '' : queriesWithoutPagination.last_days,
				},
			},
		);
		changePagination(parsedSearchParams.page ?? 1, parsedSearchParams.per_page ?? 20);
	});

	return (
		<div className='h-screen w-full p-[48px] flex flex-col bg-white overflow-hidden'>
			{/* header */}
			<div className='grow-0 shrink-0 w-full h-[44px] flex items-center gap-[8px]'>
				<div className='h3-b text-black'>
					{mode == 'agent' ? t(`LTL Orders`) : t(`My LTL Orders`)}
				</div>
				{shipmentListLoading && <LoadingOutlined />}
			</div>

			<Gap height='36px' />

			{/* filters */}
			<div className='grow-0 shrink-0 w-full h-[42px] flex items-center gap-[12px]'>
				<Input
					value={queriesWithoutPagination.query}
					prefix={<Search16pxIcon />}
					placeholder={t(`Search Zipcode, Order #, Reference #`)}
					className='h-[42px] w-[360px] border-[1px] border-solid border-grey-04'
					onChange={(e) => handleChange({ query: e.target.value })}
				/>

				<div className='p-[0_12px] h-[42px] w-[194px] border-[1px] border-solid border-grey-04 bg-white rounded-[4px] flex justify-center items-center'>
					<CalendarOutlined />
					<Select
						value={queriesWithoutPagination.last_days}
						suffixIcon={null}
						showSearch
						optionFilterProp='label'
						variant='borderless'
						options={[
							{ label: t(`Last 30 days`), value: '30' },
							{ label: t(`Last 60 days`), value: '60' },
							{ label: t(`Last 90 days`), value: '90' },
							{ label: t('all'), value: 'all' },
						]}
						className='w-full'
						onChange={(value) => handleChange({ last_days: value })}
					/>
				</div>

				<div className='p-[0_12px] h-[42px] min-w-[194px] border-[1px] border-solid border-grey-04 bg-white rounded-[4px] flex justify-center items-center'>
					<Select
						value={queriesWithoutPagination.business_status}
						suffixIcon={null}
						mode='multiple'
						showSearch
						optionFilterProp='label'
						variant='borderless'
						options={Object.entries(businessStatusMap)
							.filter(([value, label]) => value !== '60')
							.map(([value, label]) => ({ label: t(label), value: `${value}` }))}
						placeholder={t(`Status`)}
						allowClear
						className='w-full'
						onChange={(value) => handleChange({ business_status: value })}
					/>
				</div>

				<Button
					type='link'
					icon={<SyncOutlined />}
					onClick={() =>
						handleChange({
							query: '',
							last_days: '30',
							business_status: undefined,
						})
					}
				>
					{<span className='body-3-m text-primary-regular'>{t(`Reset`)}</span>}
				</Button>
			</div>

			<Gap height='24px' />

			{/* table */}
			<Table
				className='no-cell-border-table'
				rowKey='id'
				columns={_columns}
				dataSource={shipmentList}
				bordered
				pagination={{
					position: ['bottomCenter'],
					showSizeChanger: true,
					total,
					current,
					pageSize,
					onChange: handleChangePagination,
				}}
				scroll={{ scrollToFirstRowOnChange: true, x: 'max-content', y: 'calc(100vh - 337px)' }}
			/>
		</div>
	);
});

export default LTLOrders;
