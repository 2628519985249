import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { CreateWarehouseProps, CreateWarehouseRes } from './interface';

export const createWarehouse = async (props: CreateWarehouseProps) => {
	return await axios.post<CreateWarehouseRes>(`/newWarehouses`, props.data, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
