import React from 'react';
import IndustryNewsActiveSvg from '@/assets/industry-news-active.svg';

const IndustryNewsActiveIcon = (
	props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
) => {
	return <img {...props} src={IndustryNewsActiveSvg} />;
};

export default IndustryNewsActiveIcon;
