import { useCallback, useState } from 'react';

export const useContainerDetailModal = () => {
	const [openingContainerDetailModal, setOpeningContainerDetailModal] = useState(false);

	const openContainerDetailModal = useCallback(() => {
		setOpeningContainerDetailModal(true);
	}, [setOpeningContainerDetailModal]);

	const closeContainerDetailModal = useCallback(() => {
		setOpeningContainerDetailModal(false);
	}, [setOpeningContainerDetailModal]);

	return {
		openingContainerDetailModal,
		openContainerDetailModal,
		closeContainerDetailModal,
	};
};
