import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Popover, Space, Tabs, TabsProps } from 'antd';
import DropActiveIcon from '@/icons/drop-active-icon';
import DropIcon from '@/icons/drop-icon';
import { CalendarOutlined, FundProjectionScreenOutlined } from '@ant-design/icons';
import { Gap } from '@/components/gap';
import { apiHooks } from '@/hooks/apis';
import BackButton from './back-button';
import './index.less';

export const ProjectLabel = ({ title, content }: { title?: string; content: string[] }) => {
	const moreContent = content.slice(1);

	const hasMore = moreContent.length > 0;

	return (
		<div className='box-border h-[20px] p-[0_8px] flex items-center border-[1px] border-solid border-grey-05 rounded-[10px] bg-grey-06 text-grey-01 foot-note-1-r'>
			{title && (
				<>
					{title}
					<Gap width='4px' />
				</>
			)}
			{content[0]}
			<Gap width='4px' />
			{hasMore && (
				<Popover
					title=''
					content={
						<Space wrap className='max-w-[240px]'>
							{moreContent.map((c) => (
								<ProjectLabel content={[c]} />
							))}
						</Space>
					}
				>
					<span className=' cursor-pointer'>{`+${moreContent.length}`}</span>
				</Popover>
			)}
		</div>
	);
};

interface ProjectHeaderProps {
	activeKey: string;
	onActiveKeyChange: (activeKey: string) => void;
}

export const ProjectHeader = ({ activeKey, onActiveKeyChange }: ProjectHeaderProps) => {
	const { t } = useTranslation();

	const { projectId } = useParams();

	const { data } = apiHooks.useGetProject({
		cacheKey: `get-project-${projectId}`,
		defaultParams: [{ path: { projectId } }],
	});

	const items: TabsProps['items'] = [
		{
			key: 'Overview',
			label: (
				<div className='flex items-center'>
					<FundProjectionScreenOutlined style={{ fontSize: '21px', marginRight: '4px' }} />
					<span className='body-4-sb'>{t(`Overview`)}</span>
				</div>
			),
		},
		{
			key: 'Containers',
			label: (
				<div className='flex items-center'>
					{activeKey === 'Containers' ? (
						<DropActiveIcon style={{ fontSize: '21px', marginRight: '4px' }} />
					) : (
						<DropIcon style={{ fontSize: '21px', marginRight: '4px' }} />
					)}
					<span className='body-4-sb'>{t(`Containers`)}</span>
				</div>
			),
		},
		{
			key: 'Calendar',
			label: (
				<div className='flex items-center'>
					<CalendarOutlined style={{ fontSize: '21px', marginRight: '4px' }} />
					<span className='body-4-sb'>{t(`Calendar`)}</span>
				</div>
			),
		},
	];

	return (
		<div
			style={{
				borderBottom: '1px solid #D0D4E4',
			}}
			className='flex flex-col justify-center p-[12px_24px_0_24px] w-full bg-white'
		>
			<div className='flex items-center justify-between'>
				<div className='grow-0 shrink-0 flex items-center'>
					<div className='mr-[16px] h3-b text-primary-dark-01'>{data?.name ?? ''}</div>
					<Space>
						{!!data?.criteria?.port_of_discharges?.length && (
							<ProjectLabel
								title={`${t('POD')}:`}
								content={data.criteria.port_of_discharges.map((p) => p.name)}
							/>
						)}
						{!!data?.criteria?.final_ports?.length && (
							<ProjectLabel
								title={`${t('IR City')}:`}
								content={data.criteria.final_ports.map((p) => p.name)}
							/>
						)}
						{!!data?.criteria?.warehouses?.length && (
							<ProjectLabel
								title={`${t('Warehouses')}:`}
								content={data.criteria.warehouses.map((w) => w.name)}
							/>
						)}
						{!!data?.criteria?.time_range?.created_at && (
							<ProjectLabel
								title={`${t('Created At')}:`}
								content={[
									`${data.criteria.time_range.created_at.start} - ${data.criteria.time_range.created_at.end}`,
								]}
							/>
						)}
						{!!data?.criteria?.time_range?.final_port_eta && (
							<ProjectLabel
								title={`${t('Final Port ETA')}:`}
								content={[
									`${data.criteria.time_range.final_port_eta.start} - ${data.criteria.time_range.final_port_eta.end}`,
								]}
							/>
						)}
					</Space>
				</div>

				<BackButton />
			</div>

			<Tabs
				accessKey={activeKey}
				items={items}
				className='project-tabs flex-grow flex-shrink justify-end'
				onChange={onActiveKeyChange}
			/>
		</div>
	);
};
