import { createProject } from '@/services/apis';
import { useRequest } from 'ahooks';
import { message } from '@/utils/message';

export const useCreateProject = (options?: Record<any, any>) => {
	const res = useRequest(createProject, {
		manual: true,
		onError: (error) => {
			// @ts-ignore
			message.error(`${error.response?.data?.message ?? error.message}`);
		},
		...options,
	});

	return {
		...res,
		data: res.data?.data?.data,
	};
};
