import { useTranslation } from 'react-i18next';
import { Select, SelectProps } from 'antd';
import { useGlobalContext } from '@/services/contexts';

export type PodMultiSelectorProps = SelectProps;

export const PodMultiSelector = (props: PodMultiSelectorProps) => {
	const { t } = useTranslation();

	const { cacheContext } = useGlobalContext();

	const options = cacheContext.intermodalRegions.map((i) => ({
		label: i.name,
		value: i.id,
	}));

	return (
		<Select
			mode='multiple'
			labelInValue
			optionFilterProp='label'
			placeholder={t('Keep blank to select all.')}
			style={{ width: '100%' }}
			options={options}
			tagRender={() => null}
			{...props}
		/>
	);
};
