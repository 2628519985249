import { useSafeState } from 'ahooks';
import { observer } from 'mobx-react-lite';
import { CalendarPanel } from './calendar-panel';
import { ContainersPanel } from './containers-panel';
import { ProjectHeader } from './header';
import { OverviewPanel } from './overview-panel';

export default observer(() => {
	const [activeKey, setActiveKey] = useSafeState<'Overview' | 'Containers' | 'Calendar'>(
		'Overview',
	);

	return (
		<div className='h-screen w-full flex flex-col overflow-hidden'>
			{/* header */}
			<div className='grow-0 shrink-0'>
				<ProjectHeader activeKey={activeKey} onActiveKeyChange={(k) => setActiveKey(k as any)} />
			</div>

			{/* main */}
			<div className='flex grow shrink overflow-hidden'>
				{activeKey === 'Overview' && (
					<div className='w-full h-full p-[12px] bg-[#f1f5f8]'>
						<OverviewPanel />
					</div>
				)}
				{activeKey === 'Containers' && <ContainersPanel />}
				{activeKey === 'Calendar' && <CalendarPanel />}
			</div>
		</div>
	);
});
