import { baseURL } from '..';
import { downloadFactory } from '../download-factory';
import { DownloadFtlDefaultBOLFileReq } from './interface';

export const downloadFtlDefaultBOLFile = async (
	props: DownloadFtlDefaultBOLFileReq,
	config?: {
		preview?: boolean;
		previewTarget?: string;
	},
) => {
	const { shipmentId, fileName } = props;
	const url = baseURL + `ftl/shipments/${shipmentId}/bol`;

	downloadFactory(url, fileName, {
		preview: config?.preview,
		previewTarget: config?.previewTarget,
	});
};
