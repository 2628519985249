import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { SearchRatesProps, SearchRatesRes } from './interface';

export const searchRates = async (props: SearchRatesProps) => {
	return await axios.get<SearchRatesRes>(`/rates/advanceSearch`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params: props.params,
	});
};
