import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DatePicker, Select, SelectProps, Spin } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import ActualPickup16pxIcon from '@/icons/actual-pickup-16px-icon';
import Destination16pxIcon from '@/icons/destination-16px-icon';
import EstPickup16pxIcon from '@/icons/est-pickup-16px-icon';
import Eta16pxIcon from '@/icons/eta-16px-icon';
import Ir16pxIcon from '@/icons/ir-16px-icon';
import Lfd16pxIcon from '@/icons/lfd-16px-icon';
import Mbl16pxIcon from '@/icons/mbl-16px-icon';
import Progress16pxIcon from '@/icons/progress-16px-icon';
import { useGlobalContext } from '@/services/contexts';
import { apiHooks } from '@/hooks/apis';
import { useProgressMap } from '../../use-progress-map';

const { RangePicker } = DatePicker;

export enum FilterOptionKeys {
	progress = 'progress',
	mbl = 'mbl',
	final_port_ids = 'final_port_ids',
	warehouse_ids = 'warehouse_ids',
	final_port_eta = 'final_port_eta',
	lfd = 'lfd',
	schedule_terminal_pickup_at = 'schedule_terminal_pickup_at',
	actual_terminal_pickuped_at = 'actual_terminal_pickuped_at',
}

const ProgressSelector = (props?: SelectProps) => {
	const { progressMap } = useProgressMap();

	const options = Object.entries(progressMap).map(([k, v]) => ({
		label: v.title,
		value: k,
	}));

	return (
		<Select
			mode='multiple'
			optionFilterProp='label'
			style={{ width: '100%' }}
			options={options as { label: string; value: string }[]}
			maxTagCount='responsive'
			{...props}
		/>
	);
};

const MBLSelector = (props?: SelectProps) => {
	const { projectId } = useParams();

	const { data, loading } = apiHooks.useGetProjectMBLs({
		defaultParams: [{ path: { projectId } }],
	});

	const options = data.map((i) => ({
		label: i,
		value: i,
	}));

	return (
		<Select
			mode='multiple'
			optionFilterProp='label'
			style={{ width: '100%' }}
			options={options}
			maxTagCount='responsive'
			loading={loading}
			{...props}
		/>
	);
};

const IRSelector = (props?: SelectProps) => {
	const { cacheContext } = useGlobalContext();

	const options = cacheContext.intermodalRegions.map((i) => ({
		label: i.name,
		value: i.id,
	}));

	return (
		<Select
			mode='multiple'
			labelInValue
			optionFilterProp='label'
			style={{ width: '100%' }}
			options={options}
			maxTagCount='responsive'
			showSearch
			{...props}
		/>
	);
};

const DestinationSelector = (props?: SelectProps) => {
	const { runAsync, data, loading } = apiHooks.useSearchWarehouses({
		debounceWait: 500,
	});

	const options = (data ?? []).map((i) => ({
		label: i.name,
		value: i.id,
	}));

	return (
		<Select
			mode='multiple'
			labelInValue
			optionFilterProp='label'
			style={{ width: '100%' }}
			options={options as { label: string; value: number }[]}
			notFoundContent={loading ? <Spin size='small' /> : null}
			onSearch={(value) => {
				if (value.length < 2) return;
				runAsync({
					params: {
						keyword: value,
					},
				});
			}}
			{...props}
		/>
	);
};

const DateRangeSelector = (props?: RangePickerProps) => {
	return <RangePicker {...props} />;
};

export const useOptions = (values?: FilterOptionKeys[]) => {
	const { t } = useTranslation();

	const options = [
		{
			label: t(`Progress`),
			value: FilterOptionKeys.progress,
			icon: <Progress16pxIcon />,
			selector: ProgressSelector,
		},
		{
			label: t(`MBL`),
			value: FilterOptionKeys.mbl,
			icon: <Mbl16pxIcon />,
			selector: MBLSelector,
		},
		{
			label: t(`IR`),
			value: FilterOptionKeys.final_port_ids,
			icon: <Ir16pxIcon />,
			selector: IRSelector,
		},
		{
			label: t(`Destination`),
			value: FilterOptionKeys.warehouse_ids,
			icon: <Destination16pxIcon />,
			selector: DestinationSelector,
		},
		{
			label: t(`Final Port ETA`),
			value: FilterOptionKeys.final_port_eta,
			icon: <Eta16pxIcon />,
			selector: DateRangeSelector,
		},
		{
			label: t(`LFD`),
			value: FilterOptionKeys.lfd,
			icon: <Lfd16pxIcon />,
			selector: DateRangeSelector,
		},
		{
			label: t(`Est. Pickup`),
			value: FilterOptionKeys.schedule_terminal_pickup_at,
			icon: <EstPickup16pxIcon />,
			selector: DateRangeSelector,
		},
		{
			label: t(`Actual Pickup`),
			value: FilterOptionKeys.actual_terminal_pickuped_at,
			icon: <ActualPickup16pxIcon />,
			selector: DateRangeSelector,
		},
	];

	return values ? options.filter((o) => values.includes(o.value)) : options;
};
