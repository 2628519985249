import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Typography } from 'antd';

const { Text } = Typography;

export interface PalletData {
	palletLengthInches: number;
	palletWidthInches: number;
	palletHeightMaxInches: number;
}

interface PalletInputComponentProps {
	inputData: PalletData;
	onInputChange: (field: keyof PalletData, value: number) => void;
}

const inchesToCm = (inches: number): number => Math.ceil(inches * 2.54);

export const PalletInputComponent: React.FC<PalletInputComponentProps> = ({
	inputData,
	onInputChange,
}) => {
	const { t } = useTranslation();

	const handleInchesChange = (value: string, field: keyof PalletData) => {
		onInputChange(field, Math.min(Math.max(Number(value) || 1, 1), 9999));
	};

	const renderDimensionInput = (field: keyof PalletData) => {
		const label =
			field === 'palletHeightMaxInches'
				? 'Height'
				: field.replace('pallet', '').replace('Inches', '');
		return (
			<div key={field} className='flex flex-col items-start'>
				<Text className={'mb-2'}>{t(label)}</Text>
				{field === 'palletHeightMaxInches' && (
					<div className='flex flex-row items-center'>
						<Input
							type='number'
							value={inputData[field]}
							onChange={(e) => handleInchesChange(e.target.value, field)}
							placeholder={t(label)}
							min={20}
							max={9999}
							className='w-20 mb-1'
						/>
						<Text className='ml-5'>inches</Text>
					</div>
				)}
				{field !== 'palletHeightMaxInches' && (
					<Input
						type='number'
						value={inputData[field]}
						onChange={(e) => handleInchesChange(e.target.value, field)}
						placeholder={t(label)}
						min={1}
						max={9999}
						className='w-20 mb-1'
					/>
				)}
				<Text>{inchesToCm(inputData[field])} cm</Text>
			</div>
		);
	};

	return (
		<>
			<h3 className='text-xl font-semibold mb-3'>{t('Pallet Spec')}</h3>
			<div className='flex flex-col items-start w-full max-w-md'>
				<div className='flex flex-row gap-5 mb-7 w-full text-base'>
					{renderDimensionInput('palletLengthInches')}
					{renderDimensionInput('palletWidthInches')}
					{renderDimensionInput('palletHeightMaxInches')}
				</div>
				<Text className='text-base'>
					{t('The maximum load capacity of a single pallet is')}: 2000lbs
				</Text>
			</div>
		</>
	);
};
