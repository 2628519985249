import { useLTLContext } from '@/services/contexts';
import { useBoolean, useMemoizedFn } from 'ahooks';

export const useServiceDetailModal = () => {
	const ltlContext = useLTLContext();

	const [isOpen, { setTrue: open, setFalse: close }] = useBoolean(false);

	const onClose = useMemoizedFn(() => {
		ltlContext.step = 4;
		close();
	});

	return {
		isOpen,
		open,
		close: onClose,
	};
};
