import { useTranslation } from 'react-i18next';
import { Modal, ModalProps } from 'antd';
import { useBoolean } from 'ahooks';
import { Gap } from '@/components/gap';
import { CargoInsuranceExcludedCommoditiesTable } from '@/pages/ftl-get-rates/finalize-shipment-details/service-detail-modal/cargo-insurance-excluded-commodities-table';

export const InsuranceExclusionsDeclarationModal = (props: ModalProps) => {
	const { t } = useTranslation();

	const [isOpen, { setTrue: open, setFalse: close }] = useBoolean(false);

	const handleClose = () => close();

	return (
		<>
			<span className='text-primary-regular cursor-pointer' onClick={() => open()}>
				{t(`Insurance Exclusions Declaration`)}
			</span>
			<Modal {...props} open={isOpen} onCancel={handleClose} footer={null} width={'1000px'}>
				<div className='w-full h-[80vh] flex flex-col'>
					<div className='h4-b text-black font-[700]'>{t(`Insurance Exclusions Declaration`)}</div>
					<Gap height='8px' />
					<div className='overflow-y-scroll'>
						<CargoInsuranceExcludedCommoditiesTable />
					</div>
				</div>
			</Modal>
		</>
	);
};
