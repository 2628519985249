import { useTranslation } from 'react-i18next';
import {
	Button,
	Input,
	message,
	Popconfirm,
	Select,
	Space,
	Table,
	TableProps,
	Typography,
} from 'antd';
import Search16pxIcon from '@/icons/search-16px-icon';
import { Doc, PrepayOrder } from '@/interfaces/prepay-order.interface';
import { getDocUrl } from '@/services/apis';
import { useAgentContext } from '@/services/contexts/agent';
import { userContext } from '@/services/contexts/user';
import { LoadingOutlined, SyncOutlined } from '@ant-design/icons';
import { useMemoizedFn, useMount, useSetState } from 'ahooks';
import { observer } from 'mobx-react-lite';
import { Gap } from '@/components/gap';
import { apiHooks } from '@/hooks/apis';
import useAgentWalletParams, { AgentWalletQuery } from './use-agent-wallet-params';

const STATUS_APPROVED = 1;
const STATUS_PENDING = 0;
const STATUS_REJECTED = -1;
const AGENT_TIER_0 = 0;
const AGENT_TIER_1 = 1;
const AGENT_TIER_2 = 2;

const SORT_MAP = {
	ascend: 'asc',
	descend: 'desc',
};

const AgentWallet = observer(() => {
	const { t } = useTranslation();

	const { parsedSearchParams, changeSearchParams } = useAgentWalletParams();

	const [queriesWithoutPagination, setQueriesWithoutPagination] = useSetState<
		Omit<AgentWalletQuery, 'page' | 'per_page'>
	>({
		sort_by: undefined,
		sort_value: 'desc',
		search_keyword: '',
		status: undefined,
	});

	const {
		runAsync: getOrderList,
		data: orderList,
		loading: orderListLoading,
		pagination,
		refresh,
	} = apiHooks.useGetAgentCustomerPrepayOrders({
		refreshDeps: [queriesWithoutPagination],
		refreshDepsAction: () => {
			getOrderList(
				{
					current: pagination.current,
					pageSize: pagination.pageSize,
				},
				{
					...queriesWithoutPagination,
				},
			);
		},
	});

	const { current, total, pageSize, onChange: changePagination } = pagination;

	const agentContext = useAgentContext();
	const handlePreview = async (file: Doc, record: PrepayOrder) => {
		const resp = await getDocUrl(file.id.toString(), 'prepay_documents', record.id);
		const url = resp.data;
		window.open(url, '_blank');
	};
	const handleApprovePrepayOrder = async (id: number, user_id: number) => {
		try {
			const resp = await agentContext.approvePrepayOrder(id, user_id);
			message.success(resp.data.message);
			refresh();
		} catch (e) {
			message.error(e.message);
		}
	};
	const handleRejectPrepayOrder = async (id: number, user_id: number) => {
		try {
			const resp = await agentContext.rejectPrepayOrder(id, user_id);
			message.success(resp.data.message);
			refresh();
		} catch (e) {
			message.error(e.message);
		}
	};

	const columns: TableProps<PrepayOrder>['columns'] = [
		{
			title: t('Order Number'),
			dataIndex: 'order_number',
			sorter: true,
			width: 160,
			fixed: 'left',
		},
		{
			title: t('Customer'),
			dataIndex: ['account', 'user_name'],
			width: 120,
		},
		{
			title: t('Prepaid Amount'),
			dataIndex: 'prepaid_amount',
			width: 160,
		},
		{
			title: t('Prepaid Currency'),
			dataIndex: 'prepaid_currency',
			width: 160,
		},
		{
			title: t('Exchange Rate'),
			dataIndex: 'exchange_rate',
			width: 160,
		},
		{
			title: t('Exchange Rate Cost'),
			dataIndex: 'exchange_rate_cost',
			width: 180,
		},
		{
			title: t('Amount'),
			dataIndex: 'amount',
			width: 140,
		},
		{
			title: t('Bank Name'),
			dataIndex: 'bank_name',
			width: 160,
		},
		{
			title: t('Status'),
			dataIndex: 'status_name',
			width: 160,
			render: (v) => t(v),
		},
		{
			title: t('Doc of receipt'),
			dataIndex: 'docs',
			width: 160,
			render: (value, record) => (
				<>
					{record.documents.map((d: Doc) => (
						<Typography.Link key={d.id} onClick={() => handlePreview(d, record)}>
							{d.file_name}
						</Typography.Link>
					))}
				</>
			),
		},
		{
			title: t('Order Date'),
			dataIndex: 'created_at',
			sorter: true,
			width: 200,
		},
		{
			title: t('Actions'),
			key: 'actions',
			width: 120,
			fixed: 'right',
			render: (value, record) => (
				<>
					{record.status === STATUS_PENDING &&
						userContext.getUserInfo().agent_tier == AGENT_TIER_0 && (
							<Space>
								<Popconfirm
									title={t('Are you sure to approve this order?')}
									onConfirm={() => handleApprovePrepayOrder(record.id, record.account.user_id)}
								>
									<Button type='link' className='px-0'>
										{t('Approve')}
									</Button>
								</Popconfirm>
								<Popconfirm
									title={t('Are you sure to reject this order?')}
									onConfirm={() => handleRejectPrepayOrder(record.id, record.account.user_id)}
								>
									<Button type='link' className='px-0'>
										{t('Reject')}
									</Button>
								</Popconfirm>
							</Space>
						)}
				</>
			),
		},
	];

	const handleChange = useMemoizedFn((newQueries: AgentWalletQuery) => {
		console.log(`[handleChange] newQueries`, newQueries);

		if (Number.isInteger(newQueries.page) && Number.isInteger(newQueries.per_page)) {
			changePagination(newQueries.page, newQueries.per_page);
		} else if (
			Object.keys(newQueries).some((k) =>
				['sort_by', 'sort_value', 'search_keyword', 'status'].includes(k),
			)
		) {
			setQueriesWithoutPagination(newQueries);
		}

		changeSearchParams(newQueries);
	});

	const handleChangeTable = useMemoizedFn<TableProps<PrepayOrder>['onChange']>(
		(pagination, filters, sorter) => {
			console.log('handleChangeTable', pagination, filters, sorter);
			handleChange({ page: pagination.current, per_page: pagination.pageSize });
			handleChange({
				// @ts-expect-error type not matche
				sort_by: sorter.field,
				// @ts-expect-error type not matched
				sort_value: sorter.order ? SORT_MAP[sorter.order] : sorter.order,
			});
		},
	);

	useMount(async () => {
		await getOrderList(
			{
				current: parsedSearchParams.page ?? 1,
				pageSize: parsedSearchParams.per_page ?? 20,
			},
			{
				...queriesWithoutPagination,
			},
		);
		changePagination(parsedSearchParams.page ?? 1, parsedSearchParams.per_page ?? 20);
	});

	return (
		<div className='h-screen w-full p-[48px] flex flex-col bg-white overflow-hidden'>
			{/* header */}
			<div className='grow-0 shrink-0 w-full h-[44px] flex items-center gap-[8px]'>
				<div className='h3-b text-black'>{t('Wallet')}</div>
				{orderListLoading && <LoadingOutlined />}
			</div>

			<Gap height='36px' />

			{/* filters */}
			<div className='grow-0 shrink-0 w-full h-[42px] flex items-center gap-[12px]'>
				<Input
					value={queriesWithoutPagination.search_keyword}
					prefix={<Search16pxIcon />}
					placeholder={t('Enter order numbers or bill number')}
					className='h-[42px] w-[360px] border-[1px] border-solid border-grey-04'
					onChange={(e) => handleChange({ search_keyword: e.target.value })}
				/>

				<div className='p-[0_12px] h-[42px] min-w-[194px] border-[1px] border-solid border-grey-04 bg-white rounded-[4px] flex justify-center items-center'>
					<Select
						value={queriesWithoutPagination.status}
						suffixIcon={null}
						showSearch
						optionFilterProp='label'
						variant='borderless'
						options={[
							{ label: t('Pending'), value: 0 },
							{ label: t('Approved'), value: 1 },
							{ label: t('Rejected'), value: -1 },
						]}
						placeholder={t(`Status`)}
						allowClear
						className='w-full'
						onChange={(value) => handleChange({ status: value })}
					/>
				</div>

				<Button
					type='link'
					icon={<SyncOutlined />}
					onClick={() =>
						handleChange({
							search_keyword: '',
							status: undefined,
						})
					}
				>
					{<span className='body-3-m text-primary-regular'>{t(`Reset`)}</span>}
				</Button>
			</div>

			<Gap height='24px' />

			{/* table */}
			<Table
				className='no-cell-border-table'
				rowKey='id'
				columns={columns}
				dataSource={orderList}
				bordered
				pagination={{
					position: ['bottomCenter'],
					showSizeChanger: true,
					total,
					current,
					pageSize,
				}}
				scroll={{ scrollToFirstRowOnChange: true, x: 'max-content', y: 'calc(100vh - 337px)' }}
				onChange={handleChangeTable}
			/>
		</div>
	);
});

export default AgentWallet;
