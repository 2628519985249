import iconFactory from './icon-factory';

const _Svg = () => (
	<svg width='28' height='33' viewBox='0 0 28 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M14.0001 29.2499C17.389 26.1388 19.9029 23.3124 21.5417 20.7708C23.1806 18.2291 24.0001 15.9721 24.0001 13.9999C24.0001 10.9721 23.0348 8.49298 21.1042 6.56242C19.1737 4.63186 16.8056 3.66659 14.0001 3.66659C11.1945 3.66659 8.82647 4.63186 6.89591 6.56242C4.96536 8.49298 4.00008 10.9721 4.00008 13.9999C4.00008 15.9721 4.81953 18.2291 6.45841 20.7708C8.0973 23.3124 10.6112 26.1388 14.0001 29.2499ZM14.0001 32.5416C13.6112 32.5416 13.2223 32.4721 12.8334 32.3333C12.4445 32.1944 12.0973 31.986 11.7917 31.7083C9.98619 30.0416 8.38897 28.4166 7.00008 26.8333C5.61119 25.2499 4.45147 23.7152 3.52091 22.2291C2.59036 20.743 1.88203 19.3124 1.39591 17.9374C0.909804 16.5624 0.666748 15.2499 0.666748 13.9999C0.666748 9.83325 2.00703 6.51381 4.68758 4.04159C7.36814 1.56936 10.4723 0.333252 14.0001 0.333252C17.5279 0.333252 20.632 1.56936 23.3126 4.04159C25.9931 6.51381 27.3334 9.83325 27.3334 13.9999C27.3334 15.2499 27.0904 16.5624 26.6042 17.9374C26.1181 19.3124 25.4098 20.743 24.4792 22.2291C23.5487 23.7152 22.389 25.2499 21.0001 26.8333C19.6112 28.4166 18.014 30.0416 16.2084 31.7083C15.9029 31.986 15.5556 32.1944 15.1667 32.3333C14.7779 32.4721 14.389 32.5416 14.0001 32.5416ZM14.0001 16.9999C14.9167 16.9999 15.7015 16.6735 16.3542 16.0208C17.007 15.368 17.3334 14.5833 17.3334 13.6666C17.3334 12.7499 17.007 11.9652 16.3542 11.3124C15.7015 10.6596 14.9167 10.3333 14.0001 10.3333C13.0834 10.3333 12.2987 10.6596 11.6459 11.3124C10.9931 11.9652 10.6667 12.7499 10.6667 13.6666C10.6667 14.5833 10.9931 15.368 11.6459 16.0208C12.2987 16.6735 13.0834 16.9999 14.0001 16.9999Z'
			fill='#C8C8C8'
		/>
	</svg>
);

export default iconFactory(_Svg);
