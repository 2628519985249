import { useTranslation } from 'react-i18next';
import { Select, SelectProps, Spin } from 'antd';
import { apiHooks } from '@/hooks/apis';

interface DestinationWarehouseMultiSelectorProps extends SelectProps {
	selectedDestinationCityIds: number[];
}

export const DestinationWarehouseMultiSelector = ({
	selectedDestinationCityIds,
	...props
}: DestinationWarehouseMultiSelectorProps) => {
	const { t } = useTranslation();

	const { runAsync, data, loading } = apiHooks.useSearchWarehouses({
		debounceWait: 500,
	});

	const options = (data ?? []).map((i) => ({
		label: i.name,
		value: i.id,
	}));

	return (
		<Select
			mode='multiple'
			labelInValue
			optionFilterProp='label'
			style={{ width: '100%' }}
			options={options}
			placeholder={t('Keep blank to select all.')}
			notFoundContent={loading ? <Spin size='small' /> : null}
			tagRender={() => null}
			onSearch={(value) => {
				if (value.length < 2) return;
				runAsync({
					params: {
						keyword: value,
						city_id: selectedDestinationCityIds,
					},
				});
			}}
			{...props}
		/>
	);
};
