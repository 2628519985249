import { useTranslation } from 'react-i18next';
import { TrackItem } from '@/interfaces/shipment.interface';
import { useShipmentOrderContext } from '@/services/contexts';
import dayjs from 'dayjs';
import { Table, TableProps } from '@/components/table';
import { formatTimeToYYYYMMDD } from '../../utils';

export const TrackingTable = () => {
	const { t } = useTranslation();

	const shipmentOrderContext = useShipmentOrderContext();

	const data = shipmentOrderContext.shipmentOrder;

	const columns: TableProps<TrackItem>['columns'] = [
		{
			title: t(`Date`),
			width: '150px',
			dataIndex: 'timestamp',
			CellRender: ({ value, style, className }) => (
				<div style={style} className={className}>
					{formatTimeToYYYYMMDD(value)}
				</div>
			),
		},
		{
			title: t(`Time`),
			width: '150px',
			dataIndex: 'timestamp',
			CellRender: ({ value, style, className }) => (
				<div style={style} className={className}>
					{dayjs(value).format('h:mm A')}
				</div>
			),
		},
		{
			title: t(`Activity`),
			dataIndex: 'status',
			HeaderRender: ({ title, style, className }) => (
				<div
					style={{ ...style, justifyContent: 'flex-end' }}
					className={className}
				>{`${title}`}</div>
			),
			CellRender: ({ value, style, className }) => (
				<div
					style={{ ...style, justifyContent: 'flex-end' }}
					className={className}
				>{`${value}`}</div>
			),
		},
	];

	return (
		<Table
			dataSource={data.tracking_histories}
			columns={columns}
			commonHeaderClass='h-[32px] flex items-center body-4-b text-grey-01  bg-grey-05'
			commonCellClass='px-[16px]'
			commonBodyClass='h-[40px] flex items-center body-4-r text-black'
		/>
	);
};
