import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from 'antd';

export default function (props: ButtonProps) {
	const { t } = useTranslation();

	return (
		<Button
			{...props}
			type='primary'
			ghost
			className='w-[212px] h-[54px] rounded-[10px] font-[700] text-[20px]'
		>
			{t('Back')}
		</Button>
	);
}
