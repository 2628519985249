import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';
import type { FormInstance } from 'antd';
import clsx from 'clsx';

const Item = Form.Item;

interface PasswordInputProps {
	form: FormInstance;
	inputClass?: string;
	onValid: (status: boolean) => void;
	// onForgot: () => void;
}

export default ({ form, inputClass, onValid }: PasswordInputProps) => {
	const { t } = useTranslation();

	const password = form.getFieldValue('password');

	useEffect(() => {
		form.validateFields(['password'], { validateOnly: true }).then(
			() => {
				onValid(true);
			},
			() => {
				onValid(false);
			},
		);
	}, [password]);

	return (
		<>
			<Item
				name='password'
				label={t('Password')}
				rules={[{ required: true, message: t('Invalid password.') }]}
			>
				<Input.Password
					className={clsx('w-full login-input', inputClass)}
					placeholder={t('Enter password')}
				/>
			</Item>
			{/* <div className='body-4-sb text-white underline text-right cursor-pointer' onClick={onForgot}>
				{t('Forgot password?')}
			</div> */}
		</>
	);
};
