import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetShipmentListProps, GetShipmentListRes } from './interface';

export * from './interface';

export const getShipmentList = async (props: GetShipmentListProps) => {
	return await axios.get<GetShipmentListRes>(`/tl/ltl/shipments`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params: props.params,
	});
};
