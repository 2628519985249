import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetProjectMyFocusTotalProps, GetProjectMyFocusTotalResDataItem } from './interface';

export * from './interface';

export const getProjectMyFocusTotal = async (props: GetProjectMyFocusTotalProps) => {
	return await axios.get<GetProjectMyFocusTotalResDataItem[]>(
		`/projects/${props.path.projectId}/myFocusTotal`,
		{
			headers: {
				Authorization: accessTokenManager.getAccessToken(),
			},
			params: props.params,
		},
	);
};
