import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from 'antd';
import clsx from 'clsx';

export default function ({ className, ...props }: ButtonProps) {
	const { t } = useTranslation();

	return (
		<Button
			{...props}
			className={clsx(
				'w-[212px] h-[54px] rounded-[10px] bg-[linear-gradient(273.02deg,_#0090FE_0.89%,_#0167FA_104.25%)] font-[700] text-[20px] text-[#FFFFFF]',
				className,
			)}
		>
			{t('Next')}
		</Button>
	);
}
