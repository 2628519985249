import { Empty, Select, SelectProps } from 'antd';
import { Warehouse } from '@/interfaces/warehouse.interface';
import { useBoolean, useMemoizedFn } from 'ahooks';
import CenterSpin from '@/components/center-spin';
import { apiHooks } from '@/hooks/apis';

interface ZipCodeSelectProps extends SelectProps {
	onSelect: (address: Warehouse & { address2: string }) => any;
}

export const CitySelector = ({ onChange, onSelect, ...props }: ZipCodeSelectProps) => {
	const [isFirstSearched, { setTrue: setFirstSearched }] = useBoolean(false);

	const {
		runAsync: searchCity,
		loading: searchCityLoading,
		data: searchCityData,
	} = apiHooks.useSearchCity({
		debounceWait: 1000,
	});

	const options = [
		...(searchCityData ?? []).map((i) => ({
			label: i.full_name.split(',').slice(0, 2).join(','),
			value: `city-${i.id}`,
		})),
	];

	const getTargetValue = useMemoizedFn((targetId: string) => {
		let targetAddress: Warehouse & { address2: string };

		const [type, id] = targetId.split('-');

		if (type === 'warehouse') {
			// targetAddress = searchWarehouseData?.find((i) => i.id === Number(id));
		} else if (type === 'city') {
			const city = searchCityData?.find((i) => i.id === Number(id));
			if (!city) return;
			targetAddress = {
				id: -1,
				name: '',
				code: null,
				zipcode: city.zipcode,
				is_residential: false,
				address: '',
				address2: '',
				special_request: '',
				city,
				country: city.country,
				customer_memo: '',
				appointment_by: -1,
				created_at: '',
				updated_at: '',
				delivery_order_address: '',
				contacts: [
					// @ts-ignore
					{
						name: '',
						email: '',
						phone: '',
					},
				],
				// @ts-ignore
				user: {},
			};
		}

		return targetAddress;
	});

	// 把纯zipcode值包装为 warehouse，统一扔到 context
	return (
		<Select
			labelInValue
			suffixIcon={null}
			showSearch
			filterOption={false}
			variant='borderless'
			// @ts-ignore
			options={searchCityLoading ? [] : options}
			optionRender={(option, info) => {
				const targetAddress = getTargetValue(option.value as string);

				// city
				if (targetAddress?.id === -1) {
					return (
						<div>
							<div className='body-4-b'>{targetAddress?.city.full_name}</div>
							<div className='foot-note-1-r text-grey-02'>{targetAddress?.zipcode}</div>
						</div>
					);
				}

				// address
				return (
					<div>
						<div className='body-4-b'>{targetAddress?.name}</div>
						<div className='foot-note-1-r text-grey-02'>{targetAddress?.address}</div>
					</div>
				);
			}}
			notFoundContent={
				searchCityLoading ? <CenterSpin size='small' /> : isFirstSearched ? <Empty /> : null
			}
			onSearch={(value) => {
				if (value.length <= 3) return;
				!isFirstSearched && setFirstSearched();
				searchCity({ path: { cityName: value } });
			}}
			// 选中的时候同步
			onSelect={(value: { label: string; value: string }) => {
				const targetAddress = getTargetValue(value.value);
				onSelect(targetAddress);
			}}
			{...props}
		/>
	);
};
