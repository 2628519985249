import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetProjectsProps, GetProjectsRes } from './interface';

export * from './interface';

export const getProjects = async (props: GetProjectsProps) => {
	return await axios.get<GetProjectsRes>(`/projects`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params: props.params,
	});
};
