import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, message } from 'antd';
import type { FormInstance } from 'antd';
import { userContext } from '@/services/contexts/user/user.context';
import clsx from 'clsx';

const Item = Form.Item;

interface VerificationCodeProps {
	form: FormInstance;
	onValid: (status: boolean) => void;
	inputClass?: string;
}

const VerifyCode = ({ seconds = 60, email }) => {
	const { t } = useTranslation();

	const [time, setTime] = useState(0);
	const [sending, setSending] = useState(false);
	const timer = useRef(null);

	useEffect(() => {
		timer.current && clearInterval(timer.current);
		return () => timer.current && clearInterval(timer.current);
	}, []);

	useEffect(() => {
		if (time === seconds) timer.current = setInterval(() => setTime((time) => --time), 1000);
		else if (time <= 0) timer.current && clearInterval(timer.current);
	}, [time]);

	const getCode = async () => {
		if (time || sending) return;
		//直接使用
		if (email) {
			try {
				setSending(true);
				await userContext.getVerificationCode(email);
				setTime(seconds);
			} catch (e) {
				message.error(t('Something is wrong, please check your email and try again') + '..');
			} finally {
				setSending(false);
			}
		}
	};

	return (
		<div onClick={getCode}>
			{time ? (
				<span className='body-4-b text-secondary-regular'>{`${t('Resend')}(${time})`}</span>
			) : (
				<span className='body-4-b cursor-pointer text-primary-regular'>
					{sending ? t('Sending') + '...' : t('Send')}
				</span>
			)}
		</div>
	);
};

export default ({ form, inputClass, onValid }: VerificationCodeProps) => {
	const { t } = useTranslation();

	const values = Form.useWatch([], form);

	useEffect(() => {
		form.validateFields(['code'], { validateOnly: true }).then(
			() => {
				onValid(true);
			},
			() => {
				onValid(false);
			},
		);
	}, [values]);

	return (
		<Item
			name='code'
			label={t('Verification code')}
			rules={[{ required: true, message: t('Invalid code.') }]}
		>
			<Input
				className={clsx('w-full login-input', inputClass)}
				placeholder={t('Enter verification code')}
				suffix={<VerifyCode email={form.getFieldValue('email')} />}
			/>
		</Item>
	);
};
