import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Table } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';
import { FTLQuote } from '@/interfaces/ftl-quote.interface';
import { QuoteBusinessStatus, quoteBusinessStatusMap } from '@/interfaces/quote.interface';
import '@/styles/no-cell-border-table.less';
import { LoadingOutlined } from '@ant-design/icons';
import { useMemoizedFn, useMount } from 'ahooks';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { Gap } from '@/components/gap';
import { apiHooks } from '@/hooks/apis';
import { FTLGetRates } from '../ftl-get-rates';
import useFtlQuoteHistoryParams from './use-ftl-quote-history-params';

const FTLQuoteHistory = observer(() => {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const { parsedSearchParams, changeSearchParams } = useFtlQuoteHistoryParams();

	const {
		runAsync: getQuoteList,
		data: quoteList,
		loading: quoteListLoading,
		pagination,
	} = apiHooks.useGetFTLQuoteList();

	const { current, total, pageSize, onChange: changePagination } = pagination;

	const quoteColumns: ColumnsType<FTLQuote> = [
		{
			title: t('Quote#'),
			dataIndex: 'uid',
			width: 120,
			fixed: 'left',
		},
		{
			title: t('Origin'),
			dataIndex: ['pickup_zipcode'],
			width: 200,
			render: (_, record) => (
				<div>
					{record.pickup_zipcode && <div>{record.pickup_zipcode}</div>}
					<div>
						<span>{record.pickup_city_name ?? ''}</span>
						{record.pickup_city_name && record.pickup_state_name && <span>{`, `}</span>}
						<span>{record.pickup_state_name}</span>
					</div>
				</div>
			),
		},
		{
			title: t('Destination'),
			dataIndex: ['destination_zipcode'],
			width: 200,
			render: (_, record) => (
				<div>
					{record.destination_zipcode && <div>{record.destination_zipcode}</div>}
					<div>
						<span>{record.destination_city_name ?? ''}</span>
						{record.destination_city_name && record.destination_state_name && <span>{`, `}</span>}
						<span>{record.destination_state_name}</span>
					</div>
				</div>
			),
		},
		{
			title: t('Status'),
			dataIndex: 'business_status',
			width: 120,
			render: (value) => t(quoteBusinessStatusMap[value]) ?? '',
		},
		{
			title: t('Quote Date'),
			dataIndex: ['quote_at'],
			width: 140,
			render: (value) => (value ? dayjs(value).format('YYYY-MM-DD') : ''),
		},
		{
			title: t(`Actions`),
			key: 'actions',
			width: 120,
			fixed: 'right',
			render: (_, record) => (
				<Button
					type='link'
					className='px-0'
					onClick={() => {
						if (record.business_status === QuoteBusinessStatus.BOOKED) {
							record.shipment_id && navigate(`/ftl-orders/${record.shipment_id}`);
						} else {
							changeSearchParams({ ...parsedSearchParams, quote_id: record.uid });
						}
					}}
				>
					{t('View')}
				</Button>
			),
		},
	];

	const handleChange: TableProps<FTLQuote>['onChange'] = useMemoizedFn((pagination) => {
		const { current, pageSize } = pagination;
		changePagination(current, pageSize);

		changeSearchParams({
			page: current,
			per_page: pageSize,
		});
	});

	useMount(async () => {
		await getQuoteList(
			{ pageSize: parsedSearchParams.per_page ?? 20, current: parsedSearchParams.page ?? 1 },
			{ params: {} },
		);
		changePagination(parsedSearchParams.page ?? 1, parsedSearchParams.per_page ?? 20);
	});

	if (parsedSearchParams.quote_id) {
		return <FTLGetRates />;
	}

	return (
		<div className='h-screen w-full p-[48px] flex flex-col bg-white overflow-hidden'>
			{/* header */}
			<div className='grow-0 shrink-0 w-full h-[44px] flex items-center gap-[8px]'>
				<div className='h3-b text-black'>{t('FTL Quote History')}</div>
				{quoteListLoading && <LoadingOutlined />}
			</div>

			<Gap height='24px' />

			{/* table */}
			<Table
				className='no-cell-border-table'
				rowKey='id'
				columns={quoteColumns}
				dataSource={quoteList}
				bordered
				pagination={{
					position: ['bottomCenter'],
					showSizeChanger: true,
					total,
					current,
					pageSize,
				}}
				scroll={{ scrollToFirstRowOnChange: true, x: 'max-content', y: 'calc(100vh - 259px)' }}
				onChange={handleChange}
			/>
		</div>
	);
});

export default FTLQuoteHistory;
