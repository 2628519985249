import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetHistoryRes } from './interface';

export const getHistory = async () => {
	return await axios.get<GetHistoryRes>(`/rates/history`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
