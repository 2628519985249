import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { message, Modal, ModalProps, Progress, Upload } from 'antd';
import { UploadFile, UploadProps } from 'antd/es/upload';
import DeleteOneIcon from '@/icons/delete-one-icon';
import DownloadBoldIcon from '@/icons/download-bold-icon';
import InboxOutlinedIcon from '@/icons/inbox-oulined-icon';
import { OrderFile } from '@/interfaces/file.interface';
import { Order } from '@/interfaces/order.interface';
import { uploadFile } from '@/services/apis';
import { useOrderContext } from '@/services/contexts';
import dayjs from 'dayjs';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { convert } from '@/utils/convert-units';
import { FileRes } from './type';

const { Dragger } = Upload;

const ItemRender: UploadProps<FileRes>['itemRender'] = (_, file, __, actions) => {
	const { t } = useTranslation();

	const { download, remove } = actions;

	const data: OrderFile | undefined = file.response?.data?.[0];

	const size = data?.size ?? file?.size ?? 0;

	const getFileStatusText = () => {
		// @ts-ignore
		if (file.status === 'error' || file.status === 'removed' || file.percent === 0) return '';
		// @ts-ignore
		if (file.status === 'done' || file.status === 'success' || file?.percent === 100)
			return `uploaded at ${dayjs(data?.created_at).format('M/D h:m')}`;
		// @ts-ignore
		if (file.status === 'uploading' || file.percent > 0) return t('uploading');
		return '';
	};

	const getSize = () => {
		if (typeof size === 'string') return size;
		const convertedSize = convert(size).from('b').toBest() ?? { val: 0, unit: 'B' };
		return `${convertedSize.val} ${convertedSize.unit}`;
	};

	return (
		<div className='flex mt-[16px] p-[10px_16px_9px_7px] border-[2px] border-solid border-[#747578] rounded-[6px] text-neutral-dark-grey'>
			<div className='flex-grow mr-[14px]'>
				{/* File Name */}
				<div className='font-[500] text-[16px] '>{data?.file_name ?? file.name}</div>
				{/* progress */}
				<div>
					{/* @ts-ignore */}
					<Progress size={['100%', 1]} percent={file.percent} showInfo={false} />
				</div>

				<div className='flex items-center font-[400] text-[14px]'>
					{/* size */}
					<div>{`${getSize()}`}</div>

					<div className='ml-[11px] mr-[11px] w-[2px] h-[2px] bg-neutral-dark-grey rounded-[50%]' />
					{/* update time */}
					<div>{getFileStatusText()}</div>
				</div>
			</div>

			{/* buttons */}
			<div className='flex justify-between items-center w-[46px]'>
				<DownloadBoldIcon className='cursor-pointer w-[16px] h-[16px]' onClick={download} />

				<DeleteOneIcon className='cursor-pointer w-[16px] h-[16px]' onClick={remove} />
			</div>
		</div>
	);
};

interface UploadFileModalProps extends ModalProps {
	order: Order;
}

export default observer(
	({ order, onOk, ...props }: UploadFileModalProps & { onOk: () => void }) => {
		const { t } = useTranslation();

		const [fileList, setFileList] = useState<UploadFile[]>([]);

		const [uploading, setUploading] = useState(false);

		const orderContext = useOrderContext();

		const handleChange: UploadProps['onChange'] = (info) => {
			const newFileList = [...info.fileList];
			setFileList(newFileList);
		};

		const handleDeleteFile: UploadProps['onRemove'] = async (file) => {
			setFileList(fileList.filter((f) => f !== file));
		};

		const beforeUpload: UploadProps['beforeUpload'] = (file) => {
			setFileList([...fileList, file]);
			return false;
		};

		const changeFile = (id: string, values: { namePath: string; value: any }[]) => {
			setFileList((list) => {
				const targetFile = list.find((f) => f.uid === id);
				if (!targetFile) return list;
				values.map(({ namePath, value }) => {
					_.update(targetFile, namePath, () => value);
				});

				return [...list.filter((f) => f.uid !== id), { ...targetFile }];
			});
		};

		const handleUpload = async () => {
			setUploading(true);

			const uploadRes = await Promise.allSettled(
				fileList.map(async (file) => {
					const formData = new FormData();
					// @ts-ignore
					formData.append('documents', file.originFileObj);

					try {
						const res = await uploadFile({
							path: { orderId: String(order.id) },
							data: formData,
							config: {
								onUploadProgress: (e) => {
									changeFile(file.uid, [{ namePath: 'percent', value: e.progress ?? 0 }]);
								},
							},
						});
						changeFile(file.uid, [
							{ namePath: 'percent', value: 100 },
							{ namePath: 'status', value: 'done' },
							{ namePath: 'response', value: res },
						]);
					} catch (error: any) {
						console.error('file', file.fileName, 'upload error', error);
						changeFile(file.uid, [
							{ namePath: 'percent', value: 0 },
							{ namePath: 'status', value: 'error' },
						]);
						throw new Error(error?.message);
					}

					return file;
				}),
			);

			const success = uploadRes.filter((r) => r.status === 'fulfilled').length;
			const error = uploadRes.filter((r) => r.status === 'rejected').length;

			message.info(`finish files uploading, success: ${success}, error: ${error}`);

			setUploading(false);
		};

		useEffect(() => {
			// 如果全部上传完毕，关闭
			if (
				fileList.length > 0 &&
				fileList.every((file) => ['done', 'error'].includes(file.status ?? ''))
			) {
				onOk && onOk();
			}
		}, [fileList]);

		return (
			<Modal
				{...props}
				title={t('Upload File')}
				onOk={handleUpload}
				okText={t('Upload')}
				confirmLoading={uploading}
			>
				<div>
					<Dragger
						{...props}
						fileList={fileList}
						multiple
						itemRender={ItemRender}
						beforeUpload={beforeUpload}
						onChange={handleChange}
						onRemove={handleDeleteFile}
					>
						<p className='ant-upload-drag-icon'>
							<InboxOutlinedIcon />
						</p>
						<p className='ant-upload-text'>
							<span className='text-[#367cf2] underline cursor-pointer'>{t('Choose File')}</span>{' '}
							{t('or drag file to this area to upload')}
						</p>
						<p className='ant-upload-hint'>{t('Support for a single or bulk upload.')}</p>
					</Dragger>
				</div>
			</Modal>
		);
	},
);
