import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '@/interfaces/order.interface';
import clsx from 'clsx';

interface ContainerStatusTimelineProps {
	container: Container;
	forceActive?: boolean;
}

type Status = 'current' | 'arrived' | 'future';

const getStatus = (currentIndex: number, index: number): Status =>
	index === currentIndex ? 'current' : index < currentIndex ? 'arrived' : 'future';

const ArrivedDot = () => <div className='w-[10px] h-[10px] bg-primary-regular rounded-[50%]' />;

const CurrentDot = () => (
	<div className='flex-center w-[20px] h-[20px] rounded-[50%] bg-primary-light-04'>
		<div className='w-[10px] h-[10px] bg-primary-regular rounded-[50%]' />
	</div>
);

const CacnelDot = () => (
	<div className='w-[20px] h-[20px] border-[1.5px] border-solid border-grey-03 bg-grey-04 rounded-[50%]' />
);

const FutureDot = () => (
	<div className='w-[10px] h-[10px] border-[1.5px] border-solid border-grey-01 rounded-[50%]' />
);

const getDot = (status: Status) =>
	status === 'current' ? <CurrentDot /> : status === 'arrived' ? <ArrivedDot /> : <FutureDot />;

const Tail = () => <div className='ml-[19px] w-[2px] h-[20px] bg-primary-regular' />;

export default ({ container, forceActive = false }: ContainerStatusTimelineProps) => {
	const { t } = useTranslation();

	const { customer_status_logs } = container;

	const currentIndex = customer_status_logs.findLastIndex(
		(s) => !!s.updated_at || container.status == s.customer_status,
	);

	return (
		<div className='mt-[20px] h-[calc(100%_-_43px)] overflow-y-scroll'>
			{customer_status_logs.map(({ customer_status_name, updated_at }, index) => {
				const status = forceActive ? 'arrived' : getStatus(currentIndex, index);

				return (
					<React.Fragment>
						<div
							className={clsx(
								'flex items-center px-[8px]',
								!container.is_canceled && status === 'current' && 'bg-grey-06 rounded-[8px]',
							)}
						>
							{/* dot */}
							<div className='w-[24px] h-[24px] flex-center'>
								{container.is_canceled ? <CacnelDot /> : getDot(status)}
							</div>

							<div className={'ml-[36px] flex-grow flex justify-between'}>
								<span
									className={clsx(
										container.is_canceled
											? 'text-black'
											: status === 'current'
											? 'body-4-b'
											: 'body-4-m text-grey-01',
									)}
								>
									{t(customer_status_name)}
								</span>
								<span className='body-4-m text-grey-01'>{updated_at}</span>
							</div>
						</div>
						{!container.is_canceled && index < currentIndex && <Tail />}
					</React.Fragment>
				);
			})}
		</div>
	);
};
