import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { CreateFTLQuoteReq, CreateFTLQuoteRes } from './interface';

export * from './interface';

export const createFTLQuote = async (props: CreateFTLQuoteReq) => {
	return await axios.post<CreateFTLQuoteRes>(`/ftl/quotes`, props.data, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
