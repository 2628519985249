import { searchOwnMBLNumbers } from '@/services/apis';
import { useRequest } from 'ahooks';
import { message } from '@/utils/message';

export const useSearchOwnMBLNumbers = (options?: Record<any, any>) => {
	const res = useRequest(searchOwnMBLNumbers, {
		debounceWait: 100,
		manual: true,
		onError: (error) => {
			// @ts-ignore
			message.error(`${error.response?.data?.message ?? error.message}`);
		},
		...options,
	});

	return {
		...res,
		data: res.data?.data?.data ?? [],
	};
};
