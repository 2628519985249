import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { CreateUserProps, CreateUserRes } from './interface';

export const createUser = async (props: CreateUserProps) => {
	return await axios.post<CreateUserRes>(`/agent/users`, props.data, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
