import type { TableProps } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import './expand-icon.less';

export const ExpandIcon: Exclude<TableProps<any>['expandable'], undefined>['expandIcon'] = ({
	prefixCls,
	expanded,
	record,
	expandable,
	onExpand,
}) => {
	return (
		<RightOutlined
			className={clsx(expanded ? 'rotate' : 'rotate-back')}
			onClick={(e) => onExpand(record, e)}
		/>
	);
};
