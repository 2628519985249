import clsx from 'clsx';

interface PanelProps {
	classNames?: string;
	children: React.ReactNode;
}

export default ({ classNames, children }: PanelProps) => (
	<div
		className={clsx(
			'p-[20px_30px_0_30px] bg-[#ffffff] rounded-[10px] shadow-[0px_4px_16px_0px_#00000026]',
			classNames,
		)}
	>
		{children}
	</div>
);
