import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';

export const getIsWhitelist = async (type: string) => {
	return await axios.get<string[]>(`/tools/is-whitelist`, {
		params: {
			type: type,
		},
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
