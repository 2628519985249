import { useState } from 'react';
import { default as Modal } from '../components/search-rate-form/components/recommend-routes-modal';
import { RecommendRoutesModalProps } from '../components/search-rate-form/components/recommend-routes-modal/type';

export default () => {
	const [open, setOpen] = useState(false);

	const [props, setProps] = useState<RecommendRoutesModalProps | null>(null);

	const openModal = (props: RecommendRoutesModalProps) => {
		setProps({ ...props });
		setOpen(true);
	};

	const closeModal = () => {
		setOpen(false);
	};

	const RecommendRoutesModal = () =>
		props && <Modal open={open} onCancel={closeModal} {...props} />;

	return {
		openModal,
		closeModal,
		RecommendRoutesModal,
	};
};
