import React from 'react';
import { Marker, Popup } from 'react-map-gl';
import clsx from 'clsx';
import { Gap } from '@/components/gap';

export interface Point {
	id: any;
	label: string | React.ReactNode;
	longitude: number;
	latitude: number;
}

export interface PointMarkerProps {
	point: Point;
	icon?: React.ReactNode;
	activeIcon?: React.ReactNode;
	selectedPointId: Point['id'];
	disableSelectedBigger?: boolean;
	onMarkClick?: (pointId: Point['id']) => void;
}

export const PointMarker = ({
	point,
	selectedPointId,
	icon,
	activeIcon,
	disableSelectedBigger,
	onMarkClick,
}: PointMarkerProps) => {
	const isSelected = point.id === selectedPointId;

	return (
		<div
			className={onMarkClick && 'cursor-pointer'}
			onClick={() => onMarkClick && onMarkClick(point.id)}
		>
			<Marker longitude={point.longitude} latitude={point.latitude}>
				<div className=' box-border w-[14px] h-[14px] border-[1px] border-solid rounded-full border-white bg-primary-regular' />
			</Marker>
			<Popup
				offset={[0, -25] as [number, number]}
				anchor='center'
				longitude={point.longitude}
				latitude={point.latitude}
				closeButton={false}
				closeOnClick={false}
				closeOnMove={false}
				// className={clsx('to-city-popup bg-primary-dark-01', { 'opacity-50': opacity })}
				className='focus-map-popup'
			>
				<div className='flex flex-col items-center cursor-pointer'>
					<div
						className={clsx(
							'box-border p-[0_6px] flex items-center',
							isSelected
								? 'foot-note-2-b text-white bg-primary-regular'
								: 'body-4-b text-primary-regular bg-white',
							!isSelected || disableSelectedBigger
								? 'h-[21px] rounded-[12px]'
								: 'h-[30px] rounded-[20px]',
						)}
					>
						{isSelected ? activeIcon : icon}
						{activeIcon && icon && <Gap width='4px' />}
						{point.label}
					</div>

					{/* 下面的三角 */}
					<div
						style={{
							borderLeft: isSelected ? '8px solid transparent' : '5px solid transparent',
							borderRight: isSelected ? '8px solid transparent' : '5px solid transparent',
							borderTop: isSelected ? '8px solid #007BFF' : `5px solid #fff`,
						}}
						className='w-0 h-0'
					/>
				</div>
			</Popup>
		</div>
	);
};
