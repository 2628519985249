import React from 'react';
import ContactSvg from '@/assets/contact.svg';

const ContactIcon = (
	props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
) => {
	return <img {...props} src={ContactSvg} />;
};

export default ContactIcon;
