import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoComplete, message } from 'antd';
import { getAddressSuggestions } from '@/services/apis';
import { debounce } from 'lodash';
import CenterSpin from '@/components/center-spin';

const AddressAutoFill: FC<{
	value: string;
	disabled?: boolean;
	onSelect: (data) => void;
}> = ({ value = '', onSelect, disabled = false }) => {
	const { t } = useTranslation();

	const [options, setOptions] = React.useState([]);
	const [data, setData] = React.useState([]);
	const [loading, setLoading] = React.useState<boolean>(false);

	const handleSearch = async (query: string) => {
		setLoading(true);
		if (query.length === 0) {
			return;
		}
		try {
			const resp = await getAddressSuggestions(query);
			const options = resp.data.data;
			setData(options);
			setOptions(
				options.map((o, idx: number) => ({
					value: idx,
					label: o.full_address,
				})),
			);
		} catch (err) {
			message.error(err.data?.message || err.data?.error);
		} finally {
			setLoading(false);
		}
	};

	const doSearch = useCallback(debounce(handleSearch, 500), []);

	const handleSelect = (idx) => {
		setLoading(true);

		try {
			const place = data[idx];
			if (place && onSelect) {
				onSelect({
					city: place.city,
					city_id: place.city?.id || '',
					country: place.country,
					zipcode: place.zipcode,
					address: place.address,
				});
			}
		} catch (err) {
			message.error(err.data?.message || err.data?.error);
		} finally {
			setLoading(false);
		}
	};

	const handleChange = (v: string) => {
		onSelect &&
			onSelect({
				address: v,
			});
	};

	return (
		<AutoComplete
			disabled={disabled}
			options={options}
			onChange={handleChange}
			value={value}
			onSelect={handleSelect}
			onSearch={doSearch}
			placeholder=''
			notFoundContent={loading ? <CenterSpin /> : t('No Match')}
		/>
	);
};

export default AddressAutoFill;
