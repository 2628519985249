import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetProjectMBLsProps, GetProjectMBLsRes } from './interface';

export * from './interface';

export const getProjectMBLs = async (props: GetProjectMBLsProps) => {
	return await axios.get<GetProjectMBLsRes>(`/projects/${props.path.projectId}/mbls`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
