import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetDepotsProps } from './interface';

export const getDepots = async (search = '') => {
	return await axios.get<GetDepotsProps>(`/newDepots`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params: {
			search,
		},
	});
};
