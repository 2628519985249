import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetIndustryNewsProps, GetIndustryNewsRes } from './interface';

export * from './interface';

export const getIndustryNews = async (props: GetIndustryNewsProps) => {
	return await axios.get<GetIndustryNewsRes>(`/forceMajeureEvents`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params: props?.params,
	});
};
