import iconFactory from './icon-factory';

const _Svg = () => (
	<svg width='34' height='30' viewBox='0 0 34 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M2.8335 27.5H7.8335V14.1667H26.1668V27.5H31.1668V8.33333L17.0002 2.66667L2.8335 8.33333V27.5ZM0.333496 30V6.66667L17.0002 0L33.6668 6.66667V30H23.6668V16.6667H10.3335V30H0.333496ZM12.4168 30V27.5H14.9168V30H12.4168ZM15.7502 25V22.5H18.2502V25H15.7502ZM19.0835 30V27.5H21.5835V30H19.0835Z'
			fill='#C8C8C8'
		/>
	</svg>
);

export default iconFactory(_Svg);
