import { Spin, SpinProps } from 'antd';

type CenterSpinProps = SpinProps;

export default (props: CenterSpinProps) => {
	return (
		<div className='w-full h-full flex-center'>
			<Spin {...props} />
		</div>
	);
};
