import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { SelectProps } from 'antd';
import { City } from '@/interfaces/city.interface';
import { searchCity } from '@/services/apis';
import { useDebounceFn } from 'ahooks';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import Select from '@/components/select';

export const CitySelect = observer(
	forwardRef((props: SelectProps, ref) => {
		const [cities, setCities] = useState<City[]>([]);

		const { run: handleSearch } = useDebounceFn(
			async (searchText: string) => {
				if (!searchText || searchText === '') return;
				const res = await searchCity({ path: { cityName: searchText } });
				setCities(Array.isArray(res.data.data) ? res.data.data : []);
			},
			{ wait: 500 },
		);

		useImperativeHandle(ref, () => ({
			updateCities(cities = []) {
				if (cities) setCities(cities);
			},
		}));

		const options = useMemo(
			() =>
				cities.map((c) => ({
					label: c.full_name,
					value: c.id,
				})),
			[cities],
		);

		return (
			<Select
				{...props}
				options={options}
				labelInValue
				showSearch
				allowClear
				onSearch={handleSearch}
				filterOption={false}
			/>
		);
	}),
);
