import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import qs from 'qs';
import valueTransfer from '@/utils/value-transfer';
import { BookQuery } from '../type';

export default () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const parsedSearchParams = useMemo(() => {
		const searchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });

		const res = {};

		valueTransfer(res, 'draftOrderId', searchParams.draftOrderId, (v) => v);
		valueTransfer(res, 'orderId', searchParams.orderId, (v) => v);
		valueTransfer(res, 'rid', searchParams.rid, (v) => v);
		valueTransfer(res, 'useSearchContext', searchParams.useSearchContext, (v) => v === 'true');

		return res as Partial<BookQuery>;
	}, [searchParams]);

	const changeSearchParams = useCallback((values: any) => {
		setSearchParams(qs.stringify({ ...values }));
	}, []);

	return {
		parsedSearchParams,
		changeSearchParams,
	};
};
