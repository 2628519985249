import { useTranslation } from 'react-i18next';
import Risks from '@/components/risks/index.tsx';

const Tag = ({ label, value }: { label: string; value: string }) => (
	<div className='p-[2px_12px] h-[20px] w-fit flex-center rounded-[10px] border-[1px] border-solid border-grey-02 font-note-1-r'>
		<span className='mr-[4px] text-grey-01'>{label}</span>
		<span className='text-black'>{value}</span>
	</div>
);

export default ({
	number,
	status_text,
	risks,
	order_uid,
}: {
	number: string;
	status_text: string;
	risks: string[];
	order_uid: string;
}) => {
	const { t } = useTranslation();

	return (
		<div>
			<div className='mb-[8px] h3-b'>{number}</div>
			<div className='flex gap-x-1'>
				<Tag label={t('Status')} value={`${t(status_text)}`} />
				<Tag label={t('Reference')} value={`#${order_uid}`} />
				<Risks risks={risks} />
			</div>
		</div>
	);
};
