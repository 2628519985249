import { getIsWhitelist } from '@/services/apis/get-is-whitelist';
import { useRequest } from 'ahooks';
import { message } from '@/utils/message';

export const useGetIsWhitelist = (options?: Record<any, any>) => {
	const res = useRequest(getIsWhitelist, {
		cacheKey: 'useGetIsWhitelist',
		onError: (error) => {
			// @ts-ignore
			message.error(`${error.response?.data?.message ?? error.message}`);
		},
		...options,
	});

	return {
		...res,
		data: res.data?.data as unknown as { is_exist: boolean },
	};
};
