import { useTranslation } from 'react-i18next';
import { Select, SelectProps } from 'antd';
import { apiHooks } from '@/hooks/apis';

export const WarehouseSelector = (props: Omit<SelectProps, 'options'>) => {
	const { t } = useTranslation();

	const { runAsync, data, loading } = apiHooks.useSearchWarehouses();

	const options = (data ?? []).map((d) => ({
		label: d.name,
		value: d.id,
	}));

	const onSearch = (value: string) => {
		if (value.length < 2) return;
		runAsync({
			params: {
				keyword: value,
			},
		});
	};

	return (
		<Select
			placeholder={t(`Select Warehouse`)}
			options={options}
			loading={loading}
			// placeholder={t('Keep blank to select all.')}
			className='w-full'
			showSearch
			allowClear
			onSearch={onSearch}
			{...props}
		/>
	);
};
