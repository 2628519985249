import React from 'react';
import downloadwSvg from '@/assets/download.svg';

const DownloadIcon = (
	props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
) => {
	return <img {...props} src={downloadwSvg} />;
};

export default DownloadIcon;
