import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, ModalProps } from 'antd';
import useNavigateToIndex from '@/hooks/use-navigate-to-index';
import { SuccessAddProjectPic } from '../pics';

interface SuccessProjectPanelProps extends ModalProps {
	onClose: () => void;
}

export const SuccessProjectPanel = ({ onClose, ...options }: SuccessProjectPanelProps) => {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const { navigateToIndex } = useNavigateToIndex();

	const _onClose = () => {
		if (window.location.pathname === '/projects') {
			window.location.reload();
		} else {
			onClose();
		}
	};

	const goToProjectDashboard = () => {
		if (window.location.pathname === '/projects') {
			window.location.reload();
		} else {
			navigate('/projects');
		}
	};

	return (
		<Modal footer={null} width={446} onCancel={_onClose} {...options}>
			<div className='pt-[25px] h-[500px] flex flex-col items-center'>
				<SuccessAddProjectPic />
				<div className='mb-[6px] h2-b'>{t(`Congratulations!`)}</div>

				<div className='mb-[30px] body-2-r'>{t(`You are all set.`)}</div>

				<Button
					type='primary'
					className='mb-[12px] w-[274px] body-3-sb text-white'
					onClick={goToProjectDashboard}
				>
					{t(`Go to project dashboard`)}
				</Button>
				<Button
					className='w-[274px] border-primary-regular body-3-sb text-primary-regular'
					onClick={navigateToIndex}
				>
					{t(`Back to search page`)}
				</Button>
			</div>
		</Modal>
	);
};
