import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, message, Popover, Spin, Typography } from 'antd';
import { BillingCodeDefinition } from '@/interfaces/billing-code-definition.interface';
import { useBillingCodeContext } from '@/services/contexts';
import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import RenderPossibleChargeNameOrCode from './render-possible-charge-name-or-code';

interface Props {
	code: string;
	explanation?: string;
}

export const BillingCodeDefinitionPopup = observer(({ code, explanation = '' }: Props) => {
	const { i18n, t } = useTranslation();

	const isEnglish = i18n.language == 'en-US' ? true : false;

	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [definition, setDefinition] = useState<BillingCodeDefinition>();

	const billingCodeContext = useBillingCodeContext();

	const handleOpen = async () => {
		if (open) {
			setOpen(false);
			return;
		}

		setOpen(true);
		setLoading(true);
		try {
			const res = await billingCodeContext.fetchBillingCodeDefinition(code);
			setDefinition(res);
		} catch (error: any) {
			message.error(error.message);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Popover
			trigger='hover'
			placement='right'
			onOpenChange={handleOpen}
			content={
				<div
					className={clsx(
						'p-[8px_14px_0px_14px] w-[500px] min-h-fit max-h-full flex flex-col justify-between rounded-[6px]',
					)}
				>
					<Spin spinning={loading}>
						<div className='flex justify-between'>
							<strong>
								<RenderPossibleChargeNameOrCode nameOrCode={code} />
							</strong>
						</div>
						<Divider className='mt-[16px] mb-[16px]' />
						<div className='h-fit max-h-[80vh] overflow-scroll'>
							<div className='leading-relaxed'>
								<strong>{t('Definition')}: </strong>
								<div style={{ whiteSpace: 'pre-line' }}>
									{isEnglish
										? definition?.en_definition?.definition
										: definition?.cn_definition?.definition}
								</div>
							</div>
							<Divider className='mt-[16px] mb-[16px]' />
							<div className='leading-relaxed'>
								<strong>{t('When it will happen')}: </strong>
								<div style={{ whiteSpace: 'pre-line' }}>
									{isEnglish
										? definition?.en_definition?.when_will_it_happen
										: definition?.cn_definition?.when_will_it_happen}
								</div>
								{!!explanation && (
									<>
										<Divider className='mt-[16px] mb-[16px]' />
										<div className='leading-relaxed'>
											<strong>{t('Special Explanation')}: </strong>
											<div style={{ whiteSpace: 'pre-line' }}>{explanation}</div>
										</div>
									</>
								)}
							</div>
						</div>
					</Spin>
				</div>
			}
		>
			<span className='cursor-pointer' onClick={handleOpen}>
				<InfoCircleOutlined />
			</span>
		</Popover>
	);
});
