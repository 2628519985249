import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Affix, Col, Row } from 'antd';
import AddToCurrentProjectIcon from '@/icons/add-to-current-project-icon';
import CreateNewProjectIcon from '@/icons/create-new-project-icon';
import { AddProjectModal } from '@/components/add-project';
import { CreateProjectPanel } from '@/components/create-project-panel';

const SelectionToolbar = ({
	selectedRowKeys,
	clear,
}: {
	selectedRowKeys: number[];
	clear: () => void;
}) => {
	const { t } = useTranslation();

	const [isCreateProjectModalOpen, setIsCreateProjectModalOpen] = useState(false);
	const handleOpenCreateProjectModal = () => setIsCreateProjectModalOpen(true);
	const handleCloseCreateProjectModal = () => setIsCreateProjectModalOpen(false);

	const [isAddToCurrentProjectModalOpen, setIsAddToCurrentProjectModalOpen] = useState(false);
	const handleOpenAddToCurrentProjectModal = () => setIsAddToCurrentProjectModalOpen(true);
	const handleCloseAddToCurrentProjectModal = () => setIsAddToCurrentProjectModalOpen(false);

	if (selectedRowKeys.length === 0) return null;

	return (
		<Affix className='w-[calc(100%_-_96px)] absolute bottom-[18px] m-auto'>
			<div className='pl-[20px] pr-[42px] w-full h-[69px] flex justify-between items-center rounded-[16px] bg-primary-regular'>
				<div className='h4-m text-white'>
					{t(`orders selected`, { nums: selectedRowKeys.length })}
				</div>

				<Row gutter={24}>
					<Col>
						<div onClick={handleOpenCreateProjectModal}>
							<CreateNewProjectIcon className='cursor-pointer' />
						</div>
					</Col>
					<Col>
						<div onClick={handleOpenAddToCurrentProjectModal}>
							<AddToCurrentProjectIcon className='cursor-pointer' />
						</div>
					</Col>
					<Col>
						<div
							className='h-full flex items-center h4-m text-white cursor-pointer'
							onClick={clear}
						>
							{t(`Cancel`)}
						</div>
					</Col>
				</Row>
			</div>
			<AddProjectModal
				modalOptions={{
					open: isAddToCurrentProjectModalOpen,
				}}
				orderIds={selectedRowKeys}
				onClose={handleCloseAddToCurrentProjectModal}
			/>
			<CreateProjectPanel
				type='batch-create'
				open={isCreateProjectModalOpen}
				onClose={handleCloseCreateProjectModal}
				defaultValue={{ orderId: selectedRowKeys }}
			/>
		</Affix>
	);
};

export default SelectionToolbar;
