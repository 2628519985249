import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { CreatePickupNumberAgentProps, CreatePickupNumberAgentRes } from './interface';

export const createPickupNumberAgent = async (props: CreatePickupNumberAgentProps) => {
	return await axios.post<CreatePickupNumberAgentProps>(`/pickupNumberAgents`, props.data, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
