import rightArrow from '@/assets/right-arrow.svg';
import { useGlobalContext } from '@/services/contexts';
import { observer } from 'mobx-react-lite';
import Avatar from '@/components/avatar';
import Logout from '../logout';

export const UserInfo = observer(() => {
	const { userContext } = useGlobalContext();
	const userInfo = userContext.getUserInfo();
	const name = userInfo?.name;

	return (
		<Logout>
			<div className='flex justify-between items-center ml-[36px] mt-[37px] w-[168px] h-[48px]'>
				{/* info */}
				<div className='flex items-center'>
					{/* 头像 */}
					<Avatar name={name ?? ''} />
					{/* 用户名 */}
					<span className='ml-[12px] text-[16px] text-[#ffffff]'>{name}</span>
				</div>
				{/* 图标 */}
				<img src={rightArrow} className='w-[6px] h-[12px]' />
			</div>
		</Logout>
	);
});
