import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetCityListByZipcodeProps, GetCityListByZipcodeRes } from './interface';

export * from './interface';

export const getCityListByZipcode = async (props: GetCityListByZipcodeProps) => {
	return await axios.get<GetCityListByZipcodeRes>(`/tl/tools/citylist/${props.path.zipcode}`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
