import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import { useLTLContext } from '@/services/contexts';
import {
	calcTotalVolume,
	calcTotalWeight,
	imperialFreightClass,
} from '@/services/contexts/ltl/ltl.context';
import { observer } from 'mobx-react-lite';
import { FT3 } from '@/components/ft3';
import { ItemTitle } from './item-title';

export const ShipmentInfoCargoSummary = observer(() => {
	const { t } = useTranslation();

	const ltlContext = useLTLContext();

	const items = ltlContext.stepOneFormValues.items ?? [];

	const units = _.sum(items.map((item) => item.units));

	const firstPackageType = items[0]?.package_type ?? '';

	const pieces = _.sum(items.map((item) => item.pieces));

	const lbs = Number(calcTotalWeight(items));

	const volume = Number(calcTotalVolume(items));

	const pcf = (lbs / volume).toFixed(2);

	const classes = imperialFreightClass(lbs, volume);

	return (
		<ItemTitle
			content={t(`Cargo Summary`)}
			value={
				<div className='w-full flex flex-wrap body-4-sb'>
					<div className='mr-[8px]'>{`${t(`Handling units`)}: ${units} ${firstPackageType}`}</div>
					<div className='mr-[8px]'>{`${pieces} ${t(`Pieces`)}`}</div>
					<div className='mr-[8px]'>{`${lbs} ${t(`lbs`)}`}</div>
					<div className='mr-[8px]'>
						{`${volume} `}
						<FT3 />
					</div>
					<div className='mr-[8px]'>{`${pcf} ${t(`PCF`)}`}</div>
					<div className='mr-[8px]'>{`${t(`Class`)} ${classes}`}</div>
				</div>
			}
		/>
	);
});
