export const Title = ({
	title,
	Icon,
	extra,
}: {
	title: React.ReactNode;
	Icon?: (props: any) => JSX.Element;
	extra?: React.ReactNode;
}) => (
	<div className='flex justify-between mb-[22px]'>
		<div className='flex'>
			{/* icon */}
			{Icon && (
				<div className='flex justify-center items-center mr-[13px] w-[40px] h-[40px] rounded-[4px] bg-[#004A99]'>
					<Icon className='w-[24px] h-[24px]' />
				</div>
			)}

			{/* title */}
			<div className='flex items-center font-[700] text-[24px]'>{title}</div>
		</div>
		{extra}
	</div>
);
