import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps, message, Popconfirm, PopconfirmProps } from 'antd';
import { deleteFile } from '@/services/apis/delete-file';
import { DeleteFileProps } from '@/services/apis/delete-file/interface';
import { DeleteOutlined } from '@ant-design/icons';

const TableExpandedRowDocumentsTabDeleteButton = ({
	buttonChildren,
	popconfirmProps,
	buttonProps,
	path,
	afterDelete,
}: {
	buttonChildren?: ReactNode;
	popconfirmProps?: PopconfirmProps;
	buttonProps?: ButtonProps;
	afterDelete?: () => any;
} & DeleteFileProps) => {
	const { t } = useTranslation();

	const [isLoading, setIsLoading] = useState(false);
	const handleConfirm = () => {
		setIsLoading(true);
		deleteFile({
			path,
		})
			.then(() => {
				afterDelete?.();
				message.success(t('delete successful'));
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<Popconfirm
			okText={t('Yes')}
			cancelText={t('No')}
			onConfirm={handleConfirm}
			{...popconfirmProps}
		>
			<Button type='text' icon={<DeleteOutlined />} loading={isLoading} {...buttonProps}>
				{buttonChildren}
			</Button>
		</Popconfirm>
	);
};

export default TableExpandedRowDocumentsTabDeleteButton;
