import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetProjectContainersCalendarProps, GetProjectContainersCalendarRes } from './interface';

export * from './interface';

export const getProjectContainersCalendar = async (props: GetProjectContainersCalendarProps) => {
	return await axios.get<GetProjectContainersCalendarRes>(
		`/projects/${props.path.projectId}/calendarStatistics`,
		{
			headers: {
				Authorization: accessTokenManager.getAccessToken(),
			},
			params: props.params,
		},
	);
};
