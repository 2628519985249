import accessTokenManager from '@/services/contexts/user/access-token-manager';
import { omit } from 'lodash';
import axios from '../axios';
import { GetProjectMyFocusMapProps, GetProjectMyFocusMapRes } from './interface';

export * from './interface';

export const getProjectMyFocusMap = async (props: GetProjectMyFocusMapProps) => {
	return await axios.get<GetProjectMyFocusMapRes[]>(
		`/projects/${props.path.projectId}/myFocusMap/${props.path.type}`,
		{
			headers: {
				Authorization: accessTokenManager.getAccessToken(),
			},
			params: props.params,
		},
	);
};
