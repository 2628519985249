import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetShipmentProps, GetShipmentRes } from './interface';

export * from './interface';

export const getShipment = async (props: GetShipmentProps) => {
	return await axios.get<GetShipmentRes>(`/tl/ltl/shipments/${props.path.id}`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
