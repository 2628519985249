import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { isWarehouseDuplicateProps, isWarehouseDuplicateRes } from './interface';

export const isWarehouseDuplicate = async (props: isWarehouseDuplicateProps) => {
	return await axios.get<isWarehouseDuplicateRes>(`/newWarehouses/isDuplicate`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params: props.params,
	});
};
