import { useCallback } from 'react';
import { useSafeState } from 'ahooks';
import { TabSwitcherItem } from './type';

export const useTabSwitcher = (
	item1: TabSwitcherItem,
	item2: TabSwitcherItem,
	defaultValue?: TabSwitcherItem['value'],
) => {
	const [tabSwitcherValue, setTabSwitcherValue] = useSafeState(defaultValue ?? item1.value);

	const onTabSwitcherChange = useCallback(
		(v) => {
			setTabSwitcherValue(v);
		},
		[setTabSwitcherValue],
	);

	return {
		item1,
		item2,
		tabSwitcherValue,
		onTabSwitcherChange,
	};
};
