import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';
import { useSafeState } from 'ahooks';
import { Gap } from '@/components/gap';
import { CreateProjectPanelValue } from '..';
import { Buttons, ButtonsProps } from '../buttons';
import { ProjectTagGroup } from '../project-tags';
import { DestinationCityMultiSelector } from './destination-city-multi-selector';
import { DestinationWarehouseMultiSelector } from './destination-warehouse-multi-selector';

export interface DestinationAndWarehousePanelProps extends Pick<ButtonsProps, 'goPre' | 'goNext'> {
	projectName: string;
	warehouse_cities: CreateProjectPanelValue['warehouse_cities'];
	warehouses: CreateProjectPanelValue['warehouses'];
	onWarehouseCitiesChange: (value: DestinationAndWarehousePanelProps['warehouse_cities']) => void;
	onWarehousesChange: (value: DestinationAndWarehousePanelProps['warehouses']) => void;
}

export const DestinationAndWarehousePanel = ({
	projectName,
	warehouse_cities,
	warehouses,
	onWarehouseCitiesChange,
	onWarehousesChange,
	goPre,
	goNext,
}: DestinationAndWarehousePanelProps) => {
	const { t } = useTranslation();

	const items = [
		{
			key: 'Destination City',
			label: t(`Destination City`),
			children: (
				<div>
					{/* search */}
					<DestinationCityMultiSelector
						value={warehouse_cities.map((city) => city.id)}
						onChange={(newCities) => {
							onWarehouseCitiesChange(
								newCities.map((city) => ({ id: Number(city.value), name: city.label })),
							);
						}}
					/>

					<Gap height='16px' />

					{/* tags */}
					<ProjectTagGroup
						tags={warehouse_cities.map((city) => ({
							label: city.name,
							enableClose: true,
							onClose: () =>
								onWarehouseCitiesChange(warehouse_cities.filter((c) => c.id !== city.id)),
						}))}
					/>
				</div>
			),
		},
		{
			key: 'Destination Warehouse',
			label: t(`Destination Warehouse`),
			children: (
				<div>
					{/* search */}
					<DestinationWarehouseMultiSelector
						selectedDestinationCityIds={warehouse_cities.map((city) => city.id)}
						value={warehouses.map((warehouse) => warehouse.id)}
						onChange={(newWarehouses) => {
							onWarehousesChange(
								newWarehouses.map((warehouse) => ({ id: warehouse.value, name: warehouse.label })),
							);
						}}
					/>

					<Gap height='16px' />

					{/* tags */}
					<ProjectTagGroup
						tags={warehouses.map((warehouse) => ({
							label: warehouse.name,
							enableClose: true,
							onClose: () => onWarehousesChange(warehouses.filter((w) => w.id !== warehouse.id)),
						}))}
					/>
				</div>
			),
		},
	];

	return (
		<div className='w-full h-full flex flex-col'>
			<div className='h2-b text-black'>{projectName}</div>

			<Gap height='28px' />

			{/* content */}
			<div className=' box-border grow p-[16px] border-[1px] border-solid border-grey-02 rounded-[8px]'>
				{/* title */}
				<div className='body-3-b text-grey-01'>{t('Multi-Select Your Destinations')}</div>

				<Gap height='16px' />

				<Collapse
					ghost
					items={items}
					defaultActiveKey={['Destination City', 'Destination Warehouse']}
				/>
			</div>

			<Gap height='22px' />

			<Buttons goPre={goPre} goNext={goNext} />
		</div>
	);
};
