import React from 'react';
import PathActiveSvg from '@/assets/path-active.svg';
import PathSvg from '@/assets/path.svg';

export default function ({
	active,
	...props
}: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
	active?: boolean;
}) {
	return <img {...props} src={active ? PathActiveSvg : PathSvg} />;
}
