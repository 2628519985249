import { useTranslation } from 'react-i18next';
import CircleBlueFillIcon from '@/icons/circle-blue-fill';
import DestinationIcon from '@/icons/destination-icon';
import GreyDownArrowIcon from '@/icons/grey-down-arrow-icon';
import { useShipmentOrderContext } from '@/services/contexts';
import { CopyOutlined } from '@ant-design/icons';
import { MapBox } from '@/pages/search-rate/components/mapbox';
import { useCopy } from '@/hooks/use-copy';
import { formateActEstTime } from '../../utils';
import { ShipmentUpdateTimeline } from './shipment-update-timeline';
import { TrackingTable } from './tracking-table';

export const Tracking = () => {
	const { t } = useTranslation();

	const shipmentOrderContext = useShipmentOrderContext();

	const data = shipmentOrderContext.shipmentOrder;

	const { copy } = useCopy();

	return (
		<div className='w-full h-full flex flex-col gap-[32px]'>
			<div className='w-full h-[333px] flex items-center gap-[32px]'>
				{/* map */}
				<div className='h-full grow shrink rounded-[10px] overflow-hidden'>
					<MapBox start={data.pickup_city} to={data.destination_city} />
				</div>
				{/* info */}
				<div className='box-border px-[24px] py-[48px] h-full w-[416px] flex flex-col gap-[6px] border-[1px] border-solid border-primary-light-02 rounded-[10px] overflow-hidden'>
					<div className='flex flex-col gap-[4px]'>
						<div className='flex items-center gap-[5px]'>
							<CircleBlueFillIcon />
							<div className='h4-b text-[#333333]'>{`${data.pickup_city.full_name
								.split(',')
								.slice(0, 2)
								.join(',')} ${data.pickup_zipcode}`}</div>
						</div>
						<div className='body-3-sb text-grey-01'>{t(`Pick Up`)}</div>
						<div className='body-3-r text-grey-01'>
							{formateActEstTime({
								act: data.actual_pickup_date,
								est: data.estimated_pickup_date,
							})}
						</div>
					</div>

					<GreyDownArrowIcon className='w-[23px]' />

					<div className='flex flex-col gap-[4px]'>
						<div className='flex items-center gap-[5px]'>
							<DestinationIcon />
							<div className='h4-b text-[#333333]'>{`${data.destination_city.full_name
								.split(',')
								.slice(0, 2)
								.join(',')} ${data.destination_zipcode}`}</div>
						</div>
						<div className='body-3-sb text-grey-01'>{t(`Destination`)}</div>
						<div className='body-3-r text-grey-01'>
							{formateActEstTime({
								act: data.actual_delivery_date,
								est: data.estimated_delivery_date,
							})}
						</div>
					</div>
				</div>
			</div>

			{/* shipment update */}
			<div className='box-border px-[32px] py-[24px] flex flex-col gap-[24px] border-[1px] border-solid border-primary-light-02 rounded-[10px]'>
				<div className='body-3-b text-black'>{t(`Shipment Update`)}</div>
				<div>
					<ShipmentUpdateTimeline />
				</div>
			</div>

			{/* tracking */}
			<div className='box-border px-[32px] py-[24px] flex flex-col gap-[24px] border-[1px] border-solid border-primary-light-02 rounded-[10px]'>
				<div className='flex items-center gap-[40px]'>
					<div className='body-3-b text-black'>{t(`Shipment Update`)}</div>
					{!!data.customer_reference_number && (
						<div className='flex items-center gap-[5px]'>
							<div className='flex items-center body-4-r text-black'>
								<span>{t(`REF#:`)}</span>
								<span>{data.customer_reference_number}</span>
							</div>
							<CopyOutlined
								size={16}
								className='text-[#747578] cursor-pointer'
								onClick={() => copy(data.customer_reference_number)}
							/>
						</div>
					)}
				</div>

				{/* table */}
				<TrackingTable />
			</div>
		</div>
	);
};
