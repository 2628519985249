import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button, Space } from 'antd';
import ProgressDeliveredIcon from '@/icons/progress-delivered-icon';
import ProgressDeliveredInactiveIcon from '@/icons/progress-delivered-inactive-icon';
import ProgressEmptyReturnedIcon from '@/icons/progress-empty-returned-icon';
import ProgressEmptyReturnedInactiveIcon from '@/icons/progress-empty-returned-inactive-icon';
import ProgressPickedUpIcon from '@/icons/progress-picked-up-icon';
import ProgressPickedUpInactiveIcon from '@/icons/progress-picked-up-inactive-icon';
import { Calendar, CalendarProps } from '@/components/calendar';
import { useCalendar } from '@/components/calendar/use-calendar';
import { Gap } from '@/components/gap';
import { SimplePagination } from '@/components/simple-pagenation';
import { apiHooks } from '@/hooks/apis';
import { ProgressMapKeysEnum } from '../use-progress-map';
import './index.less';
// import { ProcessTrackingModal, useProcessTrackingModal } from '../components';
import { ProcessTrackingModal, useProcessTrackingModal } from './progress-tracking-modal';

const HeaderRender: CalendarProps['headerRender'] = ({ title }) => {
	const { t } = useTranslation();

	return (
		<div
			style={{
				backgroundColor: ['SUN', 'SAT'].includes(title) ? '#f3f6f9' : 'white',
			}}
			className={` sticky top-[0] box-border p-[12px] w-full h-full flex justify-end items-center border-[0.5px] border-solid border-grey-05 foot-note-1-m text-grey-01`}
		>
			{t(title)}
		</div>
	);
};

const DateRender: CalendarProps['dateRender'] = ({ current, base, isPre, isToday, isFuture }) => {
	const { t } = useTranslation();

	const { projectId } = useParams();

	const firstDay = base.startOf('month');
	const startDay = firstDay.subtract(firstDay.day(), 'day');
	const endDay = startDay.add(41, 'day');

	const dateRange = {
		start: startDay.format('YYYY-MM-DD'),
		end: endDay.format('YYYY-MM-DD'),
	};
	// const dateRange = [startDay.format('YYYY-MM-DD'), endDay.format('YYYY-MM-DD')];

	const { data } = apiHooks.useGetProjectContainersCalendar({
		cacheKey: dateRange.start + '-' + dateRange.end,
		// cacheKey: dateRange.join('-'),
		defaultParams: [{ path: { projectId }, params: { date_range: dateRange } }],
	});

	const currentData = data?.find((record) => record.date === current.format('YYYY-MM-DD'));
	// const currentData = data?.[current.format('YYYY-MM-DD')];

	const pickupNums = useMemo(() => {
		if (isToday) {
			return (
				(currentData?.actual_terminal_pickup_total || 0) +
				' / ' +
				(currentData?.schedule_terminal_pickup_total || 0)
			);
		}

		return (
			currentData?.[isPre ? 'actual_terminal_pickup_total' : 'schedule_terminal_pickup_total'] ?? 0
		);
	}, [isToday, currentData]);

	const deliveryNums = useMemo(() => {
		if (isToday) {
			return (
				(currentData?.actual_delivery_total || 0) +
				' / ' +
				(currentData?.scheduled_delivery_total || 0)
			);
		}

		return currentData?.[isPre ? 'actual_delivery_total' : 'scheduled_delivery_total'] ?? 0;
	}, [isToday, currentData]);
	// const pickupNums = currentData?.[
	// 	isPre || isToday ? 'actual_terminal_pickup_total' : 'schedule_terminal_pickup_total'
	// ] ?? 0;
	// const deliveryNums =
	// 	currentData?.[isPre || isToday ? 'actual_delivery_total' : 'scheduled_delivery_total'] ?? 0;
	const emptyReturnedNums = currentData?.actual_empty_returned_total ?? 0;

	const items: DateItemProps[] = [
		{
			key: ProgressMapKeysEnum.pickup,
			title: t(`Picked Up`),
			inactive: isFuture,
			nums: pickupNums,
			icon: <ProgressPickedUpIcon />,
			inactiveIcon: <ProgressPickedUpInactiveIcon />,
			textColor: '#FA541C',
			borderColor: '#FFD8BF',
			bgColor: '#FFF2E8',
		},
		{
			key: ProgressMapKeysEnum.delivered,
			title: t(`Delivered`),
			inactive: isFuture,
			nums: deliveryNums,
			icon: <ProgressDeliveredIcon />,
			inactiveIcon: <ProgressDeliveredInactiveIcon />,
			textColor: '#52C41A',
			borderColor: '#D9F7BE',
			bgColor: '#F6FFED',
		},
		{
			key: ProgressMapKeysEnum.empty_returned,
			title: t(`Returned`),
			inactive: isFuture,
			nums: emptyReturnedNums,
			icon: <ProgressEmptyReturnedIcon />,
			inactiveIcon: <ProgressEmptyReturnedInactiveIcon />,
			textColor: '#08979C',
			borderColor: '#B5F5EC',
			bgColor: '#E6FFFB',
		},
	];

	const isBaseMonth = current.year() === base.year() && current.month() === base.month();

	const {
		progressKey,
		openingProcessTrackingModal,
		handleTabChange,
		openProcessTrackingModal,
		closeProcessTrackingModal,
	} = useProcessTrackingModal();

	return (
		<>
			<div
				style={{
					backgroundColor: [0, 6].includes(current.day()) ? '#f3f6f9' : 'white',
				}}
				className='box-border p-[8px] w-full h-full flex flex-col border-[0.5px] border-solid border-grey-05 foot-note-1-m text-grey-01'
			>
				{!isToday && (
					<div
						style={{
							color: isBaseMonth ? 'black' : '',
						}}
						className=' flex-grow-0 flex-shrink-0 w-full h-[20px] text-right foot-note-1-b text-grey-03'
					>
						{current.date()}
					</div>
				)}
				{isToday && (
					<div className=' flex-grow-0 flex-shrink-0 w-full h-[20px] flex justify-end items-center foot-note-1-b '>
						<div className='w-[20px] h-[20px] flex justify-center items-center bg-primary-regular text-white rounded-full'>
							{current.date()}
						</div>
					</div>
				)}
				<Gap height='12px' />
				<Space direction='vertical' size={12}>
					{items.map(
						(i) =>
							i.nums !== 0 && (
								<div className=' cursor-pointer' onClick={() => openProcessTrackingModal(i.key)}>
									<DateItem {...i} />
								</div>
							),
					)}
				</Space>
			</div>
			{openingProcessTrackingModal && (
				<ProcessTrackingModal
					progressKey={progressKey}
					projectId={Number(projectId)}
					open={openingProcessTrackingModal}
					date={current.format('YYYY-MM-DD')}
					onClose={closeProcessTrackingModal}
				/>
			)}
		</>
	);
};

interface DateItemProps {
	key: ProgressMapKeysEnum;
	nums: number | string;
	inactive: boolean;
	icon: React.ReactNode;
	inactiveIcon: React.ReactNode;
	title: string;
	textColor: string;
	borderColor: string;
	bgColor: string;
}

const DateItem = ({
	nums,
	inactive,
	icon,
	inactiveIcon,
	title,
	textColor,
	borderColor,
	bgColor,
}: DateItemProps) => {
	return (
		<>
			{!inactive ? (
				<div
					style={{
						color: textColor,
						borderColor,
						backgroundColor: bgColor,
					}}
					className={`box-border w-full h-[28px] p-[0_12px] flex justify-between items-center border-[1px] border-solid rounded-[16px] foot-note-1-m`}
				>
					<div className='flex items-center'>
						{icon}
						<Gap width='4px' />
						<div>{title}</div>
					</div>
					<div>{nums}</div>
				</div>
			) : (
				<div
					className={`box-border w-full h-[28px] p-[0_12px] flex justify-between items-center border-[1px] border-dashed border-grey-03 rounded-[16px] foot-note-1-m text-grey-03 bg-grey-06`}
				>
					<div className='flex items-center'>
						{inactiveIcon}
						<Gap width='4px' />
						<div>{title}</div>
					</div>
					<div>{nums}</div>
				</div>
			)}
		</>
	);
};

export const CalendarPanel = () => {
	const { t } = useTranslation();

	const { base, goToday, goPreMonth, goNextMonth } = useCalendar();

	const PaginationLabel = <div className='min-w-[71px] text-center'>{base.format('MMMM')}</div>;

	return (
		<div className='h-full w-full flex flex-col'>
			<div className='pl-[36px] h-[56px] w-full flex items-center bg-white'>
				<Button onClick={goToday}>{t(`Today`)}</Button>
				<Gap width='16px' />
				<SimplePagination label={PaginationLabel} onPre={goPreMonth} onNext={goNextMonth} />
			</div>
			<Calendar
				base={base}
				className='w-full grow shrink overflow-y-scroll'
				headerRender={HeaderRender}
				dateRender={DateRender}
			/>
		</div>
	);
};
