import React from 'react';
import ActiveSvg from '@/assets/warehouse-48px-active.svg';
import Svg from '@/assets/warehouse-48px.svg';

export default function ({
	active,
	...props
}: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
	active?: boolean;
}) {
	return <img {...props} src={active ? ActiveSvg : Svg} />;
}
