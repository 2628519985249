import React from 'react';

export const Item = ({ title, content }: { title: string; content: string | React.ReactNode }) => {
	return (
		<div>
			<div className='body-3-r text-grey-01'>{title}</div>
			<div className='body-3-m text-[#333333]'>{content}</div>
		</div>
	);
};
