import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMemoizedFn } from 'ahooks';
import qs from 'qs';
import valueTransfer from '@/utils/value-transfer';

export interface OrdersQuery {
	per_page?: number;
	page?: number;

	sort_by?: string;
	sort_value?: 'desc' | 'asc';

	query?: string;
	container_status?: string[];
	created_at_start_date?: string;
	created_at_end_date?: string;

	order_id?: string;
}

export default () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const parsedSearchParams = useMemo(() => {
		const searchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });

		const res = {};

		valueTransfer(res, 'per_page', searchParams.per_page, (v) => (v ? Number(v) : 20));
		valueTransfer(res, 'page', searchParams.page, (v) => (v ? Number(v) : 1));
		valueTransfer(res, 'sort_by', searchParams.sort_by, (v) => v ?? undefined);
		valueTransfer(res, 'sort_value', searchParams.sort_value, (v) => v ?? 'desc');
		valueTransfer(res, 'query', searchParams.query, (v) => v ?? undefined);
		valueTransfer(res, 'container_status', searchParams.container_status, (v) => v ?? []);
		valueTransfer(
			res,
			'created_at_start_date',
			searchParams.created_at_start_date,
			(v) => v ?? undefined,
		);
		valueTransfer(
			res,
			'created_at_end_date',
			searchParams.created_at_end_date,
			(v) => v ?? undefined,
		);
		valueTransfer(res, 'order_id', searchParams.order_id, (v) => v ?? undefined);

		return res as OrdersQuery;
	}, [searchParams]);

	const changeSearchParams = useMemoizedFn((values: any) => {
		setSearchParams(qs.stringify({ ...parsedSearchParams, ...values }));
	});

	return {
		parsedSearchParams,
		changeSearchParams,
	};
};
