import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetProjectMyFocusProps, GetProjectMyFocusRes } from './interface';

export * from './interface';

export const getProjectMyFocus = async (props: GetProjectMyFocusProps) => {
	return await axios.get<GetProjectMyFocusRes>(
		`/projects/${props.path.projectId}/myFocus/${props.path.type}`,
		{
			headers: {
				Authorization: accessTokenManager.getAccessToken(),
			},
			params: props.params,
		},
	);
};
