import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { TimeRange } from '@/interfaces/time-range.interface';
import { PlusOutlined } from '@ant-design/icons';
import { useDynamicList } from 'ahooks';
import { Gap } from '@/components/gap';
import { CreateProjectPanelValue } from '..';
import { Buttons, ButtonsProps } from '../buttons';
import { TimeRangeItem } from './time-range-item';

export interface TimeRangePanelProps extends Pick<ButtonsProps, 'goPre'> {
	projectName: string;
	value: CreateProjectPanelValue['time_ranges'];
	createLoading: boolean;
	onChange: (value: TimeRangePanelProps['value']) => void;
	onClick2: () => void;
}

export const TimeRangePanel = ({
	projectName,
	value,
	createLoading,
	onChange,
	goPre,
	onClick2,
}: TimeRangePanelProps) => {
	const { t } = useTranslation();

	const { list, remove, replace, push } = useDynamicList<
		['final_port_eta' | 'created_at' | undefined, TimeRange]
	>(
		Object.entries(value) as ['final_port_eta' | 'created_at' | undefined, TimeRange | undefined][],
	);

	useEffect(() => {
		const validList = list.filter((i) => !!i[0] && !!i[1]?.start && !!i[1]?.end);
		const newValue = {};
		for (const item of validList) {
			newValue[item[0] as 'final_port_eta' | 'created_at'] = item[1];
		}
		onChange(newValue);
	}, [list]);

	return (
		<div className='w-full h-full flex flex-col'>
			<div className='h2-b text-black'>{projectName}</div>

			<Gap height='28px' />

			{/* content */}
			<div className=' box-border grow p-[16px] border-[1px] border-solid border-grey-02 rounded-[8px]'>
				{/* title */}
				<div className='body-3-b text-grey-01'>{t('Multi-Select Your Time Ranges')}</div>

				<Gap height='16px' />

				{list.map((item, index) => (
					<>
						<TimeRangeItem
							items={list.map((i) => i[0])}
							value={{
								time: item[0],
								value: item[1],
							}}
							onChange={(v) => {
								replace(index, [v.time, v.value]);
							}}
							handleDelete={() => {
								remove(index);
							}}
						/>
						<Gap height='16px' />
					</>
				))}
				<Button
					icon={<PlusOutlined className='text-grey-02' />}
					type='text'
					onClick={() => list.length < 2 && push([undefined, { start: undefined, end: undefined }])}
				>
					<span className='text-[#545F71]'>{t(`Add Items`)}</span>
				</Button>
			</div>

			<Gap height='22px' />

			<Buttons
				text2={t(`Done`)}
				preventDefaultClick2
				goPre={goPre}
				onClick2={onClick2}
				button2Props={{ loading: createLoading }}
			/>
		</div>
	);
};
