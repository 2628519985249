import { useCallback, useRef } from 'react';
import Map, { useMap } from 'react-map-gl';
import * as _ from 'lodash';
import { GetProjectContainersMapRes } from '@/services/apis';
import { useBoolean, useMount } from 'ahooks';
import CenterSpin from '@/components/center-spin';
import { ArrElement } from '@/utils/array-element.type';
import { fitCoordinates } from '@/utils/fit-coordinates';
import { PointMarker } from '../../components';
import './index.less';

export interface Point
	extends Omit<ArrElement<GetProjectContainersMapRes['arrived_at_port']>, 'city'> {
	id: any;
	longitude: number;
	latitude: number;
}

interface ContainersMapPointMarkerProps {
	point: Point;
	selectedPointId: Point['id'];
	onMarkClick: (pointId: Point['id']) => void;
}

export interface ContainersMapProps
	extends Pick<ContainersMapPointMarkerProps, 'selectedPointId' | 'onMarkClick'> {
	points: Point[];
	mapLoading: boolean;
}

// const PointMarker = ({ point, selectedPointId, onMarkClick }: ContainersMapPointMarkerProps) => {
// 	const isSelected = point.id === selectedPointId;

// 	const { progressKeys, progressMap } = useProgressMap();

// 	const validValues = progressKeys
// 		.map(
// 			(key) =>
// 				point[
// 				key
// 					.split('_')
// 					.map((s) => _.capitalize(s))
// 					.join(' ')
// 				],
// 		)
// 		.filter((v) => !!v && v !== 0).length;

// 	return (
// 		// <div></div>
// 		<PointMarker
// 			point={point}
// 			selectedPointId={selectedPointId}
// 			onMarkClick={() => { }}
// 		/>

// 		// <div onClick={() => onMarkClick(point.id)}>
// 		// 	<Marker longitude={point.longitude} latitude={point.latitude}>
// 		// 		<div className=' box-border w-[14px] h-[14px] border-[1px] border-solid rounded-full border-white bg-primary-regular' />
// 		// 	</Marker>
// 		// 	<Popup
// 		// 		offset={[0, -18 * validValues - 10] as [number, number]}
// 		// 		anchor='center'
// 		// 		longitude={point.longitude}
// 		// 		latitude={point.latitude}
// 		// 		closeButton={false}
// 		// 		closeOnClick={false}
// 		// 		closeOnMove={false}
// 		// 		// className={clsx('to-city-popup bg-primary-dark-01', { 'opacity-50': opacity })}
// 		// 		className='containers-map-popup'
// 		// 	>
// 		// 		{point.total ?? 1}

// 		// 		{/* <Space direction='vertical' size={4} className=' cursor-pointer'>
// 		// 			{progressKeys.map((key) => {
// 		// 				const _key = key
// 		// 					.split('_')
// 		// 					.map((s) => _.capitalize(s))
// 		// 					.join(' ');
// 		// 				const { borderColor, bgColor, textColor, Icon } = progressMap[key];
// 		// 				return (
// 		// 					!!point[_key] &&
// 		// 					point[_key] !== 0 && (
// 		// 						<Label
// 		// 							content={<Icon style={{ display: 'flex' }} className='w-[13px] h-[13px]' />}
// 		// 							num={point[_key]}
// 		// 							borderColor={borderColor}
// 		// 							bgColor={bgColor}
// 		// 							textColor={textColor}
// 		// 						/>
// 		// 					)
// 		// 				);
// 		// 			})}
// 		// 		</Space> */}
// 		// 	</Popup>
// 		// </div>
// 	);
// };

export const ContainersMap = ({
	points,
	selectedPointId,
	mapLoading,
	onMarkClick,
}: ContainersMapProps) => {
	const { projectContainersMap } = useMap();

	const cacheRef = useRef<string>('');

	const [isInit, { setTrue }] = useBoolean(false);

	const autoFit = useCallback(() => {
		cacheRef.current = fitCoordinates(
			projectContainersMap,
			points.map((p) => [p.longitude, p.latitude]),
			cacheRef.current,
		);
	}, [projectContainersMap, points]);

	useMount(() => {
		setTrue();
	});

	if (mapLoading) return <CenterSpin />;

	return (
		<Map
			id={`projectContainersMap`}
			preserveDrawingBuffer={true}
			// 初始化展示完整美国地图
			initialViewState={{
				longitude: -96,
				latitude: 37.8,
				zoom: 4,
			}}
			style={{ width: '100%', height: '100%' }}
			mapStyle='mapbox://styles/drayeasyben/clkhj289k003101pw47sdckps'
			mapboxAccessToken={import.meta.env.VITE_MAPBOX_TOKEN}
			// reuseMaps
			onIdle={() => {
				isInit && autoFit();
			}}
			maxBounds={[
				[-172.298804, 4.84616], // Southwest coordinates
				[-48.90097, 70.52138], // Northeast coordinates
			]}
		>
			{points.map((point) => (
				<PointMarker
					key={point.id}
					point={{
						...point,
						label: <div className='min-w-[30px] flex justify-center'>{`${point.total}`}</div>,
					}}
					selectedPointId={selectedPointId}
					disableSelectedBigger
					onMarkClick={onMarkClick}
				/>
			))}
		</Map>
	);
};
