import clsx from 'clsx';
import ItemRender from './components/item-render';
import { StepsProps } from './type';

export default ({ items, current, className }: StepsProps) => (
	<div className={clsx('flex items-start', className)}>
		{items.map((item, index) => (
			<ItemRender item={item} index={index} items={items} current={current} />
		))}
	</div>
);
