import iconFactory from './icon-factory';

const Svg = () => (
	<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<g clip-path='url(#clip0_2140_37444)'>
			<path
				d='M26.6665 7.99967H15.9998L13.3332 5.33301H5.33317C3.85317 5.33301 2.67984 6.51967 2.67984 7.99967L2.6665 23.9997C2.6665 25.4797 3.85317 26.6663 5.33317 26.6663H26.6665C28.1465 26.6663 29.3332 25.4797 29.3332 23.9997V10.6663C29.3332 9.18634 28.1465 7.99967 26.6665 7.99967ZM25.3332 18.6663H21.3332V22.6663H18.6665V18.6663H14.6665V15.9997H18.6665V11.9997H21.3332V15.9997H25.3332V18.6663Z'
				fill='white'
			/>
		</g>
		<defs>
			<clipPath id='clip0_2140_37444'>
				<rect width='32' height='32' fill='white' />
			</clipPath>
		</defs>
	</svg>
);

export default iconFactory(Svg);
