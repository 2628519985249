export const containerSizes = [
	'20FR',
	'20GP',
	'20OT',
	'20RF',
	'20TK',
	'40FR',
	'40GP',
	'40HC',
	'40HR',
	'40OT',
	'40RD',
	'40RF',
	'40RH',
	'40TK',
	'45HC',
	'45RH',
	'46P3',
	'53FT',
	'53HC',
	'20HC',
	'40HQ',
	'27GP',
	'45HQ',
];
