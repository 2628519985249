/**
 * 计算范围
 * @param coordinates
 * @returns
 */
export const calcCorners = (
	coordinates: [number, number][],
): [[number, number], [number, number]] => {
	const offset = 0.2;
	const lngs = coordinates.map((c) => c[0]);
	const lats = coordinates.map((c) => c[1]);
	const south = Math.min(...lngs) - offset;
	const north = Math.max(...lngs) + offset;
	const west = Math.min(...lats) - offset;
	const east = Math.max(...lats) + offset;

	return [
		[south, west],
		[north, east],
	];
};
