import dayjs from 'dayjs';

export const generatePreNMonthWeeksIndex = (nums: number) => {
	// 获取当前日期
	const current = dayjs();

	// 两个月前的第一天
	const firstDay = current
		.subtract(nums - 1, 'month')
		.startOf('month')
		.subtract(1, 'day');

	// 存储月份的数组
	const weeks: string[] = [];

	// 取当前周的最后一天
	let endDay = current.endOf('week');

	// endDay 的月差 < 3
	while (endDay.isAfter(firstDay)) {
		// @ts-ignore
		let weekIndex = `${endDay.week()}`;
		if (weekIndex.length === 1) weekIndex = `0${weekIndex}`;
		weeks.unshift(`${endDay.year()}${weekIndex}`);
		endDay = endDay.subtract(1, 'week');
	}

	return weeks;
};
