import { getHolidays } from '@/services/apis/get-holidays';
import { useRequest } from 'ahooks';
import { message } from '@/utils/message';

export const useGetHolidays = (options?: Record<any, any>) => {
	const res = useRequest(getHolidays, {
		cacheKey: 'useGetHolidays',
		onError: (error) => {
			// @ts-ignore
			message.error(`${error.response?.data?.message ?? error.message}`);
		},
		...options,
	});

	return {
		...res,
		data: res.data?.data,
	};
};
