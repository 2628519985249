import React from 'react';
import departureSvg from '@/assets/departure.svg';

const DepartureIcon = (
	props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
) => {
	return <img {...props} src={departureSvg} />;
};

export default DepartureIcon;
