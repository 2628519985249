import { createContext, useContext } from 'react';
import { shipmentOrder } from './shipment-order.context';

export const shipmentOrderContext = createContext(shipmentOrder);

export const ShipmentOrderContext: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
	return (
		<shipmentOrderContext.Provider value={shipmentOrder}>{children}</shipmentOrderContext.Provider>
	);
};

export const useShipmentOrderContext = () => useContext(shipmentOrderContext);
