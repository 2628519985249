import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Table, TableProps, Typography } from 'antd';
import { Container, Order } from '@/interfaces/order.interface';
import AvaliableToPickup from '@/components/container-release-status-icon/avaliable-to-pickup';
import CustomReleased from '@/components/container-release-status-icon/custom-released';
import SslReleased from '@/components/container-release-status-icon/ssl-released';
import TerminalReleased from '@/components/container-release-status-icon/terminal-released';
import { ExpandIcon } from '@/components/expand-icon';
import Risks from '@/components/risks';
import useOrdersParams from '../../hooks/use-orders-params';

const TableExpandedRowContainersTab = ({ order }: { order: Order }) => {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const { changeSearchParams } = useOrdersParams();

	const handleNavigate = (record: Container) => {
		changeSearchParams({ order_id: order.id });
		navigate(`/orders/${order.id}/containers/${record.id}`);
	};

	const tableExpandable: TableProps<Container>['expandable'] = {
		expandIcon: ExpandIcon,
		expandedRowRender: () => null,
		onExpand: (expanded, record) => handleNavigate(record),
	};

	const columns: TableProps<Container>['columns'] = [
		{
			title: t('Container #'),
			dataIndex: 'number',
			width: 160,
			fixed: 'left',
			render: (value, record) => (
				<span className='underline cursor-pointer' onClick={() => handleNavigate(record)}>
					{record.number}
					<Risks risks={record.risks}></Risks>
				</span>
			),
		},
		{
			title: t('Last Free Day'),
			dataIndex: 'lfd',
			width: 180,
			sorter: (a, b) => new Date(a.lfd ?? 0).getTime() - new Date(b.lfd ?? 0).getTime(),
		},
		{
			title: t('Pickup Date'),
			dataIndex: 'actual_terminal_pickuped_at',
			width: 140,
			sorter: (a, b) =>
				new Date(a.actual_terminal_pickuped_at ?? 0).getTime() -
				new Date(b.actual_terminal_pickuped_at ?? 0).getTime(),
		},
		{
			title: t('Delivery Date'),
			dataIndex: 'actual_delivered_at',
			width: 140,
			sorter: (a, b) =>
				new Date(a.actual_delivered_at ?? 0).getTime() -
				new Date(b.actual_delivered_at ?? 0).getTime(),
		},
		{
			title: t('Return Date'),
			dataIndex: 'actual_empty_returned_at',
			width: 140,
			sorter: (a, b) =>
				new Date(a.actual_empty_returned_at ?? 0).getTime() -
				new Date(b.actual_empty_returned_at ?? 0).getTime(),
		},
		{
			title: t('SSL'),
			dataIndex: 'freight_released_at',
			width: 80,
			render: (value, record) => (
				<SslReleased
					actual_empty_returned_at={record?.actual_empty_returned_at}
					actual_terminal_pickuped_at={record?.actual_terminal_pickuped_at}
					freight_released_at={record?.freight_released_at}
					port_of_discharge_eta={order?.port_of_discharge_eta}
				/>
			),
		},
		{
			title: t('CSTM'),
			dataIndex: 'customs_released_at',
			width: 60,
			render: (value, record) => (
				<CustomReleased
					actual_empty_returned_at={record?.actual_empty_returned_at}
					actual_terminal_pickuped_at={record?.actual_terminal_pickuped_at}
					customs_released_at={record?.customs_released_at}
					port_of_discharge_eta={order?.port_of_discharge_eta}
				/>
			),
		},
		{
			title: t('TRM'),
			dataIndex: 'terminal_released_at',
			width: 60,
			render: (value, record) => (
				<TerminalReleased
					actual_empty_returned_at={record?.actual_empty_returned_at}
					actual_terminal_pickuped_at={record?.actual_terminal_pickuped_at}
					terminal_released_at={record?.terminal_released_at}
					port_of_discharge_eta={order?.port_of_discharge_eta}
				/>
			),
		},
		{
			title: t('AVAL'),
			dataIndex: 'ready_to_pickup_at',
			width: 60,
			render: (_, record) => (
				<AvaliableToPickup
					actual_empty_returned_at={record?.actual_empty_returned_at}
					actual_terminal_pickuped_at={record?.actual_terminal_pickuped_at}
					ready_to_pickup_at={record?.ready_to_pickup_at}
					port_of_discharge_eta={order?.port_of_discharge_eta}
				/>
			),
		},
		{
			title: t('CNTR Status'),
			dataIndex: 'status_text',
			width: 160,
			render: (value) => t(value),
		},
		Table.EXPAND_COLUMN,
	];

	return (
		<Table
			columns={columns}
			dataSource={order.containers}
			pagination={false}
			rowKey='id'
			scroll={{ x: 'max-content' }}
			expandable={tableExpandable}
		/>
	);
};

export default TableExpandedRowContainersTab;
