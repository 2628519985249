import { useCallback, useState } from 'react';

export const useCreateProjectModal = () => {
	const [openingCreateProjectModal, setOpeningCreateProjectModal] = useState(false);

	const openCreateProjectModal = useCallback(() => {
		setOpeningCreateProjectModal(true);
	}, [setOpeningCreateProjectModal]);

	const closeCreateProjectModal = useCallback(() => {
		setOpeningCreateProjectModal(false);
	}, [setOpeningCreateProjectModal]);

	return {
		openingCreateProjectModal,
		openCreateProjectModal,
		closeCreateProjectModal,
	};
};
