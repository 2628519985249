import React, { useEffect } from 'react';
import { message } from 'antd';
import type { UploadProps } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { RcFile } from 'antd/lib/upload';
import {
	deleteDocument,
	getDocument,
	getDocuments,
	uploadDocument,
} from '@/services/apis/upload-doc';
import { InboxOutlined } from '@ant-design/icons';
import CenterSpin from '../center-spin';
import { DocumentsProps } from './types';
import { checkFileSize, FILE_MAX_SIZE } from './Validate';

const Documents = ({ target, targetId, disabled }: DocumentsProps) => {
	const [fileList, setFileList] = React.useState<UploadProps['fileList']>([]);
	const [isLoading, setIsLoading] = React.useState(false);

	useEffect(() => {
		fetchDocuments();
	}, []);

	const fetchDocuments = async () => {
		setIsLoading(true);
		try {
			const res = await getDocuments({ path: { target, targetId } });
			const documents = res.data.data;
			const fileList = documents.map((doc: any) => ({
				uid: doc.id,
				name: doc.name,
				status: 'done',
				url: doc.url,
			}));
			setFileList(fileList);
		} catch (err: any) {
			message.error(err.data?.message || err.data?.error);
		} finally {
			setIsLoading(false);
		}
	};

	const beforeUpload = (file: RcFile) => {
		if (disabled) {
			return false;
		}

		const sizeOk = checkFileSize(file);

		if (!sizeOk) {
			message.error(`File must smaller than ${FILE_MAX_SIZE}MB!`);
		}
		return sizeOk;
	};

	const props: UploadProps = {
		name: 'documents[0]',
		multiple: true,
		beforeUpload,
		action: `documents/${target}/${targetId}`,
		customRequest: async (options) => {
			const formData = new FormData();
			formData.append('documents[0]', options.file);
			setIsLoading(true);
			try {
				const resp = await uploadDocument({
					path: { target, targetId },
					data: formData,
				});
				setFileList(
					resp.data.data.map((doc: any) => ({ uid: doc.id, name: doc.name, status: 'done' })),
				);
			} catch (err: any) {
				message.error(err.data?.message || err.data?.error);
			} finally {
				setIsLoading(false);
			}
		},
		onRemove: async (file) => {
			if (disabled) {
				return;
			}
			setIsLoading(true);
			try {
				await deleteDocument({
					path: {
						uid: file.uid,
						target,
						targetId,
					},
				});
				setFileList(fileList?.filter((item) => item.uid !== file.uid));
				message.success(`${file.name} removed`);
			} catch (err: any) {
				message.error(err.data?.message || err.data?.error);
			} finally {
				setIsLoading(false);
			}
		},
		onPreview: async (file) => {
			const resp = await getDocument({
				path: {
					uid: file.uid,
					target,
					targetId,
				},
			});
			const url = resp.data;
			window.open(url, '_blank');
		},
	};

	return (
		<div>
			<Dragger disabled={isLoading} fileList={fileList} {...props}>
				<p className='ant-upload-drag-icon'>
					<InboxOutlined />
				</p>
				<p className='ant-upload-text'>Click or drag file to this area to upload</p>
				<p className='ant-upload-hint'>
					Support for a single or bulk upload. Strictly prohibit from uploading company data or
					other band files
				</p>
			</Dragger>
			<CenterSpin spinning={isLoading} />
		</div>
	);
};

export default Documents;
