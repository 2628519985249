import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetFTLParamsReq, GetFTLParamsRes } from './interface';

export * from './interface';

export const getFTLParams = async (props: GetFTLParamsReq) => {
	return await axios.get<GetFTLParamsRes>(`/ftl/tools/parameters`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
