import React from 'react';
import OWActiveSvg from '@/assets/ow-active.svg';
import OWSvg from '@/assets/ow.svg';

const OWIcon = ({
	active,
	...props
}: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
	active: boolean;
}) => {
	return <img {...props} src={active ? OWActiveSvg : OWSvg} />;
};

export default OWIcon;
