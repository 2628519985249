import drayEasy from '@/assets/dray-easy.svg';
import logo from '@/assets/logo.svg';
import SideBarLogoIcon from '@/icons/side-bar-logo-icon';
import LogoFilled40pxIcon from '@/icons/side-bar-logo-icon';
import clsx from 'clsx';

interface LogoProps {
	wrapperClass?: string;
	logoWrapperClass?: string;
}

export const Logo = ({ wrapperClass, logoWrapperClass }: LogoProps) => {
	return (
		<div
			className={clsx('w-full h-[80px] flex justify-center items-center gap-[16px]', wrapperClass)}
		>
			<SideBarLogoIcon />
		</div>
	);
};
