import { useCallback, useState } from 'react';

export const useAddProjectModal = () => {
	const [openingAddProjectModal, setOpeningAddProjectModal] = useState(false);

	const openProjectModal = useCallback(() => {
		setOpeningAddProjectModal(true);
	}, [setOpeningAddProjectModal]);

	const closeProjectModal = useCallback(() => {
		setOpeningAddProjectModal(false);
	}, [setOpeningAddProjectModal]);

	return {
		openingAddProjectModal,
		openProjectModal,
		closeProjectModal,
	};
};
