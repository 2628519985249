import { IntermodalRegion } from '@/interfaces/intermodal-region.interface';
import { getIntermodalRegions } from '@/services/apis';
import { makeAutoObservable, runInAction } from 'mobx';

class CacheContext {
	constructor() {
		makeAutoObservable(this);
	}

	private _fetching = false;

	private _intermodalRegions: IntermodalRegion[] = [];

	get fetching() {
		return this._fetching;
	}

	set fetching(fetching: typeof this._fetching) {
		runInAction(() => {
			this._fetching = fetching;
		});
	}

	get intermodalRegions() {
		return this._intermodalRegions;
	}

	set intermodalRegions(intermodalRegions: typeof this._intermodalRegions) {
		runInAction(() => {
			this._intermodalRegions = intermodalRegions;
		});
	}

	public async fetchIntermodalRegions() {
		const res = await getIntermodalRegions();
		this.intermodalRegions = res.data.data;
	}

	public async init() {
		await this.fetchIntermodalRegions();
	}
}

export const cacheContext = new CacheContext();
