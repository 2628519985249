import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Form, FormInstance, Select, Tag } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { CargoSelectValue } from '@/interfaces/cargo-select-value.enum';
import { WeightUnit } from '@/interfaces/weight-unit.enum';
import clsx from 'clsx';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import Input from '@/components/input';
import { LinkStyleRadioGroup } from '@/components/link-style-radio-group';
import { CargoSelectProps } from './interface';

/**
 * 判断某个value是否已选
 * @param value
 * @returns
 */
const isSelected = (form: FormInstance, formItemKey: string, value: number) => {
	const selectedValues: number[] = form.getFieldValue(formItemKey) ?? [];
	return selectedValues.includes(value);
};

const SocInput = ({ formItemKey }: { formItemKey: string }) => {
	const { t } = useTranslation();

	const handleClick = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
		e.preventDefault();
		e.stopPropagation();
	};

	return (
		<Form.Item noStyle name={formItemKey}>
			<Input
				className='border border-solid rounded-[4px] w-[224px]'
				placeholder={t('Return Address Zipcode')}
				onClick={handleClick}
			/>
		</Form.Item>
	);
};

const UnitCheckBox = ({ formItemKey }: { formItemKey: string }) => {
	const form = useFormInstance();

	const options = [WeightUnit.LB, WeightUnit.KG].map((v) => ({ label: v, value: v }));

	const value = form.getFieldValue(formItemKey);

	const onClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, value: string | number) => {
		e.preventDefault();
		e.stopPropagation();
		form.setFieldValue(formItemKey, value);
	};

	return (
		<Form.Item noStyle name={formItemKey} initialValue={WeightUnit.LB}>
			<LinkStyleRadioGroup options={options} value={value} onClick={onClick} />
		</Form.Item>
	);
};

const OwInput = ({ formItemKey }: { formItemKey: string }) => {
	const { t } = useTranslation();

	const handleClick = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
		e.preventDefault();
		e.stopPropagation();
	};

	return (
		<>
			<Form.Item noStyle name={formItemKey}>
				<Input
					className='border border-solid rounded-[4px] w-[224px]'
					placeholder={t('Weight')}
					suffix={<UnitCheckBox formItemKey='weight_unit' />}
					onClick={handleClick}
					onKeyDown={(e) => e.stopPropagation()}
				/>
			</Form.Item>
			{/* <div className='foot-note-1-r text-grey-01 mt-[4px]'>
				{t('Please contact sales if it is over 50000lb/22680kg.')}
			</div> */}
			<div className='foot-note-1-r text-grey-01 mt-[16px]'>
				{t('Please contact sales for the rate of SOC container.')}
			</div>
		</>
	);
};

const DangerousGoodHelperText = () => {
	return (
		<div className='foot-note-1-r text-grey-01 mt-[4px]'>
			Online quote for class 9 only. Please contact sales for other classes.
		</div>
	);
};

export const CargoSelect = ({
	formItemKey,
	wrapperClass,
	className,
	...props
}: CargoSelectProps) => {
	const { t } = useTranslation();

	const options = [
		{
			label: t('DG'),
			labelContent: `${t('Dangerous goods')} (${t('DG')})`,
			value: CargoSelectValue.DG,
			Extra: {
				Render: DangerousGoodHelperText,
			},
		},
		{
			label: t('RF'),
			labelContent: `${t('Reefer')} (${t('RF')})`,
			value: CargoSelectValue.RF,
		},
		// {
		// 	label: t('SOC'),
		// 	labelContent: `${t('Shipper own container')} (${t('SOC')})`,
		// 	value: 5,
		// 	Extra: {
		// 		key: 'return_address_zipcode',
		// 		Render: SocInput,
		// 	},
		// },
		{
			label: t('OW'),
			labelContent: `${t('Overweight')} (${t('OW')})`,
			value: CargoSelectValue.OW,
			disabled: true,
			Extra: {
				key: 'weight',
				Render: OwInput,
			},
		},
	];

	const form = Form.useFormInstance();

	/**
	 * 处理 portal 的开关
	 */
	const [open, setOpen] = useState(false);

	/**
	 * 自动新增、删除值
	 * @param newValue
	 */
	const handleSelect = (newValue: number) => {
		const isItemSelected = isSelected(form, formItemKey, newValue);
		const preValues: number[] = form.getFieldValue(formItemKey) ?? [];
		let values: number[] = [];
		if (isItemSelected) {
			values = preValues.filter((v) => v !== newValue);
		} else {
			values = [...preValues, newValue];
		}
		form.setFieldValue(formItemKey, values);
	};

	/**
	 * 标签渲染
	 * @param props
	 * @returns
	 */
	const TagRender = (props: CustomTagProps) => {
		const { label, closable, onClose } = props;
		const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
			event.preventDefault();
			event.stopPropagation();
		};
		return (
			<Tag
				className='bg-[#F5FAFF] text-[#3A92F7] border-[1px] border-solid leading-[34px] text-center border-[#DBEDFF] rounded-[200px] font-[400] text-[14px] w-fit h-[36px]'
				onMouseDown={onPreventMouseDown}
				closable={closable}
				onClose={(e) => {
					onClose(e);
				}}
				style={{ marginRight: 3 }}
			>
				{label}
			</Tag>
		);
	};

	/**
	 * 选择项渲染
	 * @param param0
	 * @returns
	 */
	const OptionRender = ({
		label,
		labelContent,
		value,
		Extra,
		disabled = false,
	}: {
		label: string;
		labelContent: string;
		value: number;
		disabled?: boolean;
		Extra?: { key?: string; Render: React.FC<{ formItemKey: string }> };
	}) => {
		return (
			<div
				className='flex items-start pt-[28px] pl-[16px] pr-[27px] w-[295px]'
				onClick={() => {
					!disabled && handleSelect(value);
				}}
			>
				<Checkbox
					disabled={disabled}
					checked={isSelected(form, formItemKey, value)}
					className='mr-[11px]'
				/>
				<div>
					<div className='font-[500] text-[16px] leading-[24px] text-[#555770] mb-[12px] cursor-pointer'>
						{labelContent}
					</div>
					{Extra && <Extra.Render formItemKey={Extra.key} />}
				</div>
			</div>
		);
	};

	return (
		<div className={clsx(wrapperClass)}>
			<Select
				{...props}
				className={clsx('w-full', className)}
				open={open}
				placeholder={`${t('Special Cargo Type')} (${t('Optional')})`}
				tagRender={(tagProps) => <TagRender {...tagProps} />}
				value={form.getFieldValue(formItemKey) as string[]}
				options={options}
				mode='multiple'
				onDropdownVisibleChange={(visible) => setOpen(visible)}
				maxTagCount='responsive'
				placement='bottomLeft'
				dropdownStyle={{
					// top: 'calc(50vh - 112px + 32px + 33px + 24px + 64px + 11px + 4px)',
					borderRadius: '0 0 8px 8px',
					paddingBottom: '20px',
					minWidth: '300px',
				}}
				dropdownRender={() => (
					<div>
						{options.map((o) => (
							<OptionRender key={o.value} {...o} />
						))}
					</div>
				)}
			/>
		</div>
	);
};
