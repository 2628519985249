import { DatePicker, DatePickerProps } from 'antd';
import { TimeRange } from '@/interfaces/time-range.interface';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useBoolean } from 'ahooks';
import dayjs from 'dayjs';
import { Gap } from '../gap';

interface TimePickerProps extends Omit<DatePickerProps, 'value' | 'onChange'> {
	value: string;
	onChange: (value: string) => void;
}

const TimePicker = ({ value, onChange, ...datePickerProps }: TimePickerProps) => {
	const [openingDatePicker, { toggle, set }] = useBoolean();

	return (
		<div className='relative'>
			{/* trigger */}
			<DatePicker
				open={openingDatePicker}
				defaultValue={value ? dayjs(value) : dayjs()}
				className=' absolute bottom-0 -z-10 opacity-0 w-[1px] h-[1px]'
				onChange={(_, dateString) => {
					console.log('dateString', dateString);
					onChange(dateString as string);
				}}
				onOpenChange={(o) => !o && set(o)}
				{...datePickerProps}
			/>

			{/* show */}
			<div
				className=' box-border p-[0_20px] h-[36px] min-w-[122px] flex justify-between items-center border-[1px] border-solid border-primary-light-03 rounded-[18px] body-4-r text-primary-regular'
				onClick={toggle}
			>
				<div>{value && value.length > 0 && dayjs(value).format('MM/DD/YYYY')}</div>
				{openingDatePicker ? <UpOutlined /> : <DownOutlined />}
			</div>
		</div>
	);
};

export interface TimeRangeSelectorProps {
	value: TimeRange;
	onChange: (value: TimeRange) => void;
	timePicker1Props?: Partial<TimePickerProps>;
	timePicker2Props?: Partial<TimePickerProps>;
}

export const TimeRangeSelector = ({
	value,
	onChange,
	timePicker1Props,
	timePicker2Props,
}: TimeRangeSelectorProps) => {
	return (
		<div className='flex items-center'>
			<TimePicker
				value={value?.start}
				onChange={(v) => {
					onChange({ ...value, start: v });
				}}
				{...timePicker1Props}
			/>
			<Gap width='10px' />
			<span>{` - `}</span>
			<Gap width='10px' />
			<TimePicker
				value={value?.end}
				onChange={(v) => {
					onChange({ ...value, end: v });
				}}
				{...timePicker2Props}
			/>
		</div>
	);
};
