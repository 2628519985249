import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import Delete24pxIcon from '@/icons/delete-24px-icon';
import { TimeRange } from '@/interfaces/time-range.interface';
import dayjs from 'dayjs';
import { TimeRangeSelector } from '@/components/time-range-selector';

export interface TimeRangeItemValue {
	time: 'final_port_eta' | 'created_at' | undefined;
	value: TimeRange;
}

export interface TimeRangeItemProps {
	items: string[];
	value: TimeRangeItemValue;
	onChange: (value: TimeRangeItemValue) => void;
	handleDelete: () => void;
}

export const TimeRangeItem = ({ value, items, onChange, handleDelete }: TimeRangeItemProps) => {
	const { t } = useTranslation();

	const options = [
		{
			label: t(`Final Port Eta`),
			value: 'final_port_eta',
		},
		{
			label: t(`Created At`),
			value: 'created_at',
		},
	].filter((i) => !items.includes(i.value) || i.value === value.time);

	return (
		<div className='flex justify-between items-center'>
			<Select
				value={value.time}
				options={options}
				className=' min-w-[132px]'
				onChange={(v) => onChange({ ...value, time: v })}
			/>

			<div className='flex items-center'>
				<TimeRangeSelector
					value={value.value}
					onChange={(v) => onChange({ ...value, value: v })}
					timePicker1Props={{
						minDate: dayjs().subtract(6, 'month').startOf('month'),
						maxDate: dayjs().add(6, 'month').endOf('month'),
					}}
					timePicker2Props={{
						minDate: dayjs().subtract(6, 'month').startOf('month'),
						maxDate: dayjs().add(6, 'month').endOf('month'),
					}}
				/>
				<Delete24pxIcon className=' cursor-pointer' onClick={handleDelete} />
			</div>
		</div>
	);
};
