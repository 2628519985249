import { LTLAddressType } from '@/interfaces/ltl-address-type.enum';
import { useLTLContext } from '@/services/contexts';
import { useMemoizedFn } from 'ahooks';
import dayjs from 'dayjs';
import { apiHooks } from '@/hooks/apis';
import { useGenerateAddress } from '../../use-generate-address';

export const useLTLStepOneAIAutofill = () => {
	const ltlContext = useLTLContext();

	const { runAsync: getLTLAutofill } = apiHooks.useGetLTLAutofill();

	const { generateAddress } = useGenerateAddress();

	const mapAddressTypeToEnum = (addressType: string): LTLAddressType => {
		switch (addressType.toLowerCase()) {
			case 'residential':
				return LTLAddressType.RESIDENTIAL;
			case 'limitedaccess':
				return LTLAddressType.LIMITED_ACCESS;
			case 'tradeshow':
				return LTLAddressType.TRADESHOW;
			case 'business':
			default:
				return LTLAddressType.BUSINESS;
		}
	};
	const handleAIAutofill = useMemoizedFn(async (content: string) => {
		const res = await getLTLAutofill({ data: { content } });
		const data = res.data;

		const pickupAddress = await generateAddress({
			city_name: data.pickup_city_name,
			state_name: data.pickup_state_name,
			zipcode: data.pickup_zipcode,
			address1: data.pickup_address1,
			address2: data.pickup_address2,
		});

		const destinationAddress = await generateAddress({
			city_name: data.destination_city_name,
			state_name: data.destination_state_name,
			zipcode: data.destination_zipcode,
			address1: data.destination_address1,
			address2: data.destination_address2,
		});

		if (pickupAddress) {
			ltlContext.pickupAddress = pickupAddress;
		}
		if (destinationAddress) {
			ltlContext.destinationAddress = destinationAddress;
		}

		ltlContext.pickUpAddressType = mapAddressTypeToEnum(data.pickup_address_type);
		ltlContext.destinationAddressType = mapAddressTypeToEnum(data.destination_address_type);

		ltlContext.stepOneFormValues = {
			pickup_date: data.estimated_pickup_date ? dayjs(data.estimated_pickup_date) : undefined, // 提货日期
			items:
				data.items.length > 0
					? data.items.map((item) => ({ ...item, id: Math.random().toString() }))
					: [],
			pickup_accessorials: data.pickup_accessorials, // 提货附加服务
			destination_accessorials: data.destination_accessorials, // 目的地附加服务
			shipment_accessorials: data.shipment_accessorials, // 运输附加服务
		};
		ltlContext.calcItemFreightClass();
	});

	return { handleAIAutofill };
};
