import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '@/services/contexts';
import { observer } from 'mobx-react-lite';
import { ContactUsBtn } from '@/components/contact-us-modal';
import { SuccessFinishPic } from '@/components/pics';
import { StartProjectPanel } from './start-project-panel';
import { FinishProps } from './type';

export default observer(({ order }: FinishProps) => {
	const { t } = useTranslation();

	const { userContext } = useGlobalContext();

	const orderId = order.id;

	const email = order?.user?.email ?? (order?.contact_emails ?? [])[0] ?? '';

	const __enable_project_feature = !!userContext.getUserInfo()?.__enable_project_feature;

	const navigate = useNavigate();

	return (
		<>
			<div className='pt-[100px] pb-[64px] h-full w-full flex flex-col justify-between items-center text-black'>
				<div>
					<div className='mb-[90px] w-full flex flex-col items-center'>
						<SuccessFinishPic className='mt-[18px]' />
						<div className='mb-[10px] h1-b '>{t(`Thanks for your order!`)}</div>
						<div className=' leading-[27px]'>
							{t('Your order number is')} <span className='font-[700] text-[16px]'>{orderId}</span>
						</div>
						<div className='text-[16px] leading-[27px]'>
							<span className='font-[700] mr-[1rem]'>
								{t('An order confirmation has been sent to')}
							</span>
							{email}
						</div>
						<div className='leading-[27px]'>
							{t(`You can`)}
							<span
								className='font-[700] text-[16px] text-primary-regular cursor-pointer ml-1 mr-1'
								onClick={() => navigate(`/orders/${orderId}`)}
							>
								{t(`view your order`)}
							</span>
							{t(`at Main Menu`)}
							{` - `}
							{t(`Orders`)}
						</div>
					</div>

					{__enable_project_feature && (
						<div className='w-full flex flex-col items-center'>
							<div className='h3-b'>{t(`This order doesn’t belong to any project.`)}</div>
							<div className='mb-[20px] h3-b'>{t(`Do you want to ...`)}</div>
							<StartProjectPanel order={order} />
						</div>
					)}
				</div>

				<div className='w-full flex flex-col items-center'>
					<ContactUsBtn />
				</div>
			</div>
		</>
	);
});
