import { BottomButtonProps } from './type';

export default ({
	leftButton,
	leftButtonMessage,
	rightButton1,
	rightButton1Message,
	rightButton2,
	rightButton2Message,
}: BottomButtonProps) => {
	return (
		<div className='flex justify-between pt-[30px] w-full'>
			{/* left */}
			<div>
				{leftButtonMessage ?? <div className='h-[22px]'></div>}
				<div className='w-[246px]'>{leftButton}</div>
			</div>

			{/* right */}
			<div className='flex'>
				<div className='flex flex-col items-center w-[246px]  mr-[35px]'>
					{rightButton1Message ?? <div className='h-[22px]'></div>}
					{rightButton1}
				</div>

				<div className='flex flex-col items-center w-[246px]  mr-[35px]'>
					{rightButton2Message ?? <div className='h-[22px]'></div>}
					{rightButton2}
				</div>
			</div>
		</div>
	);
};
