import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from 'antd';
import { Gap } from '@/components/gap';

export interface ButtonsProps {
	text1?: string;
	text2?: string;
	preventDefaultClick1?: boolean;
	preventDefaultClick2?: boolean;
	onClick1?: () => void;
	onClick2?: () => void;
	goPre?: () => void;
	goNext?: () => void;
	button1Props?: ButtonProps;
	button2Props?: ButtonProps;
}

export const Buttons = ({
	text1,
	text2,
	preventDefaultClick1,
	preventDefaultClick2,
	button1Props,
	button2Props,
	goPre,
	goNext,
	onClick1,
	onClick2,
}: ButtonsProps) => {
	const { t } = useTranslation();

	const handleClick1 = () => {
		onClick1 && onClick1();
		!preventDefaultClick1 && goPre && goPre();
	};

	const handleClick2 = () => {
		onClick2 && onClick2();
		!preventDefaultClick2 && goNext && goNext();
	};

	return (
		<div className='flex justify-end items-center'>
			<Button type='primary' ghost onClick={handleClick1} {...button1Props}>
				{text1 ?? t('Back')}
			</Button>
			<Gap width='16px' />
			<Button type='primary' onClick={handleClick2} {...button2Props}>
				{text2 ?? t('Next')}
			</Button>
		</div>
	);
};
