import { WeightUnit } from '@/interfaces/weight-unit.enum';
import calcWeight from './calc-weight';

export default (isReefer: boolean, size: number, weight: number, unit: WeightUnit) => {
	weight = calcWeight(weight, unit, WeightUnit.LB);

	switch (size) {
		case 20:
			return isReefer ? weight >= 34000 : weight >= 38000;
		case 40:
			return isReefer ? weight >= 40000 : weight >= 44000;
		case 45:
			return isReefer ? weight >= 38000 : weight >= 42000;
		default:
			break;
	}

	return false;

	//deprecated
	// return isReefer
	// 	? size >= 40
	// 		? weight >= 40000
	// 		: weight >= 34000
	// 	: size >= 40
	// 	? weight >= 44000
	// 	: weight >= 38000;
};
