import React from 'react';
import rampSvg from '@/assets/ramp.svg';

const RampIcon = (
	props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
) => {
	return <img {...props} src={rampSvg} />;
};

export default RampIcon;
