import React from 'react';
import upArrowSvg from '@/assets/up-arrow.svg';

const UpArrowIcon = (
	props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
) => {
	return <img {...props} src={upArrowSvg} />;
};

export default UpArrowIcon;
