import React from 'react';
import SearchActiveSvg from '@/assets/search-active.svg';
import SearchSvg from '@/assets/search.svg';

export default function ({
	active,
	...props
}: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
	active?: boolean;
}) {
	return <img {...props} src={active ? SearchActiveSvg : SearchSvg} />;
}
