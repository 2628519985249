import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import ShippingTruckIcon from '@/icons/shipping-truck-icon';

const CustomEmpty = () => {
	const { t } = useTranslation();

	return (
		<Empty
			imageStyle={{ display: 'none' }}
			description={
				<div className='flex-center'>
					<ShippingTruckIcon className='text-grey-03' />
					<span className='h-4-m text-grey-02'>{t('There’s nothing here.')}</span>
				</div>
			}
		/>
	);
};

export default CustomEmpty;
