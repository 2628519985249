import { useState } from 'react';
import { Button, ButtonProps } from 'antd';
import { downloadFile } from '@/services/apis/download-file';
import { DownloadFileProps } from '@/services/apis/download-file/interface';

const TableExpandedRowDocumentsTabPreviewButton = ({
	children,
	path,
	other,
	...props
}: ButtonProps & DownloadFileProps) => {
	const [isLoading, setIsLoading] = useState(false);

	const handleClick = () => {
		setIsLoading(true);
		downloadFile(
			{
				path,
				other,
			},
			{ preview: true },
		).finally(() => {
			setIsLoading(false);
		});
	};

	return (
		<Button type='link' loading={isLoading} onClick={handleClick} {...props}>
			{children}
		</Button>
	);
};

export default TableExpandedRowDocumentsTabPreviewButton;
