import ProcessingIcon from '@/icons/processing-icon';
import TickCircleColorIcon from '@/icons/tick-circle-color-icon';
import { Status } from '../type';

interface StatusIconProps {
	status: Status;
	value: any;
}

export default ({ status, value }: StatusIconProps) => {
	if (status === 'finished') return <TickCircleColorIcon />;
	if (status === 'processing') return value ? <TickCircleColorIcon /> : <ProcessingIcon />;
	return (
		<div className='w-[20px] h-[20px] border-[1.5px] border-solid border-grey-03 rounded-[50%] bg-white' />
	);
};
