import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Map, { Layer, Marker, Popup, Source, useMap } from 'react-map-gl';
import { message, Popover, Tooltip } from 'antd';
import i18n from '@/i18n';
import DestinationIcon from '@/icons/destination-icon';
import { getDirections } from '@/services/apis/get-directions';
import { InfoCircleOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import Point from './components/point';
import DistanceTag from './components/tag';
import ZoomController from './components/zoom-controller';
import { convert } from '@/utils/convert-units';
import './index.less';
import { MapBoxProps } from './type';

const layerStyle = {
	// id: "pathLayerDone",
	type: 'line',
	// source: "pathSourceDone",
	layout: {
		'line-join': 'round',
		'line-cap': 'round',
	},
	paint: {
		'line-width': 5,
		'line-color': '#009688',
	},
};

export const MapBox = observer(({ components, start, to, otherPoints }: MapBoxProps) => {
	const { t } = useTranslation();

	const [routes, setRoutes] = useState<any[]>([]);
	const startLng = start?.longitude;
	const startLat = start?.latitude;
	const toLng = to?.longitude;
	const toLat = to?.latitude;

	const { searchratemap } = useMap();

	/**
	 * 请求两个坐标之间的路线
	 * @param coordinates
	 * @returns
	 */
	const _getDirections = async (coordinates: [number, number][]) => {
		const uncodeUriComponentStr = coordinates.map((coordinate) => coordinate.join(',')).join(';');
		const uriComponentStr = encodeURIComponent(uncodeUriComponentStr);

		return await getDirections({
			path: uriComponentStr,
			params: {
				alternatives: true,
				geometries: 'geojson',
				overview: 'full',
				steps: false,
				exclude: 'ferry',
				access_token: import.meta.env.VITE_MAPBOX_TOKEN,
			},
		});
	};

	/**
	 * 检索主要（选中）路线
	 */
	const addRoute = async () => {
		if (!startLng || !startLat || !toLng || !toLat) return;
		try {
			const res = await _getDirections([
				[startLng, startLat],
				[toLng, toLat],
			]);
			const routes = res.data.routes;
			setRoutes([...routes]);
		} catch (error) {
			console.log('AddRoute Error: ', error);
			// message.error(t(`error`));
		}
	};

	/**
	 * 计算范围
	 * @param coordinates
	 * @returns
	 */
	const calcCorners = (coordinates: [number, number][]): [[number, number], [number, number]] => {
		const offset = 0.2;
		const lngs = coordinates.map((c) => c[0]);
		const lats = coordinates.map((c) => c[1]);
		const south = Math.min(...lngs) - offset;
		const north = Math.max(...lngs) + offset;
		const west = Math.min(...lats) - offset;
		const east = Math.max(...lats) + offset;

		return [
			[south, west],
			[north, east],
		];
	};

	/**
	 * 缩放到给定坐标的范围
	 * @param coordinates
	 * @returns
	 */
	const fitCoordinates = (coordinates: [number | undefined, number | undefined][]) => {
		coordinates = coordinates.filter((c) => c[0] !== undefined && c[1] !== undefined);
		// 如果没有坐标，展示完整地图
		if (coordinates.length === 0) {
			searchratemap?.flyTo({
				center: [-96, 37.8],
				zoom: 4,
			});
			return;
		}

		// 如果只有一组坐标，移动到该点
		if (coordinates.length === 1) {
			searchratemap?.flyTo({
				// @ts-ignore
				center: coordinates[0],
			});
			return;
		}

		// 如果有多组坐标，全部展示
		if (coordinates.length > 1) {
			// @ts-ignore
			const corners = calcCorners(coordinates);
			searchratemap?.fitBounds(corners, { padding: { top: 30, bottom: 30, left: 30, right: 30 } });
			return;
		}
	};

	/**
	 * 自动缩放，带上一次缓存
	 */
	const autoFIt = (() => {
		let _intermodalRegionLng: undefined | number = undefined;
		let _intermodalRegionLat: undefined | number = undefined;
		let _toLng: undefined | number = undefined;
		let _toLat: undefined | number = undefined;

		return () => {
			const cache = [
				[_intermodalRegionLng, _intermodalRegionLat],
				[_toLng, _toLat],
			].toString();

			const current = [
				[startLng, startLat],
				[toLng, toLat],
			].toString();

			if (cache === current) return;

			_intermodalRegionLng = startLng;
			_intermodalRegionLat = startLat;
			_toLng = toLng;
			_toLat = toLat;

			fitCoordinates([
				[startLng, startLat],
				[toLng, toLat],
				// 地图尺寸太小，把所有点展示出来被选中的路线很难找
				// ...otherPoints.map(p => ([p.longitude, p.latitude] as [number, number]))
			]);
		};
	})();

	useEffect(() => {
		addRoute();
		// addOtherRoute();
		autoFIt();
	}, [startLng, startLat, toLng, toLat, otherPoints]);

	const distance = components?.distance && routes?.[0]?.distance ? routes[0].distance : null;
	const distanceMiles = distance ? Math.floor(convert(Number(distance)).from('m').to('mi')) : null;

	// console.log(components);
	const isEnglish = i18n.language == 'en-US' ? true : false;
	return (
		<div className=' relative w-full h-full'>
			<Map
				id='searchratemap'
				preserveDrawingBuffer={true}
				// 初始化展示完整美国地图
				initialViewState={{
					longitude: -96,
					latitude: 37.8,
					zoom: 4,
				}}
				style={{ width: '100%', height: '100%' }}
				mapStyle='mapbox://styles/drayeasyben/clkhj289k003101pw47sdckps'
				mapboxAccessToken={import.meta.env.VITE_MAPBOX_TOKEN}
				// reuseMaps
				onIdle={() => autoFIt()}
				maxBounds={[
					[-172.298804, 4.84616], // Southwest coordinates
					[-48.90097, 70.52138], // Northeast coordinates
				]}
			>
				{startLng && startLat && (
					<>
						<Marker longitude={startLng} latitude={startLat} color='red'>
							{/* <span style={{ color: 'red' }}>intermodal region marker</span> */}
							{start.icon ?? (
								<div className='w-[14px] h-[14px] rounded-[50%] border-[2px] border-solid border-primary-regular bg-[#FFFFFF]' />
							)}
						</Marker>
						<Popup
							offset={[0, -25] as [number, number]}
							anchor='center'
							longitude={startLng}
							latitude={startLat}
							closeButton={false}
							closeOnClick={false}
							closeOnMove={false}
							className='to-city-popup bg-primary-dark-01'
						>
							{start.name}
						</Popup>
					</>
				)}
				{toLng && toLat && (
					<>
						<Point point={{ latitude: toLat, longitude: toLng, name: to.name }} opacity={false} />
					</>
				)}

				{otherPoints?.map((p) => (
					<>
						<Point point={p} opacity />
					</>
				))}

				{routes &&
					routes.map((route, index) => (
						<Source
							key={index}
							id='route'
							type='geojson'
							data={{
								type: 'FeatureCollection',
								features: [
									// @ts-ignore
									{
										type: 'Feature',
										geometry: {
											type: 'LineString',
											coordinates: route.geometry.coordinates,
										},
									},
								],
							}}
						>
							{/* 类型识别错误 ⬇️ */}
							{/* @ts-ignore */}
							<Layer {...layerStyle} />
						</Source>
					))}
			</Map>
			{components?.zoom && <ZoomController />}
			<div className='absolute left-[6px] bottom-[6px] z-10  '>
				{!!distance && (
					<DistanceTag>
						<>
							<span>{`${distanceMiles} ` + t('Miles')}</span>
							{/* {distanceMiles >= 250 && (
							<span>Layover fee could apply. Please confirm with sales.</span>
						)} */}
							{!!start?.special_notice && (
								<span className='ml-[6px]'>
									<Popover
										content={
											<>{(isEnglish ? start?.special_notice : start?.special_notice_cn) || ''}</>
										}
										placement='bottom'
										trigger={'click'}
									>
										<Tooltip title='Special Notice'>
											<InfoCircleOutlined />
										</Tooltip>
									</Popover>
								</span>
							)}
						</>
					</DistanceTag>
				)}
				{!!distance && distanceMiles >= 250 && (
					<>
						{/* <div className='mt-[4px]'></div> */}
						{/* <DistanceTag>
							<>
								<span>{`${distanceMiles} ` + t('Miles')}</span>
								{distanceMiles >= 250 && (
									<>
										<div>Layover fee could apply.</div>
										<div>Please confirm with sales.</div>
									</>
								)}
							</>
						</DistanceTag> */}
					</>
				)}
			</div>
		</div>
	);
});
