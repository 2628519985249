import iconFactory from './icon-factory';

const Svg = () => (
	<svg width='38' height='28' viewBox='0 0 38 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M8.35034 27.3334C6.98381 27.3334 5.82063 26.8604 4.8608 25.9145C3.90097 24.9685 3.42105 23.8001 3.42105 22.409H3.17066C2.48208 22.409 1.89262 22.1638 1.40227 21.6735C0.911923 21.1831 0.666748 20.5937 0.666748 19.9051V3.1706C0.666748 2.50289 0.917139 1.91864 1.41792 1.41786C1.9187 0.917078 2.50295 0.666687 3.17066 0.666687H24.8295C25.5181 0.666687 26.1075 0.911862 26.5979 1.40221C27.0882 1.89256 27.3334 2.48202 27.3334 3.1706V7.63591H31.0893C31.2979 7.63591 31.4857 7.67764 31.6527 7.76111C31.8196 7.84457 31.9657 7.96976 32.0908 8.13669L37.1404 14.8555C37.2239 14.9599 37.2865 15.0746 37.3282 15.1998C37.3699 15.325 37.3908 15.4606 37.3908 15.6067V21.157C37.3908 21.5118 37.2708 21.8091 37.0309 22.0491C36.7909 22.289 36.4936 22.409 36.1388 22.409H34.4278C34.4278 23.8001 33.9496 24.9685 32.993 25.9145C32.0364 26.8604 30.8749 27.3334 29.5084 27.3334C28.1419 27.3334 26.9787 26.8604 26.0189 25.9145C25.059 24.9685 24.5791 23.8001 24.5791 22.409H13.2698C13.2698 23.8001 12.7915 24.9685 11.8349 25.9145C10.8784 26.8604 9.71683 27.3334 8.35034 27.3334ZM8.34541 24.8294C9.01312 24.8294 9.58346 24.593 10.0564 24.12C10.5294 23.647 10.7659 23.0767 10.7659 22.409C10.7659 21.7413 10.5294 21.1709 10.0564 20.698C9.58346 20.225 9.01312 19.9885 8.34541 19.9885C7.6777 19.9885 7.10737 20.225 6.63441 20.698C6.16144 21.1709 5.92496 21.7413 5.92496 22.409C5.92496 23.0767 6.16144 23.647 6.63441 24.12C7.10737 24.593 7.6777 24.8294 8.34541 24.8294ZM29.5035 24.8294C30.1712 24.8294 30.7415 24.593 31.2145 24.12C31.6874 23.647 31.9239 23.0767 31.9239 22.409C31.9239 21.7413 31.6874 21.1709 31.2145 20.698C30.7415 20.225 30.1712 19.9885 29.5035 19.9885C28.8358 19.9885 28.2654 20.225 27.7925 20.698C27.3195 21.1709 27.083 21.7413 27.083 22.409C27.083 23.0767 27.3195 23.647 27.7925 24.12C28.2654 24.593 28.8358 24.8294 29.5035 24.8294ZM27.3334 16.3161H35.0955L30.4633 10.1398H27.3334V16.3161Z'
			fill='url(#paint0_linear_919_18416)'
		/>
		<defs>
			<linearGradient
				id='paint0_linear_919_18416'
				x1='-2.51936'
				y1='14'
				x2='37.3908'
				y2='14'
				gradientUnits='userSpaceOnUse'
			>
				<stop stop-color='#0094FF' />
				<stop offset='1' stop-color='#0165FA' />
			</linearGradient>
		</defs>
	</svg>
);

export default iconFactory(Svg);
