import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { SearchTerminalsProps, SearchTerminalsRes } from './interface';

export const searchTerminals = async (props: SearchTerminalsProps) => {
	return await axios.get<SearchTerminalsRes>(`/terminals/search`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params: props.params,
	});
};
