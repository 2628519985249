import React from 'react';
import DGActiveSvg from '@/assets/dg-active.svg';
import DGSvg from '@/assets/dg.svg';

const DGIcon = ({
	active,
	...props
}: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
	active: boolean;
}) => {
	return <img {...props} src={active ? DGActiveSvg : DGSvg} />;
};

export default DGIcon;
