import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { UpdateQuoteProps, UpdateQuoteRes } from './interface';

export * from './interface';

export const updateQuote = async (props: UpdateQuoteProps) => {
	return await axios.put<UpdateQuoteRes>(`/tl/ltl/quotes/${props.path.id}`, props.data, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
