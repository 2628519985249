import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { PossibleCharge } from '@/interfaces/possible-charges.interface';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

interface ProbabilityPros {
	possibleCharge: PossibleCharge;
}

const NaTooltip = ({ children }) => {
	const { t } = useTranslation();

	return <Tooltip title={t('It depends on actual situation.')}>{children}</Tooltip>;
};

export default observer(({ possibleCharge }: ProbabilityPros) => {
	// @ts-ignore
	const hasProbability = possibleCharge.probability && possibleCharge.probability > 0;
	const text = hasProbability
		? Number(possibleCharge.probability) < 20
			? '<20%'
			: Number(possibleCharge.probability).toFixed(0) + '%'
		: 'NA';

	const Render = !hasProbability ? NaTooltip : Fragment;

	return (
		<div
			className={clsx(
				'mr-[11px]  pl-[9.5px] pr-[9.5px] h-[16px] w-[52px] rounded-[4px] text-[#ffffff] text-[12px] font-[700] text-center',
				hasProbability ? 'bg-[#004A99]' : 'bg-grey-05',
			)}
		>
			<Render>{text}</Render>
		</div>
	);
});
