import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import Close16pxActiveIcon from '@/icons/close-16px-active-icon';
import { SearchOutlined } from '@ant-design/icons';
import { useBoolean } from 'ahooks';
import { Gap } from '@/components/gap';
import { FormState } from '..';

export interface SearchProps {
	value: FormState['search'];
	onChange: (value: FormState['search']) => void;
}

export const Search = ({ value, onChange }: SearchProps) => {
	const { t } = useTranslation();

	const hasValue = value !== '';

	const [isFocused, { setFalse, setTrue }] = useBoolean(false);

	const handleFocus = () => {
		setTrue();
	};

	const handleOnBlur = (value: string) => {
		onChange(value);
		setFalse();
	};

	return (
		<div>
			<div
				className={`box-border p-[0_12px] h-[32px] flex items-center ${
					hasValue ? 'border-0' : 'border-[1px]'
				}  border-solid ${
					hasValue ? 'bg-primary-light-04' : 'border-grey-04'
				}  rounded-[16px] body-4-m ${hasValue ? 'text-primary-regular' : 'text-grey-01'}`}
			>
				<SearchOutlined style={{ fontSize: '16px', color: hasValue ? '#007bff' : 'unset' }} />
				<Gap width='8px' />
				{isFocused ? (
					<Input
						variant='borderless'
						defaultValue={value}
						onBlur={(e) => handleOnBlur(e.target.value)}
						autoFocus
					/>
				) : (
					<>
						<div onClick={handleFocus}>{hasValue ? value : t(`Search`)}</div>
						<Gap width='8px' />
						{hasValue && (
							<Close16pxActiveIcon className='cursor-pointer' onClick={() => onChange('')} />
						)}
					</>
				)}
			</div>
		</div>
	);
};
