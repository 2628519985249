import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { SearchOwnMBLNumbersProps, SearchOwnMBLNumbersRes } from './interface';

export * from './interface';

export const searchOwnMBLNumbers = async (props: SearchOwnMBLNumbersProps) => {
	return await axios.get<SearchOwnMBLNumbersRes>(`/containers/getListForCreateProject`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params: props.params,
	});
};
