export const LIVE_OR_DOPR_EMPTY = 0;
export const LIVE_OR_DOPR_LIVE = 1;
export const LIVE_OR_DOPR_DROP = 2;

export enum LiveOrDrop {
	Empty = undefined,
	Live = 1,
	Drop = 2,
}

export const LIVE_OR_DOPR_MAP = {
	[LIVE_OR_DOPR_EMPTY]: '',
	[LIVE_OR_DOPR_LIVE]: 'Live',
	[LIVE_OR_DOPR_DROP]: 'Drop',
};
