import { useTranslation } from 'react-i18next';
import { useLTLContext } from '@/services/contexts';
import { observer } from 'mobx-react-lite';
import { Gap } from '@/components/gap';

export const ShipmentInfoPickupDate = observer(({ value }: { value?: string }) => {
	const { t } = useTranslation();

	const ltlContext = useLTLContext();

	const date = value ?? ltlContext.stepOneFormValues.pickup_date.format('MM/DD/YYYY');

	return (
		<div className='flex items-center gap-[4px] body-4-r text-grey-01'>
			{t(`Pickup Date`)}
			<Gap width='2px' />
			{date}
		</div>
	);
});
