export const isRampPort = (portOfDischargeId: number, finalPortId: number) => {
	if (!portOfDischargeId || !finalPortId) {
		return false;
	}

	if (portOfDischargeId == finalPortId) {
		return false;
	}

	if (portOfDischargeId != finalPortId) {
		return true;
	}

	return false;
};
