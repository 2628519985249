import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tabs } from 'antd';
import { Invoice } from '@/interfaces/invoice.interface';
import { CloseOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { BillContent } from './bill-content';

interface BillingProps {
	invoices: Invoice[];
}

// TODO
// 1. issue on
// 2. bill 数据
export default ({ invoices }: BillingProps) => {
	const { t } = useTranslation();

	const [isHideNote, setIsHideNote] = useState(false);

	return (
		<div>
			{invoices.length > 1 ? (
				<Tabs
					defaultActiveKey={invoices[0]?.uid}
					items={invoices.map((invoice) => ({
						key: invoice.uid,
						label: invoice.uid,
						children: <BillContent invoice={invoice} />,
					}))}
				/>
			) : (
				invoices.length == 1 && <BillContent invoice={invoices[0]} />
			)}
			{/* note */}
			<div
				className={clsx(
					'relative p-[16px] border-[2px] border-solid border-grey-05 rounded-[6px] bg-grey-06',
					{ hidden: isHideNote },
				)}
			>
				<Button
					type='text'
					icon={<CloseOutlined />}
					onClick={() => setIsHideNote(true)}
					className=' absolute top-[5px] right-[5px] text-grey-01'
				/>
				<div className='body-4-b'>{t('How to pay my bill?')}</div>
				<p className='body-4-r'>
					{t(
						'Please reach out to our customer service team who will provide you with the necessary account details and instructions for making the payment.',
					)}
				</p>
			</div>
		</div>
	);
};
