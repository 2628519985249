import { apiHooks } from '@/hooks/apis';
import useShipmentOrderPath from './use-shipment-order-path';

export const useShipmentOrder = (props?: Record<string, any>) => {
	const { type, id } = useShipmentOrderPath();

	const res =
		type === 'ltl'
			? apiHooks.useGetShipment({
					...(props ?? {}),
					defaultParams: [{ path: { id } }],
					cacheKey: `get-ltl-shipment-${id}`,
			  })
			: apiHooks.useGetFTLShipment({
					...(props ?? {}),
					defaultParams: [{ path: { id } }],
					cacheKey: `get-ftl-shipment-${id}`,
			  });

	return res;
};
