import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { CreateCompanyProps, CreateCompanyRes } from './interface';

export const createCompany = async (props: CreateCompanyProps) => {
	return await axios.post<CreateCompanyRes>(`/agent/companies`, props.data, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
