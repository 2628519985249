export interface ProjectTagProps {
	isActive: boolean;
}

export const ActiveTag = ({ isActive }: ProjectTagProps) => {
	return (
		<span
			className={`box-border h-[22px] w-fit p-[4px_6px] flex items-center border-[1px] border-solid rounded-[16px] body-4-r ${
				isActive
					? 'bg-primary-light-05 text-primary-regular border-primary-light-02'
					: 'text-grey-01 bg-grey-05 border-grey-04'
			}`}
		>
			{isActive ? 'Active' : 'Inactive'}
		</span>
	);
};
