import { useMemo } from 'react';
import dayjs from 'dayjs';
import { dateToLa, today } from '@/utils/today.type';

const weeks = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'] as const;

export interface CalendarProps {
	base: dayjs.Dayjs;
	className?: string;
	headerRender: (props: {
		title: 'SUN' | 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT';
		base: dayjs.Dayjs;
	}) => JSX.Element;
	dateRender: (props: {
		current: dayjs.Dayjs; // 该单元的时间
		base: dayjs.Dayjs; // 当前的时间
		today: dayjs.Dayjs;
		isPre: boolean;
		isToday: boolean;
		isFuture: boolean;
	}) => JSX.Element;
}

const generateDays = (base: dayjs.Dayjs) => {
	const firstDay = base.startOf('month');
	const startDay = firstDay.subtract(firstDay.day(), 'day');
	return new Array(42).fill(0).map((_, i) => startDay.add(i, 'day'));
};

export const Calendar = ({
	base,
	className,
	headerRender: Header,
	dateRender: Render,
}: CalendarProps) => {
	const days = useMemo(() => generateDays(base), [base]);

	const _today = today();
	console.log('today', _today.format('YYYY-MM-DD'));

	return (
		<div
			style={{
				display: 'grid',
				gridTemplateColumns: 'repeat(7, 1fr)',
				gridTemplateRows: '46px repeat(6, 1fr)',
			}}
			className={className}
		>
			{weeks.map((i) => (
				<Header key={i} title={i} base={base} />
			))}
			{days.map((i) => {
				const dateLa = dateToLa(i);
				return (
					<Render
						key={dateLa.format('YYYY-MM-DD')}
						current={dateLa}
						base={base}
						today={_today}
						isPre={dateLa.isBefore(_today, 'day')}
						isToday={dateLa.isSame(_today, 'day')}
						isFuture={dateLa.isAfter(_today, 'day')}
					/>
				);
			})}
		</div>
	);
};
