export interface NeededSearchParam {
	key: string;
	required?: boolean;
}

export const neededSearchParams: NeededSearchParam[] = [
	{
		key: 'intermodal_region_id',
		required: true,
	},
	{
		key: 'to_city_id',
		required: true,
	},
	{
		key: 'cargo_types',
		required: false,
	},
	{
		key: 'return_address_zipcode',
		required: false,
	},
	{
		key: 'weight',
		required: false,
	},
	{
		key: 'weight_unit',
		required: false,
	},
	{
		key: 'cntr_size',
		required: false,
	},
	{
		key: 'is_residential',
		required: false,
	},
	{
		key: 'order_id',
		required: false,
	},
];
